import React from "react";
import Modal from "react-modal";
import closeIcon from "../../assets/icons/closeIcon.svg";
import Button from "../Button/Button";
import { UploadEx } from "../../assets";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

const customStyles = {
  content: {
    width: "520px",
    height: "380px",
    background: "#fff",
    border: "1px solid #ccc",
    // padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

Modal.setAppElement("#root");
const UploadExcelModal = ({
  isOpen,
  onClose,
  accept,
  file,
  setFile,
  onUpload,
  uploadLoader,
  downloadSampleLink,
  downloadFormatLink,
}) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Upload Modal"
      style={customStyles}
    >
      <div className="text-center relative items-center">
        <h1 className="text-lg font-bold">Excel Upload</h1>
        <button
          onClick={onClose}
          className="absolute top-0 right-1 -mt-2 bg-[#EAEAEA] rounded-full p-2 items-center w-9 h-9"
        >
          <img src={closeIcon} alt="Close" className="w-3 h-3 ml-1" />
        </button>
      </div>
      <hr className="border-t border-[#CCC] my-4" />
      <p className="font-semibold text-sm text-center">
        Note: Please do not leave any field empty.
      </p>
      <label htmlFor="fileInput" className="block font-semibold text-sm">
        <div className="border-dashed border-2 border-[#CCC] rounded-md text-center p-6 my-4 bg-[#F9FAFC] cursor-pointer">
          <div className="flex justify-center flex-col">
            <div className="flex justify-center">
              <UploadEx className="w-6" />
            </div>
            <input
              type="file"
              id="fileInput"
              className="hidden"
              accept={accept}
              onChange={handleFileChange}
            />
            Upload .xls file
            <p className="text-[#AAAAAA] text-[13px]">or drag and drop</p>
          </div>
        </div>
      </label>
      <div className="text-sm h-4 font-semibold text-[#AAAAAA] text-ellipsis overflow-hidden ">
        {file && `File Name: ${file?.name}`}
      </div>
      <div className="flex justify-between text-red-650 mt-2 cursor-pointer text-xs font-semibold">
        <Link to={downloadSampleLink} download>
          Download Sample
        </Link>
        {/* <Link to={downloadFormatLink} download>Download Format</Link> */}
      </div>
      {/* <div className="border-b border-[#CCC]"></div> */}
      <div className="text-center mt-4">
        <Button
          label={
            <span className="w-12 text-enter inline-block">
              {uploadLoader ? (
                <CircularProgress sx={{ color: "white" }} size={17} />
              ) : (
                "Upload"
              )}
            </span>
          }
          className="px-5 py-2 disabled:opacity-50"
          disabled={!file}
          onClick={onUpload}
        />
      </div>
    </Modal>
  );
};

export default UploadExcelModal;
