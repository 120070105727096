import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { printCreditNoteInvoice } from '../../../../redux/actions/FinanceActions/BillingManagementActions/ManageCreditNoteAction'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser';
import Loader from '../../../../components/Loader/Loader'

function PrintCreditNoteReciept() {
    const {id} = useParams()
    const dispatch = useDispatch()
    const {loginData} = useSelector(state=>state.loginReducer)
    const {printLoader, invoiceData} = useSelector(state=>state.manageCreditNoteReducer)
  
    useEffect(()=>{
        dispatch(printCreditNoteInvoice({
            societyId: loginData?.default_community?.community_id,
            creditNoteId: [id]
        }))

        const afterprint = (e) => {
            e.preventDefault()
          };
          
          window.addEventListener("afterprint", afterprint);
      
          return () => {
            window.removeEventListener("afterprint", afterprint);
          }
        }, [])
      
      
        // useEffect(() => {
        //   if(!printLoader && invoiceData){
        //     window.print()
        //   }
        // }, [printLoader]);
    return (
    <div>
        {
            printLoader || !invoiceData ?
            <Loader/>
            :
            <>
                <iframe srcDoc={invoiceData} className='w-full h-screen'></iframe>
            </>
        }
    </div>
  )
}

export default PrintCreditNoteReciept