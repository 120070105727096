import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { geSocietyParkingReport, getAppDownloadReports } from "../../../redux/actions/ReportActions/SocietyReportActions";

const headCells = [
    { id: "sNo", label: "Sr. No.", sort: false, width: "60px" },
    { id: "name", label: "Name", sort: true, width: "170px" },
    { id: "email", label: "Email", sort: true, width: "220px" },
    { id: "mobile", label: "Contact no.", sort: true, width: "120px" },
    { id: "flat_no", label: "Flat", sort: true, width: "100px" },
    { id: "user_type", label: "User Type", sort: true, width: "100px" },
    { id: "download", label: "Download Status", sort: true },
];

const PrintAppDownloadReports = () => {
    const [appDownloadReports, setAppDownloadReports] = useState([]);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { loginData } = useSelector((state) => state.loginReducer);
    const { appDownloadReport, loading, error } = useSelector(
        (state) => state.societyReportReducer
    );
    const getTableData = () => {
        const data = {
            societyId: loginData?.default_community?.community_id,
            page: searchParams.get("page") || 1,
            pageLimit: searchParams.get("pageLimit") || 10,
        }
        dispatch(getAppDownloadReports(data));
    };

    useEffect(() => {
        getTableData();
        setAppDownloadReports([]);
        const afterprint = (e) => {
            e.preventDefault();
        };
        window.addEventListener("afterprint", afterprint);
        return () => {
            window.removeEventListener("afterprint", afterprint);
        };
    }, []);

    useEffect(() => {
        if (!appDownloadReports) return;
        if (!loading && appDownloadReports?.length > 0) {
            setTimeout(function () {
                window.print();
            }, 2000);
        }
    }, [appDownloadReports]);

    useEffect(() => {
        if (appDownloadReport?.appDownloadReports?.length == 0) return;
        const data = appDownloadReport?.appDownloadReports?.map((item, index) => ({
            sNo: (((appDownloadReport?.current_page - 1) * appDownloadReport?.page_limit + 1) + index)?.toString()?.padStart(2, "0"),
            id: index,
            name: item?.name || '-',
            email: item?.email || '-',
            mobile: item?.contact_no || '-',
            flat_no: item?.flat_no,
            user_type: item?.user_type,
            download: item?.Download || '-'
        }));
        setAppDownloadReports(data);
    }, [appDownloadReport?.appDownloadReports]);

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                // <>{parse(chequeDepositSlipReport)}</>
                <div className="m-1">
                    {appDownloadReports?.length > 0 && (
                        <table className="table-auto mx-auto border-collapse border border-black break-all">
                            <caption className="caption-top  mt-4 text-center">
                                <h1
                                    style={{
                                        textDecoration: "underline",
                                        fontSize: "22px",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {loginData?.default_community?.community_name}
                                </h1>
                                <div className="text-[11px] ">
                                    (REG No.{loginData?.default_community?.regn_no} Date{" "}
                                    {moment(
                                        new Date(loginData?.default_community?.date_of_regn)
                                    )?.format("DD-MM-YYYY")}
                                    )
                                </div>
                                <div className="text-[13px] mb-5">
                                    {loginData?.default_community?.office_address}
                                </div>
                            </caption>
                            <thead>
                                <tr>
                                    {headCells.length &&
                                        headCells?.map((item) => (
                                            <td
                                                className="text-sm p-1 font-semibold border border-black"
                                                key={item?.id}
                                                style={{ width: item.width || "fit-content" }}
                                            >
                                                {item?.label}
                                            </td>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {appDownloadReports?.map((row, rowIndex) => (
                                    <>
                                        {headCells.length && (
                                            <tr key={rowIndex} className="text-[#222] text-[11px]">
                                                {headCells?.map((item, headIndex) => {
                                                    return (
                                                        <td className={`p-1 border border-black align-top`}>
                                                            {row[item?.id]}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        )}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    )}
                    {!appDownloadReports?.length && !loading && (
                        <div className="text-[11px] my-10 text-center italic">
                            No Records
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PrintAppDownloadReports;
