// Navbar.js
import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/icons/logo.svg";
import settingsicon from "../../assets/icons/settingsicon.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router-dom";
import { HOME_ENDPOINT, CHANGE_PASSWORD_ENDPOINT } from "../../Routing/routes";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/AuthActions/LogoutAction";
import { relogin } from "../../redux/actions/AuthActions/LoginAction";
import Banner from "../Banner/Banner";
import { TextInput } from "../InputFields/TextInput";
import Dropdown2 from "../Dropdown/dropdown2";
import { CircularProgress } from "@mui/material";

const Navbar = () => {
  const logoAltText = "ZipGrid";
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { loading } = useSelector((state) => state.logoutReducer);

  // State to manage the visibility of the logout and society dropdowns
  const [hover, setHover] = useState(false);
  const [logoutDropdownVisible, setLogoutDropdownVisible] = useState(false);
  const [societyDropdownVisible, setSocietyDropdownVisible] = useState(false);
  const [searchValue, setSearchValue] = useState(false);
  useEffect(() => {
    // Event listener to close the dropdowns when clicking outside of them
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSocietyDropdownVisible(false);
      }
    };
    const handleClickOutside2 = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setLogoutDropdownVisible(false);
      }
    };
    // Add the event listener
    document.addEventListener("mousedown", (e) => {
      handleClickOutside(e);
      handleClickOutside2(e);
    });

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", (e) => {
        handleClickOutside(e);
        handleClickOutside2(e);
      });
    };
  }, []);

  const toggleLogoutDropdown = () => {
    setLogoutDropdownVisible(!logoutDropdownVisible);
    setSocietyDropdownVisible(false);
  };

  const toggleSocietyDropdown = () => {
    setSocietyDropdownVisible(!societyDropdownVisible);
    setLogoutDropdownVisible(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    // setLogoutDropdownVisible(false);
  };

  const handleRelogin = ({
    society_id,
    user_type,
    house_unique_id,
    callAPI,
  }) => {
    const data = {
      society_id,
      user_role: user_type,
      house_unique_id,
      ipAddress,
      pwd:loginData?.pwd,
      onSuccessCallback: () => {
        window.location.href = HOME_ENDPOINT;
      },
    };
    if (!callAPI) {
      dispatch(relogin(data));
    }
    setSocietyDropdownVisible(false);
  };
  // const filterValue = (value) =>
  //   searchValue &&
  //   value?.toString().toLowerCase().includes(searchValue.toLowerCase());

  // const filterSearchValue = (value) => {
  //   if (!searchValue || !value) return false;

  //   const newValue = { ...value };
  //   if (value.user_type === "Employee")
  //     return filterValue(value?.community_name) ? newValue : false;

  //   const filteredBlocks = value?.blocks
  //     ?.filter((item) => filterValue(item?.wing_flat))
  //     ?.map((item) => ({
  //       ...item,
  //       label: item?.wing_flat,
  //       value: item?.houseUniqueId,
  //       user_type: value?.user_type,
  //       community_id: value?.community_id,
  //     })); // Filter blocks based on wing_flat value
  //   debugger;
  //   if (filterValue(value?.community_name)) {
  //     return value?.blocks?.length ? newValue : false;
  //   } else {
  //     if (filteredBlocks.length) newValue.blocks = filteredBlocks;
  //     else return false;
  //   }

  //   return newValue;
  // };

  return (
    <header className="bg-white shadow-md min-w-[1280px] mx-auto ">
      {/*  max-w-7xl*/}
      <div className="mx-auto  flex items-center justify-between p-6 lg:px-2">
        {/* Logo */}
        {/* <div className="flex flex-1 w-1/4"> */}
        <button className="-m-1.5 p-1.5">
          <span className="sr-only">{logoAltText}</span>
          <img className="h-6 w-auto" src={logo} alt={logoAltText} />
        </button>
        {/* </div>
        <div className="flex justify-between w-3/4"> */}
        <Banner />

        {/* Desktop Navigation */}
        <div className="flex items-center">
          <div className="relative inline-block text-left " ref={dropdownRef}>
            {/* <button
              onClick={toggleSocietyDropdown}
            >
              {loginData?.default_community?.community_name +
                `${
                  loginData?.default_community?.blocks?.wing_flat
                    ? "- " + loginData?.default_community?.blocks?.wing_flat
                    : ""
                }`}
              <span aria-hidden="true"></span>
            </button> */}

            {loginData?.user_type !== "Society" &&
            loginData?.all_community_details?.length > 0 ? (
              <Dropdown2
                options={loginData?.all_community_details?.map(
                  (item, index) => {
                    if (item && item?.user_type === "Employee") {
                      return {
                        ...item,
                        label: item?.community_name,
                        value: item?.community_id,
                      };
                    } else if (item) {
                      return {
                        label: item?.community_name,
                        value: item?.community_id,
                        options: item?.blocks?.map((item2) => ({
                          ...item2, // Spread the existing properties of item2
                          label: item2?.wing_flat || "", // Set the label property, assuming `block_name` exists
                          value: item2?.houseUniqueId || "", // Set the value property, assuming `block_id` exists
                          user_type: item?.user_type,
                          community_id: item?.community_id,
                        })),
                      };
                    }
                  }
                )}
                placeholder="Select Level"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  let result =
                    selectedItem?.user_type === "Employee"
                      ? {
                          society_id: selectedItem?.community_id,
                          user_type: selectedItem?.user_type,
                          house_unique_id: 0,
                          callAPI:
                            loginData?.default_community?.community_id ===
                              selectedItem.community_id &&
                            loginData?.user_type === "Employee",
                        }
                      : selectedItem?.user_type !== "Soceity" && {
                          society_id: selectedItem?.community_id,
                          user_type: selectedItem?.user_type,
                          house_unique_id: selectedItem?.houseUniqueId,
                          callAPI:
                            loginData?.default_community?.blocks
                              ?.houseUniqueId === selectedItem?.houseUniqueId,
                        };
                  handleRelogin(result);
                }}
                value={
                  loginData?.user_type === "Employee"
                    ? {
                        value: loginData?.default_community?.community_id,
                        label: loginData?.default_community?.community_name,
                      }
                    : loginData?.user_type !== "Society" && {
                        label: loginData?.default_community?.blocks?.wing_flat,
                        value:
                          loginData?.default_community?.blocks?.houseUniqueId,
                      }
                }
              />
            ) : (
              <div className="!text-sm font-semibold leading-6 !text-[#888888]">
                {loginData?.default_community?.community_name +
                  (loginData?.default_community?.blocks?.wing_flat
                    ? "- " + loginData?.default_community?.blocks?.wing_flat
                    : "")}
              </div>
            )}

            {/* {loginData?.user_type === "Employee" && societyDropdownVisible && (
              <div className="origin-top-right absolute right-0 z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                {loginData?.all_community_details?.map((community) => (
                  <div key={community?.community_id}>
                    <div className="text-sm text-[#222]">
                      <button
                        onClick={() =>
                          handleRelogin(
                            community?.community_id,
                            community?.user_type,
                            0,
                            loginData?.default_community?.community_id ===
                              community.community_id
                          )
                        }
                        className={`block px-2 py-1 text-sm text-[#222] w-full ${
                          loginData?.default_community?.community_id ===
                          community.community_id
                            ? "bg-red-650 text-white"
                            : "hover:bg-red-650 hover:text-white"
                        }
                           border-b border-[#DDD] ring-1 ring-black ring-opacity-5`}
                        type="button"
                      >
                        {community.community_name}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )} */}
          </div>
          <div className="border-l h-6 mx-3 border-[#CCC]"></div>
          <div className="relative inline-block text-left">
            <button onClick={toggleLogoutDropdown}>
              <img src={settingsicon} alt="settings" className="mr-4" />
            </button>
            {/* Logout Dropdown */}
            {logoutDropdownVisible && (
              <>
                <div
                  className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white border border-[#CCC]  ring-1 ring-black ring-opacity-5 overflow-hidden"
                  ref={dropdownRef2}
                >
                  <button
                    className=" text-start border-[#CCC] border-b  block px-4 py-2 text-sm text-[#222] w-full hover:bg-red-650 hover:text-white"
                    onClick={() => navigate(`${CHANGE_PASSWORD_ENDPOINT}`)}
                    type="button"
                  >
                    <LockOpenIcon className="mr-2" />
                    Change Password
                  </button>
                  <button
                    className=" text-start   block px-4 py-2 text-sm text-[#222] w-full hover:bg-red-650 hover:text-white"
                    onClick={handleLogout}
                    type="button"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    {loading
                      ? <div className=" flex justify-center items-center w-f">
                        <CircularProgress sx={{ color: hover ? "white" : "#e63946" }} size={17} />
                      </div>
                      : <>
                        <LogoutIcon className="mr-2" />
                        Logout
                      </>
                    }
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </header>
  );
};

export default Navbar;
