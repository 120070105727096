import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import { logOut } from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import { errorMsg } from '../../../../consts/responseMessages';

export async function getSentSmsHistoryListReq(action) {
    const Params = {
        society_id: action?.data?.society_id,
        module_title: action?.data?.module,
        from_date: action?.data?.from_date,
        to_date: action?.data?.to_date,
        page_no: action?.data?.page,
        page_limit: action?.data?.pageLimit,
    }
    return axiosInstance
        .get(
            `${apiEndpoints.GET_SENT_SMS_HISTORY_API}`, { params: Params }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}

export async function getSmsModuleListReq(action) {
    const Params = {
        society_id: action?.data?.society_id,
    }
    return axiosInstance
        .get(
            `${apiEndpoints.GET_SMS_MODULE_API}`, { params: Params }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}

export async function getSentSmsHistoryDetailsReq(action) {
    const Params = {
        society_id: action?.data?.society_id,
        min_id: action?.data?.min_id,
        max_id: action?.data?.max_id,
    }
    return axiosInstance
        .get(
            `${apiEndpoints.GET_SENT_SMS_HISTORY_DETAILS_API}`, { params: Params }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}

export async function downloadSentSmsHistoryReq(action) {
    const Params = {
        society_id: action?.data?.society_id,
        module_title: action?.data?.module,
        from_date: action?.data?.from_date,
        to_date: action?.data?.to_date,
    }
    return axiosInstance
        .get(
            `${apiEndpoints.DOWNLOAD_SENT_SMS_HISTORY_XL_API}`,
            { params: Params, responseType: "arraybuffer" },
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}