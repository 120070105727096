import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InvoiceListTable from "./InvoiceListTable";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import Search from "../../../../components/SearchBox/Search";
import CreateNewInvoiceModal from "./CreateNewInoiceModal";
import {
  getInvoiceTypeList,
  deleteInvoiceType,
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import Loader from "../../../../components/Loader/Loader";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../Routing/permissions";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "50px" },
  { id: "dayBook", label: "Day Book", sort: false, width: "100px" },
  { id: "invoiceTitle", label: "Invoice Title", sort: false, width: "100px" },
  { id: "title", label: "Title/Category", sort: true, width: "90px" },
  { id: "frequency.", label: "Frequency", sort: true, width: "100px" },
  { id: "action", label: "Action", sort: true, width: "90px" },
];

const rows = [
  {
    id: "1",
    sNo: "01",
    invoiceTitle: "Maintainence Invoice",
    //title: "NOC for Transfer",
    frequency: "",
  },
];
const frequency = [
  {
    value: "-1",
    label: "Daily",
  },
  {
    value: "1",
    label: "Monthly",
  },
  {
    value: "2",
    label: "Bi-Monthly",
  },
  {
    value: "3",
    label: "Quarterly",
  },
  {
    value: "6",
    label: "Semi-Anually",
  },
  {
    value: "12",
    label: "Anually",
  },
];
const category = [
  {
    label: "Billing",
    value: "BILLING",
  },
];
const InvoiceList = () => {
  const [invoiceList, setInvoicelist] = useState();
  const dispatch = useDispatch();
  const [typeList, setTypelist] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setdeleteId] = useState("");
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, updateLoader, typeLists, deleteLoader } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  useEffect(() => {
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);
  useEffect(() => {
    if (typeLists) {
      setTypelist(typeLists);
    }
  }, [typeLists]);
  const handleCreateNewListClick = () => {
    setInvoicelist(true);
  };
  const handleDeleteClick = (id) => {
    setShowDeleteModal(true);
    setdeleteId(id);
  };
  const handleOnCancel = () => {
    setShowDeleteModal(false);
    setdeleteId("");
  };
  const confirmDelete = () => {
    dispatch(
      deleteInvoiceType({
        societyId: loginData?.default_community?.community_id,
        typeId: deleteId,

        onSuccessCallback: () => {
          setShowDeleteModal(false);
          dispatch(
            getInvoiceTypeList({
              societyId: loginData?.default_community?.community_id,
            })
          );
        },
      })
    );
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Invoice Types List">
          {getPermission(loginData, [permissions.ADD_INVOICE_TYPE]) && (
            <div className="flex gap-3">
              {/* <Search /> */}
              <span className="border-r border-[#CCC]"></span>
              <Button
                label="Create New Invoice  Type"
                onClick={handleCreateNewListClick}
              />
            </div>
          )}
        </ListHeader>
        <div className="ml-1">
          {
            <InvoiceListTable
              loading={loading}
              data={typeList}
              columns={headCells}
              checkbox={false}
              pagination={true}
              frequency={frequency}
              category={category}
              handleDeleteClick={handleDeleteClick}
              loginData={loginData}
            ></InvoiceListTable>
          }
        </div>
        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
      <CreateNewInvoiceModal
        isOpen={invoiceList}
        onClose={() => {
          setInvoicelist(false);
        }}
        onCancel={() => {
          setInvoicelist(false);
        }}
        frequency={frequency}
        category={category}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={handleOnCancel}
        loading={deleteLoader}
        onDelete={confirmDelete}
      />
    </Layout>
  );
};

export default InvoiceList;
