import React from "react";
import { useRef } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { useEffect } from "react";
import "./MultiSelect.css";
import { ErrorMessage } from "formik";
import { useState } from "react";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label className={`inline-flex items-center`}>
          <span
            className={`w-[14px] h-[14px] border mt-0.5 mr-2 shrink-0 ${
              props.isSelected
                ? "bg-red-650 border-red-650"
                : "bg-white border-[#CCC]"
            }`}
          >
            {props.isSelected && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            )}
          </span>
          {props.label}
        </label>
      </components.Option>
    </div>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  if (!props.hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }

  const CHIPS_LIMIT = 1;
  const [chips, otherChildren] = children;
  const overflowCounter = props.getValue().length;
  const displayChips = chips?.slice?.(0, CHIPS_LIMIT);

  return (
    <components.ValueContainer {...props}>
      <div className="flex items-center justify-between w-full">
        <div className="min-w-0 shrink">{displayChips}</div>
        {overflowCounter > CHIPS_LIMIT && (
          <span className="px-1 text-white rounded-md bg-red-650 ms-2 shrink-0">
            {overflowCounter > CHIPS_LIMIT &&
              `+ ${overflowCounter - CHIPS_LIMIT}`}
          </span>
        )}
      </div>
      {otherChildren}
    </components.ValueContainer>
  );
};

export const MultiSelect = ({
  options,
  onSelect,
  width,
  height,
  placeholder,
  value,
  defaultValue,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused && "1px solid #aaaaaa",
      minHeight: "38px",
      height: height || "38px",
      borderRadius: "0.375rem",
      fontSize: "12px",
      fontWeight: 600,
    }),
    container: (provided, state) => ({
      ...provided,
      width: width || "200px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "fit-content",
      margin: "0",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "none",
      'input[aria-readonly="true"]': {
        position: "absolute",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWeight: 600,
      color: "black",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 8px ",
      svg: {
        width: "14.5px",
      },
    }),
  };

  return (
    <div>
      <Select
        placeholder={placeholder}
        className="react-select"
        defaultValue={defaultValue}
        classNamePrefix="react-multi-select"
        isMulti={true}
        value={value}
        onChange={onSelect}
        options={options}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          isFocused: "#DDD",
          colors: {
            ...theme.colors,
            primary25: "#DDD",
            primary: "#DDD",
          },
        })}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: Option,
          ValueContainer: CustomValueContainer,
        }}
        isClearable={false}
        isSearchable={false}
      />
    </div>
  );
};

export default MultiSelect;

export const MultiSelectFM = ({
  options,
  onSelect,
  width,
  height,
  placeholder,
  name,
  value,
  defaultValue,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused && "1px solid #aaaaaa",
      minHeight: "38px",
      height: height || "38px",
      borderRadius: "0.375rem",
      fontSize: "12px",
      fontWeight: 600,
    }),
    container: (provided, state) => ({
      ...provided,
      width: width || "200px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "fit-content",
      margin: "0",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "none",
      'input[aria-readonly="true"]': {
        position: "absolute",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWeight: 600,
      color: "black",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 8px ",
      svg: {
        width: "14.5px",
      },
    }),
  };
  return (
    <div>
      <Select
        placeholder={placeholder}
        className="react-select"
        classNamePrefix="react-multi-select"
        isMulti={true}
        isSearchable={true}
        defaultValue={defaultValue}
        value={value}
        onChange={onSelect}
        options={options}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          isFocused: "#DDD",
          colors: {
            ...theme.colors,
            primary25: "#DDD",
            primary: "#DDD",
          },
        })}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: Option,
          ValueContainer: CustomValueContainer,
        }}
        isClearable={false}
        //isSearchable={false}
      />
      <div className={`text-red-500 text-xs italic h-[16px] w-[${width}]`}>
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};
export const MultiSelectFM2 = ({
  options,
  onSelect,
  width,
  height,
  placeholder,
  name,
  value,
  defaultValue,
  loading = false,
}) => {
  // ================
  const valueRef = useRef(value);
  valueRef.current = value;

  const selectAllOption = { value: "<SELECT_ALL>", label: "All Items" };

  const isSelectAllSelected = () => valueRef.current.length === options.length;

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => (!loading ? [selectAllOption, ...options] : []);
  const getValue = () =>
    !loading ? (isSelectAllSelected() ? [selectAllOption] : value) : [];

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option.value === selectAllOption.value) {
      onSelect(options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      onSelect([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      onSelect(
        options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      onSelect(newValue || [], actionMeta);
    }
  };

  // ============

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused && "1px solid #aaaaaa",
      minHeight: "38px",
      height: height || "38px",
      borderRadius: "0.375rem",
      fontSize: "12px",
      fontWeight: 600,
    }),
    container: (provided, state) => ({
      ...provided,
      width: width || "200px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "fit-content",
      margin: "0",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "none",
      'input[aria-readonly="true"]': {
        position: "absolute",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWeight: 600,
      color: "black",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 8px ",
      svg: {
        width: "14.5px",
      },
    }),
  };
  return (
    <div>
      <AsyncSelect
        isOptionSelected={isOptionSelected}
        placeholder={placeholder}
        className="react-select"
        classNamePrefix="react-multi-select"
        isMulti={true}
        defaultValue={defaultValue}
        defaultOptions={getOptions()}
        value={getValue()}
        onChange={onChange}
        isLoading={loading}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          isFocused: "#DDD",
          colors: {
            ...theme.colors,
            primary25: "#DDD",
            primary: "#DDD",
          },
        })}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: Option,
          ValueContainer: CustomValueContainer,
        }}
        isClearable={false}
        isSearchable={true}
      />
      <div className={`text-red-500 text-xs italic h-[16px] w-[${width}]`}>
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};
export const MultiSelectFM3 = ({
  options = [],
  onSelect,
  width,
  height,
  placeholder,
  name,
  value = [],
  defaultValue = [],
  loading = false,
}) => {
  const valueRef = useRef(Array.isArray(value) ? value : []);
  valueRef.current = Array.isArray(value) ? value : [];
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    console.log("Current Value:", value); // Debugging log
  }, [value]);
  const selectAllOption = { value: "<SELECT_ALL>", label: "All Items" };

  const isSelectAllSelected = () => valueRef.current.length === options?.length;
  const isOptionSelected = (option) =>
    Array.isArray(valueRef.current) &&
    (valueRef.current.some((item) => item && item.value === option.value) ||
      isSelectAllSelected());
  const getOptions = () => (!loading ? [selectAllOption, ...options] : []);
  const getValue = () =>
    !loading ? (isSelectAllSelected() ? [selectAllOption] : value) : [];
  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && searchValue && option.value === selectAllOption.value) {
      const filteredOptions =
        options.filter((opt) =>
          opt.label && opt.label.toLowerCase().includes(searchValue)
        );

      onSelect(filteredOptions, actionMeta);
      setSearchValue("");
    } else if (action === "select-option" && option.value === selectAllOption.value) {
      onSelect(options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      onSelect([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      onSelect(
        options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      onSelect(newValue || [], actionMeta);
    }
  };
  const loadOptions = (inputValue, callback) => {
    const searchValue = inputValue ? inputValue.toLowerCase() : "";
    setSearchValue(searchValue);
    setTimeout(() => {
      const filteredOptions = options.filter((option) => {
        if (!option.label) {
          console.warn('Option with null or undefined label:', option);
          return false;
        }
        return option.label.toLowerCase().includes(searchValue);
      });
      const finalOptions = [selectAllOption, ...filteredOptions];
      callback(finalOptions);
    }, 1000);
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused && "1px solid #aaaaaa",
      minHeight: "38px",
      height: height || "38px",
      borderRadius: "0.375rem",
      fontSize: "12px",
      fontWeight: 600,
    }),
    container: (provided, state) => ({
      ...provided,
      width: width || "200px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "fit-content",
      margin: "0",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "none",
      'input[aria-readonly="true"]': {
        position: "absolute",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      fontWeight: 600,
      color: "black",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 8px ",
      svg: {
        width: "14.5px",
      },
    }),
  };
  return (
    <div>
      <AsyncSelect
        isOptionSelected={isOptionSelected}
        placeholder={placeholder}
        className="react-select"
        classNamePrefix="react-multi-select"
        isMulti={true}
        isSearchable={true}
        loadOptions={loadOptions}
        defaultValue={defaultValue}
        defaultOptions={getOptions()}
        value={getValue()}
        onChange={onChange}
        isLoading={loading}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          isFocused: "#DDD",
          colors: {
            ...theme.colors,
            primary25: "#DDD",
            primary: "#DDD",
          },
        })}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: Option,
          ValueContainer: CustomValueContainer,
        }}
        isClearable={false}
      />
      <div className={`text-red-500 text-xs italic h-[16px] w-[${width}]`}>
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};
