import React from "react";
import { DatePickerComponentFM } from "../../../components/DatePicker/DatePicker";
import { DatePickerComponentFM2 } from "../../../components/DatePicker/DatePicker";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { NumberInputFM } from "../../../components/InputFields/NumberInput";
import Button from "../../../components/Button/Button";
import { Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import moment from "moment";

export const CashDetailsForm = ({
  invoiceDetails,
  updateLoader,
  loginData,
  handleSubmit: pHandleSubmit,
}) => {
  const handleSubmit = (values) => {
    pHandleSubmit(values);
  };

  return (
    <div className="flex flex-col justify-between w-full gap-5 mt-2">
      <Formik
        initialValues={{
          amount:
          parseFloat(invoiceDetails?.data?.invoicedetail?.grandtotal?.amount2) || 0,
          paymentdate: new Date(),
          clearDate: new Date(),
          remark: "",
          modeofpayment: "CASH",
        }}
        enableReinitialize
        validationSchema={""}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="pl-2 font-semibold">
                <span>Cash Details</span>
              </div>
              <div className="w-[50%] p-2">
                <div className="flex items-center justify-between">
                  <div className="-mt-4 text-sm">Amount</div>
                  <div className="">
                    <NumberInputFM
                      label=""
                      placeholder="Enter Amount"
                      value={formik.values.amount}
                      onChange={(e) => formik.setFieldValue("amount", e.target.value)}
                      className="w-56"
                      name="amount"
                      disabled={invoiceDetails?.data?.interestMethod === "dynamic" || invoiceDetails?.data?.invoiceStatus === "Proforma"}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Accounting Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM2
                      className="w-56 h-9"
                      name={`paymentdate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `paymentdate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      minDate={new Date(invoiceDetails?.data?.invoice_date) ?? null}
                      defaultValue={
                        formik.values.paymentdate
                          ? new Date(formik.values.paymentdate)
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Clearance Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM2
                      className="w-56 h-9"
                      name={`clearDate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `clearDate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      minDate={new Date(invoiceDetails?.data?.invoice_date) ?? null}
                      defaultValue={
                        formik.values.clearDate
                          ? new Date(formik.values.clearDate)
                          : null
                      }
                    />
                  </div>
                </div>
                <div>
                  <TextAreaFM
                    label="Remarks"
                    placeholder="Enter Remarks"
                    className="w-56 h-20"
                    name="remark"
                    maxLength={300}
                  />
                </div>
                <div className="flex flex-row justify-end">
                  <div className="flex gap-3 w-56 mt-2">
                    <Button
                      label={
                        <span className="inline-block w-16">
                          {updateLoader ? (
                            <CircularProgress sx={{ color: "white" }} size={17} />
                          ) : (
                            "Submit"
                          )}
                        </span>
                      }
                      className="h-8 px-5"
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const EditCashDetailsForm = ({
  invoiceDetails,
  updateLoader,
  loginData,
  handleSubmit: pHandleSubmit,
}) => {
  const handleSubmit = (values) => {
    pHandleSubmit(values);
  };

  return (
    <div className="flex flex-col justify-between w-full gap-5 mt-2">
      <Formik
        initialValues={{
          amount:
            invoiceDetails?.data?.grandtotal || "",
          paymentdate: invoiceDetails?.data?.payment_date || new Date(),
          cleardate: invoiceDetails?.data?.cleardate || new Date(),
          remark: invoiceDetails?.data?.remarks || "",
          interestMethod: invoiceDetails?.data?.interestMethod || "",
          modeofpayment: "CASH",
        }}
        enableReinitialize
        validationSchema={""}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="pl-2 font-semibold">
                <span>Cash Details</span>
              </div>
              <div className="w-[50%] p-2">
                <div className="flex items-center justify-between">
                  <div className="-mt-4 text-sm">Amount</div>
                  <div className="">
                    <TextInputFM
                      disabled={invoiceDetails?.data?.interestMethod === "dynamic"}
                      label=""
                      placeholder="Enter Amount"
                      className="w-56"
                      name="amount"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Accounting Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM2
                      className="w-56 h-9"
                      name={`paymentdate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `paymentdate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      minDate={new Date(invoiceDetails?.data?.invoice_date) ?? null}
                      defaultValue={
                        formik.values.paymentdate
                          ? new Date(formik.values.paymentdate)
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Clearance Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM2
                      className="w-56 h-9"
                      name={`cleardate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `cleardate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      minDate={new Date(invoiceDetails?.data?.invoice_date) ?? null}
                      defaultValue={
                        formik.values.cleardate
                          ? new Date(formik.values.cleardate)
                          : null
                      }
                    />
                  </div>
                </div>
                <div>
                  <TextAreaFM
                    label="Remarks"
                    placeholder="Enter Remarks"
                    className="w-56 h-20"
                    name={`remark`}
                    maxLength={300}
                  />
                </div>
                <div className="ml-[250px] mb-2">
                  <Button
                    label={
                      <span className="inline-block w-16">
                        {updateLoader ? (
                          <CircularProgress sx={{ color: "white" }} size={17} />
                        ) : (
                          "Submit"
                        )}
                      </span>
                    }
                    className="h-8 px-5"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CashDetailsForm;
