import React from "react";
import ButtonG from "../Button/ButtonG";
import Button from "../Button/Button";
import { CircularProgress } from "@mui/material";

const Modal2 = ({ isOpen, onClose, title, children, onCancel, onConfirm, loading, confirmTitle = "Confirm" }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center mt-10 ml-10 z-20">
      <div className="bg-white p-2.5 rounded-lg " style={{
        border: "0.6px solid #00000038",
        width: "25%",
        overflow: "auto",
        maxHeight: "35%",
      }} >
        <h2 className="text-lg font-semibold mb-2 flex items-center justify-center">
          {title}
        </h2>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-5 text-center" style={{
          overflowY: 'auto', // Enable vertical scrolling
          maxHeight: '150px', // Set a max height to enable scrolling if content overflows
        }}>{children}</div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="flex justify-center mt-4 gap-4">
          <Button
            label="Cancel"
            onClick={onCancel}
            className="py-2 font-semibold h-9 bg-red"
            type='button'
          />
          {onConfirm && (
            <Button
              label={
                <span className="w-12 inline-block">
                  {
                    loading ?
                      <CircularProgress sx={{ color: "white" }} size={17} />
                      :
                      <>{confirmTitle}</>
                  }
                </span>
              }
              onClick={onConfirm}
              className="py-2 font-semibold h-9 bg-red "
              type='button'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal2;
