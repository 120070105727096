import {
  ALL_DOCUMENTS_ENDPOINT,
  ALL_FACILITY_ENDPOINT,
  CONTACT_ENDPOINT,
  EMPLOYEE_ENDPOINT,
  HOME_ENDPOINT,
  I_FORM_ENDPOINT,
  LOG_A_COMPLAINT_ENDPOINT,
  MANAGE_INVOICE_CURRENT_ENDPOINT,
  OCCUPANTS_INFO_ENDPOINT,
} from "../../Routing/routes.js";
import {
  HomeIcon,
  EmployeeIcon,
  ContactsIcon,
  DocumentsIcon,
  MembersIcon,
  ManageBillsIcon,
  RegisterIcon,
  BookFacilityIcon,
  ComplaintIcon,
} from "../../assets/index.js";
import * as permissions from "../../Routing/permissions";

export const tabData = [
  {
    route: HOME_ENDPOINT,
    Icon: HomeIcon,
    text: "Home",
    apiImplemented: true,
  },
  {
    route: EMPLOYEE_ENDPOINT,
    Icon: EmployeeIcon,
    text: "Employees",
    permissionPath: [permissions.VIEW_EMPLOYEE_PERMISSION],
    apiImplemented: true,
  },
  {
    route: CONTACT_ENDPOINT,
    // route: "https://neo-archived.zipgrid.com/",
    Icon: ContactsIcon,
    text: "Important Contacts",
    apiImplemented: false,
  },
  {
    route: ALL_DOCUMENTS_ENDPOINT,
    Icon: DocumentsIcon,
    text: "Documents",
    permissionPath: [permissions.VIEW_DOCUMENTS_PERMISSION],
    apiImplemented: true,
  },
];
export const quickData = [
  {
    route: OCCUPANTS_INFO_ENDPOINT,
    Icon: MembersIcon,
    text: "Members List",
    apiImplemented: true,
    permissionPath: [permissions.VIEW_OCCUPANTS_LIST_PERMISSION],

  },
  // {
  //   route: MANAGE_INVOICE_CURRENT_ENDPOINT,
  //   Icon: ManageBillsIcon,
  //   text: "Manage Bills",
  //   apiImplemented: true
  // },
  {
    // route: I_FORM_ENDPOINT,
    route: "https://neo-archived.zipgrid.com/",
    Icon: RegisterIcon,
    text: "I Register",
    apiImplemented: false,
  },
  {
    route: ALL_FACILITY_ENDPOINT,
    Icon: BookFacilityIcon,
    text: "Book Facility",
    apiImplemented: true,
    key: "booking",
    permissionPath: [permissions.VIEW_FACILITY_PERMISSION],
  },
  {
    route: LOG_A_COMPLAINT_ENDPOINT,
    Icon: ComplaintIcon,
    text: "Log A Complaint",
    apiImplemented: true,
    key: "help_desk",

    permissionPath: [permissions.LOG_A_COMPLAINT_PERMISSION],
  },
];
