import React from "react";
// import "./Banner.css";

const Banner = () => {
  return (
    // eslint-disable-next-line jsx-a11y/no-distracting-elements
    // <marquee
    //   behaviour="scroll"
    //   direction="left"
    //   className="mb-3 -mt-3 text-red-650"
    // >
    <div className="moving-banner flex flex-col text-center">
      {/* <span className="align-center">
          We've recently upgraded and you are using an "Early Stage" version of
          Neo...
        </span> */}
      <span className="align-center">
        {/* To access old functionalities, */}
        To access archive console{" "}
        <a
          href="https://neo-archived.zipgrid.com/"
          target="_blank"
          rel="noreferrer"
          className="hover:underline text-red-800"
        >
          click here
        </a>
        {/* and access Archive Console. */}
      </span>
    </div>
    // </marquee>
  );
};

export default Banner;
