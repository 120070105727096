import * as types from '../index.types';

export function getComplaintNatureList(data) {
    return {
        type: types.GET_COMPLAINT_NATURE_LIST,
        data,
    };
}

export function getComplaintTypeList(data) {

    return {
        type: types.GET_COMPLAINT_TYPE_LIST,
        data,
    };
}

export function getWingsList(data) {

    return {
        type: types.GET_COMPLAINT_WINGS_LIST,
        data,
    };
}

export function getFlatsNo(data) {

    return {
        type: types.GET_COMPLAINT_FLATS_LIST,
        data,
    };
}

export function addLogAComplaint(data) {

    return {
        type: types.ADD_LOG_A_COMPLAINT,
        data,
    };
}

export function addLogAComplaintV2(data) {
    return {
        type: types.ADD_LOG_A_COMPLAINT,
        data,
    };
}

export function logAComplaintAuditLog(data) {

    return {
        type: types.ADD_LOG_A_COMPLAINT_AUDIT_LOG,
        data,
    };
}

export function editComplaint(data) {

    return {
        type: types.EDIT_LOG_A_COMPLAINT,
        data,
    };
}

export function editComplaintV2(data) {

    return {
        type: types.EDIT_LOG_A_COMPLAINT,
        data,
    };
}