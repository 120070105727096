import apiEndpoints from "../../../api";
import axiosInstance from "../../../axiosInstance";
import { logOut } from "../../../../utils/helpers/logOut";
import { notify } from "../../../../utils/notification";
import { errorMsg } from "../../../../consts/responseMessages";
import getBrowserDetails from "../../../../utils/helpers/getBrowserDetails";

export async function getComplaintNatureListReq(action) {
  return axiosInstance
    .get(apiEndpoints.COMPLAINT_NATURE_LIST_API)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 501) {
        notify('warning', errors.response.data.message);
      }
      return errors;
    });
}

export async function getComplaintTypeListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.COMPLAINT_TYPE_API}?society_id=${action.data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 501) {
        notify('warning', errors.response.data.message);
      }
      return errors
    });
}

export async function getWingsListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.COMPLAINT_GET_WING_LIST_API}?society_id=${action.data.societyId}`
    )

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 501) {
        notify('warning', errors.response.data.message);
      }
      return errors;
    });
}

export async function getFlatsNoReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.COMPLAINT_GET_FLAT_ID_BY_WING_ID_API}?society_id=${action.data.societyId}&wing_id=${action.data.wingId}${action.data.userType ? `&user_type=${action.data.userType}` : ''}`
    )

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 501) {
        notify('warning', errors.response.data.message);
      }
      return errors;
    });
}

export async function addLogAComplaintReq(action) {
  const BODY = {
    society_id: action.data.societyId,
    house_unique_id: action.data.flatsNo,
    category: action.data.category,
    sub_category_id: action.data.subCategory,
    complaint_nature: action.data.complaintNature,
    complaint_type: action.data.complaintType,
    is_urgent: action.data.isUrgent,
    subject: action.data.subject,
    description: action.data.description,
    attachments: action?.data?.attachments ? [action?.data?.attachments] : [],
    subscriber_id: action.data?.subscriber_id,
    is_tenant: action.data?.isTenant,
    tenant_id: action.data?.tenantId,
    ip: action.data.ipAddress,
    browser_details: getBrowserDetails(),
    session_id: action?.data?.sessionId,
  };
  return axiosInstance
    .post(`${apiEndpoints.ADD_LOG_A_COMPLAINT_API}`, { ...BODY })

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 400) {
        notify('error', errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify('error', errors.response.data.message);
        logOut()
      } else {
        notify('warning', "Something went wrong");
      }
      return errors
    });
}

export async function addLogAComplaintV2Req(action) {
  const BODY = {
    society_id: action.data.societyId,
    house_unique_id: action.data.flatsNo,
    category: action.data.category,
    sub_category_id: action.data.subCategory,
    complaint_nature: action.data.complaintNature,
    complaint_type: action.data.complaintType,
    is_urgent: action.data.isUrgent,
    subject: action.data.subject,
    description: action.data.description,
    attachments: action?.data?.attachments ? [action?.data?.attachments] : [],
    member_id: action.data?.memberId,
    member_type: action.data?.memberType,
    ip: action.data.ipAddress,
    browser_details: getBrowserDetails(),
    session_id: action?.data?.sessionId,
  };
  return axiosInstance
    .post(`${apiEndpoints.ADD_LOG_A_COMPLAINT_V2_API}`, { ...BODY })

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 400) {
        notify('error', errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify('error', errors.response.data.message);
        logOut()
      } else {
        notify('warning', "Something went wrong");
      }
      return errors
    });
}

export async function addLogAComplaintReqAuditLog(action) {

  return axiosInstance
    .get(`${apiEndpoints.ADD_LOG_A_COMPLAINT_AUDIT_LOG_API}?society_id=${action?.data?.societyId}`)

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 400) {
        notify('error', errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify('error', errors.response.data.message);
        logOut()
      } else {
        notify('warning', "Something went wrong");
      }
      return errors
    });
}

export async function editComplaintReq(action) {
  const BODY = {
    id: action.data.id,
    house_unique_id: action.data.flatsNo,
    category: action.data.category,
    sub_category_id: action.data.subCategory,
    complaint_nature: action.data.complaintNature,
    complaint_type: action.data.complaintType,
    is_urgent: action.data.isUrgent,
    description: action.data.description,
    attachments: action?.data?.attachments,
    selected_society_id: action?.data?.societyName,
    selected_subscriber_id: action?.data?.subscriber_id,
    subscriber_id: action?.data?.subscriber_id,
    is_tenant: action.data?.isTenant,
    tenant_id: action.data?.tenantId,
    email_assignee: action?.data?.emailAssignee,
    email_owner: action?.data?.emailOwner,
    ip: action.data.ipAddress,
    browser_details: getBrowserDetails(),
    session_id: action?.data?.sessionId,
    society_id: action.data.societyId,

  };
  return axiosInstance
    .put(`${apiEndpoints.EDIT_COMPLAINT_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 400) {
        notify('error', errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify('error', errors.response.data.message);
        logOut()
      } else {
        notify('warning', "Something went wrong");
      }
      return errors
    });
}

export async function editComplaintV2Req(action) {
  const BODY = {
    id: action.data.id,
    house_unique_id: action.data.flatsNo,
    member_id: action?.data?.memberId,
    member_type: action.data?.memberType,
    complaint_nature: action.data.complaintNature,
    complaint_type: action.data.complaintType,
    category: action.data.category,
    sub_category_id: action.data.subCategory,
    description: action.data.description,
    is_urgent: action.data.isUrgent,
    attachments: action?.data?.attachments,
    email_assignee: action?.data?.emailAssignee,
    email_owner: action?.data?.emailOwner,
    ip: action.data.ipAddress,
    browser_details: getBrowserDetails(),
    session_id: action?.data?.sessionId,
    society_id: action.data.societyId,

  };
  return axiosInstance
    .put(`${apiEndpoints.EDIT_COMPLAINT_V2_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 400) {
        notify('error', errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify('error', errors.response.data.message);
        logOut()
      } else {
        notify('warning', "Something went wrong");
      }
      return errors
    });
}