import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout/Layout";
import ButtonG from "../../../components/Button/ButtonG";
import Button from "../../../components/Button/Button";
import RowOrderingGrid from "../../../components/TableComponent/Taxable";
import {
  getTaxableFormatList,
  deleteTaxableMainHead,
  changeOrderTaxableHead,
} from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import Loader from "../../../components/Loader/Loader";
import AddTaxableMainHeadModal from "../../../components/Modal/AddTaxableMainHeadModal";
import EditTaxableMainHeadModal from "../../../components/Modal/EditTaxableMainHeadModal";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";

const InvoiceSettingTaxableFormat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { typeId } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const [taxableList, setTaxableList] = useState([]);
  const [addModal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState();
  const { loading, updateLoader, taxableLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );

  const handleOrderChange = (headIds) => {
    dispatch(
      changeOrderTaxableHead({
        societyId: loginData?.default_community?.community_id,
        headType: 'main',
        headIds: headIds
      })
    )
  };
  useEffect(() => {
    dispatch(
      getTaxableFormatList({
        societyId: loginData?.default_community?.community_id,
        typeId: typeId,
        // onSuccessCallback: () =>{
        //   setTaxableList(taxableLists); 
        // }
      })
    );
  }, [loginData, typeId,]);
  useEffect(() => {
    if (taxableLists) {
      setTaxableList(taxableLists);
    }
  }, [taxableLists]);
  const handleDelete = (headId) => {
    dispatch(
      deleteTaxableMainHead({
        societyId: loginData?.default_community?.community_id,
        headId: headId,
        onSuccessCallback: () => {
          dispatch(
            getTaxableFormatList({
              societyId: loginData?.default_community?.community_id,
              typeId: typeId,
            })
          );
        },
      })
    );
  };
  return (
    <Layout>
      <div className="flex flex-col w-full gap-2">
        <div className="w-full h-full bg-white border border-b  border-[#CCCCCC] rounded-lg p-2">
          <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="Invoice Grouping Setup"
            onClick={()=>navigate(-1)}
          >
            {getPermission(loginData, [permissions.ADD_HEAD_GROUP_SETTING]) && <Button
              label="Add Head"
              className="w-40 h-8"
              onClick={() => setModal(true)}
            />}
          </ListHeaderBack>
          </div>
          <div>
            {loading || Object.entries(taxableList).length === 0 ? <Loader /> : Object.entries(taxableList).length > 0 ? <RowOrderingGrid
              list={taxableList.taxable_list}
              taxableList={taxableList}
              setTaxableList={setTaxableList}
              handleDelete={handleDelete}
              setEditId={setEditId}
              handleOrderChange={handleOrderChange}
              typeId={typeId}
              setEditModal={setEditModal}
              loginData={loginData}
            >
            </RowOrderingGrid> : <></>}
          </div>
          {(Object.entries(taxableList).length === 0 && !loading) && (
            <div className="text-lg text-[#AAAAAA] mt-10 text-center italic">
              No Records
            </div>
          )}
          <AddTaxableMainHeadModal
            typeId={typeId}
            isOpen={addModal}
            onClose={() => setModal(false)}
            proforma={taxableList.proforma}
          />
           <EditTaxableMainHeadModal
            editId={editId}
            isOpen={editModal}
            typeId={typeId}
            headDeatail={editId ? taxableList.taxable_list.find(item => item.id === editId) : []}
            onClose={() => setEditModal(false)}
            proforma={taxableList.proforma}
            hideTarrifHead={taxableList.hide_tarrif_head == 'Yes' ? true : false}
          />
        </div>
      </div>
    </Layout>
  );
};

export default InvoiceSettingTaxableFormat;
