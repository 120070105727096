import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import Loader from '../../../../components/Loader/Loader'
import parse from 'html-react-parser';
import { getMyInvoiceReceipt } from '../../../../redux/actions/FinanceActions/MyInvoiceActions';

export function PrintInvoiceReceiptMulti() {
    const data = useLocation().state;
    const dispatch = useDispatch()
    const {loginData} = useSelector(state=>state.loginReducer)
    const { printReceipt, printLoader } = useSelector(
        (state) => state.myInvoiceReducer
        
      );
  
    useEffect(()=>{
        dispatch(getMyInvoiceReceipt({
            societyId: loginData?.default_community?.community_id,
            // invid: id,
            receiptid: data.id,
            onSuccessCallback: ()=>{
                setTimeout(function() {
                    window.print()
                }, 5000); 
            }
        }))
    }, [])

    return (
    <div>
        {
            printLoader || !printReceipt ?
            <Loader/>
            :
            <>
                {parse(printReceipt)}
            </>
        }
    </div>
  )
}


function PrintInvoiceReceipt() {
    const {id, receiptid} = useParams()
    const dispatch = useDispatch()
    const {loginData} = useSelector(state=>state.loginReducer)
    const { printReceipt, printLoader } = useSelector(
        (state) => state.myInvoiceReducer
      );
  
    useEffect(()=>{
        dispatch(getMyInvoiceReceipt({
            societyId: loginData?.default_community?.community_id,
            // invid: id,
            receiptid: [receiptid],
            onSuccessCallback: ()=>{
                setTimeout(function() {
                    window.print()
                }, 5000); 
            }
        }))
    }, [])

    return (
    <div>
        {
            printLoader || !printReceipt ?
            <Loader/>
            :
            <>
                {parse(printReceipt)} 
            </>
        }
    </div>
  )
}

export default PrintInvoiceReceipt