import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { OCCUPANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import FlatDetails from "./EditFlatDetailsForm";
import EditMemberDetailsForm from "./EditMemberDetailsForm";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { Form, Formik } from "formik";
import { EditOccupantsValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { useDispatch, useSelector } from "react-redux";
import { getOccupantDetails, updateOccupantDetails } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import countries from "../../../../consts/countries";
import { classMemberOptions, genderOptions } from "../../../../consts/DropDownOptions";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import { CircularProgress } from "@mui/material";

const EditMemberDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, userId } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const { occupantDetail, loading, updateLoader } = useSelector(
    (state) => state.occupantInfoReducer
  );

  const handleCancelClick = () => {
    navigate(OCCUPANTS_INFO_ENDPOINT);
  };

  const handleSaveClick = async (values, { resetForm, initialValues }) => {
    dispatch(updateOccupantDetails({
      ...values, 
      societyId: loginData?.default_community?.community_id, 
      sessionId: loginData?.session_id, 
      ipAddress,
      onSuccessCallback: ()=>{
        navigate(OCCUPANTS_INFO_ENDPOINT)
      }
    }))
  };

  useEffect(() => {
    dispatch(
      getOccupantDetails({
        subscribeId: id,
        societyId: loginData?.default_community?.community_id,
        userType: loginData?.user_type,
        userId: userId,
      })
    );
  }, []);
  return (
    <Layout>
      <Formik
        initialValues={{
          subscribeId: occupantDetail?.user_detail?.subscribe_id,
          prefix: occupantDetail?.user_detail?.prefix,
          firstName: occupantDetail?.user_detail?.f_name || "",
          middleName: occupantDetail?.user_detail?.m_name || "",
          lastName: occupantDetail?.user_detail?.l_name || "",
          classMemberId: classMemberOptions?.find(item=> item.label=== occupantDetail?.user_detail?.class_of_member)?.value,
          classMember: occupantDetail?.user_detail?.class_of_member,
          titleId: occupantDetail?.user_detail?.title_id,
          title: occupantDetail?.user_detail?.subscriber_title || '',
          gender: genderOptions?.find(item => item.label === occupantDetail?.user_detail?.gender)?.value,
          mobileCountryCode: occupantDetail?.user_detail?.mobile_country_code?.replace('+', ''),
          countryISO: countries?.find(item=> item.phonecode?.replace('+','') === occupantDetail?.user_detail?.mobile_country_code?.replace('+', ''))?.iso,
          countryId: countries?.find(item=> item.phonecode?.replace('+','') === occupantDetail?.user_detail?.mobile_country_code?.replace('+', ''))?.id,
          mobileNo: occupantDetail?.user_detail?.mobile_number || "",
          secondaryMobileNo: occupantDetail?.user_detail?.alternate_number || "",
          mobileVisible: occupantDetail?.user_detail?.number_public || "No",
          sendeSMSOnAlternateNo: occupantDetail?.user_detail?.alt_num_send_sms || "No",
          landlineNo: occupantDetail?.user_detail?.landline_office || "",
          primaryEmail: occupantDetail?.user_detail?.subscriber_pri_email || "",
          secondaryEmail: occupantDetail?.user_detail?.subscriber_sec_email || "",
          emailVisible: occupantDetail?.user_detail?.email_public || 'No',
          loginAccess: occupantDetail?.user_detail?.email_set_login || 0,
          sendeSMSOnAlternateEmail: occupantDetail?.user_detail?.send_email_on_sec_email || "No",
          adharCardNo: occupantDetail?.user_detail?.aadhar_uid_no || "",
          adharFile:  occupantDetail?.user_detail?.aadhar_file || '',
          panCardNo: occupantDetail?.user_detail?.pancard_no || "",
          gstRegistrationNumber:
            occupantDetail?.user_detail?.gst_registration_no || "",
          showInBill: occupantDetail?.user_detail?.gst_show_in_bill || 0,
          dob:  validateDate(occupantDetail?.user_detail?.dob) ? occupantDetail?.user_detail?.dob: "",
          anniversaryDate:
            validateDate(occupantDetail?.user_detail?.anniversary_date)
            ? occupantDetail?.user_detail?.anniversary_date
            : "",
          profilePic:  occupantDetail?.user_detail?.profile_image || '',

          flats: occupantDetail?.flat_data?.map((item)=>({
            flatId: item?.flat_id,
            wingNumber: item?.wing_no || '-',
            floorNo: item?.floor_no || '-',
            flatNo: item?.flat_no || '-',
            billingName: item?.billing_name || '',
            gstNo: item?.gst_no || "",
            customFlatNo: item?.custom_flat_no || "",
            mainMember: item?.main_member_name || '-',
            jointMember: item?.joint_member_name || '-',
            buildUpArea: item?.area || '-',
            liveHereOrNot: item?.lives_here || "No",
            photoSubmitted: item?.photo_id_submitted || "No",
            dateOfpayment: validateDate(item?.date_of_entrance_fee)
              ? item?.date_of_entrance_fee
              : "",
            dateOfMembership: validateDate(item?.date_of_membership)
              ? item?.date_of_membership
              : "",   
            dateofAdmission: validateDate(item?.date_of_admission)
              ? item?.date_of_admission
              : "",  
            camStartDate: validateDate(item?.cam_start_date)
              ? item?.cam_start_date
              : "",  
              age: item?.age_on_date_of_admission || "",
              landlineNumber: item?.landline_resi || '',
              profession: item?.profession || '',
              intercomNo: item?.intercom_no || '',
              fullAddress: item?.flat_address || '',
              description: item?.flat_desc || '',
              noOfShareCertificate: item?.no_of_share_certificate || '',
              shareCertificateNo: item?.share_cert_no || '',
              electricMeter: item?.electric_meter || '',
              saleDeedNo: item?.sale_deed_no || '',
              memberSecurityDeposit: item?.member_deposit || '',
              buildSecurityDeposit: item?.builder_deposit || '',
              noOfNominee: item?.no_of_nominee?.toString()?.padStart(2, '0'),
              noOfFamilymem: item?.family_count?.toString()?.padStart(2, '0'),
              noOfVehicle: item?.vehicle_count?.toString()?.padStart(2, '0'),
              noOfStaff: item?.staff_count?.toString()?.padStart(2, '0'),
              noOfWaterInlets: item?.no_of_water_inlets?.toString()?.padStart(2, '0'),
              propertyType: item?.property_type || 'residential',
              exemptionApplicable: item?.exemption_applicable,
              isSold: item?.is_sold,
              flatType: item?.flat_type || '',
              billingAddress: item?.billing_address || '',
            })) || [],
        }}
        enableReinitialize
        onSubmit={handleSaveClick}
        validationSchema={EditOccupantsValidation}
      >
        {({ values, dirty, setFieldValue,errors }) => {
          console.log(errors)
          return (
            <Form>
              <div className="flex flex-col w-full gap-2">
                <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
                  <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Edit Member Details">
                      <ButtonG
                        label="Cancel"
                        onClick={handleCancelClick}
                        className="w-20 h-8"
                      />
                      <Button
                        type="submit"
                        className="w-20 h-8"
                        label={
                          <span className="inline-block w-12">
                            {
                              updateLoader ?
                                <CircularProgress sx={{color: "white"}} size={17}/>
                                :
                                'Update'
                              }
                          </span>
                          }
                        disabled={loading || updateLoader}
                      />
                    </ListHeader>
                  </div>
                  <div className="flex gap-4 p-2">
                    <div className="flex flex-col justify-between w-full gap-3">
                      <EditMemberDetailsForm
                        values={values}
                        setFieldValue={setFieldValue}
                        loading={loading || occupantDetail?.user_detail?.subscribe_id !== id}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  {/* { occupantDetail?.user_detail?.subscribe_id +  id} */}
                  <FlatDetails
                    dirty={dirty}
                    subscribeId={id}
                    userId={userId}
                    userDetails={occupantDetail?.user_detail}
                    flatDetails={occupantDetail?.flat_data}
                    loading={loading || occupantDetail?.user_detail?.subscribe_id !== id}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default EditMemberDetails;
