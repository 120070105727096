import * as types from '../../index.types';

export function getSentSmsHistoryList(data) {
    return {
        type: types.GET_SENT_SMS_HISTORY_LIST,
        data
    }
}

export function getSmsModuleList(data) {
    return {
        type: types.GET_SMS_MODULE_LIST,
        data
    }
}

export function getSentSmsHistoryDetails(data) {
    return {
        type: types.GET_SENT_SMS_HISTORY_DETAILS,
        data
    }
}

export function downloadSentSmsHistory(data) {
    return {
        type: types.DOWNLOAD_SENT_SMS_HISTORY,
        data
    }
}