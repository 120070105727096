import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../api";
import axiosInstance from "../../../axiosInstance";
import { logOut } from "../../../../utils/helpers/logOut";
import { errorMsg } from "../../../../consts/responseMessages";
import axiosInstance2 from "../../../axiosInstance2";

export async function getSocietyEmailReportReq(action) {
    return axiosInstance2
        .get(
            `${apiEndpoints.GET_SOCIETY_EMAIL_REPORTS_API}?societyId=${action.data?.societyId}&page=${action.data?.page}&limit=${action.data?.limit}&export=${action.data?.export}&searchContent=${action.data?.search_content}&wingId=${action.data?.compsrchwing}&flatId=${action.data?.compflatid}&status=${action.data?.status1}&searchFromDate=${action.data?.search_from_date}&searchToDate=${action.data?.search_to_date}&moduleType=${action.data?.moduleType}&subModuleType=${action.data?.subModuleType}`,
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function downloadSocietyEmailReportReq(action) {
    return axiosInstance2
        .get(
            `${apiEndpoints.GET_SOCIETY_EMAIL_REPORTS_API}?societyId=${action.data?.societyId}&page=${action.data?.page}&limit=${action.data?.limit}&export=${action.data?.export}&searchContent=${action.data?.search_content}&wingId=${action.data?.compsrchwing}&flatId=${action.data?.compflatid}&status=${action.data?.status1}&searchFromDate=${action.data?.search_from_date}&searchToDate=${action.data?.search_to_date}&moduleType=${action.data?.moduleType}&subModuleType=${action.data?.subModuleType}`,
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}







export async function deleteSocietyReportExcelReq(action) {
    const BODY =
    {
        file_name: action?.data?.download_url
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.DELETE_FINANCE_REPORT_EXCEL_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function getMemberLedgerReportReq(action) {
    const BODY = {
        id: action.data?.societyId,
        typeId: action.data?.invoiceType,
        wingId: action.data?.wingId,
        flatId: action.data?.flatId,
        startDate: action.data?.startDate,
        endDate: action.data?.endDate,
        glCode: action.data?.glCode,
        type: action.data?.type,
        financialYear: action.data?.financialYear,
    }
    return axiosInstance2
        .post(
            `${apiEndpoints.GET_MEMBER_LEDGER_REPORTS_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function getSocietyParkingReportReq(action) {
    return axiosInstance
        .get(
            `${apiEndpoints.GET_SOCIETY_PARKING_REPORTS_API}?society_id=${action.data.societyId
            }&page_no=${action.data.page}&page_limit=${action.data.limit}${action.data.wingId ? `&wing_id=${action.data.wingId}` : ""
            }${action.data.flatId ? `&flat_id=${action.data.flatId}` : ""}${action.data.vehicleType ? `&vehicle_type=${action.data.vehicleType}` : ""
            }${action.data.parkingType ? `&parking_type=${action.data.parkingType}` : ""
            }${action.data.searchText
                ? `&search_text=${encodeURIComponent(action.data.searchText)}`
                : ""
            }`)
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function getAppDownloadReportsReq(action) {
    const BODY = {
        society_id: action.data?.societyId,
        page: action.data?.page,
        limit: action.data?.pageLimit,
    }
    return axiosInstance
        .get(
            `${apiEndpoints.GET_APP_DOWNLOAD_REPORTS_API}`, { params: BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function appDownloadReportsXlReq(action) {
    const BODY = {
        society_id: action.data?.societyId
    }
    return axiosInstance
        .get(
            `${apiEndpoints.APP_DOWNLOAD_REPORTS_XL_API}?society_id=${action.data?.societyId}`,
            {
                responseType: "arraybuffer",
            }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}


export async function getDownloadUploadHistoriesReq(action) {
    
    let url = `${apiEndpoints.GET_DOWNLOAD_UPLAOD_HISTORIES_API}?societyId=${action.data?.societyId}&page=${action.data?.page}&limit=${action.data?.limit}`;
    if (action.data?.moduleType) 
    {
        url += `&moduleType=${action.data?.moduleType}`;
    }
    if(action.data?.subModuleType){
        url += `&subModuleType=${action.data?.subModuleType}`;
    }
    if(action.data?.startDate)
    {
        url += `&fromDate=${action.data?.startDate}`;   
    }
    if(action.data?.endDate)
    {
        url += `&toDate=${action.data?.endDate}`;   
    }
    if(action.data?.status)
    {
        url += `&status=${action.data?.status}`;   
    }
    return axiosInstance2
        .get(
            `${url}`,
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}


export async function downloadNDC(action) {
    return axiosInstance2
        .get(
            `${apiEndpoints.GET_NDC_DETAILS_API}?societyId=${action.data?.societyId}&memberGl=${action.data?.memberGL}&ndcDate=${action.data?.ndcDate}`
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}
