import { ErrorMessage, Field } from "formik";
import { memo } from "react";

export const TextArea = ({ label, placeholder, name, value, onChange, maxLength, className, row}) => (
    <div className="flex justify-between w-full h-full">
      {label ? <span className="mt-2 text-sm">{label}</span> : null}
      <div>
        <div className={`${className} bg-[#F9FAFC] text-sm border border-[#CCC] rounded-md p-2 mb-4`}>
          <textarea
            placeholder={placeholder}
            className="bg-[#F9FAFC] outline-none border-none placeholder-italic h-full w-full text-black not-italic resize-none text-xs font-semibold"
            name={name}
            maxLength={maxLength}
            rows={row || 1}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
  </div>
);

  
export const TextAreaFM = memo(({ label, placeholder, name, className, row, maxLength}) => (
    <div className="flex justify-between w-full mb-1">
      {label ? <span className="mt-2 text-sm">{label}</span> : null}
      <div>
        <div className={`${className} bg-[#F9FAFC] text-sm border border-[#CCC] rounded-md p-2 `}>
          <Field
            as='textarea'
            placeholder={placeholder}
            className="bg-[#F9FAFC] outline-none border-none placeholder-italic h-full w-full text-black not-italic resize-none text-xs font-semibold"
            name={name}
            rows={row || 1}
            maxLength={maxLength}
          />
        </div>
        <div className="text-xs italic text-red-500">
          <ErrorMessage name={name}/>
        </div>
      </div>
    </div>
  ));
  