export const DOMESTIC_STAFF_TYPE = 'domestic'

export const userRole = {
    ADMIN: 'admin',
    USER: 'User',
    ANOTHER_USER_STRING: 'user',
    EMPLOYEE: 'Employee',
    FAMILY: 'Family',
    SOCIETY: "Society",
    DEMO_USER: "Demo_user",
    MEMBER: "Member",
    STAFF: "Staff",
    TENANT: "Tenant",
    GATEKEEPER: "Gatekeeper",
}