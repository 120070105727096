import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import ListHeaderBack from "../../../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getFamilyMemberAuditLogs } from "../../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import Layout from "../../../../Layout/Layout";
import Loader from "../../../../../components/Loader/Loader";

export const BankAuditData = [
    {
        label: "",
        values: [
            { key: "Name", label: "Name", value: "D-Block" },
            { key: "type", label: "Family type", value: "" },
            { key: "age", label: "Age", value: "" },
            { key: "gender", label: "Gender", value: "8" },
            { key: "Relationship", label: "Relationship", value: "8" },
            { key: "Contact No", label: "Contact No", value: "8" },
            { key: "Email", label: "Email", value: "8" },
            { key: "Profession", label: "Profession", value: "8" },
            { key: "Date Of Birth", label: "Date Of Birth", value: "8" },
            { key: "Anniversary Date", label: "Anniversary Date", value: "8" },
            // { key: "Anniversary Date", label: "Anniversary Date", value: "8" },
        ],
    },
];

const Accordion = React.memo(
    ({ data, isOpen, toggleAccordion, label, descr, desc }) => {
        return (
            <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
                <div
                    className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b "
                    onClick={toggleAccordion}
                >
                    <div className="flex items-center">
                        <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
                            {isOpen ? (
                                <FontAwesomeIcon icon={faChevronUp} size="xs" />
                            ) : (
                                <FontAwesomeIcon icon={faChevronDown} size="xs" />
                            )}
                        </button>
                        <div className="text-[#000000] text-sm font-semibold">{label}</div>
                    </div>
                    <div>
                        <div className="text-xs text-right text-[#888]">{desc}</div>
                        <div className="text-xs text-right text-[#888]">{descr}</div>
                    </div>
                </div>
                {isOpen && (
                    <>
                        <div className="flex justify-between w-full text-sm p-2 font-bold">
                            <div className="w-1/2">{' '}</div>
                            <div className="w-1/2">Old Value</div>
                            <div className="w-1/2">New Value</div>
                        </div>
                        {
                            BankAuditData?.[0]?.values.map((item, i0) => (
                                <React.Fragment key={i0}>
                                    <div className="flex justify-between w-full text-sm p-2">
                                        <div className="w-1/2">{item?.label}</div>
                                        {(item.key == "Date Of Birth" || item.key == "Anniversary Date") ?
                                            <>
                                                <div className="w-1/2">{data?.old_data?.[item.key] && data?.old_data?.[item.key] != '0000-00-00' ? moment(data?.old_data?.[item.key]).format('DD-MM-YYYY') : "-"}</div>
                                                <div className="w-1/2">{data?.new_data?.[item.key] && data?.old_data?.[item.key] != '0000-00-00' ? moment(data?.new_data?.[item.key]).format('DD-MM-YYYY') : "-"}</div>
                                            </>
                                            :
                                            <>
                                                <div className="w-1/2">{data?.old_data?.[item.key]?.toString() || '-'}</div>
                                                <div className="w-1/2">{data?.new_data?.[item.key]?.toString() || '-'}</div>
                                            </>
                                        }
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </>
                )}
            </div>
        );
    }
);

const FamilyMemberAuditLogs = () => {
    const navigate = useNavigate();
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const dispatch = useDispatch()
    const { loginData } = useSelector(state => state.loginReducer)
    const { loading, familyMemberAuditLog } = useSelector(state => state.occupantInfoReducer)
    const handleNavigation = (path) => () => {
        navigate(path);
    };

    const toggleAccordion = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        const sendRequest = {
            society_id: loginData?.default_community?.community_id,
        }
        dispatch(getFamilyMemberAuditLogs(sendRequest))
    }, [])

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <ListHeaderBack
                    onClick={handleNavigation(-1)}
                    title="Family Members Audit Logs"
                ></ListHeaderBack>
                <div className="border-b border-[#CCCCCC]"></div>
                <div>
                    {loading && !familyMemberAuditLog ?
                        <Loader />
                        :
                        familyMemberAuditLog &&
                        familyMemberAuditLog?.map((item, index) => {
                            return (
                                <Accordion
                                    label={`Audit ${index + 1} (${item?.audit})`}
                                    key={index}
                                    data={familyMemberAuditLog?.[index]}
                                    isOpen={index === openAccordionIndex}
                                    toggleAccordion={() => toggleAccordion(index)}
                                    // By ${item?.modified_by}
                                    desc={`Modified On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
                                    descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
                                />
                            )
                        }
                        )
                    }
                </div>
            </div>
        </Layout>
    );
};

export default FamilyMemberAuditLogs;
