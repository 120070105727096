import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

function RadioButtonsGroup({ 
  label, 
  options, 
  name, 
  value, 
  onChange, 
  setFieldValue, // Add setFieldValue prop
  flexDirection = 'row',
  accentColor = "#CF001C",
  alignItems="center"
}) {
  const flexContainerStyle = {
    display: "flex",
    flexDirection: flexDirection,
    justifyContent: "space-between",
    alignItems: alignItems,
    fontSize: "13px",
    marginBottom: "-11px",
  };

  const radioStyle = {
    '&.Mui-checked': {
      color: accentColor,
      "& .MuiSvgIcon-root": {
        borderColor: "#AAAAA", 
      },
    },
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    // Call setFieldValue to update Formik
    if (setFieldValue) {
      setFieldValue(name, selectedValue);
    }
    // Also call the original onChange if provided
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControl style={flexContainerStyle}>
      <span>{label}</span>
      <RadioGroup 
        row={flexDirection === 'row'} 
        name={name} 
        onChange={handleChange} 
        value={value}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio sx={radioStyle} size="small" />}
            label={option.label}
            disabled={option.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioButtonsGroup;
