import { call, put, takeLatest } from 'redux-saga/effects'
import { notify } from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/SocietyProfileSetupReqs/SmsCreditReqs'

function* getSmsCreditPackageList(action) {
    try {
        const result = yield call(req.getSmsCreditPackageListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SMS_CREDIT_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SMS_CREDIT_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (error) {

    }
}

function* getSmsCreditOrderHistoryList(action) {
    try {
        const result = yield call(req.getSmsCreditOrderHistoryListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SMS_CREDIT_ORDER_HISTORY_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SMS_CREDIT_ORDER_HISTORY_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (error) {

    }
}

function* getSmsCreditOrderDetails(action) {
    try {
        const result = yield call(req.getSmsCreditOrderDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SMS_CREDIT_ORDER_DETAILS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SMS_CREDIT_ORDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (error) {

    }
}

function* buySmsRequest(action) {
    try {
        const result = yield call(req.buySmsRequestReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.BUY_SMS_CREDIT_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.BUY_SMS_CREDIT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (error) {

    }
}

export default function* SmsCreditSaga() {
    yield takeLatest(types.GET_SMS_CREDIT_LIST, getSmsCreditPackageList);
    yield takeLatest(types.GET_SMS_CREDIT_ORDER_HISTORY_LIST, getSmsCreditOrderHistoryList);
    yield takeLatest(types.GET_SMS_CREDIT_ORDER_DETAILS, getSmsCreditOrderDetails);
    yield takeLatest(types.BUY_SMS_CREDIT, buySmsRequest);

}