import * as types from "../index.types"

export function getSocietyEmailReport(data) {
    return {
        type: types.GET_SOCIETY_EMAIL_REPORTS,
        data
    }
}
export function getMemberLedgerReport(data) {
    return {
        type: types.GET_MEMBER_LEDGER_REPORTS,
        data
    }
}
export function clearMemberLedgerReport(data) {
    return {
        type: types.CLEAR_MEMBER_LEDGER_REPORTS,
    }
}

export function geSocietyParkingReport(data) {
    return {
        type: types.GET_SOCIETY_PARKING_REPORTS,
        data
    }
}

export function downloadSocietyEmailReport(data) {
    return {
        type: types.DOWNLOAD_SOCIETY_EMAIL_REPORTS,
        data
    };
}

export function downloadNDC(data) {
    return {
        type: types.DOWNLOAD_NDC,
        data
    };
}

export function getAppDownloadReports(data) {
    return {
        type: types.GET_APP_DOWNLOAD_REPORTS,
        data
    };
}

export function appDownloadReportsXl(data) {
    return {
        type: types.APP_DOWNLOAD_REPORTS_XL,
        data
    };
}

export function downloadUploadHistories(data) {
    return {
        type: types.GET_DOWNLOAD_UPLOAD_HISTORIES,
        data
    };
}