import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import Loader from "../../../../components/Loader/Loader";
import { printAdvancetNoteInvoice } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction";

function PrintAdvanceBill() {
  const data = useLocation().state;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { printLoader, advanceReciept } = useSelector(state => state.manageAdvanceNoteReducer)

  useEffect(() => {
    if (data)
      dispatch(printAdvancetNoteInvoice({
        societyId: loginData?.default_community?.community_id,
        advanceNoteId: data?.invid,
      }))
  }, []);

  return (
    <div>
      {printLoader || !advanceReciept ? (
        <Loader />
      ) : (
        <>{parse(advanceReciept)}</>
      )}
    </div>
  );
}

export default PrintAdvanceBill;
