import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import ButtonG from "../../../../components/Button/ButtonG";
import Button from "../../../../components/Button/Button";
import { Box, Tab, Tabs } from "@mui/material";
import styled from "@emotion/styled";
import HeaderFooterSettings from "./InvoiceTabs/HeaderFooterSettings";
import FunctionalSettings from "./InvoiceTabs/FunctionalSettings";
import InterestCalculation from "./InvoiceTabs/InterestCalculations";
import GSTSettings from "./InvoiceTabs/GSTSettings";
import DispalyHeadSettings from "./InvoiceTabs/DispalyHeadSettings";
import PaymentOrder from "./InvoiceTabs/PaymentOrder";
import CreditNote from "./InvoiceTabs/CreditNote";
import {
  getInvoiceFunctionalSetting,
  saveInvoiceFunctionalSetting,
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import Loader from "../../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../Routing/permissions";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
const EditInvoiceSetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const [tabValue, setTabValue] = useState(0);
  const [invoiceSettingList, setInvoiceSettingList] = useState([]);
  const [invoiceEditorState, setInvoiceEditorState] = useState("");
  const [receiptEditorState, setReceiptEditorState] = useState("");
  const { loading, updateLoader, invoiceSettings } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    //console.log(id, 'type')
    dispatch(getInvoiceFunctionalSetting({
      societyId: loginData?.default_community?.community_id,
      typeId: id
    }))
  }, []);
  useEffect(() => {
    if (invoiceSettings) {
      setInvoiceSettingList(invoiceSettings)
      if (invoiceSettings?.invoice_notes) {
        setInvoiceEditorState(invoiceSettings?.invoice_notes || "");
      }
      if (invoiceSettings?.receipt_notes) {
        setReceiptEditorState(invoiceSettings?.receipt_notes || "");
      }
    }
  }, [invoiceSettings]);

  const tabData = [
    {
      index: 0,
      label: "Header/Footer Settings",
    },
    {
      index: 1,
      label: "Functional Settings",
    },
    {
      index: 2,
      label: "Interest Calculation",
    },
    {
      index: 3,
      label: "GST Settings",
    },
    {
      index: 4,
      label: "Payment Order",
    },
    // {
    //   index: 5,
    //   label: "Credit Order",
    // },
  ];

  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontSize: "13.5px",
    fontWeight: 600,
    fontFamily: "'Open Sans', sans-serif",
    borderRight: "1px solid #CCC",
    background: "#FDF2F3",

    "&.Mui-selected": {
      color: "#CF001C",
    },
  });
  const handleSaveSettings = () => {
    dispatch(saveInvoiceFunctionalSetting({
      invoiceSetting: invoiceSettingList,
      societyId: loginData?.default_community?.community_id,
      typeId: id,
      onSuccessCallback: () => {
        dispatch(getInvoiceFunctionalSetting({ societyId: loginData?.default_community?.community_id, typeId: id }))
      }
    }
    ))
  }


  const onInvoiceEditorStateChange = (newEditorState) => {
    setInvoiceEditorState(newEditorState);
    setInvoiceSettingList({
      ...invoiceSettingList,
      invoice_notes: newEditorState,
    });
  };
  const onReceiptEditorStateChange = (newEditorState) => {
    setReceiptEditorState(newEditorState);
    setInvoiceSettingList({
      ...invoiceSettingList,
      receipt_notes: newEditorState,
    });
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="Invoice Setting"
            onClick={() => {
              navigate(-1);
            }}
          >
            <div className="flex gap-3">
              <span className="border-l border-[#CCC]"></span>
              {/* <ButtonG label="Reset" className="h-8" /> */}
              {getPermission(loginData, [permissions.EDIT_INVOICE_SETTINGS]) && <Button label={
                <span className="inline-block w-12">
                  {updateLoader ? (
                    <CircularProgress
                      sx={{ color: "white" }}
                      size={17}
                    />
                  ) : ("Save")}
                </span>
              }
                onClick={handleSaveSettings} className="h-8" />}
            </div>
          </ListHeaderBack>
        </div>
        <div>
          <div className="mt-3 border border-[#CCC] rounded-lg overflow-hidden rounded-b-none ">
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#CF001C",
                },
              }}
            >
              {tabData?.map((item, index) =>
                !item?.permissionPath ? (
                  <StyledTab
                    key={index}
                    value={item.index}
                    label={item.label}
                  />
                ) : (
                  //   :
                  //   getPermission(loginData, item?.permissionPath) ?
                  //     <StyledTab key={index} value={item.index} label={item.label} />
                  ""
                )
              )}
            </Tabs>
          </div>
          {loading ?
            <Loader /> : <div>
              <TabPanel value={tabValue} index={0}>
                <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
                  <HeaderFooterSettings
                    setInvoiceSettingList={setInvoiceSettingList}
                    invoiceSettingList={invoiceSettingList}
                    invoiceEditorState={invoiceEditorState}
                    onInvoiceEditorStateChange={onInvoiceEditorStateChange}
                    receiptEditorState={receiptEditorState}
                    onReceiptEditorStateChange={onReceiptEditorStateChange}
                  />
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
                  <FunctionalSettings
                    setInvoiceSettingList={setInvoiceSettingList}
                    invoiceSettingList={invoiceSettingList}
                  />
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
                  <InterestCalculation
                    setInvoiceSettingList={setInvoiceSettingList}
                    invoiceSettingList={invoiceSettingList}
                  />
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
                  {/* <DispalyHeadSettings /> */}
                  <GSTSettings
                    setInvoiceSettingList={setInvoiceSettingList}
                    invoiceSettingList={invoiceSettingList}
                  />
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
                  <PaymentOrder
                    setInvoiceSettingList={setInvoiceSettingList}
                    invoiceSettingList={invoiceSettingList}
                  />
                </div>
              </TabPanel>
              {/* <TabPanel value={tabValue} index={5}>
                <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[150px]">
                  <CreditNote
                    setInvoiceSettingList={setInvoiceSettingList}
                    invoiceSettingList={invoiceSettingList}
                  />
                </div>
              </TabPanel> */}
            </div>}
        </div>
      </div>
    </Layout>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default EditInvoiceSetting;
