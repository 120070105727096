//SOCIETY PROFILE PERMISSION
export const VIEW_SOCIETY_PROFILE_PERMISSION = 'Home.Info.View'
export const EDIT_SOCIETY_PROFILE_PERMISSION = 'Home.Info.Edit'
export const VIEW_SOCIETY_PROFILE_AUDIT_PERMISSION = 'Home.Info.View Audit Log'
export const VIEW_PROFILE_PICTURE_PERMISSION = 'Home.Profile Picture.View'
export const EDIT_PROFILE_PICTURE_PERMISSION = 'Home.Profile Picture.Edit'
export const CHANGE_PASSWORD_PERMISSION = 'Home.Change Password.Change Password'

//SPECIFIC DETAILS PERMISSION
export const EDIT_SPECIFIC_DETAILS_PERMISSION = 'Settings.Specific Details.Edit'
export const VIEW_SPECIFIC_DETAILS_AUDIT_PERMISSION = 'Settings.Specific Details.View Audit Log'

//WINGS AND FLAT PERMISSION
export const ADD_WINGS_PERMISSION = 'Settings.Wing & Flat Details.Add Wings'
export const DELETE_WINGS_PERMISSION = 'Settings.Wing & Flat Details.Delete Wings'
export const VIEW_WINGS_AUDIT_PERMISSION = 'Settings.Wing & Flat Details.View Audit Log'
export const ADD_FLATS_PERMISSION = 'Settings.Wing & Flat Details.Add Flats'
export const DELETE_FLATS_PERMISSION = 'Settings.Wing & Flat Details.Delete Flats'
export const FLAT_EXCEL_UPLOAD_PERMISSION = 'Settings.Wing & Flat Details.Excel Upload'
export const BILL_TARIFF_EXCEL_UPLOAD_PERMISSION = 'Settings.Wing & Flat Details.Excel Upload Bill Tariff Setup'
export const ADD_BILL_TARIFF_PERMISSION = 'Settings.Wing & Flat Details.Add Bill Tariff Setup'
export const DELETE_BILL_TARIFF_PERMISSION = 'Settings.Wing & Flat Details.Delete Bill Tariff Setup'
export const VIEW_BILL_TARIFF_PERMISSION = 'Settings.Wing & Flat Details.View Bill Tariff Setup'
export const VIEW_UTILITY_READING_PERMISSION = 'Billing.Manage Invoices & Receipts.Utility Readings'
export const UPLOAD_UTILITY_READING_PERMISSION = 'Billing.Manage Invoices & Receipts.Utility Readings Bulk Upload'

// GL Mapping
export const UPDATE_GL_MAPPING = 'Settings.GL Mapping.Update GL Mapping'

// Reminder
export const FD_MATURITY_REMINDER = 'Settings.Reminder.FD Maturity Reminder'
export const NOMINEE_REMINDER = 'Settings.Reminder.Nominees Reminder'
export const MEMBER_DUE_REMINDER = 'Settings.Reminder.Member Due Reminder'

// Debit Note Causes
export const VIEW_DEBIT_NOTE_CAUSE = 'Settings.Debit Note Causes.View'
export const UPDATE_DEBIT_NOTE_CAUSE = 'Settings.Debit Note Causes.Update'
export const ADD_DEBIT_NOTE_CAUSE = 'Settings.Debit Note Causes.Add'
export const VIEW_AUDIT_LOG_DEBIT_NOTE_CAUSE = 'Settings.Debit Note Causes.View Audit Log'


//BANK DETAILS PERMISSION
export const ADD_BANK_DETAILS_PERMISSION = 'Settings.Bank Accounts & Details.Add Bank Details'
export const DELETE_BANK_DETAILS_PERMISSION = 'Settings.Bank Accounts & Details.Delete Bank Details'
export const VIEW_BANK_DETAILS_AUDIT_LOG_PERMISSION = 'Settings.Bank Accounts & Details.View Bank Details Audit Log'
export const VIEW_BANK_ACCOUNTS_PERMISSION = 'Settings.Bank Accounts & Details.View Bank Accounts'
export const CREATE_BANK_ACCOUNT_PERMISSION = 'Settings.Bank Accounts & Details.Create Bank Accounts'
export const DELETE_BANK_ACCOUNT_PERMISSION = 'Settings.Bank Accounts & Details.Delete Bank Accounts'
export const CHOOSE_BANK_FOR_PAYMENT_PERMISSION = 'Settings.Bank Accounts & Details.Choose Bank For Payment'//

//OCCUPANTS PERMISSION
export const ADD_OCCUPANTS_PERMISSION = 'Members.Members.Add'
export const EDIT_OCCUPANTS_PERMISSION = 'Members.Members.Edit Members Details'
export const VIEW_OCCUPANTS_PERMISSION = 'Members.Members.View Members Details'
export const DELETE_OCCUPANTS_PERMISSION = 'Members.Members.Delete'
export const CHANGE_OCCPANTS_STATUS_PERMISSION = 'Members.Members.Approve/Disapprove'
export const INVITE_EXISTING_OCCUPANTS_PERMISSION = 'Members.Members.Invite Existing Members'
export const EDIT_OCCUPANT_OWN_PERSONNEL_DETAILS_PERMISSION = 'Members.Members.Edit Personal Details'
export const EDIT_OCCUPANT_OWN_STAFF_DETAILS_PERMISSION = 'Members.Members.Edit Own Staff Details'
export const EDIT_OCCUPANT_OWN_VEHICLE_DETAILS_PERMISSION = 'Members.Members.Edit Own Vehicle Details'
export const EDIT_OCCUPANT_OWN_FAMILY_DETAILS_PERMISSION = 'Members.Members.Edit Own Family Details'
export const EDIT_OCCUPANT_OWN_NOMINEE_DETAILS_PERMISSION = 'Members.Members.Edit Own Nominee Details'
export const VIEW_OCCUPANTS_LIST_PERMISSION = 'Members.Members.View Members List'
export const EDIT_OCCUPANT_STAFF_DETAILS_PERMISSION = 'Members.Members.Edit Staff Details'
export const EDIT_OCCUPANT_VEHICLE_DETAILS_PERMISSION = 'Members.Members.Edit Vehicle Details'
export const EDIT_OCCUPANT_FAMILY_DETAILS_PERMISSION = 'Members.Members.Edit Family Details'
export const EDIT_OCCUPANT_NOMINEE_DETAILS_PERMISSION = 'Members.Members.Edit Nominee Details'
export const EDIT_OCCUPANT_LIVER_HERE_OR_NOT_PERMISSION = 'Members.Members.Edit Lives Here Or Not'
export const EDIT_OCCUPANT_OWN_SHARE_CERTIFICATE_PERMISSION = 'Members.Members.Edit Share Certificate Details'
export const EDIT_OCCUPANT_SHARE_CERTIFICATE_PERMISSION = 'Members.Members.Edit Own Share Certificate Details'
export const VIEW_PERSONNEL_AUDIT_LOGS_PERMISSION = 'Members.Members.View Personal Audit Log'
export const VIEW_MEMBERS_AUDIT_LOGS_PERMISSION = 'Members.Members.View Members Audit Log'
export const VIEW_MEMBER_CONTACT_NO_PERMISSION = 'Members.Members.View Members Contact No'
export const VIEW_MEMBER_EMAIL_PERMISSION = 'Members.Members.View Members Email Id'
export const CHOOSE_MORE_FLATS_PERMISSION = 'Members.Members.Choose More Flat'
export const UPLOAD_FLAT_DOCS_PERMISSION = 'Members.Members.Upload Flat Documents'
export const EDIT_MEMBER_TITLE_PERMISSION = 'Members.Members.Edit Title'
export const DATE_OF_PAYMENT_ENTRANCE_PERMISSION = 'Members.Members.Date Of Payment Entrance/Membership/Admission'
export const EDIT_MEMBER_NAME_PERMISSION = 'Members.Members.Edit Name'

export const ADD_PARKING_TYPE_IN_OCCUPANTS_PERMISSION = 'Members.Members.Add Parking Type'
export const EDIT_PARKING_TYPE_IN_OCCUPANTS_PERMISSION = 'Members.Members.Edit Parking Type'
export const VIEW_PARKING_TYPE_IN_OCCUPANTS_PERMISSION = 'Members.Members.View Parking Type'
export const DELETE_PARKING_TYPE_IN_OCCUPANTS_PERMISSION = 'Members.Members.Delete Parking Type'

export const SEND_INVITE_IN_OCCUPANTS_PERMISSION = 'Members.Members.Send Invite'
export const FAMILY_ROLE_PERMISSION = 'Members.Members.Family Role'
export const SEND_EMAIL_TO_OTHER_MEMBERS_PERMISSION = 'Members.Members.Send Emails to Other Member'

//TENANTS PERMISSION
export const ADD_TENANTS_PERMISSION = 'Members.Tenants.Add'
export const EDIT_TENANTS_PERMISSION = 'Members.Tenants.Edit'
export const VIEW_TENANTS_PERMISSION = 'Members.Tenants.View'
export const DELETE_TENANTS_PERMISSION = 'Members.Tenants.Delete'
export const ACCESS_SETTING_PERMISSION = 'Members.Tenants.Access Setting'
export const EXPIRY_REMINDER_PERMISSION = 'Members.Tenants.Expiry Reminder'
export const VIEW_TENANTS_AUDIT_LOG_PERMISSION = 'Members.Tenants.View Tenant Audit Log'
export const VIEW_ARCHIVE_TENANTS_PERMISSION = 'Members.Tenants.View Archive Tenant'
export const DELETE_ARCHIVE_TENANTS_PERMISSION = 'Members.Tenants.Delete Archive Tenant'
export const PRINT_TENANTS_REPORT_PERMISSION = 'Members.Tenants.Print Tenant Report'
export const APPROVE_TENANTS_PERMISSION = 'Members.Tenants.Approve Tenant'
export const SEND_INVITE_IN_TENANTS_PERMISSION = 'Members.Tenants.Send Invite'
export const RENEW_TENANT_PERMISSION = 'Members.Tenants.Renew Tenant'
export const PAY_ONLINE_RENT_PERMISSION = 'Members.Tenants.Pay Rent Online'
export const ADD_FROM_EXISTING_TENANTS_PERMISSION = 'Other.Other.Add Exisiting Tenants'

// Member Roles & Rights Permission
export const ADD_MEMBER_TITLE = 'Members.Member Title.Add'
export const EDIT_MEMBER_TITLE = 'Members.Member Title.Edit'
export const VIEW_MEMBER_TITLE = 'Members.Member Title.View'
export const DELETE_MEMBER_TITLE = 'Members.Member Title.Delete'
export const STATUS_MEMBER_TITLE = 'Members.Member Title.Active/Inactive'
export const ACCESS_CLONING_MEMBER_TITLE = 'Members.Member Title.Access Cloning'
export const SWITCH_TO_ADMIN_MEMBER_TITLE = 'Members.Member Title.Switch To Admin'


//LEAVE AND LICENSE
export const VIEW_LEAVE_AND_LICENSE_PERMISSION = 'Members.Tenants.View Leave And License'

//VIEW INVITIES
export const VIEW_INVITIES_PERMISSION = 'Members.Members.View Invites'



//MAINTANENCE STAFF AND STAFF PERSONNEL
export const VIEW_STAFF_PERMISSION = 'Directory.Maintenance Staff.View'
export const ADD_STAFF_PERMISSION = 'Directory.Maintenance Staff.Add'
export const EDIT_STAFF_PERMISSION = 'Directory.Maintenance Staff.Edit'
export const DELETE_STAFF_PERMISSION = 'Directory.Maintenance Staff.Delete'
export const VIEW_STAFF_AUDIT_LOGS_PERMISSION = 'Directory.Maintenance Staff.View Staff Audit Log'//
export const VIEW_OWN_FLAT_STAFF_PERMISSION = 'Directory.Maintenance Staff.View Own Flat Staff'//
export const GIVE_STAFF_RATING_PERMISSION = 'Directory.Maintenance Staff.Give Staff Rating'
export const DOWNLOAD_STAFF_ATTENDENCE_REPORT_PERMISSION = 'Directory.Maintenance Staff.Attendence Report'
export const STAFF_ATTENDENCE_PERMISSION = 'Directory.Maintenance Staff.Staff Attendence'//
export const STAFF_APPROVAL_SETTING_PERMISSION = 'Directory.Maintenance Staff.Staff Approval Setting'
export const SHOW_REVIEWER_NAME_IN_STAFF_REVIEWS_PERMISSION = 'Directory.Maintenance Staff.Show Reviewer Name'
export const APPROVE_STAFF_PERMISSION = 'Directory.Maintenance Staff.Approve Staff'//
export const PRINT_STAFF_ID_CARD_PERMISSION = 'Directory.Maintenance Staff.Print Staff Id Card'
export const SEND_STAFF_SMS_CODE_PERMISSION = 'Directory.Maintenance Staff.Send Staff Code SMS'


//AGENCY
export const VIEW_AGENCY_PERMISSION = 'Directory.Agency.View'
export const ADD_AGENCY_PERMISSION = 'Directory.Agency.Add'
export const EDIT_AGENCY_PERMISSION = 'Directory.Agency.Edit'
export const DELETE_AGENCY_PERMISSION = 'Directory.Agency.Delete'

//EMPLOYEE MANAGEMENT
export const VIEW_EMPLOYEE_PERMISSION = 'Home.Employee.View'
export const ADD_EMPLOYEE_PERMISSION = 'Home.Employee.Add'
export const EDIT_EMPLOYEE_PERMISSION = 'Home.Employee.Edit'
export const DELETE_EMPLOYEE_PERMISSION = 'Home.Employee.Delete'
export const ACTIVE_INACTIVE_EMPLOYEE_PERMISSION = 'Home.Employee.Active/Inactive'
export const VIEW_EMPLOYEE_AUDIT_LOGS_PERMISSION = 'Home.Employee.View Employee Audit Log'
export const VIEW_EMPLOYEE_LOGIN_REPORT_PERMISSION = 'Home.Employee.Employee Login Report'

//MANAGING COMMITTEE
export const VIEW_MANAGING_COMMITTEE_PERMISSION = 'Directory.Managing Committee.View'
export const ADD_MANAGING_COMMITTEE_PERMISSION = 'Directory.Managing Committee.Add'
export const EDIT_MANAGING_COMMITTEE_PERMISSION = 'Directory.Managing Committee.Edit'
export const DELETE_MANAGING_COMMITTEE_PERMISSION = 'Directory.Managing Committee.Delete'
export const VIEW_ACCESS_SETTING_MANAGING_COMMITTEE_PERMISSION = 'Directory.Managing Committee.Access Setting'
export const UPDATE_ACCESS_SETTING_MANAGING_COMMITTEE_PERMISSION = 'Directory.Managing Committee.View Access Setting'
export const RESIGN_MANAGING_COMMITTEE_PERMISSION = 'Directory.Managing Committee.Resign'
export const RETIRE_MANAGING_COMMITTEE_PERMISSION = 'Directory.Managing Committee.Retire'
export const VIEW_ARCHIVE_MANAGING_COMMITTEE_MEMBER_PERMISSION = 'Directory.Managing Committee.View Archived Member'
export const VIEW_MANAGING_COMMITTEE_AUDIT_LOGS_PERMISSION = 'Directory.Managing Committee.View Audit Log'
export const VIEW_MANAGING_COMMITTEE_CONTACT_NO_PERMISSION = 'Directory.Managing Committee.View Committee Contact No'
export const VIEW_MANAGING_COMMITTEE_EMAIL_PERMISSION = 'Directory.Managing Committee.View Committee Email Id'

// MEMBER LEDGER
export const VIEW_MEMBER_LEDGER_REPORT_PERMISSION = 'Directory.Member Ledger.View Member Ledger Report'
export const VIEW_ALL_LEDGER_PERMISSION = 'Directory.Member Ledger.View All Ledgers'


//BULK UPLOAD
export const BULK_UPLOAD_TENANTS_PERMISSION = 'Members.Tenants.Bulk upload Tenants'
export const BULK_UPLOAD_STAFF_PERSONNEL_PERMISSION = 'Other.Other.Bulk upload Staff Personnel'
export const BULK_UPLOAD_OPENING_BALANCE_PERMISSION = 'Settings.Invoice Setting.Opening Balance Excel Upload'

//MANAGE EMPLOYEE ROLES
export const VIEW_EMPLOYEE_ROLE_PERMISSION = 'Home.Employee Role.View'
export const ADD_EMPLOYEE_ROLE_PERMISSION = 'Home.Employee Role.Add'
export const EDIT_EMPLOYEE_ROLE_PERMISSION = 'Home.Employee Role.Edit'
export const DELETE_EMPLOYEE_ROLE_PERMISSION = 'Home.Employee Role.Delete'
export const UPDATE_EMPLOYEE_ROLE_STATUS_PERMISSION = 'Home.Employee Role.Active/Inactive'

//SOCIETY FORMS AND BYE LAWS
export const VIEW_SOCIETY_FORMS_AND_BYE_LAWS_PERMISSION = 'Directory.Society Forms & Bye Laws.Society Forms and Bye Laws'

//ACCOUNT
export const ACCOUNT_PERMISSION = 'Accounts.Accounts Section'
export const SINKING_FUNCTION_REPORT_PERMISSION = 'Accounts.Report.Sinking Fund Report'
export const RECEIVALBE_REPORT_PERMISSION = 'Accounts.Report.Receivable Report'
export const INCOME_AND_EXPENDITURE_PERMISSION = 'Accounts.Report.Income And Expenditure Report'
export const EXPENDITURE_REPORT_PERMISSION = 'Accounts.Report.Expenditure Report'
export const BALANCE_SHEET_REPORT_PERMISSION = 'Accounts.Report.Balance Sheet'
export const TRIAL_BALANCE_PERMISSION = 'Accounts.Report.Trial Balance'
export const LEDGER_REPORT_PERMISSION = 'Accounts.Report.Ledger Report'
export const MEMBER_LEDGER_PERMISSION = 'Accounts.Report.Member Ledger'
export const FIXED_DEPOSIT_LIST_PERMISSION = 'Accounts.Report.Fixed Deposit List'
export const FIXED_ASSETS_REPORT_PERMISSION = 'Accounts.Report.Fixed Assets Report'
export const DELETE_SAVE_DRAFT_BRS_PERMISSION = 'Accounts.Brs .Delete or Save Draft Brs'
export const JOURNAL_ENTRY_EDIT_AND_MORE_OPTION_PERMISSION = 'Accounts.Brs .Journal Entry Edit Add More Option'
export const SHOW_MEMBER_ACCOUNT_IN_JOURNAL_ENTRY_PERMISSION = 'Accounts.Brs .Show Member Account In Journal Entry From '

//DESK
export const VIEW_COMPLAINT_DASHBOARD_PERMISSION = 'Complaints & Instructions.Complaint.Complaints Dashboard'
export const ASSIGNMENT_OF_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.Assignment Of Complaint'
export const PRINT_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.Print Complaints'
export const OPEN_CLOSE_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.Open/Closed'
export const LOG_A_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.Log A New Complaint'
export const POST_COMMENT_ON_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.Post Comment'//
export const LOG_A_COMPLAINT_ONBEHALF_OF_PERMISSION = 'Complaints & Instructions.Complaint.Log A Complaint On Behalf Of Members'//
export const VIEW_OPEN_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.View Open Complaints'//
export const VIEW_ALL_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.View All Complaints'//
export const VIEW_CLOSED_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.View Closed Complaints'//
export const MANAGE_OPEN_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.Manage Open Complaints'//
export const MANAGE_ASSIGNED_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.Manage Assigned Complaints'//
export const MANAGE_CLOSED_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.Manage Closed Complaints'//
export const VIEW_AUDIT_OF_COMPLAINT_PERMISSION = 'Complaints & Instructions.Complaint.View Audit Log of Complaints'//

export const VIEW_COMPLAINT_SETTING_PERMISSION = 'Settings.Complaints.Complaints Setting'
export const VIEW_COMPLAINT_ESCALATION_LEVEL_PERMISSION = 'Settings.Complaints.View Complaint Escalation Level'
export const EDIT_COMPLAINT_ESCALATION_LEVEL_PERMISSION = 'Settings.Complaints.Edit Complaint Escalation Level'
export const COMPLAINT_EMAIL_SMS_SETTING_PERMISSION = 'Settings.Complaints.Complaint Email & SMS Setting'
export const COMPLAINT_TYPE_SETTING_PERMISSION = 'Settings.Complaints.Complaint Type Settings'
export const VIEW_COMPLAINT_CATEGORY_PERMISSION = 'Settings.Complaints.View Complaints Category'
export const ADD_COMPLAINT_CATEGORY_PERMISSION = 'Settings.Complaints.Add Complaints Category'
export const EDIT_COMPLAINT_CATEGORY_PERMISSION = 'Settings.Complaints.Edit Complaints Category'
export const DELETE_COMPLAINT_CATEGORY_PERMISSION = 'Settings.Complaints.Delete Complaints Category'

export const VIEW_DOCUMENTS_PERMISSION = 'Documents.Documents.View'
export const VIEW_ONLY_OWN_DOCUMENTS_PERMISSION = 'Documents.Documents.View Only Own Documents'//
export const UPLOAD_DOCUMENTS_PERMISSION = 'Documents.Documents.Upload'
export const DOWNLOAD_DOCUMENTS_PERMISSION = 'Documents.Documents.Download'
export const EDIT_DOCUMENTS_PERMISSION = 'Documents.Documents.Edit'
export const DELETE_DOCUMENTS_PERMISSION = 'Documents.Documents.Delete'
export const CREATE_FOLDER_PERMISSION = 'Documents.Documents.Create Folder'
export const EDIT_FOLDER_PERMISSION = 'Documents.Documents.Edit Folder'//
export const DELETE_FOLDER_PERMISSION = 'Documents.Documents.Delete Folder'//


//VISITOR
export const ADD_VISITOR_PERMISSION = 'Visitor.Visitor Module.Add Visitor'
export const ADD_PURPOSE_PERMISSION = 'Visitor.Visitor Module.Add Purpose'//
export const VIEW_VISITOR_LOG_PERMISSION = 'Visitor.Visitor Module.View Visitor Log'
export const VIEW_VISITOR_BY_SPECIFIC_FLAT_DETAILS_PERMISSION = 'Visitor.Visitor Module.View by Only Specific flat details'//
export const VIEW_FREQUENT_VISITOR_PERMISSION = 'Visitor.Visitor Module.View Frequent Visitors'
export const VIEW_EXPECTED_VISITOR_PERMISSION = 'Visitor.Visitor Module.Expected Visitors'
export const VIEW_MEMBER_VISITOR_PERMISSION = 'Visitor.Visitor Module.Member Visitor'//
export const VIEW_VEHICLE_IN_OUT_PERMISSION = 'Visitor.Visitor Module.Vehicles In / Out'

//Forum
export const CREATE_NOTICE_CIRCULAR_PERMISSION = 'Forum.Notice & circulars.Create Notice'
export const POST_COMMENT_NOTICE_CIRCULAR_PERMISSION = 'Forum.Notice & circulars.Post Comment'
export const PRINT_NOTICE_CIRCULAR_PERMISSION = 'Forum.Notice & circulars.Print Notice'
export const DELETE_NOTICE_CIRCULAR_PERMISSION = 'Forum.Notice & circulars.Delete Notice'
export const VIEW_NOTICE_CIRCULAR_PERMISSION = 'Forum.Notice & circulars.View Notices'
export const VIEW_NOTICE_CIRCULAR_ARCHIVES_PERMISSION = 'Forum.Notice & circulars.View Archives'
export const VIEW_NOTICE_CIRCULAR_DRAFTS_PERMISSION = 'Forum.Notice & circulars.View Drafts'
export const RESEND_NOTICE_CIRCULAR_PERMISSION = 'Forum.Notice & circulars.Resend Notice'
export const EDIT_NOTICE_CIRCULAR_PERMISSION = 'Forum.Notice & circulars.Edit Notice'



//PARKING CHARGES
export const VIEW_PARKING_CHARGES_PERMISSION = 'Settings.Parking charges.View'
export const EDIT_PARKING_CHARGES_PERMISSION = 'Settings.Parking charges.Edit'
export const VIEW_PARKING_CHARGE_AUDIT_PERMISSION = 'Settings.Parking charges.View Audit Log'

//VIEW BOOKING
export const VIEW_BOOKING_PERMISSION = 'Facilities.Booking.View All Booking'
export const VIEW_OWN_BOOKING_PERMISSION = 'Facilities.Booking.View Own Booking'
export const MAKE_BOOKING_PERMISSION = 'Facilities.Booking.Make Booking'
export const VIEW_BOOKING_CALENDER_PERMISSION = 'Facilities.Booking.Booking Calendar'
export const APPROVE_BOOKING_PERMISSION = 'Facilities.Booking.Approve Booking'
export const CANCEL_BOOKING_PERMISSION = 'Facilities.Booking.Cancel Booking'
export const DELETE_BOOKING_PERMISSION = 'Facilities.Booking.Delete Booking'
export const PRINT_BOOKING_INVOICE_PERMISSION = 'Facilities.Booking.Print Invoice'
export const BOOKING_REQUEST_FOR_CANCELLATION_PERMISSION = 'Facilities.Booking.Request For Cancellation'
export const SEND_MESSAGE_TO_ADMINISTRATOR_PERMISSION = 'Facilities.Booking.Send Message To Administrator'
export const MAKE_BOOKING_PAYMENT_PERMISSION = 'Facilities.Booking.Make Payment'
export const MAKE_OWN_BOOKING_PAYMENT_PERMISSION = 'Facilities.Booking.Make Own Payment'
export const PRINT_FACILITY_INVOICE_PERMISSION = 'Facilities.Booking.Print Facility Invoice'// 
export const DELETE_FACILITY_RECIEPT_PERMISSION = 'Facilities.Booking.Delete Facility Receipt'//

//FACILITY
export const VIEW_FACILITY_PERMISSION = 'Facilities.Facility.View'
export const ADD_FACILITY_PERMISSION = 'Facilities.Facility.Add'
export const EDIT_FACILITY_PERMISSION = 'Facilities.Facility.Edit'
export const DELETE_FACILITY_PERMISSION = 'Facilities.Facility.Delete'
export const CHAGE_FACILITY_STATUS_PERMISSION = 'Facilities.Facility.Activate/Deactivate'

export const VIEW_RATE_CHART_PERMISSION = 'Facilities.Rate Chart.View'//
export const ADD_RATE_CHART_PERMISSION = 'Facilities.Rate Chart.Add'
export const EDIT_RATE_CHART_PERMISSION = 'Facilities.Rate Chart.Edit'
export const DELETE_RATE_CHART_PERMISSION = 'Facilities.Rate Chart.Delete'

export const ADD_TIME_SLOT_PERMISSION = 'Facilities.Time Slot.Add'
export const EDIT_TIME_SLOT_PERMISSION = 'Facilities.Time Slot.Edit'
export const DELETE_TIME_SLOT_PERMISSION = 'Facilities.Time Slot.Delete'


//Billing
export const VIEW_INVOICE = 'Billing.Manage Invoices & Receipts.View'; //DONE
export const VIEW_INVOICE_RECEIPT = 'Billing.Manage Invoices & Receipts.View Receipts'; //DONE
export const GENERATE_INVOICE = 'Billing.Manage Invoices & Receipts.Generate Invoice'; //DONE
export const EDIT_INVOICE = 'Billing.Manage Invoices & Receipts.Edit Invoice'; //DONE
export const INVOICE_ADJUSTMENT = 'Billing.Manage Invoices & Receipts.Adjustment'; //DONE
export const INVOICE_CANCEL_VERIFICATION = 'Billing.Manage Invoices & Receipts.Cancel Verification';
export const DELETE_INVOICE = 'Billing.Manage Invoices & Receipts.Delete Invoice'; //DONE
export const EDIT_DRAFT_INVOICE = 'Billing.Manage Invoices & Receipts.Edit drafts'; //DONE
export const DELETE_DRAFT_INVOICE = 'Billing.Manage Invoices & Receipts.Delete drafts'; //DONE
export const DELETED_INVOICE_REPORT = 'Billing.Manage Invoices & Receipts.Deleted Invoice Report';
export const DELETE_RECEIPT = 'Billing.Manage Invoices & Receipts.Deleted Receipt';//DONE
export const DIRECT_ENTRY = 'Billing.Manage Invoices & Receipts.Direct Entry';//done
export const EDIT_PAYMENT = 'Billing.Manage Invoices & Receipts.Edit Payment';//DONE
export const EDIT_RECEIPT = 'Billing.Manage Invoices & Receipts.Edit Receipt'; //DONE
export const HIDE_DETAILED_INVOICE = 'Billing.Manage Invoices & Receipts.Hide Detailed Invoice';
export const HIDE_DETAILED_RECEIPT = 'Billing.Manage Invoices & Receipts.Hide Detailed Receipt';
export const INVOICE_SERVICE_TAX_SETTINGS = 'Billing.Manage Invoices & Receipts.Invoice Service Tax Settings';
export const INVOICE_MAKE_PAYMENT = 'Billing.Manage Invoices & Receipts.Make Payment'; //done
export const POST_ENTRY = 'Billing.Manage Invoices & Receipts.Post Entry';//DONE
export const POST_REMARK = 'Billing.Manage Invoices & Receipts.Post Remarks';//DONE
export const PRINT_INVOICE = 'Billing.Manage Invoices & Receipts.Print Invoice'; //DONE
export const PRINT_RECIEPT = 'Billing.Manage Invoices & Receipts.Print Receipt';//DONE
export const UTILITY_READINGS = 'Billing.Manage Invoices & Receipts.Utility Readings';
export const UTILITY_READINGS_BULK_UPLOAD = 'Billing.Manage Invoices & Receipts.Utility Readings Bulk Upload';
export const VERIFY_REJECT_PAYMENT = 'Billing.Manage Invoices & Receipts.Verify/Reject Payment';//DONE
export const VIEW_DELETED_RECEIPT = 'Billing.Manage Invoices & Receipts.View Deleted Receipts';
export const VIEW_TRANSACIION_HISTORY_INVOICE = 'Billing.Manage Invoices & Receipts.View Transaction History';
export const INVOICE_SEND_SMS = 'Billing.Manage Invoices & Receipts.Send Invoice SMS';//DONE
export const INVOICE_SEND_EMAIL = 'Billing.Manage Invoices & Receipts.Send Invoice Email';//DONE
export const RECIEPT_SEND_SMS = 'Billing.Manage Invoices & Receipts.Send Receipt SMS';//DONE
export const RECIEPT_SEND_EMAIL = 'Billing.Manage Invoices & Receipts.Send Reciept Email';//DONE
export const INVOICE_SYNC = 'Billing.Manage Invoices & Receipts.Invoice Sync';//DONE
export const INVOICE_RECEIPT_SYNC = 'Billing.Manage Invoices & Receipts.Invoice Receipt Sync';//DONE
export const DEBIT_NOTE_SYNC = 'Billing.Manage Invoices & Receipts.Debit Note Sync';//DONE
export const DEBIT_NOTE_RECEIPT_SYNC = 'Billing.Manage Invoices & Receipts.Debit Note Receipt Sync';//DONE
export const CREDIT_SYNC = 'Billing.Manage Invoices & Receipts.Credit Note Sync';//DONE
export const ADVANCE_SYNC = 'Billing.Manage Invoices & Receipts.Advance Sync';//DONE

//Setting 
export const INVOICE_DUE_REMINDER = "Settings.Reminder.Invoice Due Reminder"

// Invoice Settings
export const VIEW_INVOICE_TYPE_LIST = "Settings.Invoice Setting.View Invoice Type"
export const ADD_INVOICE_TYPE = "Settings.Invoice Setting.Add Invoice Type"
export const DELETE_INVOICE_TYPE = "Settings.Invoice Setting.Delete Invoice Type"
export const VIEW_INVOICE_SETTINGS = "Settings.Invoice Setting.View Invoice Settings"
export const EDIT_INVOICE_SETTINGS = "Settings.Invoice Setting.Edit Invoice Settings"
export const VIEW_HEAD_GROUP_SETTING = "Settings.Invoice Setting.View Head Group Settings"
export const ADD_HEAD_GROUP_SETTING = "Settings.Invoice Setting.Add Head Group"
export const EDIT_HEAD_GROUP_SETTING = "Settings.Invoice Setting.Edit Head Group"
export const DELETE_HEAD_GROUP_SETTING = "Settings.Invoice Setting.Delete Head Group"
export const VIEW_SUB_HEAD_GROUP_SETTING = "Settings.Invoice Setting.View Sub Head Group"
export const ADD_SUB_HEAD_GROUP_SETTING = "Settings.Invoice Setting.Add Sub Head Group"
export const DELETE_SUB_HEAD_GROUP_SETTING = "Settings.Invoice Setting.Delete Sub Head Group"
export const ACTIVE_SUB_HEAD_GROUP_SETTING = "Settings.Invoice Setting.Active/Deactive sub Head Group"
export const ADD_DEBIT_CREDIT_HEADS_SETTING = "Settings.Invoice Setting.Add Debit Credit Heads"

export const VIEW_INVOICE_HEAD_SETTINGS = "Settings.Invoice Setting.View Invoice Head Settings"
export const EDIT_INVOICE_HEAD_SETTINGS = "Settings.Invoice Setting.Edit Invoice Head"
export const ADD_MORE_INVOICE_HEAD = "Settings.Invoice Setting.Add More Invoice Head"
export const EDIT_MORE_INVOICE_HEAD = "Settings.Invoice Setting.Edit More Invoice Head"
export const DELETE_MORE_INVOICE_HEAD = "Settings.Invoice Setting.Delete More Invoice Head"

export const VIEW_INVOICE_SERIES_SETTINGS = "Settings.Invoice Setting.View Invoice Series Settings"
export const ADD_INVOICE_SERIES_SETTINGS = "Settings.Invoice Setting.Add Invoice Series"
export const DELETE_INVOICE_SERIES_SETTINGS = "Settings.Invoice Setting.Delete Invoice Series"

export const VIEW_CREDIT_NOTE_SETTINGS = "Settings.Invoice Setting.View Credit Note Settings"
export const EDIT_CREDIT_NOTE_SETTINGS = "Settings.Invoice Setting.Edit Credit Note Settings"

export const VIEW_DEBIT_NOTE_SETTINGS = "Settings.Invoice Setting.View Debit Note Settings"
export const EDIT_DEBIT_NOTE_SETTINGS = "Settings.Invoice Setting.Edit Debit Note Settings"

//Master Traffic
export const VIEW_MASTER_TARIFF_SETTINGS = "Settings.Invoice Setting.View Master Tariff"
export const EDIT_MASTER_TARIFF_SETTINGS = "Settings.Invoice Setting.Edit Master Tariff"

//Bulk Task
export const BULK_TASK_VIEW = 'Billing.Bulk Task.Bulk Task View';
export const BULK_TASK_ADD = 'Billing.Bulk Task.Bulk Task Add';

//Invoice 
export const VIEW_INVOICES = 'Billing.Invoices & Receipts.View Invoices';
export const VIEW_RECEIPTS = 'Billing.Invoices & Receipts.View Receipts';
export const MAKE_PAYMENT = 'Billing.Invoices & Receipts.Make Payment';
//export const EDIT_PAYMENT = 'Billing.Invoices & Receipts.Edit Payment';
export const PRINT_RECEIPT = 'Billing.Invoices & Receipts.Print Receipt';
//export const PRINT_INVOICE = 'Billing.Invoices & Receipts.Print Invoice';
export const HIDE_CHEQUE_PAYMENT = 'Billing.Invoices & Receipts.Hide Cheque Payment';
export const HIDE_CASH_PAYMENT = 'Billing.Invoices & Receipts.Hide Cash Payment';
export const HIDE_NEFT_PAYMENT = 'Billing.Invoices & Receipts.Hide NEFT Payment';
export const HIDE_CREDIT_CARD_PAYMENT = 'Billing.Invoices & Receipts.Hide Credit Card Payment';
export const HIDE_DEBIT_CARD_PAYMENT = 'Billing.Invoices & Receipts.Hide Debit Card Payment';
export const HIDE_NET_BANKING_PAYMENT = 'Billing.Invoices & Receipts.Hide Net Banking Payment';
export const HIDE_PAYTM_PAYMENT = 'Billing.Invoices & Receipts.Hide Paytm Payment';
export const INVOICE_AGING_REPORT = 'Billing.Invoices & Receipts.Invoice Aging Report';

export const VIEW_DEBIT_NOTE = 'Billing.Debit Note.View';
export const VIEW_OWN_DEBIT_NOTE = 'Billing.Debit Note.View Own Debit Note';
export const ADD_DEBIT_NOTE = 'Billing.Debit Note.Add';
export const EDIT_DEBIT_NOTE = 'Billing.Debit Note.Edit';
export const DELETE_DEBIT_NOTE = 'Billing.Debit Note.Delete';
export const VIEW_DRAFT_DEBIT_NOTE = 'Billing.Debit Note.View Drafts';
export const EDIT_DRAFT_DEBIT_NOTE = 'Billing.Debit Note.Edit Drafts';
export const DELETE_DRAFT_DEBIT_NOTE = 'Billing.Debit Note.Delete Drafts';
export const MAKE_PAYMENT_DEBIT_NOTE = 'Billing.Debit Note.Make Payment';
export const ADJUSTMENT_DEBIT_NOTE = 'Billing.Debit Note.Adjustment';
export const VERIFY_POST_DEBIT_NOTE = 'Billing.Debit Note.Verify & Post';
export const HIDE_CHEQUE_PAYMENT_DEBIT_NOTE = 'Billing.Debit Note.Hide Cheque Payment';
export const VIEW_RECEIPT_DEBIT_NOTE = 'Billing.Debit Note.View Receipt';
export const HIDE_CASH_PAYMENT_DEBIT_NOTE = 'Billing.Debit Note.Hide Cash Payment';
export const EDIT_RECEIPT_DEBIT_NOTE = 'Billing.Debit Note.Edit Receipt';
export const HIDE_NEFT_PAYMENT_DEBIT_NOTE = 'Billing.Debit Note.Hide NEFT Payment';
export const DELETE_RECEIPT_DEBIT_NOTE = 'Billing.Debit Note.Delete Receipt';
export const EDIT_DEBIT_NOTE_HEADER_DEBIT_NOTE = 'Billing.Debit Note.Edit Debit Note Header';
export const SEND_DUE_REMINDER_EMAIL_SMS_DEBIT_NOTE = 'Billing.Debit Note.Send Due Reminder Email Sms';
export const RESEND_RECEIPT_EMAIL_SMS_DEBIT_NOTE = 'Billing.Debit Note.Resend Receipt Email Sms';
export const MULTIPLE_PAYMENT_DEBIT_NOTE = 'Billing.Debit Note.Multiple Payment';
export const VIEW_TRANSACIION_HISTORY_DEBIT_NOTE = 'Billing.Debit Note.View Transaction History';
export const HISTORY_DEBIT_NOTE = 'Billing.Debit Note.Debit Notes History';
export const INTEREST_CALCULATION_REPORT_DEBIT_NOTE = 'Billing.Debit Note.Debitnote Interest Calculation Report';
export const INVOICE_REPORT_DEBIT_NOTE = 'Billing.Debit Note.Debitnote Invoice Report';
export const RECEIPT_REPORT_DEBIT_NOTE = 'Billing.Debit Note.Debitnote Reciept Report';
export const BULK_RECEIPT_DELETE_DEBIT_NOTE = 'Billing.Debit Note.Debit note Bulk Reciept Delete';
export const ACCOUNT_SYNC_DEBIT_NOTE = 'Billing.Debit Note.Debit note Account Sync ';
export const DIRECT_ENTRY_DEBIT_NOTE = 'Billing.Debit Note.Direct Entry';

export const ADD_CREDIT_NOTE = 'Billing.Credit Note.Add'
export const VIEW_OWN_CREDIT_NOTE = 'Billing.Credit Note.View Own Credit Note'
export const EDIT_CREDIT_NOTE = 'Billing.Credit Note.Edit'
export const DELETE_CREDIT_NOTE = 'Billing.Credit Note.Delete'
export const VIEW_CREDIT_NOTE = 'Billing.Credit Note.View'
export const PAYMENT_ADJUSTMENT_CREDIT_NOTE = 'Billing.Credit Note.Payment Or Adjustment'
export const VERIFY_CREDIT_NOTE = 'Billing.Credit Note.Verify'
export const SEND_SMS_CREDIT_NOTE = 'Billing.Credit Note.Send SMS'
export const SEND_EMAIL_CREDIT_NOTE = 'Billing.Credit Note.Send Email'

export const ADD_ADVANCE_NOTE = 'Billing.Advance.Add'
export const EDIT_ADVANCE_NOTE = 'Billing.Advance.Edit'
export const VIEW_ADVANCE_NOTE = 'Billing.Advance.View'
export const PAYMENT_ADJUSTMENT_ADVANCE_NOTE = 'Billing.Advance.Payment Or Adjustment'
export const VIEW_OWN_ADVANCE_NOTE = 'Billing.Advance.View Own Advances'
export const DELETE_ADVANCE_NOTE = 'Billing.Advance.Delete'
export const SEND_SMS_ADVANCE_NOTE = 'Billing.Advance.Send SMS'
export const SEND_EMAIL_ADVANCE_NOTE = 'Billing.Advance.Send Email'

// Reports

export const PARKING_REPORTS = 'Reports.Reports.Parking Reports'
export const STAFF_REPORTS = 'Reports.Reports.Staff Reports'
export const INVOICE_REPORTS = 'Reports.Reports.Invoice Report'
export const MEMBER_DUE_REPORTS = 'Reports.Reports.Member Due Report'
export const SINKING_FUND_REPORTS = 'Reports.Reports.Sinking Fund Report'
export const REPAIR_FUND_REPORTS = 'Reports.Reports.Repair Fund Report'
export const RECEIPT_REPORTS = 'Reports.Reports.Receipt Report'
export const CHEQUE_REPORTS = 'Reports.Reports.Cheque Report'
export const INTEREST_CALCULATION_REPORTS = 'Reports.Reports.Interest Calculation Report'
export const VIEW_OWN_INTEREST_CALCULATION_REPORTS = 'Reports.Reports.View Own Interest Calculation Report'
export const APP_DOWNLOAD_REPORTS = 'Reports.Reports.App Download Report'
export const OPENING_BALANCE_REPORTS = 'Reports.Reports.Opening Balance'
export const GST_REPORTS = 'Reports.Reports.Gst Report'
