import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DownloadExt, UploadEx } from "../../../assets";
import { WINGS_AND_FLATS_ENDPOINT } from "../../../Routing/routes";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import BillTarrifTable from "./BillTarrifTable";
import UploadExcelModal from "../../../components/Modal/UploadExcelModal";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { useDispatch, useSelector } from "react-redux";
import {downloadBillTariffExcel, getBillTariffDetails, uploadBillTariffExcel} from '../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction'
import { downloadExcel } from "../../../utils/helpers/downloadMedia";
import Loader from "../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";
import * as permissions from '../../../Routing/permissions'
import { getPermission } from "../../../utils/helpers/getPermissions";

const BillTarrifSetup = () => {
  const navigate = useNavigate();
  const {id} = useParams()
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, uploadLoader, updateLoader, billTariffData} = useSelector(state=>state.wingsReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)

  const [file, setFile] = useState(null)
  const [onClickSave, setOnClickSave] = useState(undefined)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleDownloadExcel = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'bill_tariff_excel_sheet.xlsx'})
    }
    dispatch(downloadBillTariffExcel({societyId: loginData?.default_community?.community_id, wingId: id, onSucessCallback}))
  }

  const onUpload = ()=>{
    let data={
      wingId: id,
      societyId: loginData?.default_community?.community_id,
      ipAddress,
      sessionId: loginData?.session_id,
      file,
      onSuccessCallback: ()=>{
        dispatch(getBillTariffDetails(data))
      },
      onRequestEndCallback: ()=>{
        setFile(null)
        setIsUploadModalOpen(false)
      }
    }
    dispatch(uploadBillTariffExcel(data))
  }

  useEffect(()=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      wingId: id,
    }
    dispatch(getBillTariffDetails(data))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg pt-2 p-1">
      <ListHeaderBack
          onClick={handleNavigation(WINGS_AND_FLATS_ENDPOINT)}
          title={`Bill Tariff Setup - ${!(loading && id !== billTariffData?.wing?.wing_id?.toString()) ? billTariffData?.wing?.wing_title : ''}`}
        >
          {/* <ButtonIco icon={DownloadExt} children="Download Excel" onClick={handleDownloadExcel}/> */}
          {/* {
            getPermission(loginData, [permissions.BILL_TARIFF_EXCEL_UPLOAD_PERMISSION]) &&
            <button
              className="flex items-center h-8 px-2 text-xs font-semibold bg-white border rounded-lg text-red-650 border-red-650 whitespace-nowrap"
              onClick={handleOpenUploadModal}
            >
              <UploadEx className="mr-2 fill-red-650" /> Upload Modified/New Flats Tariff
              Excel
            </button>
          }
           */}
          {
            getPermission(loginData, [permissions.ADD_BILL_TARIFF_PERMISSION]) &&
            <>
              <div className="border-r border-[#CCC] h-full"></div>
              <Button label={
                  <span className="inline-block w-8">
                    {
                      updateLoader ?
                        <CircularProgress sx={{color: "white"}} size={17}/>
                        :
                        'Save'
                      }
                  </span>
                } 
                onClick={onClickSave} className="h-8 px-4 mr-2" />
            </>
          }
        </ListHeaderBack>
        <div className="border-b border-[#CCCCCC]"></div>
        <div>
          {
            loading && id !== billTariffData?.wing?.wing_id?.toString()  ?
            <Loader/>
            :
            <BillTarrifTable wingId={id} societyId={loginData?.default_community?.community_id} setOnClickSave={setOnClickSave}/>
          }
        </div>
      </div>
      {isUploadModalOpen && (
        <UploadExcelModal 
          isOpen={isUploadModalOpen} 
          onClose={handleCloseUploadModal} 
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          file={file}
          setFile={setFile}
          onUpload={onUpload}
          uploadLoader={uploadLoader}
          downloadSampleLink={'https://firebasestorage.googleapis.com/v0/b/np-zg-dev-neo-bucket/o/excelSheetSampleAndFormat%2Fbill_tariff_excel_sheet_sample.xlsx?alt=media&token=3073c899-159d-482d-8b7c-22d46811a908'}
          downloadFormatLink={'https://firebasestorage.googleapis.com/v0/b/np-zg-dev-neo-bucket/o/excelSheetSampleAndFormat%2Fbill_tariff_excel_sheet_format.xlsx?alt=media&token=df71eef9-e492-47ec-8b39-e74f26046135'}
        />
      )}
    </Layout>
  );
};

export default BillTarrifSetup;
