import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";
import moment from "moment";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Button from "../../../components/Button/Button";
import {
  getNoticeCircularDetails,
  postNoticeCircularComment,
} from "../../../redux/actions/SocialAndCommunicationActions/NoticeAndCircularAction";
import ButtonG from "../../../components/Button/ButtonG";
import { TextArea } from "../../../components/InputFields/TextArea";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
function ViewNotice() {
  const [noticeCircularComment, setNoticeCircularComment] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, status } = useParams();
  const { loading, noticeCircularDetail } = useSelector(
    (state) => state.noticeAndCircularReducer
  );
  const { loginData } = useSelector((state) => state.loginReducer);
  const postNoticeCircularCommentSave = () => {
    setNoticeCircularComment("");
    dispatch(
      postNoticeCircularComment({
        id,
        comment: noticeCircularComment,
        fetchData: true,
      })
    );
  };

  // const contentRef = useRef();

  // useEffect(() => {
  //   if (noticeCircularDetail?.notice && contentRef) {
  //     contentRef.current.innerHTML = noticeCircularDetail?.notice;
  //   }
  // }, [noticeCircularDetail?.notice]);

  useEffect(() => {
    dispatch(
      getNoticeCircularDetails({
        id,
      })
    );
  }, []);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="View Notice & Circular"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>{" "}
        </div>
        {loading && id !== noticeCircularDetail?.notice_detail?.id ? (
          <Loader />
        ) : (
          <>
            <div className="p-2 gap-2">
              <table className="table-auto w-full text-[#222222] text-sm">
                <tbody>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Subject:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.title || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Type:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.type || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Description:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.description ? (
                        <div
                          className="vc-description  border-2 border-gray-100 bg-[##f5f5f5] p-2"
                          // ref={contentRef}
                          dangerouslySetInnerHTML={{
                            __html:
                              noticeCircularDetail?.notice_detail?.description,
                          }}
                        ></div>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Attachment:
                    </td>

                    <td>
                      {noticeCircularDetail?.attachments &&
                      noticeCircularDetail?.attachments?.length > 0 ? (
                        <ol className=" list-[auto] ps-4">
                          {noticeCircularDetail?.attachments?.map(
                            (attach, index) => {
                              {
                                return attach?.attachment_url ? (
                                  <>
                                    <li key={index}>
                                      <Link
                                        target="_blank"
                                        className="ms-1 underline text-xs text-blue-500"
                                        to={attach?.attachment_url}
                                      >
                                        View Uploaded Doc
                                      </Link>
                                    </li>
                                  </>
                                ) : (
                                  <li key={index}>-</li>
                                );
                              }
                            }
                          )}
                        </ol>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Created By:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.createdBy || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Created On:
                    </td>
                    <td className="py-2">
                      {moment(
                        new Date(noticeCircularDetail?.notice_detail?.date)
                      )?.format("MMM DD, YYYY") || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Reference No:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.ref_no || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Notice Date:
                    </td>
                    <td className="py-2">
                      {moment(
                        new Date(
                          noticeCircularDetail?.notice_detail?.notice_date
                        )
                      )?.format("MMM DD, YYYY") || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Expiry Date:
                    </td>
                    <td className="py-2">
                      {
                      noticeCircularDetail?.notice_detail?.expiry_date !='0000-00-00'?
                      moment(
                        new Date(
                          noticeCircularDetail?.notice_detail?.expiry_date
                        )
                      )?.format("MMM DD, YYYY") : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Email Notification Send To:
                    </td>
                    <td className="py-2">
                      <ul className="">
                        {noticeCircularDetail?.notice_detail?.email_send_to
                          .length > 0
                          ? noticeCircularDetail?.notice_detail?.email_send_to.map(
                              (item, index) => {
                                return <li> {index + 1 + ". " + item}</li>;
                              }
                            )
                          : "-"}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="border-t-2 my-3"></div>
              <table className="table-auto w-full text-sm">
                <tbody>
                  {/* <tr className="text-[#222222] text-sm">
              <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                Assigned To:
              </td>
              <td className="py-2"></td>
            </tr>
            <tr className="text-[#222222] text-sm">
              <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                Serviced By:
              </td>
              <td className="py-2"></td>
            </tr> */}
                  {getPermission(loginData, [
                    permissions.POST_COMMENT_NOTICE_CIRCULAR_PERMISSION,
                  ]) && (
                    <tr className="text-[#222222] ">
                      <td className=" w-44 py-2 text-[#AAAAAA] font-bold align-top">
                        Write your comment:
                      </td>
                      <td className="py-2">
                        <TextArea
                          placeholder={"Enter"}
                          onChange={(elem) => {
                            setNoticeCircularComment(elem.target.value);
                          }}
                          value={noticeCircularComment}
                          className={"w-80"}
                          row={4}
                        />
                        <div className="flex gap-2">
                          <ButtonG
                            label="Cancel"
                            className="h-8"
                            onClick={() => {
                              navigate(-1);
                            }}
                            type={`button`}
                          />
                          <Button
                            label="Post"
                            disabled={
                              loading || !noticeCircularComment.trim()
                                ? true
                                : false
                            }
                            className={`${
                              loading || !noticeCircularComment.trim()
                                ? "opacity-35"
                                : ""
                            } h-8 px-4`}
                            onClick={postNoticeCircularCommentSave}
                            type={`submit`}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Comments:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.comments &&
                      noticeCircularDetail?.comments?.length > 0
                        ? noticeCircularDetail?.comments?.map(
                            (comment, index) => (
                              <>
                                <div className="flex items-start justify-between  border-dashed border-b border-[#CCC]">
                                  <div className=" gap-3 pr-10 w-[calc(100%-230px)] whitespace-pre-wrap break-words">
                                    <div key={index} className="py-2 ">
                                      {/* <div className="flex gap-3">
                              <p> User Name: </p>
                              <p className="font-bold"> {comment.comment_by} </p>
                              </div> */}

                                      <div className="flex gap-3">
                                        <p> - </p>
                                        <p className=" text-[#AAA]">
                                          {comment.comment || "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className=" gap-3 w-[230px] whitespace-pre-wrap break-words">
                                    <div
                                      key={index}
                                      className="py-2 text-[#AAA] "
                                    >
                                      <div className="flex gap-3">
                                        <p className=" whitespace-nowrap">
                                          {" "}
                                          Posted By:{" "}
                                        </p>
                                        <p className="font-bold">
                                          {" "}
                                          {comment.created_by}{" "}
                                        </p>
                                      </div>

                                      <div className="flex gap-3">
                                        <p className=" whitespace-nowrap">
                                          {" "}
                                          Posted On:{" "}
                                        </p>
                                        <p className=" text-[#AAA]">
                                          {comment.date !==
                                          "0000-00-00 00:00:00"
                                            ? new Date(
                                                comment.date
                                              ).toLocaleString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                              }) +
                                              " " +
                                              new Date(
                                                "1970-01-01T" + comment.time
                                              ).toLocaleString("en-GB", {
                                                hour12: true,
                                                hour: "numeric",
                                                minute: "numeric",
                                              })
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          )
                        : "-"}

                      {/* <div className="">
                          
                        </div> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default ViewNotice;
