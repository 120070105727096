import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "../../../components/TableComponent/Table.css";
import { SortIcon } from "../../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../../../components/ItemsPerPage/ItemsPerPage";
import Pagination from "../../../components/Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../../utils/helpers/universalFunctions";
import Loader from '../../../components/Loader/Loader'
import { useSearchParams } from "react-router-dom";

const SmsHistoryDetailsTable = ({
  children,

  data,
  loading,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,

  getTableData,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams()

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(currentPage || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    !pagination ? data?.length : 10
  );

  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #ffffff;
    }
    &:nth-of-type(even) {
      background-color: #f4f5f8;
    }
    &:nth-of-type(odd):hover {
      background-color: #ffffff;
    }
    &:nth-of-type(even):hover {
      background-color: #f4f5f8;
    }
  `;

  const TableCellStyled = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      zIndex: 0,
      color: "#AAAAAA",
      fontSize: "13px",
      padding: '12px 8px 12px 0',
      wordBreak: "break-word",
      fontSize: "13px",
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      fontSize: "13px",
      padding: '12px 8px 12px 0',
      wordBreak: "break-word",
      fontSize: "13px",
      cursor: "pointer"
    },
  }));

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data?.map((item) => item.id) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };


  const handleChangePage = (newPage) => {
    if (page !== newPage) {
      setPage(newPage);
      getTableData(rowsPerPage, newPage);
    }
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      getTableData(value, page);
      setRowsPerPage(value);
      setPage(0);
    }
  };


  const isSelected = (id) => selected.includes(id);

  useEffect(()=>{
    if(!pagination && data?.length){
      setRowsPerPage(data?.length)
    }
  }, [data])

  useEffect(()=>{

  },[searchParams])

  return (
    <Box sx={{ width: "100%",  fontSize: "13px"}} className="table-component">
      <TableContainer className="border-t">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{pl:'0 !important', pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={data?.length > 0 && selected.length === data?.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                  />
                </TableCellStyled>
              )}
              {columns.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ?
              <TableRow>
                <TableCellStyled sx={{border: 'none'}} colSpan={columns?.length+1}><Loader/></TableCellStyled>
              </TableRow>
              :
              <>
                {!!data?.length && stableSort(data, getComparator(order, orderBy))
                .map((row, rowIndex) => (
                    <TableRowStyled
                      key={rowIndex}
                      role="checkbox"
                      aria-checked={isSelected(row.id)}
                      tabIndex={-1}
                      selected={isSelected(row.id)}
                      sx={{ cursor: "pointer" }}
                    >
                      {checkbox && (
                        <TableCellStyled
                          size="small"
                          onClick={(event) => handleClick(event, row.id)}
                        >
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={isSelected(row.id)}
                            inputProps={{
                              "aria-labelledby": `generic-table-checkbox-${row.id}`,
                            }}
                            sx={{
                              p: 0,
                              color: "#AAAAA",
                              "&.Mui-checked": {
                                color: "#CF001C",
                              },
                            }}
                          />
                        </TableCellStyled>
                      )}
                      {columns.map((column) => (
                        <TableCellStyled size="small" key={column.id+rowIndex} align={column.numeric ? "right" : "left"}
                          sx={{textTransform: row?.capitalize || 'none'}}
                          onClick={ column.id === "action" ? undefined : (event) => handleClick(event, row.id) }
                        >
                          {column.id === "sNo" ? (
                            row.sNo || ((page - 1) * rowsPerPage + rowIndex + 1)?.toString()?.padStart(2, "0")
                          ) : column.id === "action" ? (
                            React.isValidElement(children) &&
                            React.cloneElement(children, {
                              id: row.id,
                              status: row?.status,
                            })
                          ) : column.id === "status" ? (
                            <span
                              className={`${row[column.id]
                                ?.replace(/ /g, "-")
                                ?.toLowerCase()} font-medium`}
                            >
                              {row[column.id]}
                            </span>
                          ) : row[column.id] !== undefined &&
                            row[column.id] !== null ? (
                            row[column.id]
                          ) : (
                            "-"
                          )}
                        </TableCellStyled>
                      ))}
                    </TableRowStyled>
                  ))}
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={page}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

SmsHistoryDetailsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default SmsHistoryDetailsTable;
