import React, { useEffect } from 'react'
import Layout from '../../Layout/Layout';
import GenericTable from '../../../components/TableComponent/Table';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSentSmsHistoryDetails } from '../../../redux/actions/SetupRecordsActions/SocietyProfileSetupActions/SentSmsHistoryActions';
import ListHeaderBack from '../../../components/ListHeader/ListHeaderBack';
import SmsHistoryDetailsTable from './SmsHistoryDetailsTable';
import { Skeleton } from '@mui/material';
import Loader from '../../../components/Loader/Loader';

const headCells = [
    { id: "sNo", label: "Sr. No.", sort: false, width: "10%" },
    { id: "name", label: "Name", sort: false, width: "60%" },
    { id: "contact", label: "Contact", sort: false, width: "20%" },
];

const SmsHistoryDetails = () => {
    const { min_id, max_id } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { loginData } = useSelector((state) => state.loginReducer)
    const { loading, sentSmsHistoryDetails } = useSelector((state) => state.sentSmsHistoryReducer)

    const getTableData = () => {
        const data = {
            society_id: loginData?.default_community?.community_id,
            min_id: min_id,
            max_id: max_id,
        }
        dispatch(getSentSmsHistoryDetails(data));
    }

    useEffect(() => {
        getTableData();
    }, [min_id, max_id]);

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <div className="border-b border-[#CCC]">
                    <ListHeaderBack title="SMS History Details" onClick={() => { navigate(-1) }}>
                    </ListHeaderBack>
                </div>
                {!loading ? sentSmsHistoryDetails?.map((value) => (
                    <>
                        <div className=" border-y-[1px] border-[#CCC] text-center p-2 bg-red-650 text-white">
                            {value?.date}
                        </div>
                        <div className="p-1 border-b">
                            <div className=' flex flex-row'><p className=" w-[11%]">Panel</p>:{value?.module_title}</div>
                            <div className=' flex flex-row'><p className=" w-[11%]">Section</p>:{value?.section}</div>
                            <div className=' flex flex-row'><p className=" w-[11%]">Subject</p>:{value?.subject}</div>
                            <div className=' flex flex-row'><p className=" w-[11%]">Message</p>:<p className=" w-[89%]">{(value?.message).replaceAll("+", " ")}</p></div>
                            <div className=' flex flex-row'><p className=" w-[11%]">Date</p>:{value?.date}</div>
                        </div>
                        {value?.sent_contact_list.length > 0 &&
                            <div className="ml-1">
                                <SmsHistoryDetailsTable
                                    // loading={loading}
                                    data={value?.sent_contact_list?.map((item, index) => ({
                                        sNo: index + 1,
                                        id: item?.sent_id,
                                        min_id: item?.min_id,
                                        id: item?.max_id,
                                        name: item?.receiver_name,
                                        contact: item?.receiver_phone
                                    }))}
                                    columns={headCells}
                                    checkbox={false}
                                    pagination={false}
                                    currentPage={1}
                                    getTableData={(rowsPerPage, page) => { }}
                                >
                                </SmsHistoryDetailsTable>
                            </div>
                        }
                    </>
                ))
                    :
                    // <Loader />
                    <Skeleton
                        animation="wave"
                        variant="rounded"
                        sx={{ height: "200px", width: "100%" }}
                    />
                }
            </div>
        </Layout>
    )
}

export default SmsHistoryDetails;
