import * as type from '../../../actions/index.types'

export function smsCreditReducer(state = {
    error: null,
    loading: false,
    smsCreditPackageList: null,
    smsCreditOrderHistoryList: null,
    smsCreditOrderDetails: null,
}, action) {

    switch (action.type) {
        case type.GET_SMS_CREDIT_LIST:
            return {
                ...state,
                loading: true,
            }
        case type.GET_SMS_CREDIT_LIST_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                smsCreditPackageList: action.payload,
            }
        case type.GET_SMS_CREDIT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                smsCreditPackageList: null,
                error: action?.message || 'Somthing went wrong.',
            }

        case type.GET_SMS_CREDIT_ORDER_HISTORY_LIST:
            return {
                ...state,
                loading: true,
            }
        case type.GET_SMS_CREDIT_ORDER_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                smsCreditOrderHistoryList: action.payload,
            }
        case type.GET_SMS_CREDIT_ORDER_HISTORY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                smsCreditOrderHistoryList: null,
                error: action?.message || 'Somthing went wrong.',
            }

        case type.GET_SMS_CREDIT_ORDER_DETAILS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_SMS_CREDIT_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                smsCreditOrderDetails: action.payload,
            }
        case type.GET_SMS_CREDIT_ORDER_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                smsCreditOrderDetails: null,
                error: action?.message || 'Somthing went wrong.',
            }

        case type.BUY_SMS_CREDIT:
            return {
                ...state,
                loading: true,
            }
        case type.BUY_SMS_CREDIT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            }
        case type.BUY_SMS_CREDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.message || 'Somthing went wrong.',
            }

        default:
            return state
    }
}