import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ALL_DOCUMENTS_ENDPOINT,
  LOG_A_COMPLAINT_AUDIT_LOG_ENDPOINT,
} from "../../../Routing/routes";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import { UploadButtonFM } from "../../../components/Button/UploadButton";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { Form, Formik } from "formik";
import { logaTicketValidation } from "../../../validationSchema/deskSchema/logaTicketSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  addLogAComplaint,
  addLogAComplaintV2,
  getComplaintNatureList,
  getComplaintTypeList,
  getFlatsNo,
  getWingsList,
} from "../../../redux/actions/DeskActions/LogaComplaintActions";
import { onBehalfOfOptions } from "../../../consts/DropDownOptions";
import { CircularProgress } from "@mui/material";
import {
  getComplaintCategoryDetailsList,
  getComplaintCategoryList,
} from "../../../redux/actions/DeskActions/ComplaintCategoryAction";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { getFlatAllMembersList } from "../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { userRole } from "../../../consts/constant";

const LogAComplaint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [checked, setChecked] = useState(false);
  const [onBehalf, setOnBehalf] = useState();
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const dropdownData = useSelector((state) => state.logaComplaintReducer);
  const { wingsList, flatsList } = useSelector(
    (state) => state.logaComplaintReducer
  );
  const { flatAllMembersList } = useSelector((state) => state.occupantInfoReducer);
  const { updateLoader } = useSelector((state) => state.logaComplaintReducer);
  const { complaintCategoryList, complaintCategoryDetailsList } = useSelector(
    (state) => state.complaintCategoryReducer
  );

  const handleChecked = (event, setFieldValue) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue("isUrgent", "Yes");
    } else {
      setFieldValue("isUrgent", "No");
    }
  };

  useEffect(() => {
    dispatch(getComplaintNatureList());
    dispatch(getComplaintCategoryList());
    const data = { societyId: loginData?.default_community?.community_id };
    setOnBehalf(getPermission(loginData, [permissions.LOG_A_COMPLAINT_ONBEHALF_OF_PERMISSION]));
    dispatch(getComplaintTypeList(data));
    dispatch(getWingsList(data));
  }, []);

  const handleDownloadClick = () => {
    navigate(ALL_DOCUMENTS_ENDPOINT);
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async (values, { resetForm }) => {
    let sendRequest = {
      ...values,
      ipAddress,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
      onSuccessCallback: () => {
        resetForm();
      },
    };
    // dispatch(addLogAComplaint(sendRequest));
    dispatch(addLogAComplaintV2(sendRequest));
  };

  useEffect(() => {
    if (values.flatsNo) {
      const data = {
        society_id: loginData?.default_community?.community_id,
        flat_id: values.flatsNo
      };
      dispatch(getFlatAllMembersList(data));
    }
  }, [values.flatsNo]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <Formik
          initialValues={{
            complaintNature: "",
            complaintType: "",
            category: "",
            subCategory: "",
            isUrgent: "No",
            subject: "",
            description: "",
            wingId: (onBehalf || loginData?.user_type == "Employee") ? "" : loginData?.default_community?.blocks?.wing_id || "",
            flatsNo: (onBehalf || loginData?.user_type == "Employee") ? "" : loginData?.default_community?.blocks?.houseUniqueId || "",
            memberId: (onBehalf || loginData?.user_type == "Employee") ? "" : loginData?.user_type === userRole.TENANT ? loginData?.default_community?.tenant_id : (loginData?.user_type === userRole.USER || loginData?.user_type === userRole.ANOTHER_USER_STRING ? loginData?.default_community?.subscriber_id : ''),
            // memberId: loginData?.user_type === 'Tenant' ? loginData?.default_community?.tenant_id : (loginData?.user_type === 'User' ? loginData?.default_community?.subscriber_id : ''),
            memberType: (onBehalf || loginData?.user_type == "Employee") ? "" : loginData?.user_type === userRole.TENANT ? userRole.TENANT : userRole.MEMBER,
            memberName: (onBehalf || loginData?.user_type == "Employee") ? "" : loginData?.user_name || '',
            attachments: "",
            permissionOnBehalf: (onBehalf || loginData?.user_type == "Employee")
          }}
          enableReinitialize
          validationSchema={logaTicketValidation}
          onSubmit={handleSaveClick}
          innerRef={(formikActions) => formikActions ? setValues(formikActions.values) : setValues({})}
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Log A Complaint">
                    {getPermission(loginData, [
                      permissions.VIEW_DOCUMENTS_PERMISSION,
                    ]) && (
                        <Button
                          label="Download Forms"
                          onClick={handleDownloadClick}
                          className="px-4"
                          type={"button"}
                        />
                      )}
                    {/* <Button
                      label="Audit Log"
                      onClick={() =>
                        navigate(LOG_A_COMPLAINT_AUDIT_LOG_ENDPOINT)
                      }
                      className="px-4"
                      type={"button"}
                    /> */}
                    <div className="mx-0.5 border-l-2"></div>
                    <ButtonG
                      label="Cancel"
                      onClick={handleCancelClick}
                      type={"button"}
                    />
                    <Button
                      label={
                        <span className="w-12 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      className="px-3 h-8"
                      type="submit"
                    />
                  </ListHeader>
                </div>
                <div className="flex justify-between gap-4 mt-4">
                  <table className="border-separate">
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Ticket Nature{" "}
                      </td>
                      <td>
                        <DropdownFM2
                          options={dropdownData.complaintNatureList?.map(
                            (item) => ({
                              label: item.title,
                              value: item.title,
                            })
                          )}
                          placeholder="Select Ticket Nature"
                          className=""
                          width="208px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue(
                              "complaintNature",
                              selectedItem.value
                            );
                          }}
                          {...(values.complaintNature === "" && {
                            value: null,
                          })}
                          name={"complaintNature"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Ticket Type
                      </td>
                      <td>
                        <DropdownFM2
                          options={dropdownData.complaintType?.map((item) => ({
                            label: item.title,
                            value: item.title,
                          }))}
                          placeholder="Select Type"
                          className=""
                          width="208px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue(
                              "complaintType",
                              selectedItem.value
                            );
                          }}
                          {...(values.complaintType === "" && {
                            value: null,
                          })}
                          name={"complaintType"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Category
                      </td>
                      <td>
                        <DropdownFM2
                          options={complaintCategoryList?.map((item) => ({
                            label: item.title,
                            value: item.id,
                          }))}
                          placeholder="Select Category"
                          className=""
                          width="208px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue(
                              "category",
                              selectedItem.value
                            );
                            if (values.category !== selectedItem.value) {
                              setFieldValue("subCategory", "");
                              dispatch(
                                getComplaintCategoryDetailsList({
                                  categoryId: selectedItem.value,
                                })
                              );
                            }
                          }}
                          {...(values.category === "" && {
                            value: null,
                          })}
                          name={"category"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Sub Category
                      </td>
                      <td>
                        <DropdownFM2
                          options={complaintCategoryDetailsList?.[0]?.sub_category?.map(
                            (item) => ({
                              label: item.sub_title,
                              value: item.id,
                            })
                          )}
                          placeholder="Select SubCategory"
                          className=""
                          width="208px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue(
                              "subCategory",
                              selectedItem.value
                            );
                          }}
                          {...(values.subCategory === "" && {
                            value: null,
                          })}
                          name={"subCategory"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Is Urgent ?
                      </td>
                      <td>
                        <div className="w-52 mb-4">
                          <Checkbox
                            text="Yes"
                            className="text-sm"
                            initialchecked={checked}
                            onChange={(e) => {
                              handleChecked(e, setFieldValue);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">Subject</td>
                      <td>
                        <TextInputFM
                          placeholder="Enter Subject"
                          className="w-52 h-9"
                          name="subject"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Description
                      </td>
                      <td>
                        <TextAreaFM
                          placeholder="Enter Description"
                          className={"w-52"}
                          name="description"
                          row="5"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Attachments
                      </td>
                      <td>
                        <div className="w-52 overflow-hidden mb-5">
                          <UploadButtonFM
                            showFileName={true}
                            name={`attachments`}
                            accept={
                              "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                            }
                            onSelect={(e) =>
                              setFieldValue(`attachments`, e)
                            }
                            fileName={values?.attachments?.name}
                          />
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        On Behalf Of
                      </td>
                      <td>
                        <DropdownFM2
                          options={onBehalfOfOptions}
                          width="208px"
                          height="36px"
                          name={"isTenant"}
                          placeholder="Select OnBehalf"
                          onSelect={(selectedItem) => {
                            if (selectedItem.value === 0) {
                              setFieldValue(
                                "tenantId",
                                selectedItem.value
                              );
                            }
                            setFieldValue(
                              "isTenant",
                              selectedItem.value
                            );
                            if (values.wingId) {
                              let data = {
                                societyId:
                                  loginData?.default_community?.community_id,
                                wingId: values.wingId,
                                userType: selectedItem.value
                                  ? "Tenant"
                                  : "Member",
                              };
                              dispatch(getFlatsNo(data));
                            }
                            setFieldValue("flatsNo", "");
                          }}
                          value={
                            values.isTenant === ""
                              ? null
                              : {
                                  label: onBehalfOfOptions?.find(
                                    (item) =>
                                      item.value === values.isTenant
                                  )?.label,
                                  value: values.isTenant,
                                }
                          }
                        />
                      </td>
                    </tr> */}
                    {(onBehalf || loginData?.user_type == "Employee") &&
                      <>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Member/Tenant Details
                          </td>
                          <td>
                            <div className="flex items-center">
                              <div className="w-52 flex gap-4 mr-4">
                                <DropdownFM2
                                  className={"me-4"}
                                  options={wingsList?.map((item) => ({
                                    label: item.number,
                                    value: item.id,
                                  }))}
                                  width="96px"
                                  height="36px"
                                  placeholder="Wing"
                                  name={"wingId"}
                                  onSelect={(selectedItem) => {
                                    setFieldValue("wingId", selectedItem.value);
                                    setFieldValue("flatsNo", '');
                                    setFieldValue("memberId", '');
                                    setFieldValue("memberName", '');
                                    let data = {
                                      societyId: loginData?.default_community?.community_id,
                                      wingId: selectedItem.value,
                                      // userType: values.isTenant
                                      //   ? "Tenant"
                                      //   : "Member",
                                    };
                                    dispatch(getFlatsNo(data));
                                  }}
                                  {...(values.wingId === "" && {
                                    value: null,
                                  })}
                                  
                                />
                                <DropdownFM2
                                  options={flatsList?.map((item) => ({
                                    label: item.flat_no,
                                    value: item.house_unique_id,
                                    subscriber_id: item.subscriber_id,
                                    memberName: item.full_name,
                                    tenant_id: item.tenantid,
                                    tenantName: item.tenant_full_name,
                                  }))}
                                  name={"flatsNo"}
                                  width="96px"
                                  height="36px"
                                  placeholder="Flat/Unit"
                                  onSelect={(selectedItem) => {
                                    setFieldValue("flatsNo", selectedItem.value);
                                    setFieldValue("memberId", values.memberType === 'Member' ? selectedItem.subscriber_id : selectedItem.tenant_id);
                                    setFieldValue("memberName", values.memberType === 'Member' ? selectedItem.memberName : selectedItem.tenantName);
                                  }}
                                  {...(values.flatsNo === "" && {
                                    value: null,
                                  })}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            On Behalf Of
                          </td>
                          <td>
                            <DropdownFM2
                              options={flatAllMembersList?.length !== 0 ?
                                flatAllMembersList?.map((item) => ({
                                  value: item.member_id,
                                  label: item.member_name,
                                  type: item.member_type,
                                })) : []
                              }
                              placeholder="Select Member"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue('memberId', selectedItem.value)
                                setFieldValue('memberName', selectedItem.label)
                                setFieldValue('memberType', selectedItem.type)
                              }}
                              value={values.memberId === "" ?
                                null
                                :
                                {
                                  label: flatAllMembersList?.find(item => item.member_id === values.memberId)?.member_name,
                                  value: values.memberId
                                }
                              }
                              name={"memberName"}
                            // disabled={loginData?.user_type === 'User' || loginData?.user_type === 'Tenant'}
                            />
                          </td>
                        </tr>
                      </>
                    }
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default LogAComplaint;
