import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import Loader from "../../../../components/Loader/Loader";
import { getDebiteNoteInvoicePrint } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";

function PrintDebitBill() {
  const data = useLocation().state;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { printDebitInvoice, printLoader } = useSelector(
    (state) => state.manageDebitNoteReducer
  );

  useEffect(() => {
    if (data)
      dispatch(
        getDebiteNoteInvoicePrint({
          societyId: loginData?.default_community?.community_id,
          invid: data?.invid,
          onSuccessCallback: ()=>{
            setTimeout(function() {
                window.print()
            }, 5000); 
        }
        })
      );
    else
      dispatch(
        getDebiteNoteInvoicePrint({
          societyId: loginData?.default_community?.community_id,
          invid: [id],
          onSuccessCallback: ()=>{
            setTimeout(function() {
                window.print()
            }, 5000); 
        }
        })
      );
  }, []);

  return (
    <div>
      {printLoader || !printDebitInvoice ? (
        <Loader />
      ) : (
        <>{parse(printDebitInvoice)}</>
      )}
    </div>
  );
}

export default PrintDebitBill;
