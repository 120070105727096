import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListHeader from "../ListHeader/ListHeader";
import Button from "../Button/Button";
import ButtonG from "../Button/ButtonG";
import Dropdown2 from "../Dropdown/dropdown2";
import Box from "@mui/material/Box";
import Modal from "react-modal";
import { TextInputFM } from "../InputFields/TextInput";
import { DropdownFM2 } from "../Dropdown/dropdown2";
import {
  getTaxableFormatList,
  editTaxableMainHead,
} from "../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { notify } from "../../utils/notification";
import { Form, Formik, Field } from "formik";
import moment from "moment";
import { DatePickerComponent } from "../DatePicker/DatePicker";
import { addInvoiceSeriesValidation } from "../../validationSchema/setupRecordsSchema/invoiceSettingSchema";
import { CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const customStyles = {
  content: {
    width: "500px",
    height: "350px",
    minHeight: "fit-content",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};
const EditTaxableMainHeadModal = ({ isOpen, onClose,editId,headDeatail, typeId, proforma, hideTarrifHead }) => {

  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
 
  const { editLoader } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  let types = [
    {
      value: 1,
      label: "Above Sub-total",
    },
    {
      value: 2,
      label: "Below Sub-total",
    }
  ];
  const onSubmit = (values) => {
    dispatch(
      editTaxableMainHead({
        societyId: loginData?.default_community?.community_id,
        headId : editId,
        headLable: values.header_text,
        hideHead: values.hide_show_parent_head,
        HedType: values.head_type,
        showSubtotal: values.hide_show_sub_total,
        subTotalLabel: values.sub_total_text,
        typeId : typeId,
        hideTarrifHead: values.hide_tarrif_head,
        onSuccessCallback: () => {
          onClose();
          dispatch(
            getTaxableFormatList({
              societyId: loginData?.default_community?.community_id,
              typeId : typeId,
            })
          );
          //navigate(-1);
        },
      })
    );
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <Formik
        initialValues={{
          id: headDeatail?.header_text,
          header_text: headDeatail?.header_text,
          hide_show_parent_head: headDeatail?.status === 3 ? 1 : 0,
          head_type: headDeatail?.head_type,
          hide_show_sub_total: headDeatail?.hide_show_sub_total,
          sub_total_text: headDeatail?.sub_total_text,
          hide_tarrif_head: hideTarrifHead === true ? 1 : 0,
        }}
        enableReinitialize
        // validationSchema={addInvoiceSeriesValidation}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="bg-white border-b rounded-lg">
                <ListHeader leftContent="Edit Parents Heads">
                  <span className="border-r border-[#CCC]"></span>
                  <ButtonG label="Cancel" type={"button"} onClick={onClose} />
                  <Button label={editLoader ? (
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={17}
                                />
                              ) : (
                                "Save"
                              )} type={"submit"} disabled={editLoader} />
                </ListHeader>
              </div>
              <Box sx={{ width: "100%", height: "67%", fontSize: "13px" }}>
                <div className="flex flex-wrap w-full gap-4 p-2 mt-3">
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td>Head label</td>
                        <td>
                          <TextInputFM
                            label=""
                            placeholder="Enter Head label"
                            className="w-56 h-9"
                            name="header_text"
                            type="text"
                            maxLength={20}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Hide Head label</td>
                        <td>
                          <Checkbox
                            // initialchecked={checkedInputData(row.id)}
                            onChange={(e) =>
                              formik.setFieldValue('hide_show_parent_head', e.target.checked ? 1 : 0) 
                              //handleClick(e.target.value,row)
                            }
                            size="small"
                            color="primary"
                            checked={formik.values.hide_show_parent_head===1}
                            sx={{
                              p: 0,
                              color: "#AAAAA",
                              "&.Mui-checked": {
                                color: "#CF001C",
                              },
                            }}
                          />
                          {/* <Field type="checkbox" name="hide_show_parent_head" className="mb-3 mr-2" /> */}
                          Yes
                        </td>
                      </tr>
                      <tr>
                        <td>Head Type</td>
                        <td>
                          <DropdownFM2
                            options={types.map((detail) => {
                              return {
                                value: detail.value,
                                label: detail.label,
                              };
                            })}
                            value={
                              formik.values.head_type
                                ? {
                                    label: types.find((item) => item.value === formik.values.head_type).label,
                                    value: formik.values.head_type,
                                  }
                                : null
                            }
                            onSelect={(selectedItem) => {
                              formik.setFieldValue(
                                "head_type",
                                selectedItem.value
                              );
                            }}
                            name="head_type"
                            placeholder="Select head Type"
                            width="14rem"
                            height="36px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Show Subtotal</td>
                        <td>
                          <Checkbox
                            // initialchecked={checkedInputData(row.id)}
                            onChange={(e) =>
                              formik.setFieldValue('hide_show_sub_total', e.target.checked ? 1 : 0) 
                              //handleClick(e.target.value,row)
                            }
                            size="small"
                            color="primary"
                            checked={formik.values.hide_show_sub_total===1}
                            sx={{
                              p: 0,
                              color: "#AAAAA",
                              "&.Mui-checked": {
                                color: "#CF001C",
                              },
                            }}
                          />
                          Yes
                        </td>
                      </tr>
                      <tr>
                        <td>Subtotal label</td>
                        <td>
                          <TextInputFM
                            label=""
                            placeholder="Enter Subtotal label"
                            className="w-56 h-9"
                            name="sub_total_text"
                            type="text"
                            maxLength={20}
                          />
                        </td>
                      </tr>
                          
                      {
                        proforma === true ? (
                          <tr>
                            <td>Hide Tarrif Heads</td>
                            <td>
                              <Checkbox
                                onChange={(e) =>
                                  formik.setFieldValue('hide_tarrif_head', e.target.checked ? 1 : 0) 
                                }
                                size="small"
                                color="primary"
                                checked={formik.values.hide_tarrif_head ===1}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}
                              />
                              Yes
                            </td>
                          </tr>
                        ) : ( <></>)
                      }
                     
                    </tbody>
                  </table>
                </div>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default EditTaxableMainHeadModal;
