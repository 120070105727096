import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../../../components/Button/ButtonG";
import Button from "../../../../../components/Button/Button";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import { DatePickerComponentFM } from "../../../../../components/DatePicker/DatePicker";
import Layout from "../../../../Layout/Layout";
import { TextAreaFM } from "../../../../../components/InputFields/TextArea";
import ListHeader from "../../../../../components/ListHeader/ListHeader";
import {
    DropdownFM2,
} from "../../../../../components/Dropdown/dropdown2";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import { FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getOccupantDetails, updateFamilyMember, viewFamilyDetails } from "../../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { CircularProgress } from "@mui/material";
import { FamilyMemberValidation } from "../../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import countries from "../../../../../consts/countries";
import moment from "moment";
import { genderOptions } from "../../../../../consts/DropDownOptions";
import { getFamilyRolesList } from "../../../../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";
import { validateDate } from "../../../../../utils/helpers/universalFunctions";
import Loader from "../../../../../components/Loader/Loader";

const EditFamilyInfoMember = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { familyMemberId } = useParams();
    const { loading, familyMemberData } = useSelector(state => state.occupantInfoReducer);
    const { loginData } = useSelector((state) => state.loginReducer);
    const { updateLoader } = useSelector(
        (state) => state.occupantInfoReducer
    );
    const { familyRoleList } = useSelector(state => state.manageRoleAndRightsReducer);
    const { ipAddress } = useSelector((state) => state.ipAddressReducer);

    const handleCancelClick = () => {
        navigate(-1);
    };

    const handleSaveClick = async (values, { resetForm, initialValues }) => {
        dispatch(
            updateFamilyMember({
                societyId: loginData?.default_community?.community_id,
                ...values,
                flatId: values?.familyMembers?.[0]?.flatId,
                ipAddress,
                sessionId: loginData?.session_id,
                onSuccessCallback: () => {
                    navigate(-1)
                },
            })
        );
    };


    useEffect(() => {
        dispatch(viewFamilyDetails({
            id: familyMemberId,
            user_type: "Family"
        }))
        dispatch(getFamilyRolesList({ societyId: loginData?.default_community?.community_id }))

    }, []);

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
                <Formik
                    initialValues={{

                        familyMembers: [
                            {
                                id: familyMemberData?.id,
                                flatId: familyMemberData?.memberid,
                                role: familyMemberData?.family_role_id || '',
                                isFamilyLogin: familyMemberData?.is_family_login || 0,
                                name: familyMemberData?.full_name || "",
                                age: familyMemberData?.age || "",
                                emailAddress: familyMemberData?.email || "",
                                // gender: ((familyMemberData === 'undefined' || '' || null) ? "" : (familyMemberData?.gender == 'undefined' || '' || null ? "Other" : familyMemberData?.gender)),
                                gender: familyMemberData?.gender === 'undefined' || familyMemberData?.gender === '' || familyMemberData?.gender === null ? "Other" : familyMemberData?.gender,
                                relationship: familyMemberData?.relationship || "",
                                mobileCountryCode: familyMemberData?.mobile_country_code?.replace('+', ''),
                                countryISO: countries?.find(item => item.phonecode?.replace('+', '') === familyMemberData?.mobile_country_code?.replace('+', ''))?.iso,
                                countryId: countries?.find(item => item.phonecode?.replace('+', '') === familyMemberData?.mobile_country_code?.replace('+', ''))?.id,
                                mobileNo: familyMemberData?.mobile_number || "",
                                profession: familyMemberData?.profession || "",
                                dateOfBirth: validateDate(familyMemberData?.dob) ? familyMemberData?.dob : "",
                                anniversaryDate: validateDate(familyMemberData?.anniversary_date) ? familyMemberData?.anniversary_date : "",
                                bloodGroup: familyMemberData?.blood_group || "",
                                otherDetails: familyMemberData?.other_details || "",
                            },
                        ],
                    }}
                    enableReinitialize
                    onSubmit={handleSaveClick}
                    validationSchema={FamilyMemberValidation}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <div className="border-b border-[#CCC]">
                                    <ListHeader
                                        leftContent={`Edit Family Member`}
                                    >
                                        <ButtonG
                                            label="Cancel"
                                            onClick={handleCancelClick}
                                            className="h-8 w-16"
                                            type={`button`}
                                        />
                                        <Button
                                            label={
                                                <span className="w-12 inline-block">
                                                    {updateLoader ? (
                                                        <CircularProgress
                                                            sx={{ color: "white" }}
                                                            size={17}
                                                        />
                                                    ) : (
                                                        "Save"
                                                    )}
                                                </span>
                                            }
                                            className="px-3 h-8"
                                            type="submit"
                                        />
                                    </ListHeader>
                                </div>
                                {
                                    loading ?
                                        <Loader />
                                        :
                                        <div className="flex-wrap flex gap-2 w-full">
                                            <FieldArray
                                                name="familyMembers"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {formik.values.familyMembers &&
                                                            formik.values.familyMembers.length > 0 &&
                                                            formik.values.familyMembers.map((item, index) => (
                                                                <div className="border rounded-lg mt-2 w-[49%]">
                                                                    <div className="flex justify-between items-center bg-[#FDF2F3] border-b border-[#CCC] py-2 px-2">
                                                                        <div className="">
                                                                            Family Member: {index + 1}
                                                                        </div>
                                                                    </div>
                                                                    <div key={index} className="flex gap-4 p-2">
                                                                        <div className="flex flex-col justify-between w-1/2 gap-3">
                                                                            {/* Role Dropdown */}
                                                                            <div className="flex justify-between w-full gap-3">
                                                                                <div className=" gap-2 pr-5 mt-3">
                                                                                    <div className="flex justify-between">
                                                                                        <span className="text-sm mt-2">
                                                                                            Select Role
                                                                                        </span>
                                                                                        <DropdownFM2
                                                                                            options={familyRoleList?.map((item) => ({
                                                                                                value: item.family_role_id,
                                                                                                label: item.title
                                                                                            })) || []}
                                                                                            name={`familyMembers.${index}.role`}
                                                                                            placeholder="Select"
                                                                                            width="224px"
                                                                                            height="36px"
                                                                                            onSelect={(selectedItem) => formik.setFieldValue(`familyMembers.${index}.role`, selectedItem.value)}
                                                                                            value={
                                                                                                formik.values.familyMembers[index].role ?
                                                                                                    {
                                                                                                        label: familyRoleList?.find(item => item.family_role_id?.toString() === formik.values.familyMembers[index].role?.toString())?.title,
                                                                                                        value: formik.values.familyMembers[index].role
                                                                                                    }
                                                                                                    : null
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    {/* Is Family Login Checkbox */}
                                                                                    <div className="flex justify-between items-center mb-4">
                                                                                        <span className="text-sm w-56 align-top">
                                                                                            Is Family Login?
                                                                                        </span>
                                                                                        <div className="w-56">
                                                                                            <Checkbox
                                                                                                text='Yes'
                                                                                                initialchecked={formik.values.familyMembers[index].isFamilyLogin === 1 ? true : false}
                                                                                                onChange={(isChecked) =>
                                                                                                    formik.setFieldValue(
                                                                                                        `familyMembers.${index}.isFamilyLogin`,
                                                                                                        formik.values.familyMembers[index].isFamilyLogin === 1
                                                                                                            ? 0
                                                                                                            : 1
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <TextInputFM
                                                                                        label="Name"
                                                                                        placeholder="Enter Name"
                                                                                        className="w-56"
                                                                                        name={`familyMembers.${index}.name`}
                                                                                    />
                                                                                    <TextInputFM
                                                                                        label="Age"
                                                                                        placeholder="Enter Age"
                                                                                        className="w-56"
                                                                                        type={`number`}
                                                                                        maxLength={3}
                                                                                        name={`familyMembers.${index}.age`}
                                                                                    />
                                                                                    <TextInputFM
                                                                                        label="Email Address"
                                                                                        placeholder="Enter Email"
                                                                                        className="w-56"
                                                                                        name={`familyMembers.${index}.emailAddress`}
                                                                                    />
                                                                                    <div className="flex justify-between">
                                                                                        <span className="text-sm mt-2">
                                                                                            Gender
                                                                                        </span>
                                                                                        <DropdownFM2
                                                                                            options={genderOptions}
                                                                                            width={"224px"}
                                                                                            className="text-xs"
                                                                                            placeholder="Select Gender"
                                                                                            onSelect={(selectedItem) => {
                                                                                                formik.setFieldValue(
                                                                                                    `familyMembers.${index}.gender`,
                                                                                                    selectedItem.label
                                                                                                );
                                                                                            }}
                                                                                            height="38px"
                                                                                            name={`familyMembers.${index}.gender`}
                                                                                            value={
                                                                                                formik.values.familyMembers[index].gender ?
                                                                                                    {
                                                                                                        label: formik.values.familyMembers[index].gender,
                                                                                                        value: genderOptions?.find(item => item.value === formik.values.familyMembers[index].gender)?.value,
                                                                                                    }
                                                                                                    : null
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <TextInputFM
                                                                                        label="Relationship"
                                                                                        placeholder="Enter "
                                                                                        className="w-56"
                                                                                        name={`familyMembers.${index}.relationship`}
                                                                                    />
                                                                                    <div className="flex justify-between">
                                                                                        <span className="text-sm mt-2">
                                                                                            Country
                                                                                        </span>
                                                                                        <DropdownFM2
                                                                                            options={
                                                                                                countries?.map((item) => ({
                                                                                                    label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                                                                                                    value: item?.phonecode?.replace("+", ""),
                                                                                                    iso: item.iso.toUpperCase(),
                                                                                                    id: item?.id,
                                                                                                })) || []
                                                                                            }
                                                                                            width={"224px"}
                                                                                            className="text-xs"
                                                                                            placeholder={"Select Country"}
                                                                                            onSelect={(selectedItem) => {
                                                                                                formik.setFieldValue(
                                                                                                    `familyMembers.${index}.mobileCountryCode`,
                                                                                                    selectedItem.value
                                                                                                );
                                                                                                formik.setFieldValue(
                                                                                                    `familyMembers.${index}.countryISO`,
                                                                                                    selectedItem.iso
                                                                                                );
                                                                                                formik.setFieldValue(
                                                                                                    `familyMembers.${index}.countryId`,
                                                                                                    selectedItem.id
                                                                                                );
                                                                                            }}
                                                                                            height="38px"
                                                                                            name={`familyMembers.${index}.mobileCountryCode`}
                                                                                            value={
                                                                                                formik.values.familyMembers[index].mobileCountryCode ?
                                                                                                    {
                                                                                                        label: `${countries?.find(item => item.phonecode?.replace('+', '') === formik.values.familyMembers[index].mobileCountryCode?.replace('+', ''))
                                                                                                            ?.name
                                                                                                            ?.toUpperCase()} (+${formik.values.familyMembers[index].mobileCountryCode})`,

                                                                                                        value: `${countries?.find(item =>
                                                                                                            item.phonecode === formik.values.familyMembers[index].mobileCountryCode)
                                                                                                            ?.phonecode?.replace("+", "")}`,

                                                                                                        iso: `${countries?.find(item => item.phonecode?.replace('+', '') === formik.values.familyMembers[index].mobileCountryCode?.replace('+', ''))
                                                                                                            ?.iso
                                                                                                            ?.toUpperCase()}`,

                                                                                                        id: `${countries?.find(item => item.phonecode?.replace('+', '') === formik.values.familyMembers[index].mobileCountryCode?.replace('+', ''))
                                                                                                            ?.id}`,
                                                                                                    }
                                                                                                    : null
                                                                                            }

                                                                                        />
                                                                                    </div>
                                                                                    <TextInputFM
                                                                                        label="Contact No."
                                                                                        placeholder="Enter Number"
                                                                                        className="w-56"
                                                                                        type={`number`}
                                                                                        name={`familyMembers.${index}.mobileNo`}
                                                                                    />
                                                                                    <TextInputFM
                                                                                        name={`familyMembers.${index}.profession`}
                                                                                        label="Profession"
                                                                                        placeholder="Enter Profession"
                                                                                        className="w-56"
                                                                                    />
                                                                                    <div className="flex justify-between text-sm">
                                                                                        <span className="mt-2">Date of Birth</span>
                                                                                        <DatePickerComponentFM
                                                                                            className="w-56 h-9 justify-between"
                                                                                            onDateChange={(selectedDate) =>
                                                                                                formik.setFieldValue(
                                                                                                    `familyMembers.${index}.dateOfBirth`,
                                                                                                    moment(
                                                                                                        new Date(selectedDate)
                                                                                                    ).format("YYYY-MM-DD")
                                                                                                )
                                                                                            }
                                                                                            name={`familyMembers.${index}.dateOfBirth`}
                                                                                            defaultValue={
                                                                                                formik.values?.familyMembers[index]?.dateOfBirth
                                                                                                    ? new Date(
                                                                                                        formik.values?.familyMembers[index]?.dateOfBirth
                                                                                                    )
                                                                                                    : null
                                                                                            }
                                                                                            // maxDate={moment().subtract(18, 'years').toDate()}
                                                                                            maxDate={new Date()}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="flex justify-between text-sm">
                                                                                        <span className='mt-2'>Anniversary Date</span>
                                                                                        <DatePickerComponentFM
                                                                                            className="w-56 h-9 justify-between"
                                                                                            onDateChange={(selectedDate) =>
                                                                                                formik.setFieldValue(
                                                                                                    `familyMembers.${index}.anniversaryDate`,
                                                                                                    moment(
                                                                                                        new Date(selectedDate)
                                                                                                    ).format("YYYY-MM-DD")
                                                                                                )
                                                                                            }
                                                                                            name={`familyMembers.${index}.anniversaryDate`}
                                                                                            defaultValue={
                                                                                                formik.values?.familyMembers[
                                                                                                    index
                                                                                                ]?.anniversaryDate
                                                                                                    ? new Date(
                                                                                                        formik.values?.familyMembers[
                                                                                                            index
                                                                                                        ]?.anniversaryDate
                                                                                                    )
                                                                                                    : null
                                                                                            }
                                                                                            maxDate={new Date()}
                                                                                        />
                                                                                    </div>
                                                                                    <TextInputFM
                                                                                        label="Blood Group"
                                                                                        placeholder="Enter Blood Group"
                                                                                        className="w-56"
                                                                                        name={`familyMembers.${index}.bloodGroup`}
                                                                                    />
                                                                                    <div>
                                                                                        <TextAreaFM
                                                                                            label="Other Details"
                                                                                            placeholder="Enter Details"
                                                                                            className="w-56 h-20"
                                                                                            name={`familyMembers.${index}.otherDetails`}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </>
                                                )}
                                            ></FieldArray>
                                        </div>
                                }
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </Layout>
    );
};


export default EditFamilyInfoMember;
