import React from "react";
import Modal from "react-modal";
import closeIcon from "../../assets/icons/closeIcon.svg";
import Button from "../Button/Button";
import { UploadEx } from "../../assets";
import { CircularProgress } from "@mui/material";

const customStyles = {
  content: {
    width: "520px",
    height: "fit-content",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

Modal.setAppElement("#root");
const UploadTenantExcelModal = ({ isOpen, onClose, buttons = [], accept, file, setFile, onUpload, uploadLoader, downloadSampleLink, downloadFormatLink, downloadCSVSampleLink }) => {

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Upload Modal"
      style={customStyles}
    >
      <div className="text-center relative flex justify-center items-center">
        <h1 className="text-lg font-bold">Excel Upload</h1>
        <button
          onClick={onClose}
          className="absolute top-0 right-1 -mt-2 bg-[#EAEAEA] rounded-full p-2 items-center w-9 h-9"
        >
          <img src={closeIcon} alt="Close" className="w-3 h-3 ml-1" />
        </button>
      </div>
      <hr className="border-t border-[#CCC] my-4" />
      <p className="font-semibold text-sm text-left">
        Note: Please do not leave any field empty.
      </p>
      <label htmlFor="fileInput" className="block font-semibold text-sm cursor-pointer">
        <div className="border-dashed border-2 border-[#CCC] rounded-md text-center p-6 mt-4 bg-[#F9FAFC] cursor-pointer">
          <div className="flex justify-center flex-col ">
            <div className="flex justify-center mb-1 cursor-pointer">
              <UploadEx className="w-6" />
            </div>
            <input type="file" id="fileInput" accept={accept} onChange={handleFileChange} className="hidden cursor-pointer" />
            Upload .xls file
            <p className="text-[#AAAAAA] text-[13px]">or drag and drop</p>
          </div>
        </div>
      </label>
      <div className="text-sm h-4 font-semibold text-[#AAAAAA] text-ellipsis overflow-hidden my-1 mb-3">{file && `File Name: ${file?.name}`}</div>
      <div className="flex justify-between text-red-650 cursor-pointer text-xs font-semibold">
        {buttons.includes("Download Sample") && <span onClick={downloadSampleLink} download>Download Sample</span>}
        {buttons.includes("Download CSV Sample") && <span onClick={downloadCSVSampleLink} download>Download CSV Sample</span>}
        {buttons.includes("Download Format") && <span onClick={downloadFormatLink} download>Download Format</span>}
      </div>

      <div className="text-center pt-4">
        <div className="border-b border-[#CCC] mb-3"></div>
        <Button
          label={
            <span className="w-12 text-enter inline-block">
              {
                uploadLoader ?
                  <CircularProgress sx={{ color: "white" }} size={17} />
                  :
                  'Upload'
              }
            </span>
          }
          className="w-[80px] h-9 disabled:opacity-50"
          disabled={!file}
          onClick={onUpload}
        />
      </div>
    </Modal>
  );
};

export default UploadTenantExcelModal;
