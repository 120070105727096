import * as type from '../../../actions/index.types'

export function wingsReducer(state = {
  loading: false,
  updateLoader: false,
  uploadLoader: false,
  wingsData: null,
  wingsAuditData: null,
  flatsData: null,
  flatsAuditLogData:null,
  flatsAuditLogDataLoading:false,
  billTariffData: null,
  utilityReadings: null,
  flatsDetailsWithUsername: null,
  error:null
}, action) {

switch (action.type) {
  case type.GET_WINGS_AND_FLATS_DETAILS:
    return {
      ...state,
      loading: true,
    }
  case type.GET_WINGS_AND_FLATS_DETAILS_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      wingsData: action.payload
    }
  case type.GET_WINGS_AND_FLATS_DETAILS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      wingsData: null,
    }


  case type.ADD_WINGS:
    return {
      ...state,
      updateLoader: true,
    }
  case type.ADD_WINGS_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.ADD_WINGS_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }


  case type.GET_WINGS_AUDIT_LOGS:
    return {
      ...state,
      loading: true,
    }
  case type.GET_WINGS_AUDIT_LOGS_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      wingsAuditData: action.payload
    }
  case type.GET_WINGS_AUDIT_LOGS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      wingsAuditData: null,
    }


  case type.GET_FLATS_DETAILS:
    return {
      ...state,
      loading: true,
    }
  case type.GET_FLATS_DETAILS_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      flatsData: action.payload
    }
  case type.GET_FLATS_DETAILS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      flatsData: null,
    }

    case type.GET_FLATS_AUDIT_LOG_DETAILS:
    return {
      ...state,
      flatsAuditLogDataLoading: true,
    }
  case type.GET_FLATS_AUDIT_LOG_DETAILS_SUCCESS:
    return {
      ...state,
      error: null,
      flatsAuditLogDataLoading: false,
      flatsAuditLogData: action.payload
    }
  case type.GET_FLATS_AUDIT_LOG_DETAILS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      flatsAuditLogDataLoading: false,
      flatsAuditLogData: null,
    }

  case type.ADD_FLATS_DETAILS:
    return {
      ...state,
      updateLoader: true,
    }
  case type.ADD_FLATS_DETAILS_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.ADD_FLATS_DETAILS_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }
    
  case type.UPLOAD_FLATS_EXCEL_SHEET:
    return {
      ...state,
      uploadLoader: true,
    }
  case type.UPLOAD_FLATS_EXCEL_SHEET_SUCCESS:
    return {
      ...state,
      uploadLoader: false,
    }
  case type.UPLOAD_FLATS_EXCEL_SHEET_FAILURE:
    return {
      ...state,
      uploadLoader: false,
    }


  case type.GET_BILL_TARIFF_DETAILS:
    return {
      ...state,
      loading: true,
    }
  case type.GET_BILL_TARIFF_DETAILS_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      billTariffData: action.payload
    }
  case type.GET_BILL_TARIFF_DETAILS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      billTariffData: null,
    }

  case type.UPLOAD_BILL_TARIFF_EXCEL_SHEET:
    return {
      ...state,
      uploadLoader: true,
    }
  case type.UPLOAD_BILL_TARIFF_EXCEL_SHEET_SUCCESS:
    return {
      ...state,
      uploadLoader: false,
    }
  case type.UPLOAD_BILL_TARIFF_EXCEL_SHEET_FAILURE:
    return {
      ...state,
      uploadLoader: false,
    }

    
  case type.UPDATE_BILL_TARIFF_DETAILS:
    return {
      ...state,
      updateLoader: true,
    }
  case type.UPDATE_BILL_TARIFF_DETAILS_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.UPDATE_BILL_TARIFF_DETAILS_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }


    case type.GET_UTILITY_READING_DETAILS:
    return {
      ...state,
      loading: true,
    }
  case type.GET_UTILITY_READING_DETAILS_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      utilityReadings: action.payload
    }
  case type.GET_UTILITY_READING_DETAILS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      utilityReadings: null,
    }


  case type.UPDATE_UTILITY_READING_DETAILS:
    return {
      ...state,
      updateLoader: true,
    }
  case type.UPDATE_UTILITY_READING_DETAILS_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.UPDATE_UTILITY_READING_DETAILS_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }
    
  case type.UPLOAD_UTILITY_READING_EXCEL_SHEET:
    return {
      ...state,
      uploadLoader: true,
    }
  case type.UPLOAD_UTILITY_READING_EXCEL_SHEET_SUCCESS:
    return {
      ...state,
      uploadLoader: false,
    }
  case type.UPLOAD_UTILITY_READING_EXCEL_SHEET_FAILURE:
    return {
      ...state,
      uploadLoader: false,
    }

    case type.GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME:
      return {
        ...state,
        loading: true,
      }
    case type.GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        flatsDetailsWithUsername: action.payload
      }
    case type.GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        flatsDetailsWithUsername: null,
      }

  default:
    return state
}
}

export function headListReducer(state = {
  loading: false,
  headList: null,
  error:null
}, action) {

switch (action.type) {
  case type.GET_HEAD_LIST:
    return {
      ...state,
      loading: true,
    }
  case type.GET_HEAD_LIST_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      headList: action.payload
    }
  case type.GET_HEAD_LIST_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      headList: null,
    }
  default:
    return state
}
}