import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import Button from "../../../../components/Button/Button";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { DownloadExt, UploadEx } from "../../../../assets";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearBulkUploadError } from "../../../../redux/actions/SetupRecordsActions/BulkUploadActions/BulkUploadAction";
import {
  saveManageInvoicePaymentExcel,
  uploadManageInvoicePaymentExcel,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import { getBankDetailList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import UploadExcelTable from "./UplaodExcelTable";
import { useNavigate } from "react-router-dom";
import { MANAGE_INVOICE_CURRENT_ENDPOINT } from "../../../../Routing/routes";
import Dropdown from "../../../../components/Dropdown/dropdown2";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";
import {
  downloadManageInvoicePaymentExcelSample,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../Routing/permissions";

export const headCells = [
  {
    id: "invoiceDetails",
    label: "Invoice Details",
    sort: false,
    width: "130px",
  },
  { id: "paymentMode", label: "Payment Mode", sort: false },
  { id: "dueAmount", label: "Due Amount", sort: false, width: "60px", },
  { id: "amountPaid", label: "Amount Paid", sort: false, },
  {
    id: "chqTranNumber",
    label: "Chq/Tran Number",
    sort: false,
    //width: "100px",
  },
  { id: "chqTranDate", label: "Chq/Tran Date", sort: false },
  { id: "bankName", label: "Bank Name", sort: false },
  { id: "branchName", label: "Branch Name", sort: false },
  { id: "paymentDate", label: "Payment Date", sort: false },
  { id: "clearDate", label: "Clear Date", sort: false },
];

const UploadPaymentExcel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const inputFile = useRef(null);
  const [uploadingExcelData, setUploadingExcelData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [bankId, setBankId] = useState("");
  const { loginData } = useSelector((state) => state.loginReducer);
  const { uploadLoader, loading, error, excelInvoiceData } = useSelector(
    (state) => state.manageInvoiceReducer
  );
  const { bankList } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const handleNavigation = (path) => () => {
    navigate(path);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };
  const handleDownloadSampleAndFormatExcel = (flag) => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `SocietyPayment ${flag}.xlsx` });
    };
    const data = {
      flag,
      onSucessCallback,
    };
    dispatch(downloadManageInvoicePaymentExcelSample(data));
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const onUpload = () => {
    if (!excelInvoiceData) {
      let data = {
        societyId: loginData?.default_community?.community_id,
        file,
        onRequestEndCallback: () => {
          setUploadingExcelData([]);
          setFile(null);
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        },
      };
      dispatch(uploadManageInvoicePaymentExcel(data));
    } else {
      let data = {
        societyId: loginData?.default_community?.community_id,
        bankId: bankId,
        uploadingExcelData,
        onRequestEndCallback: () => {
          setUploadingExcelData([]);
          dispatch(clearBulkUploadError());
          setFile(null);
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        },
        onSuccessCallback: () => {
          handleNavigation(MANAGE_INVOICE_CURRENT_ENDPOINT)
        }
      };
      dispatch(saveManageInvoicePaymentExcel(data));
    }
  };

  useEffect(() => {
    return () => dispatch(clearBulkUploadError());
  }, []);

  useEffect(() => {
    if (excelInvoiceData) {
      setErrorData(error);
      setUploadingExcelData(
        excelInvoiceData?.paymentdetail
          ? excelInvoiceData?.paymentdetail
          : []
      );
    }
  }, [excelInvoiceData]);
  useEffect(() => {
    dispatch(getBankDetailList({ societyId: loginData?.default_community?.community_id, }));
  }, [])
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Upload Payment Excel">
            {excelInvoiceData && getPermission(loginData, [permissions.DIRECT_ENTRY]) ?
              <Dropdown
                options={
                  bankList?.map((item) => ({
                    label: `${item?.bank_name} [Account - ${item?.account_no}]`,
                    value: item?.bankid,
                  })) || []
                }
                placeholder="Select a Bank"
                width="224px"
                height="36px"
                onSelect={(selectedItem) => {
                  setBankId(selectedItem.value);
                }}
                value={
                  bankId === ""
                    ? null
                    : {
                      label: bankList?.find(
                        (item) => item.bankid === bankId
                      )?.bank_name,
                      values: bankId,
                    }
                }
              /> : <></>}
            <div className="border-r border-[#CCC]"></div>
            <ButtonIco
              icon={DownloadExt}
              children="Download payment Excel"
              onClick={() => {
                handleDownloadSampleAndFormatExcel("sample");
              }}
            />
            <div className="border-r border-[#CCC]"></div>
            <Button
              label="Cancel"
              className="h-8"
              onClick={handleNavigation(-1)}
              type="button"
            />
            <Button
              label={
                <span className="inline-block w-12 text-enter">
                  {uploadLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : !excelInvoiceData ? (
                    "Process"
                  ) : (
                    "Upload"
                  )}
                </span>
              }
              className="h-8 px-4 disabled:opacity-50"
              disabled={(!file && !excelInvoiceData) || uploadLoader}
              onClick={onUpload}
            />
          </ListHeader>
        </div>
        {excelInvoiceData ? (
          <div className="w-full h-full">
            {/* {console.log(Object.entries(errorData))} */}
            {errorData ? Object.entries(errorData).map((item, index) => (
              <p className="p-2 text-xs text-red-700">{item[1]}</p>
            )) : <></>}
            <UploadExcelTable
              loading={loading}
              data={uploadingExcelData}
              columns={headCells}
              checkbox={false}
              setUploadingExcelData={setUploadingExcelData}
            ></UploadExcelTable>
            {excelInvoiceData?.paymentdetail?.length === 0 && !loading && (
              <div className="text-lg text-[#AAAAAA] my-10 text-center italic">
                No Records
              </div>
            )}
          </div>
        ) : (
          <div className="w-full mt-4">
            <p className="mt-6 mb-4 text-sm font-semibold text-center">
              {/* Note: Please do not leave any field empty except annual property
              tax, if it is not applied */}
            </p>
            <div className="">
              <label
                htmlFor="fileInput"
                className="block text-sm font-semibold"
              >
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  className="w-[60%] ml-48 flex justify-center border-dashed border-2 border-[#CCC] rounded-md text-center p-9 bg-[#F9FAFC] cursor-pointer"
                >
                  <div className="flex flex-col justify-center">
                    <div className="flex justify-center">
                      <UploadEx className="w-6" />
                    </div>
                    <input
                      type="file"
                      id="fileInput"
                      className="hidden"
                      accept={
                        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      }
                      onChange={handleFileChange}
                      ref={inputFile}
                    />
                    Upload .xlsx file
                    <p className="text-[#AAAAAA] text-[13px]">or drag and drop</p>
                  </div>
                </div>
              </label>
              <div className="w-[60%] ml-48 my-3 text-sm h-4 font-semibol text-nowrap text-[#AAAAAA] text-ellipsis overflow-hidden ">
                {file && `File Name: ${file?.name}`}
              </div>
              {error && !uploadLoader && (
                <div className="w-[60%] mt-4 ml-48 text-red-650 cursor-pointer text-wrap break-all text-xs font-semibold text-justify">
                  Error:
                  <br />
                  {typeof error === "string"
                    ? error
                    : Object.entries(error)?.map((item) => (
                      <div>
                        {/* {item[1]?.includes('Opening') ? '' : `Row ${item[0]}: `}   */}
                        {item[1]}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UploadPaymentExcel;
