import React, { useEffect, useRef } from "react";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Form, Formik } from "formik";
import { FlatValidation } from "../../../validationSchema/setupRecordsSchema/buildingSchema";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { getFlatDetails, addFlats, deleteFlat } from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import * as permissions from '../../../Routing/permissions'
import { getPermission } from "../../../utils/helpers/getPermissions";

const FlatTable = ({ wingId, societyId, setOnClickSave }) => {

  const { flatsData } = useSelector(state => state.wingsReducer)
  const { ipAddress } = useSelector(state => state.ipAddressReducer)
  const { loginData } = useSelector(state => state.loginReducer)
  const dispatch = useDispatch()
  const formRef = useRef();

  const handleDelete = (arrayHelpers, index, flat) => {
    if (flat.flatId) {
      dispatch(deleteFlat({
        flatId: flat.flatId,
        ipAddress,
        sessionId: loginData?.session_id,
        onSuccessCallback: () => {
          arrayHelpers.remove(index);
        }
      }))
    } else {
      arrayHelpers.remove(index);
    }
  }

  useEffect(() => {
    setOnClickSave(() => () => {
      formRef.current?.submitForm()
    })
  }, [formRef])


  return (
    <div className="table-container w-full">
      <Formik
        enableReinitialize
        initialValues={{
          flats: flatsData?.manage_flat_details ? flatsData?.manage_flat_details?.map((item) => ({
            flatId: item?.flat_id,
            flatNo: item?.flat_no,
            area: item?.['area_sq.ft'] || '0',
            numberOfFloors: item?.floor_no,
            virtualAccNo: item?.virtual_account_no || '',
            flatStatus: item?.flat_status || '',
            main_member:item?.main_member || ''
            // status: item?.main_member || '',
            // allotmentStatus: item?.main_member || false,
            // allotmentStatusValue: item?.main_member || '',
          })) : []
        }}
        innerRef={(f) => (formRef.current = f)}
        onSubmit={values =>
          dispatch(addFlats({ ...values, ipAddress, wingId, societyId, sessionId: loginData?.session_id, onSucessCallback: () => { dispatch(getFlatDetails({ wingId, societyId })) } }))
        }
        validationSchema={FlatValidation}
      >
        {
          ({ values, setFieldValue, }) => {
            return (
              <Form>
                <FieldArray
                  name="flats"
                  render={arrayHelpers => (
                    <>
                      <table className="table-auto w-full">
                        <thead>
                          <tr className="border-b border-[#CCCCCC] whitespace-nowrap text-xs text-[#AAAAAA] text-left">
                            <th className="w-[35px] px-2 py-2">S.No</th>
                            <th className="w-40 px-3">Flat Number</th>
                            <th className="w-[100px] px-3">Floor Number</th>
                            <th className="w-40 px-3 ">Billable Area (sq. Ft.)</th>
                            <th className="w-40 px-3 ">VAN</th>

                            <th className=" text-center px-3">Status</th>

                            <th className=" text-center px-3"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="h-4"></tr>
                          {
                            values.flats && values.flats.length > 0 && (
                              values.flats.map((flat, index) => (
                                <tr key={index} className="font-semibold text-xs">

                                  <td className="p-2 align-top pt-[17px]"> {(index + 1)?.toString()?.padStart(2, 0)}</td>

                                  <td className="w-48 px-2 align-middle">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter Detail"
                                      value={flat.flatNo}
                                      className="w-48 text-xs h-9"
                                      name={`flats.${index}.flatNo`}
                                      maxLength={15}
                                    />
                                  </td>


                                  <td className="px-2">
                                    <DropdownFM2
                                      options={
                                        [
                                          { label: '0', value: '0' },
                                          ...Array.from({ length: flatsData.wing?.no_of_floors }, (_, index) => {
                                            return {
                                              label: flatsData.wing?.no_of_floors - index,
                                              value: flatsData.wing?.no_of_floors - index
                                            }
                                          }).reverse()
                                        ]
                                      }
                                      width="110px"
                                      height="36px"
                                      placeholder="Select"
                                      value={{ label: flat.numberOfFloors, value: flat.numberOfFloors }}
                                      className="text-xs"
                                      onSelect={(selectedItem) => {
                                        setFieldValue(`flats.${index}.numberOfFloors`, selectedItem.value)
                                      }}
                                      name={`flats.${index}.numberOfFloors`}
                                    />
                                  </td>


                                  <td className="w-40 px-2">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter Detail"
                                      value={flat['area_sq.ft']}
                                      className="w-40 h-9"
                                      name={`flats.${index}.area`}
                                      maxLength={10}
                                      type='decimal'
                                    />
                                  </td>


                                  <td className="w-40 px-2">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter Detail"
                                      value={flat.virtual_account_no}
                                      className="w-40 h-9"
                                      name={`flats.${index}.virtualAccNo`}
                                      maxLength={10}
                                    />
                                  </td>

                                  {/* Status column values starts here */}
                                  <td className="p-2 align-top text-center">
                                    {/* <span> */}
                                    {
                                      <div className="flex space-x-1 ">
                                      
                                        <TextInputFM
                                          placeholder="Enter Detail"
                                          className=" w-full text-xs h-9"
                                          disabled={false}
                                          name={`flats.${index}.flatStatus`} />
                                        
                                      </div>
                                    }
                                    {/* </span> */}
                                  </td>

                                  {/* Delete column values starts here */}
                                  <td className=" align-top text-center text-[#00D94B] p-4">
                                    {
                                      getPermission(loginData, [permissions.DELETE_FLATS_PERMISSION]) &&
                                        values.flats?.[index].main_member ?
                                        <span className="pt-2">{values.flats?.[index].main_member ? "Allotted" : ""}</span>
                                        : 
                                        
                                        <button
                                          className={`${values.flats?.[index].main_member ? 'opacity-50' : ''} text-red-650 font-semibold  fill-neutral-600`}
                                          onClick={() => { handleDelete(arrayHelpers, index, flat) }}
                                          type='button'

                                        >
                                          <span className="mr-2">
                                            <FontAwesomeIcon icon={faTrashCan} />
                                          </span>
                                        </button>

                                    }

                                  </td>

                                </tr>
                              ))
                            )
                          }
                        </tbody>
                      </table>
                      {
                        getPermission(loginData, [permissions.ADD_FLATS_PERMISSION]) &&
                        <div className="flex justify-center my-2">
                          <div className="border-2 border-dashed rounded-md border-red-650 w-44 h-9 text-xs p-2 bg-[#FDF6F6] flex justify-center cursor-pointer "
                            onClick={() =>
                              arrayHelpers.push({
                                flatId: 0,
                                flatNo: '',
                                area: '',
                                numberOfFloors: '',
                                virtualAccNo: '',
                              })
                            }>
                            <button
                              className="border-dashed-2 text-red-650 font-bold rounded items-center"
                              type='button'
                            >
                              Add More Flat
                            </button>
                          </div>
                          {/* <Button className={'ms-5'} label={'Save'} type='submit' /> */}
                        </div>
                      }
                    </>
                  )
                  }
                />
              </Form>
            )
          }
        }
      </Formik>

    </div>
  );
};

export default FlatTable;
