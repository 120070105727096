import * as types from '../../index.types';

export function getSmsCreditPackageList(data) {
    return {
        type: types.GET_SMS_CREDIT_LIST,
        data
    }
}

export function getSmsCreditOrderHistoryList(data) {
    return {
        type: types.GET_SMS_CREDIT_ORDER_HISTORY_LIST,
        data
    }
}

export function getSmsCreditOrderDetails(data) {
    return {
        type: types.GET_SMS_CREDIT_ORDER_DETAILS,
        data
    }
}

export function buySmsRequest(data) {
    return {
        type: types.BUY_SMS_CREDIT,
        data
    }
}