import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import { logOut } from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import { errorMsg } from '../../../../consts/responseMessages';

export async function getBankDetailsAndTypeListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_BANK_DEATLS_AND_TYPE_API}?flag=${action.data.flag}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getBankDetailsReq(action) {

    return axiosInstance.get(apiEndpoints.GET_BANK_DETAILS_API)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addBankDetailsReq(action) {

    const BODY = {
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress,
        "bank_data": action.data?.bankData?.map((item) => (
            {
                "society_bank_detail_id": item?.societyBankDetailId || 0,
                "bank_detail_id": item?.detailsId,
                "account_no": parseInt(item?.accountNumber) ? parseInt(item?.accountNumber) : 0,
                "ifsc_code": item?.ifscCode?.toUpperCase(),
                "micr_code": item?.micrCode,
                "cheque_return_charge": parseFloat(item?.chequeReturnCharges) || 0,
                "bank_name_id": item?.bankNameId,
                "bank_account_type_id": item?.accountTypeId
            }))
    }

    return axiosInstance.post(apiEndpoints.ADD_BANK_DETIALS_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteBankDataReq(action) {

    const BODY = {
        "ip": action.data.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ids": [action.data?.id],
        "flag": action.data?.flag
    }

    return axiosInstance.delete(apiEndpoints.DELETE_BUILDING_DETAILS_API, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else {
                notify('warning', errors.response?.data.message || "Something went wrong");
            }
            return errors

        })
}

export async function getBankDetailsAuditLogReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_BANK_DETAILS_AUDIT_LOGS_API}?society_id=${action.data.societyId}&flag=society_bank`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getBankNameReq(action) {

    return axiosInstance.get(apiEndpoints.GET_BANK_NAME_LIST_API)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addBankNameReq(action) {

    const BODY = {
        "bank_name": action?.data?.bankName,
        "society_id": action.data?.societyId
    }

    return axiosInstance.post(apiEndpoints.ADD_BANK_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}