import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import Modal from "../Modal/Modal";
import { notify } from "../../utils/notification";
import "./Table.css";
import {
  DeleteIcon,
  EditIcon,
  PaymentAdjustmentIcon,
  PrinterIcon,
  SendSMSIcon,
  SortIcon,
  ViewIcon,
} from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination, { BillingPagination } from "../Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";
import {
  createSearchParams,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import Loader from "../Loader/Loader";
import {
  MAKE_PAYMENT_ENDPOINT,
  MANAGE_DEBIT_PREVIOUS_ENDPOINT,
  PAYMENT_AND_ADJUSTMENT_ENDPOINT,
  PRINT_DEBIT_NOTE_INVOICE_ENDPOINT,
  PRINT_DEBIT_NOTE_INVOICE_MULTI_ENDPOINT,
  PRINT_DEBIT_NOTE_RECEIPT_MULTI_ENDPOINT,
  VIEW_MANAGE_DEBIT_NOTE_PREVIOUS_ENDPOINT,
} from "../../Routing/routes";
import SendSmsModal from "../Modal/SendSmsModal";
import DeleteModal from "../Modal/DeleteModal";
import {
  deleteDebitNoteCurrent,
  getDebitNoteActionList,
} from "../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Dropdown2 from "../Dropdown/dropdown2";
import Button from "../Button/Button";

function ActionComponent({ id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="View" arrow>
        <ViewIcon
          className="fill-[#555] hover:fill-red-650 me-1.5"
          onClick={() => {
            navigate(`${VIEW_MANAGE_DEBIT_NOTE_PREVIOUS_ENDPOINT}/${id}`);
          }}
        />
      </Tooltip>
      <Tooltip title="Print" arrow>
        <Link target="_blank" to={`${PRINT_DEBIT_NOTE_INVOICE_ENDPOINT}/${id}`}>
          <PrinterIcon className="fill-[#555] hover:fill-red-650 me-1.5" />
        </Link>
      </Tooltip>
    </Box>
  );
}

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const ManageDebitPreviousTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  selected = [],
  setSelected = () => {},
  getTableData = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  // const [selected, setSelected] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [action, setAction] = useState("");
  const [isModal,setIsModal] = useState(false)
  const { updateLoader, manageDebitNoteActionList } = useSelector(
    (state) => state.manageDebitNoteReducer
  );
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.id) : [];
    setSelected(newSelected);
  };
  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };
  const handleChangePage = (newPage) => {
    searchParams.delete("page");
    navigate({
      pathname: `${MANAGE_DEBIT_PREVIOUS_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["page", newPage],
      ])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit");
      navigate({
        pathname: `${MANAGE_DEBIT_PREVIOUS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["pageLimit", value],
        ])}`,
      });
    }
  };
  const handleApply = () => {
    const societyId = loginData?.default_community?.community_id;
    switch (action) {
      case "Print Debit Note":
        navigate(PRINT_DEBIT_NOTE_INVOICE_MULTI_ENDPOINT, {
          state: { invid: selected },
        });
        break;

      case "Print Receipt(s)":
        navigate(PRINT_DEBIT_NOTE_RECEIPT_MULTI_ENDPOINT, {
          state: { invid: selected },
        });
        break;

      default:
        // Handle other actions if any
        break;
    }
  };

  const isSelected = (id) => selected.includes(id);

  useEffect(() => {
    setSelected([]);
  }, [searchParams]);
  useEffect(() => {
    dispatch(getDebitNoteActionList());
  }, [dispatch]);

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
           <Modal
                        isOpen={isModal}
                        onClose={() => setIsModal(false)}
                        title="Are you sure?"
                        children="Are you sure you want to apply this action? This change cannot be undone."
                        onConfirm={
                              handleApply
                        }
                        onCancel={() => setIsModal(false)}
                        loading={updateLoader}
                    />
      {!!selected?.length && (
        <div className="flex justify-end items-center border-t border-[#CCC] gap-2 p-2">
          <div className="flex items-center gap-2">
            <Dropdown2
              options={Object.entries(
                manageDebitNoteActionList?.data?.previous
              ).map((item) => ({
                label: item[0],
                value: item[0],
              }))}
              placeholder="Choose Action"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                setAction(selectedItem.value);
              }}
            />
            <Button
              label={
                <span className="w-8 inline-block">
                  {updateLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Apply"
                  )}
                </span>
              }
              className="font-semibold px-4 h-8"
              type="button"
                 onClick={() => {
                                                         if (!action) {
                                                           notify("warning", "Please select an action from the dropdown.");
                                                         } else {
                                                           setIsModal(true); 
                                                         }
                                                       }}
            />
          </div>
        </div>
      )}
      <TableContainer
        className="border-t border-[#CCC]"
        style={{ textWrap: "nowrap" }}
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{ pl: "0 !important", pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={
                      data?.length > 0 && selected?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      const isRowSelected = isSelected(row?.id);

                      return (
                        <TableRowStyled
                          key={row?.id}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox && (
                            <TableCellStyled
                              size="small"
                              sx={{ pl: "0 !important", zIndex: 0 }}
                              onClick={(event) => handleClick(event, row?.id)}
                            >
                              <Checkbox
                                checked={selected?.includes(row?.id)}
                                onChange={(e) => handleClick(e, row?.id)}
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}
                              />
                            </TableCellStyled>
                          )}

                          {/* Serial Number */}
                          <TableCellStyled
                            size="small"
                            align="left"
                            onClick={(event) => handleClick(event, row?.id)}
                          >
                            {row?.sNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.invoiceNo}
                          </TableCellStyled>

                          <TableCellStyled
                            size="small"
                            align="left"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {row.memberDetails} <br /> -{row.wingNo} -
                            {row.flatNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.invoiceDate}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.generationDate}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <div>
                              <b>Amount: </b> {row.amount}
                            </div>
                            {/* <div>
                              <b>Amount Paid: </b> {row.amountPaid}
                            </div>
                            <div>
                              <b>Amount Due: </b> {row.amountDue}
                            </div> */}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.status}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            <ActionComponent
                              id={row.id}
                              getTableData={getTableData}
                            />
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            totalCount={totalCount}
          />
          <BillingPagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

ManageDebitPreviousTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default ManageDebitPreviousTable;
