import React from "react";
import { DatePickerComponentFM2 } from "../../../../../components/DatePicker/DatePicker";
import { TextAreaFM } from "../../../../../components/InputFields/TextArea";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import { DropdownFM2 } from "../../../../../components/Dropdown/dropdown2";
import { Form, Formik } from "formik";
import { NEFTSchema } from "../../../../../validationSchema/financeSchema/billingManagementSchema";
import moment from "moment";
import Button from "../../../../../components/Button/Button";
import { CircularProgress } from "@mui/material";
import { getPermission } from "../../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../../Routing/permissions";

export const NEFTDetailsForm = ({
  invoiceDetails,
  updateLoader,
  loginData,
  handleSubmit: pHandleSubmit,
}) => {
  const handleSubmit = (values) => {
    pHandleSubmit(values);
  };

  return (
    <div className="flex flex-col justify-between w-full gap-5 mt-2">
      <Formik
        initialValues={{
          amount: Math.round(invoiceDetails?.data?.due) || "",
          bankId: "",
          transaction_number: "",
          date: new Date(),
          paymentdate: new Date(),
          cleardate: new Date(),
          bankname: "",
          remark: "",
          modeofpayment: "NEFT",
        }}
        enableReinitialize
        validationSchema={NEFTSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="pl-2 font-semibold">
                <span>NEFT Details</span>
              </div>
              <div className="w-[50%] p-2">
                <div className="flex items-center justify-between">
                  <div className="-mt-4 text-sm">Amount</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Amount"
                      className="w-56"
                      name="amount"
                    />
                  </div>
                </div>
                {getPermission(loginData, [
                  permissions.DIRECT_ENTRY_DEBIT_NOTE,
                ]) && (
                  <div className="flex items-center justify-between">
                    <div className="-mt-4 text-sm">Society Bank Name</div>
                    <div className="">
                      <DropdownFM2
                        options={
                          invoiceDetails?.data?.bankdetail?.map((item) => ({
                            label: `${item?.bank_name} [Account - ${item?.account_no}]`,
                            value: item?.bankid,
                          })) || []
                        }
                        placeholder="Select"
                        width="224px"
                        height="36px"
                        onSelect={(selectedItem) => {
                          formik.setFieldValue("bankId", selectedItem.value);
                        }}
                        value={
                          formik.values.bankId === ""
                            ? null
                            : {
                                label: invoiceDetails?.data?.bankdetail?.find(
                                  (item) => item.bankid === formik.values.bankId
                                )?.bank_name,
                                values: formik.values.bankId,
                              }
                        }
                        name="bankId"
                      />
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <div className="-mt-4 text-sm">Transaction Number</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Transaction No."
                      className="w-56"
                      name="transaction_number"
                      type="alphaNumber"
                      // maxLength={4}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Transaction Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM2
                      className="justify-between w-56 h-9"
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `date`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      minDate={new Date(invoiceDetails?.data?.invoice_date)}
                      name="date"
                      defaultValue={
                        formik.values.date ? new Date(formik.values.date) : null
                      }
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="-mt-4 text-sm">Bank Name</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Bank Name"
                      className="w-56"
                      name="bankname"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Payment Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM2
                      className="w-56 h-9"
                      name={`paymentdate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `cleardate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                        formik.setFieldValue(
                          `paymentdate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={
                        formik.values.paymentdate
                          ? new Date(formik.values.paymentdate)
                          : null
                      }
                      minDate={new Date(invoiceDetails?.data?.invoice_date)}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Payment Clear Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM2
                      className="w-56 h-9"
                      name={`cleardate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `cleardate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={
                        formik.values.cleardate
                          ? new Date(formik.values.cleardate)
                          : null
                      }
                      minDate={new Date(invoiceDetails?.data?.invoice_date)}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div>
                  <TextAreaFM
                    label="Remarks"
                    placeholder="Enter Remarks"
                    className="w-56 h-20"
                    name="remark"
                    maxLength={300}
                  />
                </div>
                <div className="flex flex-row justify-end">
                <div className="flex gap-3 w-56 mt-2">
                  <Button
                    label={
                      <span className="inline-block w-16">
                        {updateLoader ? (
                          <CircularProgress sx={{ color: "white" }} size={17} />
                        ) : (
                          "Submit"
                        )}
                      </span>
                    }
                    className="h-8 px-5"
                    type="submit"
                  />
                </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const EditNEFTDetailsForm = ({
  invoiceDetails,
  updateLoader,
  loginData,
  handleSubmit: pHandleSubmit,
}) => {
  const handleSubmit = (values) => {
    pHandleSubmit(values);
  };

  return (
    <div className="flex flex-col justify-between w-full gap-5 mt-2">
      <Formik
        initialValues={{
          amount:
            Math.round(invoiceDetails?.data?.payments?.[0]?.grandtotal) ||
            "" ||
            "",
          bankId: invoiceDetails?.data?.payTo
            ? invoiceDetails?.data?.bankdetail?.find(
                (item) => item.gl_code === invoiceDetails?.data?.payTo
              )?.bankid
            : "",
          transaction_number: invoiceDetails?.data?.transaction_number || "",
          date: invoiceDetails?.data?.dated || "",
          paymentdate: invoiceDetails?.data?.payment_date || "",
          cleardate: invoiceDetails?.data?.clear_date || "",
          bankname: invoiceDetails?.data?.bank_name || "",
          remark: invoiceDetails?.data?.remarks || "",
          modeofpayment: "NEFT",
        }}
        enableReinitialize
        validationSchema={NEFTSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="pl-2 font-semibold">
                <span>NEFT Details</span>
              </div>
              <div className="w-[50%] p-2">
                <div className="flex items-center justify-between">
                  <div className="-mt-4 text-sm">Amount</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Amount"
                      className="w-56"
                      name="amount"
                      // maxLength={8}
                    />
                  </div>
                </div>
                {getPermission(loginData, [
                  permissions.DIRECT_ENTRY_DEBIT_NOTE,
                ]) && (
                  <div className="flex items-center justify-between">
                    <div className="-mt-4 text-sm">Society Bank Name</div>
                    <div className="">
                      <DropdownFM2
                        options={
                          invoiceDetails?.data?.bankdetail?.map((item) => ({
                            label: `${item?.bank_name} [Account - ${item?.account_no}]`,
                            value: item?.bankid,
                          })) || []
                        }
                        placeholder="Select"
                        width="224px"
                        height="36px"
                        onSelect={(selectedItem) => {
                          formik.setFieldValue("bankId", selectedItem.value);
                        }}
                        value={
                          formik.values.bankId === ""
                            ? null
                            : {
                                label: invoiceDetails?.data?.bankdetail?.find(
                                  (item) => item.bankid === formik.values.bankId
                                )?.bank_name,
                                values: formik.values.bankId,
                              }
                        }
                        name="bankId"
                      />
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <div className="-mt-4 text-sm">Transaction Number</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Transaction No."
                      className="w-56"
                      name="transaction_number"
                      type="alphaNumber"
                      // maxLength={4}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Transaction Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM2
                      className="justify-between w-56 h-9"
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `date`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      minDate={new Date(invoiceDetails?.data?.invoice_date)}
                      name="date"
                      defaultValue={
                        formik.values.date ? new Date(formik.values.date) : null
                      }
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="-mt-4 text-sm">Bank Name</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Bank Name"
                      className="w-56"
                      name="bankname"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Payment Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM2
                      className="w-56 h-9"
                      name={`paymentdate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `paymentdate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={
                        formik.values.paymentdate
                          ? new Date(formik.values.paymentdate)
                          : null
                      }
                      minDate={new Date(invoiceDetails?.data?.invoice_date)}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Payment Clear Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM2
                      className="w-56 h-9"
                      name={`cleardate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `cleardate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={
                        formik.values.cleardate
                          ? new Date(formik.values.cleardate)
                          : null
                      }
                      minDate={new Date(invoiceDetails?.data?.invoice_date)}
                    />
                  </div>
                </div>
                <div>
                  <TextAreaFM
                    label="Remarks"
                    placeholder="Enter Remarks"
                    className="w-56 h-20"
                    name="remark"
                    maxLength={300}
                  />
                </div>
                <div className="ml-[250px] mb-2">
                  <Button
                    label={
                      <span className="inline-block w-16">
                        {updateLoader ? (
                          <CircularProgress sx={{ color: "white" }} size={17} />
                        ) : (
                          "Submit"
                        )}
                      </span>
                    }
                    className="h-8 px-5"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NEFTDetailsForm;
