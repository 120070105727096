import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import FilterTextField from "../../../../components/InputFields/FilterTextFields";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { TextInput } from "../../../../components/InputFields/TextInput";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx, PrinterIcon } from "../../../../assets";
import UploadTenantExcelModal from "../../../../components/Modal/UploadTenantExcelModal";
import UploadDebitNoteExcelModal from "../../../../components/Modal/UploadDebitNoteExcelUploadModal";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  MANAGE_FLATS_AUDIT_LOG_ENDPOINT,
  MANAGE_FLATS_ENDPOINT,
} from "../../../../Routing/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import ButtonG from "../../../../components/Button/ButtonG";
import {
  getFlatAuditLogDetails,
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import ManageFlatsAuditLogTable from "./ManageFlatsAuditLogTable";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";

const audit = [
  { label: "Add", value: 1 },
  { label: "Edit", value: 2},
  { label: "Delete", value: 3 },
];


const ManageFlatsAuditLog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);

  const [memberName, setMemberName] = useState("");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState(null);
  const [headCells, setHeadCells] = useState([]);
  const [total, setTotal] = useState([]);
  const [totalLoading, setTotalLoading] = useState(true);

  const { wingsData, flatsData, flatsAuditLogData, flatsAuditLogDataLoading, loading } = useSelector((state) => state.wingsReducer);

  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_FLATS_AUDIT_LOG_ENDPOINT}/${id}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_FLATS_AUDIT_LOG_ENDPOINT}/${id}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };
  const handleSelectAuditType = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("auditType")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("auditType");
      navigate({
        pathname: `${MANAGE_FLATS_AUDIT_LOG_ENDPOINT}/${id}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["auditType", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${MANAGE_FLATS_AUDIT_LOG_ENDPOINT}/${id}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${MANAGE_FLATS_AUDIT_LOG_ENDPOINT}/${id}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    setMemberName("");
    navigate(`${MANAGE_FLATS_AUDIT_LOG_ENDPOINT}/${id}`);
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));

    dispatch(
      getFlatDetails({ societyId, wingId: id })
    );

  }, []);

  const getTableData = () => {
    dispatch(
      getFlatAuditLogDetails({
        societyId: loginData?.default_community?.community_id,
        flatId: searchParams.get("flatId") || "",
        auditType: searchParams.get("auditType") || "",
        wingId: id,
        startDate: searchParams.get("startDate") || "",
        endDate: searchParams.get("endDate") || "",
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
      })
    );
  };
  useEffect(() => {
    getTableData();
  }, [searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack onClick={()=>navigate(`${MANAGE_FLATS_ENDPOINT}/${id}`)} title="Flat Audit Logs">
            <div className="flex text-sm gap-3">
              {/* <button
                type="button"
                onClick={handlePrintData}
                className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              >
                <PrinterIcon className="mr-2 fill-red-650" /> Print
              </button>
              <ButtonIco
                type="button"
                onClick={handleDownloadExcel}
                icon={DownloadExt}
                children="Download Excel"
              /> */}
              <b className="text-base">Wing No: {wingsData?.find(
                (item) =>
                  item.wing_id?.toString() === id
              )?.number}
              </b>
            </div>
          </ListHeaderBack>
        </div>

        <div className="flex items-center gap-3 p-2">
          {/* <Dropdown2
            options={
              wingsData?.map((item) => ({
                value: item?.wing_id,
                label: item?.number,
              })) || []
            }
            placeholder="Select Wing"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectWing(selectedItem);
            }}
            value={
              searchParams.get("wingId") && {
                value: searchParams.get("wingId"),
                label: wingsData?.find(
                  (item) =>
                    item.wing_id?.toString() === searchParams.get("wingId")
                )?.number,
              }
            }
          /> */}
          <Dropdown2
            options={
              (flatsData?.manage_flat_details?.length &&
                flatsData?.manage_flat_details?.map((item) => ({
                  value: item?.flat_id,
                  label: item?.flat_no,
                }))) ||
              []
            }
            placeholder="Select Flat"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectFlat(selectedItem);
            }}
            value={
              searchParams.get("flatId") && {
                value: searchParams.get("flatId"),
                label: flatsData?.manage_flat_details?.find(
                  (item) =>
                    item.flat_id?.toString() === searchParams.get("flatId")
                )?.flat_no,
              }
            }
          />
          <Dropdown2
            options={audit}
            placeholder="Select Audit Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectAuditType(selectedItem);
            }}
            value={
              searchParams.get("auditType") && {
                value: searchParams.get("auditType"),
                label: audit?.find(
                  (item) =>
                    item.value == searchParams.get("auditType")
                )?.label,
              }
            }
          />


          <div className="flex justify-between gap-3 items-center">
            <div className="text-sm"> Date</div>
            <RangeDatePicker
              className={"w-[190px] h-8"}
              defaultStartValue={
                searchParams.get("startDate")
                  ? new Date(searchParams.get("startDate"))
                  : ""
              }
              defaultEndValue={
                searchParams.get("endDate")
                  ? new Date(searchParams.get("endDate"))
                  : ""
              }
              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}
            />
          </div>
          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>
        <div>
          <ManageFlatsAuditLogTable
            loading={flatsAuditLogDataLoading}
            data={flatsAuditLogData?.list}
            columns={headCells}
            checkbox={false}
            pagination={flatsAuditLogData?.list?.length}
            totalCount={flatsAuditLogData?.total_count}
            totalPages={flatsAuditLogData?.total_pages}
            start={
              (flatsAuditLogData?.current_page - 1) *
              flatsAuditLogData?.limit +
              1
            }
            end={
              (flatsAuditLogData?.current_page - 1) *
              flatsAuditLogData?.limit +
              flatsAuditLogData?.list?.length
            }
            currentPage={flatsAuditLogData?.current_page}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          />

          {!flatsAuditLogData?.list?.length > 0 &&
            !flatsAuditLogDataLoading && (
              <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                No Records
              </div>
            )}
        </div>
      </div>
    </Layout>
  );
};

export default ManageFlatsAuditLog;
