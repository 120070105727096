import React, { useState } from "react";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import { AppleStoreIcon, GooglePlayIcon, ZipgridIcon } from "../../../../../assets";
import IncermentDropdown from "../../../../../components/Dropdown/IncrementDropdown";
import { TextInput } from "../../../../../components/InputFields/TextInput";

const GSTSettings = ({
  setInvoiceSettingList,
  invoiceSettingList,
}) => {
  const [checked, setChecked] = useState(false);
  const [ticketPasses, setTicketPasses] = useState(0);

  const handleTicketPassesChange = (e) => {
    setTicketPasses(e.target.value);
  };

  const handleChecked = (event, setFieldValue) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue("isUrgent", "Yes");
    } else {
      setFieldValue("isUrgent", "No");
    }
  };

  return (
    <div>
      <div className="mt-2">
        <table className="border-separate w-[65%]">
          <tbody>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Auto Calculate GST Charges</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.autoCalculateGstCharge==='Yes'?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, autoCalculateGstCharge: e.target.checked ? 'Yes' : "No" });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">SGST Rate</td>
              <td className="pb-4">
                <IncermentDropdown
                  id="invoiceFontSize"
                  type="number"
                  value={invoiceSettingList.servicetax_rate}
                  className="w-[60px]"
                  onChange={(e)=>{
                    //invoiceSetting.printInvoiceFontSize = e.target.value
                    setInvoiceSettingList({...invoiceSettingList, servicetax_rate: Math.abs(e.target.value) });
                  }}
                />
                <span className="text-[#AAA] text-xs ml-2">%</span>
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">CGST Rate</td>
              <td className="pb-4">
                <IncermentDropdown
                  id="invoiceFontSize"
                  type="number"
                  value={invoiceSettingList.Sb_Cess}
                  className="w-[60px]"
                  onChange={(e)=>{
                    //invoiceSetting.printInvoiceFontSize = e.target.value
                    setInvoiceSettingList({...invoiceSettingList, Sb_Cess: Math.abs(e.target.value) });
                  }}
                />
                 <span className="text-[#AAA] text-xs ml-2">%</span>
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">IGST Rate</td>
              <td className="pb-4">
                <IncermentDropdown
                  id="invoiceFontSize"
                  type="number"
                  value={invoiceSettingList.kk_Cess}
                  className="w-[60px]"
                  onChange={(e)=>{
                    //invoiceSetting.printInvoiceFontSize = e.target.value
                    setInvoiceSettingList({...invoiceSettingList, kk_Cess: Math.abs(e.target.value) });
                  }}
                />
                 <span className="text-[#AAA] text-xs ml-2">%</span>
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Montly Excemption Limit (Rs) </td>
              <td className="pb-4">
                <TextInput
                  value={invoiceSettingList.exemption_limit}
                  className="w-56"
                  type="decimal"
                  placeholder="Enter Excemption Limit"
                  onChange={(e)=>{
                    //invoiceSetting.printInvoiceFontSize = e.target.value
                    setInvoiceSettingList({...invoiceSettingList, exemption_limit: Math.abs(e.target.value) });
                  }}
                />
              </td>
            </tr>
            {/* <tr className="items-center text-sm">
              <td className="pb-4">GST Applied On </td>
              <td className="pb-4">
                <span className="mr-2"><input
                  type="radio"
                  name="appliedOn"
                  value="Total Amount"
                  checked={invoiceSettingList.appliedOn === "Total Amount"}
                  onChange={() =>  setInvoiceSettingList({ ...invoiceSettingList, appliedOn: "Total Amount" })}
                  className="mr-2"
                  style={{
                    accentColor: "#CF001C",
                  }}
                /> Total Amount </span>
                <span className="mr-2"><input
                  type="radio"
                  name="appliedOn"
                  value="Net Amount"
                  checked={invoiceSettingList.appliedOn === "Net Amount"}
                  onChange={() =>  setInvoiceSettingList({ ...invoiceSettingList, appliedOn: "Net Amount" })}
                  className="mr-2"
                  style={{
                    accentColor: "#CF001C",
                  }}
                /> Net Amount</span>
              </td>
            </tr> */}
           
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GSTSettings;
