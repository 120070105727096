import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import parse from "html-react-parser";

import "../../../../components/TableComponent/Table.css";

import {
  DeleteIcon,
  PaymentIcon,
  SendSMSIcon,
  PrinterIcon,
  SortIcon,
  ViewIcon,
  EditIcon,
  PaymentAdjustmentIcon,
} from "../../../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../../../../components/ItemsPerPage/ItemsPerPage";
import Pagination, {
  BillingPagination,
} from "../../../../components/Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../../../utils/helpers/universalFunctions";
import {
  Link,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import {
  MAKE_PAYMENT_ENDPOINT,
  MANAGE_DEBIT_CURRENT_ENDPOINT,
  PRINT_DEBIT_NOTE_INVOICE_ENDPOINT,
  VIEW_MANAGE_DEBIT_NOTE_ENDPOINT,
  EDIT_MANAGE_DEBIT_NOTE_ENDPOINT,
  PAYMENT_AND_ADJUSTMENT_DEBIT_NOTE_ENDPOINT,
  MAKE_PAYMENT_DEBIT_NOTE_ENDPOINT,
  PRINT_DEBIT_NOTE_INVOICE_MULTI_ENDPOINT,
  MAKE_PAYMENT_DEBIT_NOTE_MULTI_ENDPOINT,
  PRINT_DEBIT_NOTE_RECEIPT_MULTI_ENDPOINT,
  INVOICE_REPORT_ENDPOINT,
  MANAGE_FLATS_AUDIT_LOG_ENDPOINT,
} from "../../../../Routing/routes";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import BankSelectionModel from "../../../Finance/BillingManagement/ManageDebitNote/BankSelectionModal";
import SendSmsModal from "../../../../components/Modal/SendSmsModal";
import { useSelector } from "react-redux";
import Button from "../../../../components/Button/Button";
import { societyStaffTypeOptions } from "../../../../consts/DropDownOptions";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { useDispatch } from "react-redux";
import {
  getDebitNoteActionList,
  sendDebitNoteCurrentEmailSms,
  deleteDebitNoteCurrent,
  resendDebitNoteCurrentEmailSms,
  resendDebitNoteReceiptEmailSms,
  deleteDebitNoteReceipt,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import { notify } from "../../../../utils/notification";
import moment from "moment";


const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px",
    wordBreak: "break-word",
  },
}));
const list = [
  { label: "heading", id: "heading" },
  { label: "Flat Number", id: "flat no" },
  { label: "Floor Number", id: "floor no" },
  { label: "Build Up Area", id: "area sq.ft" },
  { label: "VAN", id: "van" },
];
const ManageFlatsAuditLogTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = false,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  getTableData = false,
  selected = [],
  setSelected = () => { },
}) => {
  const navigate = useNavigate();
  const {id}=useParams();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [selected, setSelected] = useState([]);
  const [receiptId, setReceiptId] = useState([]);
  const [action, setAction] = useState("");


  const { loginData } = useSelector((state) => state.loginReducer);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.id) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page");
    navigate({
      pathname: `${MANAGE_FLATS_AUDIT_LOG_ENDPOINT}/${id}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["page", newPage],
      ])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit");
      navigate({
        pathname: `${MANAGE_FLATS_AUDIT_LOG_ENDPOINT}/${id}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["pageLimit", value],
        ])}`,
      });
    }
  };


  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(
                    data,
                    getComparator(order, orderBy)
                  )?.map((row, rowIndex) => {

                    return (
                      <React.Fragment key={rowIndex}>

                        <TableRowStyled
                          key={row?.id}
                          role="checkbox"
                          tabIndex={-1}
                          style={{ background: '#f4f5f8' }}
                        >
                            <TableCellStyled
                            size="small"
                            align="left"
                            width="25%"
                          >
                            <div className="font-semibold">
                             {row?.audit_type}
                            </div>
                          </TableCellStyled>
                          <TableCellStyled
                            size="small"
                            align="left"
                            width="25%"

                          >
                            <div className="font-semibold">
                              Flat No: {row?.flat_no}
                            </div>
                          </TableCellStyled>
                        
                          <TableCellStyled
                            size="small"
                            align="left"
                            width="50%"                        
                          >
                            <div>
                              <div className="text-xs text-right text-[#888]">{`Modified By Type 
                                ${row?.modified_by} On ${moment(
                                  new Date(row?.modified_dateTime)
                                )?.format("MMM DD, YYYY hh:mm A")}`

                              }</div>
                              <div className="text-xs text-right text-[#888]">{`IP Address:
                                 ${row?.IpAddress} 
                                Session ID -
                                ${row?.modified_sessionId}`
                              }</div>
                            </div>
                          </TableCellStyled>

                        </TableRowStyled>
                        {
                          list.map((item, index) => {
                            return (<TableRowStyled
                              key={item?.id + index}
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <TableCellStyled
                                size="small"
                                align="left"

                              >
                                {index == 0 ? '' : item?.label}
                              </TableCellStyled>
                              <TableCellStyled
                                size="small"
                                align="left"
                              >
                                <div
                                  className={`${index == 0 && 'font-bold'}`}
                                > {index == 0 ? "Old Value" : row?.old_flats_details?.[item?.id] || "-"}</div>

                              </TableCellStyled>
                              <TableCellStyled
                                size="small"
                                align="left"
                              >
                                <div
                                className={`${index==0 && 'font-bold'}`}
                                
                                >{index == 0 ? "New Value" : row?.new_flats_details?.[item?.id] || "-"}</div>

                              </TableCellStyled>
                            </TableRowStyled>)
                          }
                          )
                        }
                      </React.Fragment>

                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};


export default ManageFlatsAuditLogTable;
