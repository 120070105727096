import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Search from "../../../components/SearchBox/Search";
import Button from "../../../components/Button/Button";
import {
  ADD_CAUSE_ENDPOINT,
  CREDIT_DEBIT_NOTE_CAUSE_ALL_AUDIT_LOGS_ENDPOINT,
  CREDIT_DEBIT_NOTE_CAUSE_ENDPOINT,
  DEBIT_NOTE_SETTINGS_ENDPOINT,
} from "../../../Routing/routes";
import { createSearchParams, useNavigate, useSearchParams,useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ButtonG from "../../../components/Button/ButtonG";
import { getCreditDebitTariffHeaderList } from "../../../redux/actions/FinanceActions/SettingActions/CreditDebitTariffHeaderAction";
import { useSelector } from "react-redux";
import CreditDebitNoteCauseTable from "./CreditDebitNoteCauseTable";


const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: '70px' },
  { id: "orderNo", label: "Order No.", sort: true, width: '100px' },
  { id: "type", label: "Type", sort: true, width: '120px'},
  { id: "description", label: "Description", sort: true, width: '450px' },
  { id: "amount", label: "Amount", sort: true },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const CreditDebitNoteCause = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, tariffHeaderList} = useSelector(state=>state.creditDebitTariffHeaderReducer)

  const handleAddCauseClick = () => {
    navigate(`${ADD_CAUSE_ENDPOINT}?type=${id}`);
  };

  const handleDebitCauseClick = () => {
    navigate(DEBIT_NOTE_SETTINGS_ENDPOINT);
  };

  const handleCreditDebitAuditLogsClick = () => {
    navigate(CREDIT_DEBIT_NOTE_CAUSE_ALL_AUDIT_LOGS_ENDPOINT);
  }

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete("search")
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      navigate({
        pathname: `${CREDIT_DEBIT_NOTE_CAUSE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text]])}`,
      })
    }
  }

  const handleClear = ()=>{
    navigate(CREDIT_DEBIT_NOTE_CAUSE_ENDPOINT)
  }

  const handleCancelClick = ()=>{
    navigate(-1)
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      typeId: id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
    }

    dispatch(getCreditDebitTariffHeaderList(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Credit/Debit Note Tariff Header">
            <div className="flex items-center gap-3">
              {/* <Button
                label="Debit Note Setting"
                onClick={handleDebitCauseClick}
              /> */}
              <ButtonG label="Cancel" onClick={handleCancelClick} type='button' />
              <Button label="Audit Logs" onClick={handleCreditDebitAuditLogsClick}/>
              <div className="border-r border-[#CCC] h-full"></div>
              <Button label="Add Tariff Header" onClick={handleAddCauseClick} />
            </div>
          </ListHeader>
        </div>

        <div className="flex justify-between gap-2 p-2">
          <Search height="8" placeholder={'Search Order No.'} onclick={handleSearch} value={searchParams.get("search")} />
          <ButtonG label='Clear' className='h-8' onClick={handleClear} />
        </div>

        <div className="ml-1">
          <CreditDebitNoteCauseTable
            loading={loading}
            data={tariffHeaderList?.causes?.map((item, index)=>({
              sNo: (((parseInt(tariffHeaderList?.paginate?.current)) * tariffHeaderList?.paginate?.limit + 1)+index)?.toString()?.padStart(2, "0"),
              headerId: item?.id,
              orderNo: item?.orderid?.toString() || '-',
              type: item.type ? (item.type==='debitnote' ? 'Debit Note' : 'Credit Note' ) :'-',
              description: item?.cause || '-',
              amount: item?.amount || '0',
            }))}
            columns={headCells}
            checkbox={false}
            pagination={tariffHeaderList?.causes?.length}
            totalCount={tariffHeaderList?.paginate?.total_count}
            totalPages={tariffHeaderList?.paginate?.total_page}
            start={(parseInt(tariffHeaderList?.paginate?.current)) * tariffHeaderList?.paginate?.limit + 1}
            end={(parseInt(tariffHeaderList?.paginate?.current)) * tariffHeaderList?.paginate?.limit + tariffHeaderList?.causes?.length}
            currentPage={parseInt(tariffHeaderList?.paginate?.current)+1}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></CreditDebitNoteCauseTable>
        </div>
        {!tariffHeaderList?.causes?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CreditDebitNoteCause;
