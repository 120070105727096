import React, { useEffect } from 'react'
import ListHeaderBack from '../../../components/ListHeader/ListHeaderBack'
import Layout from '../../Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSmsCreditOrderDetails } from '../../../redux/actions/SetupRecordsActions/SocietyProfileSetupActions/SmsCreditAction';
import { useSelector } from 'react-redux';

export const smsOrder = [
  {
    key: "ticket_id",
    label: "Sms Credit:",
    value: "034",
  },
  {
    key: "open_for",
    label: "Amount:",
    value: "Testing",
  },
  {
    key: "logged_by",
    label: "Sent Date:",
    value: "Lift Maintenance Recovery",
  },
  {
    key: "flat_no",
    label: "Status:",
    value: "N/A",
  },
]

function ViewSmsOrder() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, smsCreditOrderDetails } = useSelector((state) => state.smsCreditReducer)

  useEffect(() => {
    dispatch(getSmsCreditOrderDetails({ id }));
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack title="View SMS Order" onClick={() => { navigate(-1) }}>
          </ListHeaderBack>
        </div>
        <div className="py-1 gap-2 my-1">
          <table className="table-auto w-full">
            <tbody>
              <tr className="text-[#222222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">Sms Credit:</td>
                <td className="py-2">{smsCreditOrderDetails?.credit || '-'}</td>
              </tr>
              <tr className="text-[#222222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">Amount:</td>
                <td className="py-2">{smsCreditOrderDetails?.amount || '-'}</td>
              </tr>
              <tr className="text-[#222222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">Sent Date:</td>
                <td className="py-2">{smsCreditOrderDetails?.date || '-'}</td>
              </tr>
              <tr className="text-[#222222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">Status:</td>
                <td className="py-2">{smsCreditOrderDetails?.status} {(smsCreditOrderDetails?.status == 'Accepted' && smsCreditOrderDetails?.modification_date) && `On ${smsCreditOrderDetails?.modification_date}` }</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default ViewSmsOrder