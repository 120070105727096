import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Button from "../../../../components/Button/Button";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ManageAdvanceNoteTable from "./ManageAdvanceNoteTable";
import {
  ADD_ADVANCE_NOTE_ENDPOINT,
  _ADVANCE_NOTE_ENDPOINT,
  UPLOAD_ADVANCE_NOTE_ENDPOINT,
  MANAGE_ADVANCE_NOTE_ENDPOINT,
} from "../../../../Routing/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import moment from "moment";
import ButtonG from "../../../../components/Button/ButtonG";
import { getManageAdvanceList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction";
import Search from "../../../../components/SearchBox/Search";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../Routing/permissions";

const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: "40px" },
  { id: "invoiceNo", label: "Invoice No.", sort: true, width: "120px" },
  { id: "date", label: "Date", sort: true, width: "80px" },
  { id: "debitName", label: "Debit A/C", sort: true, width: "130px" },
  { id: "creditName", label: "Credit A/C", sort: true, width: "130px" },
  { id: "memberName", label: "Member Name", sort: true, width: "130px" },
  { id: "amount", label: "Amount", sort: true, width: "80px" },
  { id: "remarks", label: "Remarks", sort: true, width: "80px" },
  { id: "status", label: "Status", sort: true, width: "95px" },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const AdvanceNote = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, advanceNoteList } = useSelector(
    (state) => state.manageAdvanceNoteReducer
  );
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${MANAGE_ADVANCE_NOTE_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handelSelectWing = (selectedItem) => {
    searchParams.delete("page");
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );

      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_ADVANCE_NOTE_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };
  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("typeid");
    navigate({
      pathname: `${MANAGE_ADVANCE_NOTE_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["typeid", selectedItem.value],
      ])}`,
    });
  };
  const handelSelectFlat = (selectedItem) => {
    searchParams.delete("page");
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_ADVANCE_NOTE_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(MANAGE_ADVANCE_NOTE_ENDPOINT);
  };

  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || "",
      pageLimit: searchParams.get("pageLimit") || 10,
      wingId: searchParams.get("wingId") || "",
      flatId: searchParams.get("flatId") || "",
      InvoiceType: searchParams.get("typeid") || 1,
    };
    dispatch(getManageAdvanceList(data));
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }
  }, []);

  useEffect(() => {
    getTableData();
  }, [searchParams]);
  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists);
    }
  }, [typeLists]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Advance Note">
            <div className="flex gap-3 text-sm">
              {/* <div className="border-r border-[#CCC]"></div> */}
              {getPermission(loginData, [permissions.ADD_ADVANCE_NOTE]) && (
                <Button
                  label="Upload Advance Note Excel"
                  className="h-8"
                  onClick={handleNavigation(UPLOAD_ADVANCE_NOTE_ENDPOINT)}
                />
              )}
              {getPermission(loginData, [permissions.ADD_ADVANCE_NOTE]) && (
                <Button
                  label="Add Advance Note"
                  className="h-8 px-4"
                  onClick={handleNavigation(ADD_ADVANCE_NOTE_ENDPOINT)}
                />
              )}
            </div>
          </ListHeader>
        </div>

        <div className="flex flex-row justify-between gap-4 p-1 my-1">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
            placeholder="Search By Invoice No."
          />

          <div className="flex items-center justify-between gap-3">
            <Dropdown2
              options={
                invoiceTypeList
                  ? invoiceTypeList?.map((item) => ({
                      label: item?.invoice_title,
                      value: item?.id,
                    }))
                  : []
              }
              placeholder="Invoice type"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectInvoiceType(selectedItem);
              }}
              value={
                searchParams.get("typeid") && {
                  value: searchParams.get("typeid"),
                  label: invoiceTypeList
                    ? invoiceTypeList?.find(
                        (item) =>
                          item?.id === parseInt(searchParams.get("typeid"))
                      )?.invoice_title
                    : "",
                }
              }
            />
            {/* <div className="text-sm">Wing No.</div> */}
            {(loginData?.user_type == "Society"
              ? true
              : !getPermission(loginData, [
                  permissions.VIEW_OWN_ADVANCE_NOTE,
                ])) && (
              <>
                {" "}
                <Dropdown2
                  options={
                    wingsData?.map((item) => ({
                      value: item?.wing_id,
                      label: item?.number,
                    })) || []
                  }
                  placeholder="Select Wing"
                  className="text-[11px] ms-4"
                  width="172px"
                  height="32px"
                  onSelect={(selectedItem) => {
                    handelSelectWing(selectedItem);
                  }}
                  value={
                    searchParams.get("wingId") && {
                      value: searchParams.get("wingId"),
                      label: wingsData?.find(
                        (item) =>
                          item.wing_id?.toString() ===
                          searchParams.get("wingId")
                      )?.number,
                    }
                  }
                />
                {/* <div className="text-sm">Flat No.</div> */}
                <Dropdown2
                  options={
                    (searchParams.get("wingId") &&
                      flatsData?.manage_flat_details?.map((item) => ({
                        value: item?.flat_id,
                        label: item?.flat_no,
                      }))) ||
                    []
                  }
                  placeholder="Select Flat"
                  className="text-[11px] ms-4"
                  width="172px"
                  height="32px"
                  onSelect={(selectedItem) => {
                    handelSelectFlat(selectedItem);
                  }}
                  value={
                    searchParams.get("flatId") && {
                      value: searchParams.get("flatId"),
                      label: flatsData?.manage_flat_details?.find(
                        (item) =>
                          item.flat_id?.toString() ===
                          searchParams.get("flatId")
                      )?.flat_no,
                    }
                  }
                />
              </>
            )}
            <ButtonG label="Clear" className="h-8" onClick={handleClear} />
          </div>
        </div>
        <div>
          <ManageAdvanceNoteTable
            loading={loading}
            data={advanceNoteList?.list?.map((item, index) => ({
              sNo: (
                parseInt(advanceNoteList?.paginate?.current) *
                  advanceNoteList?.paginate?.limit +
                1 +
                index
              )
                ?.toString()
                ?.padStart(2, "0"),
              advanceNoteId: item?.id,
              jounralId: item?.journal_id,
              invoiceNo: item?.invoice_no,
              date: validateDate(item.date)
                ? moment(item.date)?.format("DD-MM-YYYY")
                : "-",
              debitName: item?.debit_name || "-",
              creditName: item?.credit_name || "-",
              memberName: item?.member_name || "-",
              remarks: item?.remarks || "-",
              status: item.row_status || "-",
              amount: item?.amount ? parseFloat(item?.amount)?.toFixed(2) : "0",
            }))}
            columns={headCells}
            checkbox={true}
            pagination={advanceNoteList?.list?.length}
            totalCount={advanceNoteList?.paginate?.total_count}
            totalPages={advanceNoteList?.paginate?.total_page}
            start={
              parseInt(advanceNoteList?.paginate?.current) *
                advanceNoteList?.paginate?.limit +
              1
            }
            end={
              parseInt(advanceNoteList?.paginate?.current) *
                advanceNoteList?.paginate?.limit +
              advanceNoteList?.list?.length
            }
            currentPage={parseInt(advanceNoteList?.paginate?.current) + 1}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></ManageAdvanceNoteTable>
        </div>
        {!advanceNoteList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AdvanceNote;
