import React, { useState } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { Box, Tab, Tabs } from "@mui/material";
import styled from "@emotion/styled";
import GroupTrailBalance from "./GroupTrailBalance";
import OpeningBalance from "./OpeningBalance";
import ReceiptPayment from "./ReceiptPayment";
import Journal from "./Journal";
import CashBankbook from "./CashBankbook";
import Ledger from "./Ledger";
import Report from "./Report";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ACCOUNT_REPORTS_ENDPOINT } from "../../../../Routing/routes";

function AccountReports() {
  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "'Open Sans', sans-serif",
    borderRight: "1px solid #CCC",
    background: "#FDF2F3",

    "&.Mui-selected": {
      color: "#CF001C",
    },
  });
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const qtab = parseInt(searchParams.get("tab"));
  const [tabValue, setTabValue] = useState(qtab ? qtab : 0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    searchParams.delete("tab");
    navigate({
      pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
      search: `?${createSearchParams([["tab", newValue]])}`,
    });
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        {/* <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Account Reports"></ListHeader>
        </div> */}
        <Ledger />

        {/* <div className="mt-3 border border-[#CCC] rounded-lg overflow-hidden rounded-b-none ">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#CF001C",
              },
            }}
          >
            <StyledTab label="Group Trail Balance" />
            <StyledTab label="Opening Balance" />
            <StyledTab label="Receipt/Payment" />
            <StyledTab label="Journal" />
            <StyledTab label="Cash/Bankbook " />
            <StyledTab label="Ledgers" />
            <StyledTab label="Reports" />
          </Tabs>
        </div> */}
        {/* 
        <div>
          <TabPanel value={tabValue} index={0}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
              <GroupTrailBalance />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
              <OpeningBalance />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
              <ReceiptPayment />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
              <Journal />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
              <CashBankbook />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
              <Ledger />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
              <Report />
            </div>
          </TabPanel>
        </div> */}
      </div>
    </Layout>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default AccountReports;
