import React, { useEffect, useRef } from "react";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Form, Formik } from "formik";
import { BillTariffValidation } from "../../../validationSchema/setupRecordsSchema/buildingSchema";
import { updateBillTariffDetails } from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";

const numberWithTrailingZeros = (value) => {
  // Parse the value to ensure it's a valid number
  const parsedValue = parseFloat(value);

  // If the parsed value is NaN, return '0.00'
  if (isNaN(parsedValue)) {
    return '0.00';
  }

  // Format the number with two decimal places
  const formattedValue = parsedValue.toFixed(2);

  // Pad the formatted value with trailing zeros
  const paddedValue = formattedValue.padEnd(3, '0'); // Ensure total length is 5 including the decimal point

  return paddedValue;
};

// Function to check if a string is not exactly zero but still represents a numeric value
const checkString = (str) => {
  // Empty string check
  if (str === '') {
    return '';
  }

  // Null check
  if (str === null) {
    return '';
  }

  // Check if the string represents a numeric value
  const numericValue = parseFloat(str);
  if (!isNaN(numericValue)) {
    // Check if the numeric value is not exactly zero
    if (numericValue !== 0) {
      return numberWithTrailingZeros(numericValue);
    } else {
      return '';
    }
  }

  // If the string is neither empty, null, nor a numeric value, return 'Invalid string'
  return '';
};


const BillTarrifTable = ({ wingId, societyId, setOnClickSave }) => {
  const { billTariffData } = useSelector(state => state.wingsReducer)
  const { ipAddress } = useSelector(state => state.ipAddressReducer)
  const { loginData } = useSelector(state => state.loginReducer)

  const dispatch = useDispatch()
  const formRef = useRef();

  useEffect(() => {
    setOnClickSave(() => () => {
      formRef.current?.submitForm()
    })
  }, [formRef])

  return (
    <div className="w-full px-2 overflow-x-auto table-container">

      <Formik
        enableReinitialize
        initialValues={{
          billTariff: billTariffData?.bill_taxriff_details ? billTariffData?.bill_taxriff_details?.map((item) => ({
            flatId: item?.flat_id,
            flatNo: item?.flat_no,
            floorNo: item?.floor_no,
            waterInletes: checkString(item?.no_of_water_inlets),
            annualPropertyTax: numberWithTrailingZeros(item?.property_tax),
            annualWaterTax: numberWithTrailingZeros(item?.water_tax),
            annualOccupancyTax: numberWithTrailingZeros(item?.nonoccupancy_tax),
            annualOtherTax: numberWithTrailingZeros(item?.other_tax),
            other1: numberWithTrailingZeros(item?.other_field1),
            other2: numberWithTrailingZeros(item?.other_field2),
            other3: numberWithTrailingZeros(item?.other_field3),
            other4: numberWithTrailingZeros(item?.other_field4)
          })) : []
        }}
        innerRef={(f) => (formRef.current = f)}
        onSubmit={values =>
          dispatch(updateBillTariffDetails({ ...values, ipAddress, wingId, societyId, sessionId: loginData?.session_id }))
        }
        validationSchema={BillTariffValidation}
      >
        {
          ({ values, errors }) => {
            return (
              <Form>
                <FieldArray
                  name="billTariff"
                  render={arrayHelpers => (
                    <>
                      <table className="w-full table-auto">
                        <thead>
                          <tr className="border-b border-[#CCC] text-xs text-[#AAAAAA] text-left">
                            <th className="w-[33px] px-2 ">S.No</th>
                            <th className="w-[50px] px-2 ">Flat No.</th>
                            <th className="w-[59px] px-3 ">Floor No.</th>
                            <th className="w-[39px] px-3 ">Water Inlets</th>
                            <th className="w-[82px] px-3 ">Annual Property Tax</th>
                            <th className="w-[65px] px-3 ">Annual Water Tax</th>
                            <th className="w-[100px] px-3">Other-15 (Annually)</th>
                            <th className="w-[63px] px-3 ">Other-2 (Annually)</th>
                            <th className="w-[67px] px-3 ">Other-3 (Annually)</th>
                            <th className="w-[67px] px-3 ">Other-4 (Annually)</th>
                            <th className="w-[67px] px-3 ">Other-5 (Annually)</th>
                            <th className="w-[67px] px-3 ">Other-6 (Annually)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="h-4"></tr>
                          {
                            values.billTariff && values.billTariff.length > 0 ? (
                              values.billTariff.map((data, index) => (
                                <tr key={index} className="text-xs font-semibold align-middle">
                                  <td className="w-[33px] px-2 ">
                                    <div class="w-[50px] h-9 text-sm rounded-md p-2 ">
                                      {(index + 1)?.toString()?.padStart(2, 0)}
                                    </div>
                                    <div className={`text-red-500 text-xs italic h-4 text-wrap break-words`}></div>
                                  </td>
                                  <td className="w-[50px] px-2  ">
                                    <div class="w-[50px] h-9 text-sm rounded-md p-2 ">{data.flatNo}</div>
                                    <div className={`text-red-500 text-xs italic h-4 text-wrap break-words`}></div>
                                  </td>
                                  <td className="w-[59px] px-5  ">
                                    <div class="w-[50px] h-9 text-sm rounded-md p-2 "> {data.floorNo}</div>
                                    <div className={`text-red-500 text-xs italic h-4 text-wrap break-words`}></div>
                                  </td>
                                  <td className="w-[39px] px-2">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter"
                                      value={billTariffData.waterInletes}
                                      step="00.1"
                                      className="w-[50px] h-9"
                                      name={`billTariff.${index}.waterInletes`}
                                      type='number'
                                    />
                                  </td>
                                  <td className="w-[82px] px-2">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter"
                                      value={billTariffData.annualPropertyTax}
                                      className="w-[70px] h-9"
                                      name={`billTariff.${index}.annualPropertyTax`}
                                      type='decimal'
                                    />
                                  </td>
                                  <td className="w-[65px] px-2">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter"
                                      value={billTariffData.annualWaterTax}
                                      className="w-16 h-9"
                                      name={`billTariff.${index}.annualWaterTax`}
                                      type='decimal'
                                    />
                                  </td>
                                  <td className="w-[96px] px-4">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter"
                                      value={billTariffData.annualOccupancyTax}
                                      className="w-16 h-9"
                                      name={`billTariff.${index}.annualOccupancyTax`}
                                      type='decimal'
                                    />
                                  </td>
                                  <td className="w-[63px] px-2">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter"
                                      value={billTariffData.annualOtherTax}
                                      className="w-16 h-9"
                                      name={`billTariff.${index}.annualOtherTax`}
                                      type='decimal'
                                    />
                                  </td>
                                  <td className="w-[67px] px-2">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter"
                                      value={billTariffData.other1}
                                      className="w-16 h-9"
                                      name={`billTariff.${index}.other1`}
                                      type='decimal'
                                    />
                                  </td>
                                  <td className="w-[67px] px-2">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter"
                                      value={billTariffData.other2}
                                      className="w-16 h-9"
                                      name={`billTariff.${index}.other2`}
                                      type='decimal'
                                    />
                                  </td>
                                  <td className="w-[67px] px-2">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter"
                                      value={billTariffData.other3}
                                      className="w-16 h-9"
                                      name={`billTariff.${index}.other3`}
                                      type='decimal'
                                    />
                                  </td>
                                  <td className="w-[67px] px-2">
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter"
                                      value={billTariffData.other4}
                                      className="w-16 h-9"
                                      name={`billTariff.${index}.other4`}
                                      type='decimal'
                                    />
                                  </td>
                                </tr>
                              )
                              ))
                              :
                              <tr className="text-[#999999] w-full">
                                <td colSpan={12} className="py-4 text-sm text-center" >No Flats found</td>
                              </tr>
                          }
                        </tbody>
                      </table>
                    </>)
                  }
                >

                </FieldArray>
              </Form>
            )
          }
        }
      </Formik>

    </div>
  );
};

export default BillTarrifTable;
