import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate, useSearchParams } from "react-router-dom";
import SmsCreditOrderHistoryTable from "../../../components/TableComponent/SmsCreditOrderHistoryTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSmsCreditOrderHistoryList } from "../../../redux/actions/SetupRecordsActions/SocietyProfileSetupActions/SmsCreditAction";
import { SMS_CREDIT_ORDER_HISTORY_ENDPOINT, SMS_CREDIT_PACKAGES_ENDPOINT } from "../../../Routing/routes";
import ButtonG from "../../../components/Button/ButtonG";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "80px" },
  { id: "smsCredit", label: "SMS Credit", sort: false, width: "150px" },
  { id: "amount", label: "Amount", sort: true, width: "150px" },
  { id: "status", label: "Status", sort: true, width: "150px" },
  { id: "orderDate", label: "Order Date", sort: true, width: "180px" },
  { id: "action", label: "Acion", sort: true, width: "80px" },
];

const SmsCreditOrderHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, smsCreditOrderHistoryList } = useSelector((state) => state.smsCreditReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const getTableData = () => {
    const data = {
      page: searchParams.get('page') || 1,
      pageLimit: searchParams.get('pageLimit') || 10,
    }
    dispatch(getSmsCreditOrderHistoryList(data));
  }

  useEffect(() => {
    getTableData()
  }, [searchParams])
  
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          title="SMS Credit Order History"
          onClick={handleNavigation(SMS_CREDIT_PACKAGES_ENDPOINT)}
        >
          <div className="text-[#AAA] items-center text-xs mt-2 font-semibold">
            Remaining SMS Credit ({smsCreditOrderHistoryList?.remaining_credit})
          </div>
          <ButtonG label='Clear' className='h-8' onClick={handleNavigation(SMS_CREDIT_ORDER_HISTORY_ENDPOINT)} />
        </ListHeaderBack>
        <div className="ml-1">
          <SmsCreditOrderHistoryTable
            data={smsCreditOrderHistoryList?.list?.map((item, index) => ({
              sNo: index + 1,
              id: item?.request_order_id,
              smsCredit: item?.credit,
              amount: item?.amount,
              orderDate: item?.date,
              status: item?.status,
            }))}
            columns={headCells}
            checkbox={false}
            pagination={smsCreditOrderHistoryList?.list?.length}
            totalCount={smsCreditOrderHistoryList?.total_count}
            totalPages={smsCreditOrderHistoryList?.total_pages}
            start={(smsCreditOrderHistoryList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + 1}
            end={(smsCreditOrderHistoryList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + smsCreditOrderHistoryList?.list?.length}
            currentPage={parseInt(smsCreditOrderHistoryList?.current_page)}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></SmsCreditOrderHistoryTable>
        </div>
        {!smsCreditOrderHistoryList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SmsCreditOrderHistory;
