//society profile
export const isRegisteredOptions = [
  { label: "Yes", value: "Y" },
  { label: "No", value: "N" },
];

//specific details
export const areaTypeOptions = [
  { value: 1, label: "Built Up Area" },
  { value: 2, label: "Carpet Area" },
];

export const constructionUnitOptions = [
  { value: "Sq. Ft.", label: "Sq. Ft." },
  { value: "Sq. Mtr", label: "Sq. Mtr" },
];

export const monthOptions = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const dateOptions = [
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

export const frequencyOptions = [
  { label: "Monthly", value: "monthly" },
  { label: "Quaterly", value: "quaterly" },
  { label: "Yearly", value: "yearly" },
];

export const yearOptionsForCalander = Array.from(
  { length: new Date().getFullYear() + 10 - 2016 },
  (_, index) => {
    return {
      label: 2017 + index,
      value: 2017 + index,
    };
  }
).reverse();

export const makeBookingYearOptionsForCalander = Array.from(
  { length: new Date().getFullYear() + 10 - 2000 },
  (_, index) => {
    return {
      label: 2000 + index,
      value: 2000 + index,
    };
  }
);

export const yearOptions = Array.from(
  { length: new Date().getFullYear() - 2016 },
  (_, index) => {
    return {
      label: 2017 + index,
      value: 2017 + index,
    };
  }
).reverse();

export const utilityYearOptions = Array.from(
  { length: new Date().getFullYear() - 2016 },
  (_, index) => {
    return {
      label: 2017 + index,
      value: 2017 + index,
    };
  }
).reverse();

export const onBehalfOfOptions = [
  { value: 0, label: "Member" },
  { value: 1, label: "Tenant" },
];

export const genderOptions = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "O", label: "Other" },
];

export const namePrefixOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  { value: "Master", label: "Master" },
  { value: "Miss", label: "Miss" },
  { value: "Dr", label: "Dr" },
  { value: "M/S", label: "M/S" },
  { value: "Capt", label: "Capt" },
  { value: "Smt", label: "Smt" },
];

export const registerAsOptions = [
  { name: "mainoption", value: "main", label: "Main" },
  { name: "jointoption", value: "joint", label: "Joint" },
];

export const tenantsStatusOptions = [
  { value: "P", label: "Unapprove" },
  { value: "A", label: "Active" },
  { value: "I", label: "Inactive" },
  { value: "E", label: "Expired" },
];

export const societyStaffStatusOptions = [
  { value: "pending", label: "Pending" },
  { value: "approval", label: "Approved" },
  { value: "reject", label: "Rejected" },
];

export const societyStaffVehicleTypeOptions = [
  { value: "", label: "None" },
  { value: "2Wheeler", label: "2 Wheeler" },
  { value: "4Wheeler", label: "4 Wheeler" },
];

export const societyStaffTypeOptions = [
  { value: "Domestic", label: "Domestic" },
  { value: "Society", label: "Society" },
];

export const rentRegistrationStatusOptions = [
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
];

export const societyStaffCurrentStatusptions = [
  { value: "A", label: "Working" },
  { value: "D", label: "Not Working" },
  { value: "I", label: "Leave" },
];

export const classMemberOptions = [
  { value: "1", label: "Individual" },
  { value: "2", label: "Company" },
  { value: "3", label: "Firm" },
  { value: "4", label: "Local Authority" },
  { value: "5", label: "Public Trust" },
  { value: "6", label: "State Govt Society" },
];

export const leaveAndLicenseStatusOptions = [
  { label: "All", value: "" },
  { label: "Open", value: "A" },
  { label: "In-progress", value: "P" },
  { label: "Hold", value: "H" },
  { label: "Closed", value: "I" },
];

const years = Array.from(

  { length: 8 },
  // { length: new Date().getFullYear() - 2000 },
  (_, index) => new Date().getFullYear() + 1 - index - 1
  // (_, index) => 2000 + index + 1
);
export const financialYears = years.map((year) => ({
  value: `${year}-${year + 1}`,
  label: `${year}-${year + 1}`,
}));

export const financialYears2 = years
  .map((year) => ({
    // value: `${year}`,
    value: `${year}-${year + 1}`,
    // value1: `${year + 1}`,
    label: `${year}-${year + 1}`,
  }))
  .reverse();

export const GstNocTableOption = [
  { name: "GstTableOption", value: "service", label: "Service" },
  { name: "GstTableOption", value: "goods", label: "Goods" },
];

export const receiptReportTypeOptions = [
  { name: "reportType", value: "all", label: "All" },
  { name: "reportType", value: "paid", label: "Paid" },
  { name: "reportType", value: "due", label: "Due" },
];

export const invoiceDurationOptions = [
  { name: "duration", value: 1, label: "Monthly" },
  { name: "duration", value: 2, label: "Bi-Monthly" },
  { name: "duration", value: 3, label: "Quaterly" },
  { name: "duration", value: 6, label: "Semi Annually" },
  { name: "duration", value: 12, label: "Annually" },
];

export const GateVehicleTypeOptions = [
  { value: 2, label: "2 Wheeler" },
  { value: 4, label: "4 Wheeler" },
];

export const VisitorTypeOptions = [
  { value: "visitors", label: "Visitor" },
  { value: "staff", label: "Staff" },
];

export const ProofTypeOptions = [
  { value: "PAN", label: "PAN Card" },
  { value: "Adhar", label: "Adhar Card" },
];

export const MainVisitorTypeOptions = [
  { value: "Visitor", label: "Visitor" },
  { value: "Staff", label: "Staff" },
];

export const PaymentStatusOptions = [
  { value: "BookednPaid", label: "Paid" },
  { value: "Booked", label: "Unpaid" },
];

export const BookingFrequencyTypeOptions = [
  { value: "One Time", label: "One Time" },
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];

export const TimeSlotFrequencyOptions = [
  { value: "All", label: "Everyday" },
  { value: "Selected", label: "Selected Days" },
];

export const TimeSlotSelectedDaysOptions = [
  { value: "Sun", label: "Su" },
  { value: "Mon", label: "Mo" },
  { value: "Tue", label: "Tu" },
  { value: "Wed", label: "We" },
  { value: "Thu", label: "Th" },
  { value: "Fri", label: "Fr" },
  { value: "Sat", label: "Sa" },
];

export const TimeSlotLessThanOptions = [
  { value: "less than", label: "Less Than" },
  { value: "greater than", label: "Greater Than" },
];

export const Hoursoptions = [
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
];

export const MinuteIntervaloptions = [
  { value: "00", label: "00" },
  { value: "30", label: "30" },
];

export const CreditNoteMemberTypeOptions = [
  { value: "Member", label: "Member" },
  { value: "Vendor", label: "Non Member", isDisabled: false },
];

export const AdvanceNoteMemberTypeOptions = [
  { value: "member", label: "Member" },
  { value: "vendor", label: "Non Member" },
];

export const paymentOption = [
  { value: "Neft", label: "NEFT/IMPS" },
  { value: "Cash", label: "CASH" },
  { value: "Cheque", label: "CHEQUE" },
]

export const AdvanceNoteSearchByOptions = [
  { value: "date", label: "Advance Date" },
  { value: "CreatedOn", label: "Generation Date" },
  { value: "payment_date", label: "Adjustment Date" },
];

export const NomineeDueReminderScheduleOptions = [
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Semiannually", label: "Semiannually" },
  { value: "Annually", label: "Annually" },
];

export const BookingMemberTypeOptions = [
  { value: "Member", label: "Member" },
  { value: "Tenant", label: "Tenant" },
];

export const jFormYearsOptions = Array.from(
  { length: new Date().getFullYear() - 2023 + 1 },
  (_, index) => ({
    label: `${2023 + index} - ${2023 + index + 1}`,
    value: `${2023 + index} - ${2023 + index + 1}`,
    fromYear: 2023 + index,
    toYear: 2023 + index + 1,
  })
);

export const SavedNoticeMemberTypeOptions = [
  { value: "Annual Genral Meeting", label: "Annual Genral Meeting" },
  { value: "Special Genral Meeting", label: "Special Genral Meeting" },
  { value: "Committee Meeting", label: "Committee Meeting" },
];

export const VehicleTypeOptions = [
  { value: "2", label: "2 Wheeler" },
  { value: "4", label: "4 Wheeler" },
  { value: "Other", label: "Other" },
];
export const TypeOfReportOptions = [
  { value: "draft", label: "Draft" },
  { value: "final", label: "Final" },
];

export const TypeOfStatusOptions = [
  { value: "all", label: "All" },
  { value: "posted", label: "Posted" },
  { value: "cancel", label: "Cancel" },
];

export const ReceiptsPaymentsReceiptsOptions = [
  { value: "receipt", label: "Receipt" },
  { value: "payment", label: "Payment" },
  { value: "pg", label: "PG" },
];

export const ARCReportsYearOptions = Array.from(
  { length: new Date().getFullYear() - 2009 },
  (_, index) => {
    return {
      label: 2010 + index,
      value: 2010 + index,
    };
  }
).reverse();

export const EInvoiceStatusOptions = [
  { value: "GENERATED", label: "Generated" },
  { value: "GENERATION_FAILED", label: "Generation Failed" },
  { value: "CANCELLED", label: "Cancelled" },
];

export const OutstandingReportOptions = [
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const GstVoucherTypeOptions = [
  { value: "main", label: "Bill" },
  { value: "debitnote", label: "DN" },
  { value: "creditnote", label: "CN" },
];

export const SelectTypes = [
  { value: 'POST_DRAFT_INVOICE', label: 'POST DRAFT INVOICE' },
  { value: 'DELETE_DRAFT_INVOICE', label: 'DELETE DRAFT INVOICE' },
  { value: 'POST_DRAFT_DEBITNOTE', label: 'POST DRAFT DEBITNOTE' },
  { value: 'DELETE_DRAFT_DEBITNOTE', label: 'DELETE DRAFT DEBITNOTE' },
  { value: 'DELETE_CURRENT_INVOICE', label: 'DELETE CURRENT INVOICE' },
  { value: 'DELETE_CURRENT_DEBITNOTE', label: 'DELETE CURRENT DEBITNOTE' },
  { value: 'ALL_TRANSACTION_REPORT', label: 'ALL TRANSACTION REPORT' },
  { value: 'SYNC_INVOICE', label: 'INVOICE SYNC' },
  { value: 'SYNC_INVOICE_RECEIPT', label: 'INVOICE RECEIPT SYNC' },
  { value: 'SYNC_DEBITNOTE', label: 'DEBIT NOTE SYNC' },
  { value: 'SYNC_DEBITNOTE_RECEIPT', label: 'DEBIT NOTE RECEIPT SYNC' },
  { value: 'SYNC_CREDITNOTE', label: 'CREDIT NOTE SYNC' },
  { value: 'SYNC_ADVANCE', label: 'ADVANCE SYNC' },

  { value: 'TALLY_PURCHASE', label: 'TALLY PURCHASE REPORT' },
  { value: 'TALLY_RECEIPT', label: 'TALLY RECEIPT REPORT' },
  { value: 'TALLY_SALES', label: 'TALLY SALES REPORT' },
  { value: 'TALLY_CREDIT_NOTE', label: 'TALLY CREDIT REPORT' },
  { value: 'TALLY_DEBIT_NOTE', label: 'TALLY DEBIT REPORT' },
  { value: 'TALLY_PAYMENT', label: 'TALLY PAYMENT REPORT' },
  { value: 'TALLY_OTHER', label: 'TALLY OTHER  REPORT' },


]
export const typeMap = [
  { value: 'POST_DRAFT_INVOICE', label: "Bulk Draft Invoices" },
  { value: 'DELETE_DRAFT_INVOICE', label: "Bulk Delete Draft Invoices" },
  { value: 'POST_DRAFT_DEBITNOTE', label: "Bulk Draft Debit Note" },
  { value: 'DELETE_DRAFT_DEBITNOTE', label: "Bulk Delete Draft Debit Note" },
  { value: 'DELETE_CURRENT_INVOICE', label: "Bulk Delete Current Invoices" },
  { value: "DELETE_CURRENT_DEBITNOTE", label: "Bulk Delete Current Debit Note" },
  { value: 'ALL_TRANSACTION_REPORT', label: "Generate Transaction Report" },
  { value: 'SYNC_INVOICE', label: "Bulk Invoice Sync" },
  { value: 'SYNC_INVOICE_RECEIPT', label: "Bulk Invoice receipt Sync" },
  { value: 'SYNC_DEBITNOTE', label: "Bulk Debit note Sync" },
  { value: 'SYNC_DEBITNOTE_RECEIPT', label: "Bulk Debit note receipt Sync" },
  { value: 'SYNC_CREDITNOTE', label: "Bulk credit note Sync" },
  { value: 'SYNC_ADVANCE', label: "Bulk advance Sync" },

  { value: 'TALLY_PURCHASE', label: "Tally Purchase Report" },
  { value: 'TALLY_RECEIPT', label: "Tally Receipt Report" },
  { value: 'TALLY_SALES', label: "Tally Sales Report" },
  { value: 'TALLY_CREDIT_NOTE', label: "Tally Credit Report" },
  { value: 'TALLY_DEBIT_NOTE', label: "Tally Debit Report" },
  { value: 'TALLY_PAYMENT', label: "Tally Payment Report" },
  { value: 'TALLY_OTHER', label: "Tally Other Report" },

];
export const buttonConfig = [
  { value: 'DELETE_DRAFT_INVOICE', label: "Delete" },
  { value: 'DELETE_DRAFT_DEBITNOTE', label: "Delete" },
  { value: 'DELETE_CURRENT_INVOICE', label: "Delete" },
  { value: 'DELETE_CURRENT_DEBITNOTE', label: "Delete" },
  { value: 'POST_DRAFT_INVOICE', label: "Post" },
  { value: 'POST_DRAFT_DEBITNOTE', label: "Post" },
  { value: 'ALL_TRANSACTION_REPORT', label: "Generate" },
  { value: 'SYNC_INVOICE', label: "Sync" },
  { value: 'SYNC_INVOICE_RECEIPT', label: "Sync" },
  { value: 'SYNC_DEBITNOTE', label: "Sync" },
  { value: 'SYNC_DEBITNOTE_RECEIPT', label: "Sync" },
  { value: 'SYNC_CREDITNOTE', label: "Sync" },
  { value: 'SYNC_ADVANCE', label: "Sync" },
  
  { value: 'TALLY_PURCHASE', label: "Generate" },
  { value: 'TALLY_RECEIPT', label: "Generate" },
  { value: 'TALLY_SALES', label: "Generate" },
  { value: 'TALLY_CREDIT_NOTE', label: "Generate" },
  { value: 'TALLY_DEBIT_NOTE', label: "Generate" },
  { value: 'TALLY_PAYMENT', label: "Generate" },
  { value: 'TALLY_OTHER', label: "Generate" },

];
export const childrenType = [
  { value: 'POST_DRAFT_INVOICE', label: "This action will permanently post all draft invoices. Do you wish to proceed?" },
  { value: 'DELETE_DRAFT_INVOICE', label: "This action will permanently delete all draft invoices. Do you wish to proceed?" },
  { value: 'POST_DRAFT_DEBITNOTE', label: "This action will permanently post all draft debit note. Do you wish to proceed?" },
  { value: 'DELETE_DRAFT_DEBITNOTE', label: "This action will permanently delete all draft debit notes. Do you wish to proceed?" },
  { value: 'DELETE_CURRENT_INVOICE', label: "This action will permanently delete all current invoices. Do you wish to proceed?" },
  { value: 'DELETE_CURRENT_DEBITNOTE', label: "This action will permanently delete all current invoices. Do you wish to proceed?" },

  { value: 'TALLY_PURCHASE', label: "This action will process a purchase tally. Do you wish to proceed?" },
  { value: 'TALLY_RECEIPT', label: "This action will process a receipt tally. Do you wish to proceed?" },
  { value: 'TALLY_SALES', label: "This action will process a sales tally. Do you wish to proceed?" },
  { value: 'TALLY_CREDIT_NOTE', label: "This action will process a credit tally. Do you wish to proceed?" },
  { value: 'TALLY_DEBIT_NOTE', label: "This action will process a debit tally. Do you wish to proceed?" },
  { value: 'TALLY_PAYMENT', label: "This action will process a payment tally. Do you wish to proceed?" },
  { value: 'TALLY_OTHER', label: "This action will process an other tally. Do you wish to proceed?" },

  { value: 'ALL_TRANSACTION_REPORT', label: "This action will permanently generate all transaction reports. Do you wish to proceed?" },
  { value: 'SYNC_INVOICE', label: "This action will permanently sync all invoices with accounts portal. Do you wish to proceed?" },
  { value: 'SYNC_INVOICE_RECEIPT', label: "This action will permanently sync all invoices receipts with accounts portal. Do you wish to proceed?" },
  { value: 'SYNC_DEBITNOTE', label: "This action will permanently sync all debit note with accounts portal. Do you wish to proceed?" },
  { value: 'SYNC_DEBITNOTE_RECEIPT', label: "This action will permanently sync all debit note receipts sync with accounts portal. Do you wish to proceed?" },
  { value: 'SYNC_CREDITNOTE', label: "This action will permanently sync all credit notes with accounts portal. Do you wish to proceed?" },
  { value: 'SYNC_ADVANCE', label: "This action will permanently sync all advance with accounts portal. Do you wish to proceed?" },
];
export const getFlatTypeList = [
  { value: "1 RK", label: "1 RK" },
  { value: "1 BHK", label: "1 BHK" },
  { value: "1.5 BHK", label: "1.5 BHK" },
  { value: "2 BHK", label: "2 BHK" },
  { value: "2.5 BHK", label: "2.5 BHK" },
  { value: "3 BHK", label: "3 BHK" },
  { value: "3.5 BHK", label: "3.5 BHK" },
  { value: "4 BHK", label: "4 BHK" },
  { value: "Penthouse", label: "Penthouse" },
  { value: "Garage", label: "Garage" },
  { value: "Refuge", label: "Refuge" },
];


export const moduleAndSubModuleType = [
  {
    value: "INVOICE",
    label: "INVOICE",
    subModules: [
      { value: "INVOICE_RECEIPT", label: "Invoice Receipt" },
      { value: "INVOICE_DUE_REMINDER", label: "Invoice Due Reminder" },
    ]
  },
  {
    value: "DEBIT_NOTE",
    label: "Debit Note",
    subModules: [
      { value: "DEBIT_NOTE_RECEIPT", label: "Debit Note Receipt" },
      { value: "DEBIT_NOTE_DUE_REMINDER", label: "Debit Note Due Reminder" },

    ]
  },
];
const apiName = [
  { value: 'TALLY_PURCHASE', label: '/api/auth/tally/purchase' },
  { value: 'TALLY_RECEIPT', label: '/api/auth/tally/receipt' },
  { value: 'TALLY_SALES', label: '/api/auth/tally/sales' },
  { value: 'TALLY_CREDIT_NOTE', label: '/api/auth/tally/credit' },
  { value: 'TALLY_DEBIT_NOTE', label: '/api/auth/tally/debit' },
  { value: 'TALLY_PAYMENT', label: '/api/auth/tally/payment' },
  { value: 'TALLY_OTHER', label: '/api/auth/tally/other' },
  { value: 'SYNC_ADVANCE', label: '/api/auth/accounts/creditAccountSync' },
  { value: 'SYNC_CREDITNOTE', label: '/api/auth/accounts/creditAccountSync' },
  { value: 'SYNC_DEBITNOTE', label: '/api/auth/debitNoteAccountSync' },
  { value: 'SYNC_INVOICE', label: '/api/auth/accounts/invoiceAccountSync' },
  { value: 'SYNC_INVOICE_RECEIPT', label: '/api/auth/accounts/invoiceReceiptSync' },
  { value: 'SYNC_DEBITNOTE_RECEIPT', label: '/api/auth/accounts/debitnoteReceiptSync' },
  { value: 'DELETE_DRAFT_INVOICE', label: '/api/auth/deleteInvoice' },
  { value: 'DELETE_DRAFT_DEBITNOTE', label: '/api/auth/deleteDraftDebitNoteV1' },
  { value: 'DELETE_CURRENT_INVOICE', label: '/api/auth/deleteInvoice' },
  { value: 'DELETE_CURRENT_DEBITNOTE', label: '/api/auth/deleteDebitNote' },
  { value: 'POST_DRAFT_INVOICE', label: '/api/auth/postDraftInvoices' },
  { value: 'POST_DRAFT_DEBITNOTE', label: '/api/auth/updateDebitNote' },
  { value: 'ALL_TRANSACTION_REPORT', label: '/api/allTransactionsReport' }
];


export const downloadUploadModuleTypes = [
  {
    value: "TALLY",
    label: "TALLY",
    subModules: [
      { value: "CREDIT_NOTE", label: "CREDIT_NOTE" },
      { value: "DEBIT_NOTE", label: "DEBIT_NOTE" },
      { value: "SALES", label: "SALES" },
      { value: "RECEIPT", label: "RECEIPT" },
      { value: "PAYMENTS", label: "PAYMENTS" },
      { value: "OTHER", label: "OTHER" }
    ]
  },
  {
    value: "INVOICE",
    label: "INVOICE",
    subModules: [
      { value: "INVOICE_PAYMENT", label: "INVOICE_PAYMENT" },
      { value: "INVOICE_OPENING", label: "INVOICE_OPENING" }
    ]
  },
  {
    value: "DEBIT_NOTE",
    label: "DEBIT_NOTE",
    subModules: [
      { value: "DEBIT_NOTE_PAYMENT", label: "DEBIT_NOTE_PAYMENT" }
    ]
  },
  {
    value: "REPORT",
    label: "REPORT",
    subModules: [
      { value: "ALL_TRANSACTION_REPORT", label: "ALL_TRANSACTION_REPORT" }
    ]
  },
  {
    value: "OCCUPANTS",
    label: "OCCUPANTS",
    subModules: [
      { value: "ADD_OCCUPANTS", label: "ADD_OCCUPANTS" },
      { value: "UPDATE_OCCUPANTS", label: "UPDATE_OCCUPANTS" }
    ]
  },
  {
    value: "FAMILY",
    label: "FAMILY",
    subModules: [
      { value: "ADD_FAMILY", label: "ADD_FAMILY" },
      { value: "UPDATE_FAMILY", label: "UPDATE_FAMILY" }
    ]
  },
  { value: "ADVANCE", label: "ADVANCE", subModules: [] },
  { value: "TENANTS", label: "TENANTS", subModules: [] },
  { value: "STAFF", label: "STAFF", subModules: [] },
  { value: "VEHICLE", label: "VEHICLE", subModules: [] },
  { value: "MANAGE_FLAT", label: "MANAGE_FLAT", subModules: [] },
  { value: "FLAT_BILL_TARRIF", label: "FLAT_BILL_TARRIF", subModules: [] },
  { value: "UTILITY_READING", label: "UTILITY_READING", subModules: [] }
];

export const downloadUploadStatusList = [
  {value: "PENDING" , label: "PENDING" },
  {value: "IN_PROGRESS" , label: "IN_PROGRESS" },
  {value: "COMPLETED" , label: "COMPLETED" },
  {value: "FAILED" , label: "FAILED" },
]