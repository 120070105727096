import React, { useEffect } from "react";
import { HeaderData } from "./HeaderData";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HOME_ENDPOINT } from "../../Routing/routes";
import { HomeIcon } from "../../assets";
import "./header.css";
import { getPermission } from "../../utils/helpers/getPermissions";
import { useSelector } from "react-redux";
import webTokenStorer from "../../utils/webStorer";
import { useDispatch } from "react-redux";
import { getSocietyAccessSetting } from "../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";
import { encryptData } from "../../utils/helpers/encryptionDecryption";

const SubMenuTab = ({ currentTab, keyValue }) => {
  const { loginData } = useSelector((state) => state.loginReducer);
  const complianceLinkLabel = [];
  const complianceLink = [
    ...HeaderData[3].menuList.map((item) => item.menuList),
  ].map((item) =>
    item.map((item2) => {
      complianceLinkLabel.push(item2.label);
    })
  );
  const handleMouseOver = (event) => {
    const nextSibling = event.target.nextSibling;

    if (nextSibling)
      if (
        document.body.offsetWidth -
          nextSibling.offsetWidth -
          nextSibling.getBoundingClientRect().x <
        0
      ) {
        nextSibling.style.left = "unset";
        nextSibling.style.right = "100%";
      }
  };
  return (
    <div className="SubMenuTab" key={keyValue}>
      {currentTab.link ? (
        <Link
          to={
            currentTab.label === "Accounts"
              ? `${currentTab.link}?sign=${
                  webTokenStorer?.getToken()?.access_token
                }`
              : complianceLinkLabel.includes(currentTab.label)
              ? `${currentTab.link}?id=${encryptData(loginData.email)}&seckey=${
                  loginData.pwd
                }&routename=${encryptData(currentTab?.linkLabel || "")}`
              : currentTab.link
          }
          rel="opener"
          className={`link ${
            currentTab.apiImplemented
              ? ""
              : window.location.origin == "https://neo.zipgrid.com"
              ? "hidden"
              : " text-gray-200"
          } w-full inline-block text-wrap `}
          target={currentTab.openInNewpage ? "_blank" : "_self"}
        >
          {currentTab.label}{" "}
        </Link>
      ) : (
        <div
          key={keyValue}
          className={`link ${
            currentTab.apiImplemented
              ? ""
              : window.location.origin == "https://neo.zipgrid.com"
              ? "hidden"
              : " text-gray-200"
          }`}
          onMouseOver={handleMouseOver}
        >
          {currentTab.label}{" "}
        </div>
      )}
      <SubMenu
        keyValue={keyValue + 1}
        data={currentTab.menuList}
        className="SubMenu"
      />
    </div>
  );
};
const SubMenu = ({ data, className, keyValue }) => {
  const { loginData } = useSelector((state) => state.loginReducer);

  if (data) {
    return (
      <div className={className} key={keyValue}>
        {data.map((item, index) =>
          item?.permissionPath ? (
            getPermission(loginData, item?.permissionPath) ? (
              <SubMenuTab currentTab={item} keyValue={index} />
            ) : (
              <></>
            )
          ) : item?.label == "ARC Member Ledger" &&
            (loginData?.default_community?.is_ar_portal == 0 ||
              !loginData?.default_community?.is_ar_portal) ? (
            <></>
          ) : (
            <SubMenuTab currentTab={item} keyValue={index} />
          )
        )}
      </div>
    );
  } else {
    return null;
  }
};

const HeaderNav = () => {
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, updateLoader, societyAccessSetting } = useSelector(
    (state) => state.manageRoleAndRightsReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getSocietyAccessSetting({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  return (
    <>
      {HeaderData.map((entry, index) =>
        entry?.key ? (
          societyAccessSetting?.settings &&
          JSON.parse(societyAccessSetting?.settings)?.service_config?.[
            entry?.key
          ] && (
            <div
              key={index + 1}
              className={`${
                entry.apiImplemented
                  ? ""
                  : window.location.origin == "https://neo.zipgrid.com"
                  ? "!hidden "
                  : ""
              } relative HeaderNavTab`}
            >
              <button
                className={`py-2 px-6 text-sm text-[#222] font-semibold  ${
                  index < HeaderData.length - 1 ? "" : ""
                } ${
                  entry.apiImplemented
                    ? ""
                    : window.location.origin == "https://neo.zipgrid.com"
                    ? "hidden"
                    : "  text-gray-200"
                }`}
              >
                <span>{entry.label}</span>
              </button>
              <span className="h-5 py-1 border-r"></span>
              <SubMenu data={entry.menuList} className={"HeaderNavSubMenu"} />
            </div>
          )
        ) : (
          <div
            key={index + 1}
            className={`${
              entry.apiImplemented
                ? ""
                : window.location.origin == "https://neo.zipgrid.com"
                ? "!hidden "
                : ""
            } relative HeaderNavTab`}
          >
            <button
              className={`py-2 px-6 text-sm text-[#222] font-semibold  ${
                index < HeaderData.length - 1 ? "" : ""
              } ${
                entry.apiImplemented
                  ? ""
                  : window.location.origin == "https://neo.zipgrid.com"
                  ? "hidden"
                  : " text-gray-200"
              }`}
            >
              <span>{entry.label}</span>
            </button>
            <span className="h-5 py-1 border-r"></span>
            <SubMenu
              data={entry.menuList}
              keyValue={index}
              className={"HeaderNavSubMenu"}
            />
          </div>
        )
      )}
    </>
  );
};
const Header = () => {
  const location = useLocation();
  const isHomeTab = location.pathname === HOME_ENDPOINT;

  const navigate = useNavigate();

  return (
    <div className="mx-auto">
      <header className="bg-white flex md:flex-row items-center border border-[#CCC] rounded-lg mx-auto">
        <div className="flex w-full whitespace-nowrap">
          <div
            key={0}
            className={`relative ${isHomeTab ? "group" : null}`}
          >
            <button
              className={`py-2 px-7 text-sm items-center font-semibold  ${
                isHomeTab ? "bg-red-650 text-white rounded-s-md h-full" : ""
              }`}
              onClick={() => {
                navigate(HOME_ENDPOINT);
              }}
            >
              <span>
                <HomeIcon fill={isHomeTab ? "white" : "black"} />
              </span>
            </button>
            <span className="h-5 py-1 border-r"></span>
          </div>
          <div className="mx-auto"> <HeaderNav /></div>
         
        </div>
      </header>
    </div>
  );
};

export default Header;
