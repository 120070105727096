import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import { UploadButtonFM } from "../../../components/Button/UploadButton";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { Form, Formik } from "formik";
import { editComplaintValidation } from "../../../validationSchema/deskSchema/logaTicketSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  editComplaint,
  editComplaintV2,
  getComplaintNatureList,
  getComplaintTypeList,
  getFlatsNo,
  getWingsList,
} from "../../../redux/actions/DeskActions/LogaComplaintActions";
import { onBehalfOfOptions } from "../../../consts/DropDownOptions";
import { CircularProgress } from "@mui/material";
import {
  getComplaintCategoryDetailsList,
  getComplaintCategoryList,
} from "../../../redux/actions/DeskActions/ComplaintCategoryAction";
import {
  getLoggedComplaintTicketDetails,
  getSocietyDetailsList,
} from "../../../redux/actions/DeskActions/DeskAction";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import * as permissions from "../../../Routing/permissions";
import Loader from "../../../components/Loader/Loader";
import { getFlatAllMembersList } from "../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { getPermission } from "../../../utils/helpers/getPermissions";

const EditComplaint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [checked, setChecked] = useState(false);
  const [onBehalf, setOnBehalf] = useState();
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const dropdownData = useSelector((state) => state.logaComplaintReducer);
  const { wingsList, flatsList } = useSelector(
    (state) => state.logaComplaintReducer
  );
  const { flatAllMembersList } = useSelector((state) => state.occupantInfoReducer);
  const { updateLoader } = useSelector((state) => state.logaComplaintReducer);
  const { complaintCategoryList, complaintCategoryDetailsList } = useSelector(
    (state) => state.complaintCategoryReducer
  );
  const { loading, complaintLoggedTicket } = useSelector(
    (state) => state.deskReducers
  );
  const { id } = useParams();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleChecked = (event, setFieldValue) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue("isUrgent", "Yes");
    } else {
      setFieldValue("isUrgent", "No");
    }
  };

  useEffect(() => {
    if (complaintLoggedTicket?.description) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(complaintLoggedTicket?.description || "")
          )
        )
      );
    }
  }, [complaintLoggedTicket?.description]);

  useEffect(() => {
    if (complaintLoggedTicket) {
      dispatch(
        getComplaintCategoryDetailsList({
          categoryId: complaintLoggedTicket?.p_cat_id
            ? complaintLoggedTicket?.p_cat_id
            : complaintLoggedTicket?.cat_id,
        })
      );
      let data = {
        societyId: loginData?.default_community?.community_id,
        wingId: complaintLoggedTicket?.wing_no,
        // userType:
        //   complaintLoggedTicket?.logged_type === "Member"
        //     ? "Member"
        //     : complaintLoggedTicket?.logged_type === "Tenant"
        //       ? "Tenant"
        //       : "",
      };
      dispatch(getFlatsNo(data));
    }
  }, [complaintLoggedTicket]);

  useEffect(() => {
    dispatch(getComplaintNatureList());
    dispatch(getComplaintCategoryList());
    const data = { societyId: loginData?.default_community?.community_id };
    setOnBehalf(getPermission(loginData, [permissions.LOG_A_COMPLAINT_ONBEHALF_OF_PERMISSION]));
    dispatch(getComplaintTypeList(data));
    dispatch(getWingsList(data));
    const societyId =
      loginData?.default_community?.community_id === 4202
        ? 0
        : loginData?.default_community?.community_id;
    // dispatch(getSocietyDetailsList({ societyId }));
    dispatch(getLoggedComplaintTicketDetails({ ticketId: id }));
  }, []);

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async (values, { resetForm }) => {
    // values.subscriber_id =
    //   dropdownData.flatsList?.find(
    //     (item) =>
    //       item.house_unique_id === parseInt(complaintLoggedTicket?.flatid)
    //   )?.subscriber_id || "";
    let sendRequest = {
      id: id,
      ...values,
      ipAddress,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
    };
    // dispatch(editComplaint(sendRequest));
    dispatch(editComplaintV2(sendRequest));
  };

  useEffect(() => {
    if (values.flatsNo) {
      const data = {
        society_id: loginData?.default_community?.community_id,
        flat_id: values.flatsNo
      };
      dispatch(getFlatAllMembersList(data));
    }
  }, [values.flatsNo]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <Formik
          initialValues={{
            complaintNature: complaintLoggedTicket?.nature || "",
            complaintType: complaintLoggedTicket?.type || "",
            category: parseInt(complaintLoggedTicket?.p_cat_id),
            subCategory: parseInt(complaintLoggedTicket?.cat_id),
            isUrgent: complaintLoggedTicket?.isUrgent || "No",
            description: complaintLoggedTicket?.description || "",
            wingId: parseInt(complaintLoggedTicket?.wing_no) || "",
            flatsNo: parseInt(complaintLoggedTicket?.flatid) || "",
            attachments: "",
            // societyName: parseInt(complaintLoggedTicket?.societyid) || "",
            // subscriber_id:
            //   complaintLoggedTicket?.logged_type === "Member"
            //     ? complaintLoggedTicket?.logged_by
            //     : "",
            // isTenant:
            //   complaintLoggedTicket?.logged_type === "Member"
            //     ? 0
            //     : complaintLoggedTicket?.logged_type === "Tenant"
            //     ? 1
            //     : "",
            memberId: complaintLoggedTicket?.logged_by || "",
            memberType: complaintLoggedTicket?.logged_type || "",
            memberName: complaintLoggedTicket?.logged_by_name || "",
            societyId: parseInt(complaintLoggedTicket?.societyid) || "",
            emailAssignee: 0,
            emailOwner: 0,
            permissionOnBehalf: (getPermission(loginData, [permissions.LOG_A_COMPLAINT_ONBEHALF_OF_PERMISSION]) || loginData?.user_type == "Employee"),
          }}
          enableReinitialize
          validationSchema={editComplaintValidation}
          onSubmit={handleSaveClick}
          innerRef={(formikActions) => formikActions ? setValues(formikActions.values) : setValues({})}
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Edit Complaint">
                    <div className="mx-0.5 border-l-2"></div>
                    <ButtonG
                      label="Cancel"
                      onClick={handleCancelClick}
                      type={"button"}
                    />
                    <Button
                      label={
                        <span className="w-12 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      className="px-3 h-8"
                      type="submit"
                    />
                  </ListHeader>
                </div>
                {loading && id !== complaintLoggedTicket?.id ? (
                  <Loader />
                ) : (
                  <div className="flex justify-between gap-4 mt-4">
                    <table className="border-separate">
                      <tbody>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Ticket Nature
                          </td>
                          <td>
                            <DropdownFM2
                              options={dropdownData.complaintNatureList?.map(
                                (item) => ({
                                  label: item.title,
                                  value: item.title,
                                })
                              )}
                              placeholder="Select Ticket Nature"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue(
                                  "complaintNature",
                                  selectedItem.value
                                );
                              }}
                              value={{
                                label: dropdownData.complaintNatureList?.find(
                                  (item) =>
                                    item.title === values.complaintNature
                                )?.title,
                                value: values.complaintNature,
                              }}
                              name={"complaintNature"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Ticket Type
                          </td>
                          <td>
                            <DropdownFM2
                              options={dropdownData.complaintType?.map(
                                (item) => ({
                                  label: item.title,
                                  value: item.title,
                                })
                              )}
                              placeholder="Select Type"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue(
                                  "complaintType",
                                  selectedItem.value
                                );
                              }}
                              value={{
                                label: dropdownData.complaintType?.find(
                                  (item) =>
                                    item.title === values.complaintType
                                )?.title,
                                value: values.complaintType,
                              }}
                              name={"complaintType"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Category
                          </td>
                          <td>
                            <DropdownFM2
                              options={complaintCategoryList?.map((item) => ({
                                label: item.title,
                                value: item.id,
                              }))}
                              placeholder="Select Category"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue(
                                  "category",
                                  selectedItem.value
                                );
                                if (
                                  values.category !== selectedItem.value
                                ) {
                                  setFieldValue("subCategory", "");
                                  dispatch(
                                    getComplaintCategoryDetailsList({
                                      categoryId: selectedItem.value,
                                    })
                                  );
                                }
                              }}
                              value={{
                                label: complaintCategoryList?.find(
                                  (item) => item.id === values.category
                                )?.title,
                                value: values.category,
                              }}
                              name={"category"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Sub Category
                          </td>
                          <td>
                            <DropdownFM2
                              options={complaintCategoryDetailsList?.[0]?.sub_category?.map(
                                (item) => ({
                                  label: item.sub_title,
                                  value: item.id,
                                })
                              )}
                              placeholder="Select SubCategory"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue(
                                  "subCategory",
                                  selectedItem.value
                                );
                              }}
                              value={{
                                label:
                                  complaintCategoryDetailsList?.[0]?.sub_category?.find(
                                    (item) =>
                                      item.id === values.subCategory
                                  )?.sub_title,
                                value: values.subCategory,
                              }}
                              name={"subCategory"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Is Urgent ?
                          </td>
                          <td>
                            <div className="w-52 mb-4">
                              <Checkbox
                                text="Yes"
                                className="text-sm"
                                initialchecked={
                                  values?.isUrgent == "Yes" || checked
                                }
                                onChange={(e) => {
                                  handleChecked(e, setFieldValue);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Description
                          </td>
                          <td>
                            <div className="occupant-info-editor border border-[#CCC] rounded-md w-full mb-4 -z-100">
                              <Editor
                                editorState={editorState}
                                toolbar={{
                                  options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "list",
                                    "textAlign",
                                    "history",
                                    "colorPicker",
                                    "image",
                                  ],
                                  inline: {
                                    inDropdown: false,
                                    options: ["bold", "italic", "underline"],
                                  },
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: {
                                    inDropdown: false,
                                    options: ["redo", "undo"],
                                  },
                                  colorPicker: {
                                    className: undefined,
                                    component: undefined,
                                    popupClassName: undefined,
                                  },
                                  image: {},
                                }}
                                onEditorStateChange={(neweditorState) => {
                                  onEditorStateChange(neweditorState);
                                  setFieldValue(
                                    "description",
                                    draftToHtml(
                                      convertToRaw(
                                        neweditorState.getCurrentContent()
                                      )
                                    )
                                  );
                                }}
                                // contentState={{}}
                                name="description"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr className="text-[#222] text-sm">
                          <td className="text-sm w-56 pt-2.5 align-top">
                            View Attachment:
                          </td>
                          <td>
                            {complaintLoggedTicket?.attachments?.length > 0 ? (
                              <span>
                                <Link
                                  target="_blank"
                                  className="ms-1 underline text-xs text-blue-500"
                                  to={
                                    complaintLoggedTicket.attachments[0]
                                      .attachment_Url
                                  }
                                >
                                  View Uploaded Doc
                                </Link>
                              </span>
                            ) : (
                              <span>-</span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Attachments
                          </td>
                          <td>
                            <div className="w-52 overflow-hidden mb-5">
                              <UploadButtonFM
                                showFileName={true}
                                name={`attachments`}
                                accept={
                                  "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                }
                                onSelect={(e) =>
                                  setFieldValue(`attachments`, e)
                                }
                                fileName={values?.attachments?.name}
                              />
                            </div>
                          </td>
                        </tr>
                        {/* <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            On Behalf Of
                          </td>
                          <td>
                            <DropdownFM2
                              options={onBehalfOfOptions}
                              width="208px"
                              height="36px"
                              name={"isTenant"}
                              placeholder="Select OnBehalf"
                              onSelect={(selectedItem) => {
                                // debugger;
                                // console.log("ONBehalf:" + selectedItem.value);
                                if (selectedItem.value === 0) {
                                  setFieldValue(
                                    "tenantId",
                                    selectedItem.value
                                  );
                                }
                                setFieldValue(
                                  "isTenant",
                                  selectedItem.value
                                );
                                if (values.wingId) {
                                  let data = {
                                    societyId:
                                      loginData?.default_community
                                        ?.community_id,
                                    wingId: values.wingId,
                                    userType: selectedItem.value
                                      ? "Tenant"
                                      : "Member",
                                  };
                                  dispatch(getFlatsNo(data));
                                }
                                setFieldValue("flatsNo", "");
                              }}
                              value={
                                values.isTenant === ""
                                  ? null
                                  : {
                                      label: onBehalfOfOptions?.find(
                                        (item) =>
                                          item.value === values.isTenant
                                      )?.label,
                                      value: values.isTenant,
                                    }
                              }
                            />
                          </td>
                        </tr> */}
                        {(onBehalf || loginData?.user_type == "Employee") &&
                          <>
                            <tr>
                              <td className="text-sm w-56 pt-2.5 align-top">
                                Member/Tenant Details
                              </td>
                              <td>
                                <div className="flex items-center">
                                  <div className="w-52 flex gap-4 mr-4">
                                    <DropdownFM2
                                      placeholder="Wing"
                                      name={"wingId"}
                                      className={"me-4"}
                                      options={wingsList.map((item) => ({
                                        label: item.number,
                                        value: item.id,
                                      }))}
                                      width="96px"
                                      height="36px"
                                      onSelect={(selectedItem) => {
                                        setFieldValue("wingId", selectedItem.value);
                                        let data = {
                                          societyId:
                                            loginData?.default_community
                                              ?.community_id,
                                          wingId: selectedItem.value,
                                          // userType: values.isTenant
                                          //   ? "Tenant"
                                          //   : "Member",
                                        };
                                        dispatch(getFlatsNo(data));
                                      }}
                                      value={{
                                        label: wingsList?.find(
                                          (item) => item.id === values.wingId
                                        )?.number,
                                        value: values.wingId,
                                      }}
                                    />
                                    <DropdownFM2
                                      options={flatsList?.map((item) => ({
                                        label: item.flat_no,
                                        value: item.house_unique_id,
                                        subscriber_id: item.subscriber_id,
                                        memberName: item.full_name,
                                        tenant_id: item.tenantid,
                                        tenantName: item.tenant_full_name,
                                      }))}
                                      name={"flatsNo"}
                                      width="96px"
                                      height="36px"
                                      placeholder="Flat/Unit"
                                      onSelect={(selectedItem) => {
                                        setFieldValue("flatsNo", selectedItem.value);
                                        setFieldValue("memberId", values.memberType === 'Member' ? selectedItem.subscriber_id : selectedItem.tenant_id);
                                        setFieldValue("memberName", values.memberType === 'Member' ? selectedItem.memberName : selectedItem.tenantName);
                                      }}
                                      value={
                                        values.flatsNo
                                          ? {
                                            label: flatsList?.find(
                                              (item) =>
                                                item.house_unique_id ===
                                                values.flatsNo
                                            )?.flat_no,
                                            value: values.flatsNo,
                                          }
                                          : null
                                      }
                                    />
                                  </div>
                                  {/* <div className="text-[#AAAAAA] font-bold text-xs -mt-3">
                                —{" "}
                                {values.isTenant === "Tenant"
                                  ? dropdownData?.flatsList?.find(
                                      (item) =>
                                        item.house_unique_id ===
                                        values.flatsNo
                                    )?.tenant_full_name
                                  : dropdownData?.flatsList?.find(
                                      (item) =>
                                        item.house_unique_id ===
                                        values.flatsNo
                                    )?.full_name}
                              </div> */}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-sm w-56 pt-2.5 align-top">
                                On Behalf Of
                              </td>
                              <td>
                                <DropdownFM2
                                  options={flatAllMembersList?.length !== 0 ?
                                    flatAllMembersList?.map((item) => ({
                                      value: item.member_id,
                                      label: item.member_name,
                                      type: item.member_type,
                                    })) : []
                                  }
                                  placeholder="Select Member"
                                  className=""
                                  width="208px"
                                  height="36px"
                                  onSelect={(selectedItem) => {
                                    setFieldValue('memberId', selectedItem.value)
                                    setFieldValue('memberName', selectedItem.label)
                                    setFieldValue('memberType', selectedItem.type)
                                  }}
                                  value={values.memberId === "" ?
                                    null
                                    :
                                    {
                                      label: flatAllMembersList?.find(item => item.member_id === values.memberId)?.member_name,
                                      value: values.memberId
                                    }
                                  }
                                  name={"memberName"}
                                // disabled={loginData?.user_type === 'User' || loginData?.user_type === 'Tenant'}
                                />
                              </td>
                            </tr>
                          </>
                        }
                        <tr>
                          <td className="w-44 py-2 text-sm align-top">
                            Email to:
                          </td>
                          <td className="py-2">
                            <div className="text-sm">
                              <Checkbox
                                text={"Assignee"}
                                className={"w-28"}
                                initialchecked={
                                  values.emailAssignee === 1
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    `emailAssignee`,
                                    e.target.checked ? 1 : 0
                                  );
                                }}
                              />
                              <Checkbox
                                text={"Owner"}
                                className={"w-28"}
                                initialchecked={values.emailOwner === 1}
                                onChange={(e) => {
                                  setFieldValue(
                                    `emailOwner`,
                                    e.target.checked ? 1 : 0
                                  );
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default EditComplaint;
