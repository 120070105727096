import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser';
import Loader from '../../../../components/Loader/Loader'
import { printAdvancetNoteInvoice } from '../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction'

function PrintAdvanceNoteReciept() {
    const {id} = useParams()
    const dispatch = useDispatch()
    const {loginData} = useSelector(state=>state.loginReducer)
    const {printLoader, advanceReciept} = useSelector(state=>state.manageAdvanceNoteReducer)
  
    useEffect(()=>{
        dispatch(printAdvancetNoteInvoice({
            societyId: loginData?.default_community?.community_id,
            advanceNoteId: [id]
        }))
    
        const afterprint = (e) => {
          e.preventDefault()
        };
        
        window.addEventListener("afterprint", afterprint);
    
        return () => {
          window.removeEventListener("afterprint", afterprint);
        }
      }, [])
    
    
    //   useEffect(() => {
    //     if(!printLoader && advanceReciept){
    //       window.print()
    //     }
    //   }, [printLoader]);
    
    return (
    <div>
        {
            printLoader || !advanceReciept ?
            <Loader/>
            :
            <>
                <iframe srcDoc={advanceReciept} className='w-full h-screen'></iframe>
            </>
        }
    </div>
  )
}

export default PrintAdvanceNoteReciept