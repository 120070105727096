import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import { DeleteIcon, EditIcon, SortIcon, ViewIcon } from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import {
    stableSort,
    getComparator,
} from "../../utils/helpers/universalFunctions";
import Loader from '../../components/Loader/Loader'
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteFamilyMember } from "../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { EDIT_FAMILY_MEMBERS_ENDPOINT, FAMILY_INFO_ENDPOINT, VIEW_FAMILY_MEMBERS_ENDPOINT } from "../../Routing/routes";
import { Tooltip } from "@mui/material";
import DeleteModal from "../Modal/DeleteModal";

function ActionComponent({ id, flatId, userId, subscribeId }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { loginData } = useSelector((state) => state.loginReducer);
    const { ipAddress } = useSelector((state) => state.ipAddressReducer);

    const handleDeleteClick = () => {
        setShowDeleteModal(true);
    };

    const handleDelete = () => {
        let data = {
            deleteFamilyId: id,
            flatId,
            searchText: searchParams.get("search") || "",
            subscribeId: subscribeId,
            societyId: loginData?.default_community?.community_id,
            userType: loginData?.user_type,
            userId: userId,
            ipAddress,
            sessionId: loginData?.session_id,
        };
        dispatch(deleteFamilyMember(data));
    };
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="View">
                <ViewIcon
                    className="fill-[#555] hover:fill-red-650 me-3"
                    onClick={() => { navigate(`${VIEW_FAMILY_MEMBERS_ENDPOINT}/${id}`) }}
                />
            </Tooltip>
            <Tooltip title="Edit" arrow>
                <EditIcon
                    className="fill-[#555] hover:fill-red-650 me-3"
                    onClick={() => {
                        navigate(
                            `${EDIT_FAMILY_MEMBERS_ENDPOINT}/${id}`
                        );
                    }}
                />
            </Tooltip>
            <Tooltip title="Delete" arrow>
                <DeleteIcon
                    className="fill-[#555] hover:fill-red-650"
                    onClick={() => handleDeleteClick()}
                />
            </Tooltip>
            {/* Delete Confirmation Modal */}
            <DeleteModal
                isOpen={showDeleteModal}
                onCancel={() => setShowDeleteModal(false)}
                onDelete={handleDelete}
            />
        </Box>
    );
}

const FamilyInfoListTable = ({
    children,

    data,
    loading,
    columns,
    checkbox = true,
    pagination = true,

    //props are required if pagination is true
    totalCount,
    totalPages,
    start,
    end,
    currentPage,
    rowsPerPage,
    getTableData,
}) => {
    const navigate = useNavigate();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(null);
    const [selected, setSelected] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams()


    const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #ffffff;
    }
    &:nth-of-type(even) {
      background-color: #f4f5f8;
    }
    &:nth-of-type(odd):hover {
      background-color: #ffffff;
    }
    &:nth-of-type(even):hover {
      background-color: #f4f5f8;
    }
  `;

    const TableCellStyled = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontWeight: 600,
            zIndex: 0,
            color: "#AAAAAA",
            fontSize: "13px",
            padding: '12px 8px 12px 0',
            wordBreak: "break-word",
            fontSize: "13px",
        },
        [`&.${tableCellClasses.body}`]: {
            borderBottom: "none",
            fontSize: "13px",
            padding: '12px 8px 12px 0',
            wordBreak: "break-word",
            fontSize: "13px",
            cursor: "pointer"
        },
    }));

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        const newSelected = event.target.checked ? data?.map((item) => item.id) : [];
        setSelected(newSelected);
    };

    const handleClick = (event, id) => {
        const newSelected = selected.includes(id)
            ? selected.filter((selectedId) => selectedId !== id)
            : [...selected, id];
        setSelected(newSelected);
    };


    const handleChangePage = (newPage) => {
        searchParams.delete("page")
        navigate({
            pathname: `${FAMILY_INFO_ENDPOINT}`,
            search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
        });
    };

    const handleChangeRowsPerPage = (value) => {
        if (rowsPerPage !== value) {
            searchParams.delete("pageLimit")
            // searchParams.delete("page")
            navigate({
                pathname: `${FAMILY_INFO_ENDPOINT}`,
                search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
            });
        }
    };


    const isSelected = (id) => selected.includes(id);

    return (
        <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
            <TableContainer className="border-t" sx={{ minHeight: data?.length ? "500px" : 'none' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {!!checkbox && (
                                <TableCellStyled
                                    size="small"
                                    sx={{ pl: '0 !important', pr: 2, width: 36 }}
                                >
                                    <Checkbox
                                        size="small"
                                        indeterminate={false}
                                        checked={data?.length > 0 && selected.length === data?.length}
                                        onChange={handleSelectAllClick}
                                        inputProps={{
                                            "aria-label": "select all",
                                        }}
                                        sx={{
                                            p: 0,
                                            color: "#AAAAA",
                                            "&.Mui-checked": {
                                                color: "#CF001C",
                                            },
                                        }}
                                    />
                                </TableCellStyled>
                            )}
                            {columns.map((column) => (
                                <TableCellStyled
                                    size="small"
                                    key={column.id}
                                    align={column.numeric ? "right" : "left"}
                                    sortDirection={orderBy === column.id ? order : false}
                                    sx={{
                                        width: column.width || "fit-content",
                                        minWidth: column.width || "fit-content",
                                    }}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : "asc"}
                                        onClick={() => {
                                            if (column.sort) {
                                                handleRequestSort(column.id);
                                            }
                                        }}
                                        IconComponent={SortIcon}
                                        sx={{
                                            ".MuiTableSortLabel-icon": {
                                                opacity: "1 !important",
                                            },
                                            svg: {
                                                flexShrink: 0,
                                            },
                                        }}
                                        hideSortIcon={!column.sort}
                                    >
                                        {column.label}
                                        {orderBy === column.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === "desc"
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCellStyled>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ?
                                <TableRow>
                                    <TableCellStyled sx={{ border: 'none' }} colSpan={columns?.length + 1}><Loader /></TableCellStyled>
                                </TableRow>
                                :
                                <>
                                    {!!data?.length && stableSort(data, getComparator(order, orderBy))
                                        .map((row, rowIndex) => (
                                            <TableRowStyled
                                                key={rowIndex}
                                                role="checkbox"
                                                aria-checked={isSelected(row.id)}
                                                tabIndex={-1}
                                                selected={isSelected(row.id)}
                                                sx={{ cursor: "pointer" }}
                                            >
                                                {checkbox && (
                                                    <TableCellStyled
                                                        size="small"
                                                        onClick={(event) => handleClick(event, row.id)}
                                                    >
                                                        <Checkbox
                                                            size="small"
                                                            color="primary"
                                                            checked={isSelected(row.id)}
                                                            inputProps={{
                                                                "aria-labelledby": `generic-table-checkbox-${row.id}`,
                                                            }}
                                                            sx={{
                                                                p: 0,
                                                                color: "#AAAAA",
                                                                "&.Mui-checked": {
                                                                    color: "#CF001C",
                                                                },
                                                            }}
                                                        />
                                                    </TableCellStyled>
                                                )}
                                                <TableCellStyled size="small" align="left">
                                                    {row?.sNo}
                                                </TableCellStyled>
                                                <TableCellStyled size="small" align="left">
                                                    {row?.roleAssign}
                                                </TableCellStyled>
                                                <TableCellStyled size="small" align="left">
                                                    {row?.name}
                                                </TableCellStyled>
                                                <TableCellStyled size="small" align="left">
                                                    {row?.flat_no}
                                                </TableCellStyled>
                                                <TableCellStyled size="small" align="left">
                                                    {row?.mobile}
                                                </TableCellStyled>
                                                <TableCellStyled size="small" align="left">
                                                    {row?.email}
                                                </TableCellStyled>
                                                <TableCellStyled size="small" align="left">
                                                    {row?.gender}
                                                </TableCellStyled>
                                                <TableCellStyled size="small" align="left">
                                                    {row?.relationship}
                                                </TableCellStyled>
                                                <TableCellStyled>
                                                    <ActionComponent id={row?.id} flatId={row?.flatId} userId={row?.userId} subscribeId={row?.subscribeId} />
                                                </TableCellStyled>
                                            </TableRowStyled>
                                        ))}
                                </>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {!!pagination && data && (
                <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
                    <ItemsPerPage
                        rowsPerPage={rowsPerPage}
                        onChange={handleChangeRowsPerPage}
                    />
                    <Pagination
                        totalCount={totalCount}
                        totalPages={totalPages}
                        start={start}
                        end={end}
                        currentPage={currentPage}
                        onChange={handleChangePage}
                    />
                </div>
            )}
        </Box>
    );
};

FamilyInfoListTable.propTypes = {
    columns: PropTypes.array.isRequired,
    defaultSortField: PropTypes.string,
    rowsPerPageOptions: PropTypes.array,
};

export default FamilyInfoListTable;
