import * as type from "../../../actions/index.types";

export function occupantInfoReducer(
  state = {
    loading: false,
    updateLoader: false,
    uploadLoader: false,
    titleList: null,
    occupantsList: null,
    familyList: null,
    familyMemberData: null,
    memberPrivacyData: null,
    occupantsAuditLogs: null,
    existingOccupantsList: null,
    archiveOccupants: null,
    occupantDetail: null,
    mortgageDetails: null,
    houseDetails: null,
    occupantStaffDetail: null,
    flatDocumentsDetails: null,
    nomineeDetails: null,
    vehicleDetailsList: null,
    uploadVehicleDetailsList: null,
    familyMemberAuditLog: null,
    flatAllMembersList: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_TITLE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        titleList: action.payload,
      };
    case type.GET_TITLE_LIST_FAILURE:
      return {
        ...state,
        titleList: null,
      };

    case type.GET_OCCUPANTS_DETAILS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_OCCUPANTS_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        occupantsList: action.payload,
      };
    case type.GET_OCCUPANTS_DETAILS_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        occupantsList: null,
      };

    case type.SEND_EMAIL_TO_OCCUPANT:
      return {
        ...state,
        updateLoader: true,
      };
    case type.SEND_EMAIL_TO_OCCUPANT_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.SEND_EMAIL_TO_OCCUPANT_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.CHANGE_OCCUPANT_STATUS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.CHANGE_OCCUPANT_STATUS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        occupantsList: {
          ...state.occupantsList,
          occupants_list: state.occupantsList?.occupants_list?.map((occupant) =>
            occupant?.subscriber_id === action.payload.subscribeId
              ? { ...occupant, status: action.payload.status }
              : occupant
          ),
        },
      };
    case type.CHANGE_OCCUPANT_STATUS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.CHANGE_OCCUPANT_STATUS_IN_BULK:
      return {
        ...state,
        updateLoader: true,
      };
    case type.CHANGE_OCCUPANT_STATUS_IN_BULK_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        occupantsList: {
          ...state.occupantsList,
          occupants_list: state.occupantsList?.occupants_list?.map((occupant) =>
            action.payload.subscribeIds?.includes(occupant?.subscriber_id)
              ? { ...occupant, status: "A" }
              : occupant
          ),
        },
      };
    case type.CHANGE_OCCUPANT_STATUS_IN_BULK_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.GET_MEMBERS_PRIVACY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_MEMBERS_PRIVACY_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        memberPrivacyData: action.payload,
      };
    case type.GET_MEMBERS_PRIVACY_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        memberPrivacyData: null,
      };

    case type.UPDATE_MEMBERS_PRIVACY:
      return {
        ...state,
        updateLoader: true,
      };
    case type.UPDATE_MEMBERS_PRIVACY_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.UPDATE_MEMBERS_PRIVACY_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.GET_OCCUPANTS_AUDIT_LOGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_OCCUPANTS_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        occupantsAuditLogs: action.payload,
      };
    case type.GET_OCCUPANTS_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        occupantsAuditLogs: null,
      };

    case type.ADD_OCCUPANTS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.ADD_OCCUPANTS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.ADD_OCCUPANTS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.GET_EXISTING_OCCUPANTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_EXISTING_OCCUPANTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        existingOccupantsList: action.payload,
      };
    case type.GET_EXISTING_OCCUPANTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        existingOccupantsList: null,
      };

    case type.ADD_EXISTING_OCCUPANTS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.ADD_EXISTING_OCCUPANTS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.ADD_EXISTING_OCCUPANTS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.DELETE_OCCUPANT:
      return {
        ...state,
      };
    case type.DELETE_OCCUPANT_SUCCESS:
      return {
        ...state,
        occupantsList: {
          ...state.occupantsList,
          occupants_list: state.occupantsList?.occupants_list?.filter(
            (item) =>
              !(
                item.subscriber_id === action.payload.subscriberId &&
                item.flat_id === action.payload.deleteflatId
              )
          ),
        },
      };
    case type.DELETE_OCCUPANT_FAILURE:
      return {
        ...state,
      };

    case type.GET_ARCHIVE_OCCUPANTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_ARCHIVE_OCCUPANTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        archiveOccupants: action.payload,
      };
    case type.GET_ARCHIVE_OCCUPANTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        archiveOccupants: null,
      };

    case type.GET_OCCUPANTS_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_OCCUPANTS_DETAILS_SUCCESS:
      return {
        ...state,
        occupantDetail: action.payload,
        error: null,
        loading: false,
      };
    case type.GET_OCCUPANTS_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        occupantDetail: null,
      };

    case type.UPDATE_OCCUPANTS_DETAILS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.UPDATE_OCCUPANTS_DETAILS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.UPDATE_OCCUPANTS_DETAILS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.GET_MORTGAGE_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_MORTGAGE_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        mortgageDetails: action.payload,
      };
    case type.GET_MORTGAGE_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        mortgageDetails: null,
      };

    case type.ADD_MORTGAGE_DETAILS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.ADD_MORTGAGE_DETAILS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.ADD_MORTGAGE_DETAILS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.GET_MY_HOUSE_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_MY_HOUSE_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        houseDetails: action.payload,
      };
    case type.GET_MY_HOUSE_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        houseDetails: null,
      };


    case type.GET_FAMILY_MEMBERS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_FAMILY_MEMBERS_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        familyList: action.payload,
      };
    case type.GET_FAMILY_MEMBERS_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        familyList: null,
      };

    case type.VIEW_FAMILY_MEMBERS_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.VIEW_FAMILY_MEMBERS_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        familyMemberData: action.payload,
      };
    case type.VIEW_FAMILY_MEMBERS_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        familyMemberData: null,
      };


    case type.ADD_FAMILY_MEMBER:
      return {
        ...state,
        updateLoader: true,
      };
    case type.ADD_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.ADD_FAMILY_MEMBER_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };
    case type.DELETE_FAMILY_MEMBER:
      return {
        ...state,
      };
    case type.DELETE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        occupantDetail: {
          ...state.occupantDetail,
          flat_data: [...state.occupantDetail?.flat_data?.filter(flat => !(flat.flat_id?.toString() === action.payload.flatId)), {
            ...state.occupantDetail?.flat_data?.find(flat => flat.flat_id?.toString() === action.payload.flatId), family_data: [
              ...state.occupantDetail?.flat_data?.find(flat => flat.flat_id?.toString() === action.payload.flatId)?.family_data?.filter(
                (item) =>
                  !(item.id === action.payload.deleteFamilyId)
              )
            ]
          }]
        },
      };
    case type.DELETE_FAMILY_MEMBER_FAILURE:
      return {
        ...state,
      };

    case type.GET_OCCUPANTS_STAFF_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_OCCUPANTS_STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        occupantStaffDetail: action.payload,
      };
    case type.GET_OCCUPANTS_STAFF_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
      };

    case type.UPDATE_FAMILY_MEMBER:
      return {
        ...state,
        updateLoader: true,
      };
    case type.UPDATE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.UPDATE_FAMILY_MEMBER_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.GET_FAMILY_MEMBERS_AUDIT_LOGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_FAMILY_MEMBERS_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        familyMemberAuditLog: action.payload,
      };
    case type.GET_FAMILY_MEMBERS_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
      };

    case type.ADD_FAMILY_MEMBER_THROUGH_EXCEL:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.ADD_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.ADD_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };

    case type.UPDATE_FAMILY_MEMBER_THROUGH_EXCEL:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.UPDATE_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.UPDATE_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };

    case type.GET_FLAT_DOCUMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_FLAT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        flatDocumentsDetails: action.payload,
      };
    case type.GET_FLAT_DOCUMENTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        flatDocumentsDetails: null,
      };

    case type.UPDATE_FLAT_DOCUMENTS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.UPDATE_FLAT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.UPDATE_FLAT_DOCUMENTS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };


    case type.VIEW_NOMINEE_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.VIEW_NOMINEE_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        nomineeDetails: action.payload,
      };
    case type.VIEW_NOMINEE_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        nomineeDetails: null,
      };


    case type.ADD_UPDATE_NOMINEE_DETAILS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.ADD_UPDATE_NOMINEE_DETAILS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.ADD_UPDATE_NOMINEE_DETAILS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.DELETE_NOMINEE_DETAILS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.DELETE_NOMINEE_DETAILS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.DELETE_NOMINEE_DETAILS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };


    case type.GET_VEHICLE_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        vehicleDetailsList: action.payload,
      };
    case type.GET_VEHICLE_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        vehicleDetailsList: null,
      };

    case type.UPDATE_VEHICLE_DETAILS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.UPDATE_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.UPDATE_VEHICLE_DETAILS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };


    case type.UPLOAD_VEHICLE_DETAILS:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.UPLOAD_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
        uploadVehicleDetailsList: action.payload,
      };
    case type.UPLOAD_VEHICLE_DETAILS_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };


    case type.BULK_ADD_VEHICLE_DETAILS:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.BULK_ADD_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.BULK_ADD_VEHICLE_DETAILS_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };


    case type.DELETE_VEHICLE_DETAILS:
      return {
        ...state,
        updateLoader: true
      };
    case type.DELETE_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        updateLoader: false
      };
    case type.DELETE_VEHICLE_DETAILS_FAILURE:
      return {
        ...state,
        updateLoader: false
      };

    case type.GET_FLAT_ALL_MEMBERS_LIST:
      return {
        ...state,
        loading: true
      };
    case type.GET_FLAT_ALL_MEMBERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        flatAllMembersList: action.payload,
      };
    case type.GET_FLAT_ALL_MEMBERS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        flatAllMembersList: null,
      };



    default:
      return state;
  }
}
