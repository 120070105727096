import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpecificDetails,
  updateSpecificDetails,
} from "../../../redux/actions/SetupRecordsActions/BuildingActions/SpecificDetailsAction";
import { Form, Formik } from "formik";
import { updateSpecificDetailsValidation } from "../../../validationSchema/setupRecordsSchema/buildingSchema";
import { DatePickerComponentFM } from "../../../components/DatePicker/DatePicker";
import {
  areaTypeOptions,
  constructionUnitOptions,
} from "../../../consts/DropDownOptions";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { CircularProgress } from "@mui/material";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Loader/Loader";
import { Dashboard } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  HOME_ENDPOINT,
  SPECIFIC_DETAILS_AUDIT_LOG_ENDPOINT,
} from "../../../Routing/routes";

const SpecificDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, updateLoader, specificDetailsData } = useSelector(
    (state) => state.specificDetailsReducer
  );
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSaveClick = (values, { resetForm }) => {
    let sendRequest = {
      ...values,
      ipAddress,
      action: specificDetailsData ? "Modified" : "Add",
      id: specificDetailsData?.specific_detail_id,
      sessionId: loginData?.session_id,
      onSuccessCallback: () => {
        navigate(HOME_ENDPOINT);
      },
    };
    dispatch(updateSpecificDetails(sendRequest));
  };

  useEffect(() => {
    dispatch(getSpecificDetails());
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            registrationNo: specificDetailsData?.regn_no || "",
            dateOfRegistration: specificDetailsData?.date_of_regn || "",
            act: specificDetailsData?.act || "",
            buildUpArea: specificDetailsData?.built_up_area || "",
            areaOfPlot: specificDetailsData?.area_of_plot || "",
            noOfWaterInlets: specificDetailsData?.no_of_water_inlets || "",
            constructionCosttPerUnit:
              specificDetailsData?.construction_cost || "",
            valuePerShare: specificDetailsData?.value_per_share || "",
            constructionUnit: specificDetailsData?.const_unit || "",
            areaType: specificDetailsData?.builtup_option || "",
          }}
          enableReinitialize
          validationSchema={updateSpecificDetailsValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="flex justify-between border-b border-[#CCC] items-center pb-2">
                  <h1 className="text-base font-semibold p-1">
                    Specific Details
                  </h1>
                  <div className="flex gap-2">
                    <Button
                      label={
                        <span className="w-8 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      className="px-6 h-8"
                      type={"submit"}
                      disabled={loading || updateLoader || !formik.dirty}
                    />
                    <Button
                      label="Audit Log"
                      onClick={handleNavigation(
                        SPECIFIC_DETAILS_AUDIT_LOG_ENDPOINT
                      )}
                      className="h-8 px-4 font-semibold"
                      type="button"
                    />
                  </div>
                </div>
                {loading && !specificDetailsData ? (
                  <Loader />
                ) : (
                  <div className="flex justify-between gap-4 p-2 mt-2">
                    <div className="flex flex-col w-1/2 pr-5">
                      <TextInputFM
                        label="Registration Number:"
                        placeholder="Enter Registration No."
                        className="w-56 h-9"
                        name="registrationNo"
                        maxLength={50}
                      />

                      <div className="flex justify-between">
                        <span className="text-sm">
                          {"Date of Registration:"}
                        </span>
                        <div>
                          <DatePickerComponentFM
                            className="w-56 h-9 ml-4 justify-between"
                            onDateChange={(selectedDate) => {
                              formik.setFieldValue(
                                "dateOfRegistration",
                                selectedDate
                              );
                            }}
                            name="dateOfRegistration"
                            defaultValue={
                              formik.values.dateOfRegistration
                                ? new Date(formik.values.dateOfRegistration)
                                : null
                            }
                          />
                        </div>
                      </div>
                      <TextAreaFM
                        label="Act"
                        placeholder="Enter Act"
                        className="w-56"
                        name="act"
                        row={5}
                        maxLength={300}
                      />

                      <div className="flex justify-between w-full">
                        <span className="text-sm whitespace-nowrap mt-2 w-1/2">
                          Total Build up Area
                        </span>
                        <div className="flex justify-between w-1/2 ml-6">
                          <TextInputFM
                            placeholder="Enter Detail"
                            className=" w-[6.5rem] h-9 "
                            name="buildUpArea"
                            type={"decimal"}
                          />
                          <DropdownFM2
                            options={areaTypeOptions}
                            placeholder="Select Area"
                            className="text-[11px] ms-4"
                            width="110px"
                            height="37.6px"
                            onSelect={(selectedItem) => {
                              formik.setFieldValue(
                                "areaType",
                                selectedItem.value
                              );
                            }}
                            value={areaTypeOptions?.find(
                              (item) => item.value === formik.values.areaType
                            )}
                            name={"areaType"}
                          />
                        </div>
                      </div>

                      <TextInputFM
                        label="Construction Cost Per Unit:"
                        placeholder="Enter Details"
                        className="w-56 h-9"
                        name="constructionCosttPerUnit"
                      />
                      <TextInputFM
                        label="Value Per Share:"
                        placeholder="Enter Value"
                        className="w-56 h-9"
                        name="valuePerShare"
                        type={"decimal"}
                      />
                    </div>
                    <div className="flex flex-col w-1/2 pr-5">
                      <div className="mt-[230px]">
                        <TextInputFM
                          label="Area Of Plot:"
                          placeholder="Enter area"
                          className="w-56 h-9"
                          name="areaOfPlot"
                          type={"decimal"}
                        />
                      </div>
                      <div className="flex justify-between  text-sm">
                        <span className="mt-2">Constructions Unit</span>
                        <DropdownFM2
                          options={constructionUnitOptions}
                          placeholder="Select Unit"
                          width="224px"
                          height="37.6px"
                          onSelect={(selectedItem) => {
                            formik.setFieldValue(
                              "constructionUnit",
                              selectedItem.value
                            );
                          }}
                          value={constructionUnitOptions?.find(
                            (item) =>
                              item.value === formik.values.constructionUnit
                          )}
                          name={"constructionUnit"}
                        />
                      </div>
                      <div>
                        <TextInputFM
                          label="Total number of Water Inlets:"
                          placeholder="Enter Details"
                          className="w-56 h-9"
                          name="noOfWaterInlets"
                          type={"number"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default SpecificDetails;
