import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { AdvanceNoteMemberTypeOptions, paymentOption } from "../../../../consts/DropDownOptions";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { Form, Formik } from "formik";
import { AddAdvanceNoteValidation } from "../../../../validationSchema/financeSchema/billingManagementSchema";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { getFlatWiseInvoiceList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageCreditNoteAction";
import { CircularProgress } from "@mui/material";
import { getBookingBankList, getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import { addAdvanceNote, getNonMemberList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction";
import { getInvoiceTypeList, } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { MANAGE_ADVANCE_NOTE_ENDPOINT } from "../../../../Routing/routes";

const AddAdvanceNote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { wingsData } = useSelector(state => state.wingsReducer)
  const { loginData } = useSelector(state => state.loginReducer)
  const { invoiceList, loadingInvoiceList } = useSelector(state => state.manageCreditNoteReducer)
  const { updateLoader, nonMemberList } = useSelector(state => state.manageAdvanceNoteReducer)
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer)
  const { bankList } = useSelector(state => state.viewBookingReducer)

  const handleSubmit = (values, { resetForm }) => {
    dispatch(addAdvanceNote({
      ...values,
      societyId: loginData?.default_community?.community_id,
      loginType: loginData?.user_type,
      onSuccessCallback: () => {
        navigate(MANAGE_ADVANCE_NOTE_ENDPOINT)
      }
    }))
  }

  useEffect(() => {
    const societyId = loginData?.default_community?.community_id
    dispatch(getInvoiceTypeList({ societyId }))
    dispatch(getBookingBankList({ societyId }))
    dispatch(getBookingGLMappingList({ societyId }))
    dispatch(getWingsAndFlatDetails({ societyId }))
    dispatch(getNonMemberList({ societyId }))
  }, [])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            memberType: 'member',
            invoiceType: 1,
            date: '',
            voucher: '',
            debitAccountId: '',
            wingId: '',
            flatId: '',
            memberId: '',
            memberGLCode: '',
            memberGLName: '',
            vendorId: '',
            vendorGLCode: '',
            vendorGLName: '',
            paymentOption: '',
            chequeNumber: '',
            transactionNumber: '',
            bankName: '',
            chequeDate: '',
            paymentDate: '',
            amount: '',
            remarks: '',
            sendEmail: 'Y',
            sendSMS: 'Y',
          }}
          validationSchema={AddAdvanceNoteValidation}
          onSubmit={handleSubmit}
        >
          {
            ({ values, errors, setFieldValue }) => (
              <Form>{console.log(values)}
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Add Advance Note">
                    <div className="flex gap-2">
                      <ButtonG
                        label="Cancel"
                        onClick={() => { navigate(-1) }}
                        className="h-8"
                        type="button"
                      />
                      <Button
                        label={
                          <span className="inline-block w-16">
                            {
                              updateLoader ?
                                <CircularProgress sx={{ color: "white" }} size={17} />
                                :
                                <>Save</>
                            }
                          </span>
                        }
                        className="h-8 px-5"
                        type="submit"
                      />
                    </div>
                  </ListHeader>
                </div>
                <div className="flex gap-4 p-2">
                  <div className="flex flex-row justify-between w-full gap-7">
                    <div className="flex flex-col w-1/2">
                      <div className="flex justify-between gap-3">
                        <div className="mt-2 text-sm">Invoice Type</div>
                        <DropdownFM2
                          options={typeLists?.map(
                            (item) => ({
                              label: item?.invoice_title,
                              value: item?.id,
                            })
                          ) || []}
                          placeholder="Select Invoice Type"
                          className=""
                          width="225px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue('invoiceType', selectedItem.value)

                          }}
                          value={values.invoiceType === "" ?
                            null
                            :
                            {
                              label: typeLists?.find(item => item.id === values.invoiceType)?.invoice_title || "",
                              value: values.invoiceType
                            }
                          }
                          name={"invoiceType"}
                        />
                      </div>
                      <div className="flex justify-between gap-3">
                        <div className="mt-2 text-sm">Member Type</div>
                        <DropdownFM2
                          options={AdvanceNoteMemberTypeOptions}
                          placeholder="Select Member Type"
                          className=""
                          width="225px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue('memberType', selectedItem.value)
                            setFieldValue('wingId', '')
                            setFieldValue('flatId', '')
                            setFieldValue('memberId', '')
                            setFieldValue('memberGLCode', '')
                            setFieldValue('memberGLName', '')
                            setFieldValue('vendorId', '')
                            setFieldValue('vendorGLCode', '')
                            setFieldValue('vendorGLName', '')
                          }}
                          value={values.memberType === "" ?
                            null
                            :
                            {
                              label: AdvanceNoteMemberTypeOptions?.find(item => item.value === values.memberType)?.label,
                              value: values.memberType
                            }
                          }
                          name={"memberType"}
                        />
                      </div>
                      {
                        values.memberType === 'member' ?
                          <div className="flex justify-between gap-3">
                            <div className="mt-2 text-sm">Member/Non-Member Details</div>
                            <div>
                              <div className="flex gap-2">
                                <DropdownFM2
                                  options={wingsData?.map((item) => ({
                                    value: item?.wing_id,
                                    label: item?.number,
                                  })) || []}
                                  width={"108px"}
                                  className="text-xs"
                                  placeholder={"Wing"}
                                  onSelect={(selectedItem) => {
                                    setFieldValue(`wingId`, selectedItem.value)
                                    setFieldValue(`flatId`, '')
                                    setFieldValue(`memberId`, '')
                                    setFieldValue(`memberGLCode`, '')
                                    setFieldValue(`memberGLName`, '')
                                    dispatch(getFlatWiseInvoiceList({ societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value }))
                                  }}
                                  height='36px'
                                  name={`wingId`}
                                  {...(values.wingId === '' && { value: null })}
                                />
                                <DropdownFM2
                                  loading={loadingInvoiceList}
                                  options={values.wingId && invoiceList?.filter(item => item.main_member && item.gl_codeid > 0)?.map((item) => ({
                                    value: item?.id,
                                    label: item?.flat_no,
                                    memberId: item?.main_member,
                                    memberGLCode: item?.codeId,
                                    memberGLName: item?.acName,
                                    invoiceList: item?.invoices
                                  })) || []}
                                  width={"108px"}
                                  className="text-xs"
                                  placeholder={"Flat/Unit"}
                                  onSelect={(selectedItem) => {
                                    setFieldValue(`memberId`, selectedItem.memberId)
                                    setFieldValue(`memberGLCode`, selectedItem.memberGLCode)
                                    setFieldValue(`memberGLName`, selectedItem.memberGLName)
                                    setFieldValue(`flatId`, selectedItem.value)
                                  }}
                                  height='36px'
                                  name={`flatId`}
                                  {...(values.flatId === '' && { value: null })}
                                />
                              </div>
                            </div>
                          </div>
                          :
                          <div className="flex justify-between gap-3">
                            <div className="mt-2 text-sm">Member/Non-Member Details	</div>
                            <DropdownFM2
                              options={nonMemberList?.map((item) => ({
                                label: item.vendorname,
                                value: item?.id,
                                venderGLCode: item?.glcode
                              })) || []}
                              placeholder="Select Non Member"
                              className=""
                              width="225px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue('vendorGLCode', selectedItem.venderGLCode)
                                setFieldValue('vendorId', selectedItem.value)
                              }}
                              {...(values.vendorId === "" && {
                                value: null,
                              })}
                              name={"vendorId"}
                            />
                          </div>
                      }
                      {/* <div className="flex justify-between gap-3">
                        <div className="mt-2 text-sm">Member Name</div>
                        <DropdownFM2
                          options={

                          }
                          placeholder="Select Payment Mode"
                          className=""
                          width="225px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue('paymentOption', selectedItem.value);
                            if (selectedItem.value === 'Cash') {
                              setFieldValue('debitAccountId', '01-01-01-01');
                            } else {
                              setFieldValue('debitAccountId', '');
                            }
                          }}

                          value={values.paymentOption === "" ? null : {
                            label: paymentOption?.find(item => item.value === values.paymentOption)?.label,
                            value: values.paymentOption
                          }}
                          name="paymentOption"
                        />
                      </div> */}
                      <div className="flex flex-row justify-between">
                        <span className="mt-2 text-sm">Date of Advance</span>
                        <div className="flex gap-3">
                          <DatePickerComponentFM
                            className="w-56 h-9"
                            name={`date`}
                            placeHolder="Select Date"
                            onDateChange={(selectedDate) => {
                              setFieldValue(`date`, moment(selectedDate).format('YYYY-MM-DD'))
                            }}
                            //minDate={new Date()}
                            defaultValue={values.date ? new Date(values.date) : null}
                          />
                        </div>
                      </div>
                      <div className="flex justify-between gap-3">
                        <div className="mt-2 text-sm">Payment Mode</div>
                        <DropdownFM2
                          options={paymentOption}
                          placeholder="Select Payment Mode"
                          className=""
                          width="225px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue('paymentOption', selectedItem.value);
                            if (selectedItem.value === 'Cash') {
                              setFieldValue('debitAccountId', '01-01-01-01');
                            } else {
                              setFieldValue('debitAccountId', '');
                            }
                          }}

                          value={values.paymentOption === "" ? null : {
                            label: paymentOption?.find(item => item.value === values.paymentOption)?.label,
                            value: values.paymentOption
                          }}
                          name="paymentOption"
                        />
                      </div>
                      <div className="flex justify-between gap-3">
                        <div className="mt-2 text-sm">Cash & Bank A/C</div>
                        {values.paymentOption == "Cash" ? (
                          <DropdownFM2
                            options={bankList?.filter(item => item.ac_code === "01-01-01-01")?.map((item) => ({
                              label: item.name,
                              value: item?.ac_code
                            })) || []}
                            placeholder="Select A/C"
                            className=""
                            width="225px"
                            height="36px"
                            onSelect={(selectedItem) => {
                              setFieldValue('debitAccountId', selectedItem.value)
                            }}

                            value={values.debitAccountId === "" ? null : {
                              label: bankList?.find(item => item.ac_code === values.debitAccountId)?.name,
                              value: values.debitAccountId
                            }}
                            name={"debitAccountId"}
                          />) : (
                          <DropdownFM2
                            options={bankList?.map((item) => ({
                              label: item.name,
                              value: item?.ac_code
                            })) || []}
                            placeholder="Select A/C"
                            className=""
                            width="225px"
                            height="36px"
                            onSelect={(selectedItem) => {
                              setFieldValue('debitAccountId', selectedItem.value)
                            }}
                            {...(values.debitAccountId === "" && {
                              value: null,
                            })}
                            name={"debitAccountId"}
                          />
                        )}
                      </div>
                      {(values.paymentOption !== '' && values.paymentOption !== 'Cash' && values.paymentOption !== 'Neft') && (
                        <TextInputFM
                          label="Cheque UTR No"
                          placeholder="Enter Cheque Number"
                          className="w-56"
                          type="alphaNumber"
                          name='chequeNumber'
                          maxLength={20}
                        />
                      )}
                      {(values.paymentOption !== '' && values.paymentOption !== 'Cash' && values.paymentOption !== 'Cheque') && (
                        <TextInputFM
                          label="Transaction UTR No"
                          placeholder="Enter Transaction Number"
                          className="w-56"
                          type="alphaNumber"
                          name='transactionNumber'
                          maxLength={20}
                        />
                      )}
                      {values.paymentOption !== 'Cash' && (
                        <TextInputFM
                          label="Bank Name"
                          placeholder="Enter Bank Name"
                          className="w-56"
                          name='bankName'
                        />)}
                      {(values.paymentOption !== '' && values.paymentOption !== 'Cash' && values.paymentOption !== 'Neft') && (
                        <div className="flex flex-row justify-between">
                          <span className="mt-2 text-sm">Cheque Date</span>
                          <div className="flex gap-3">
                            <DatePickerComponentFM
                              className="w-56 h-9"
                              name={`chequeDate`}
                              onDateChange={(selectedDate) => {
                                setFieldValue(`chequeDate`, moment(selectedDate).format('YYYY-MM-DD'))
                              }}
                              defaultValue={values.chequeDate ? new Date(values.chequeDate) : null}
                            />
                          </div>
                        </div>
                      )}
                      <div className="flex flex-row justify-between">
                        <span className="mt-2 text-sm">Clearance Date</span>
                        <div className="flex gap-3">
                          <DatePickerComponentFM
                            className="w-56 h-9"
                            name={`paymentDate`}
                            placeHolder="Select Date"
                            onDateChange={(selectedDate) => {
                              setFieldValue(`paymentDate`, moment(selectedDate).format('YYYY-MM-DD'))
                            }}
                            defaultValue={values.paymentDate ? new Date(values.paymentDate) : null}
                          />
                        </div>
                      </div>
                      <TextInputFM
                        label="Amount"
                        placeholder="Enter Amount"
                        className="w-56"
                        type="decimal"
                        name='amount'
                        maxLength={10}
                      />
                      <div className="w-full">
                        <TextAreaFM
                          label="Remarks"
                          placeholder="Enter Remarks"
                          className={"w-56 h-[87px]"}
                          maxLength={300}
                          name='remarks'
                        />
                      </div>
                      <div className="flex items-center justify-between gap-3">
                        <div className="w-1/2 text-sm">Send SMS And Email</div>
                        <div className="flex items-center w-1/2 p-2 text-sm">
                          <Checkbox
                            text={"SMS"}
                            className={"w-28"}
                            initialchecked={values.sendSMS === 'Y'}
                            onChange={(e) => { setFieldValue('sendSMS', e.target.checked ? 'Y' : 'N') }}
                          />
                          <Checkbox
                            text={"Email"}
                            className={"w-28"}
                            initialchecked={values.sendEmail === 'Y'}
                            onChange={(e) => { setFieldValue('sendEmail', e.target.checked ? 'Y' : 'N') }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                      <div className="mt-[275px] break-all text-sm text-[#AAAAAA]">
                        {values.memberType === 'Member' && values.memberGLName}
                      </div>
                    </div>
                  </div>

                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </Layout>
  );
};

export default AddAdvanceNote;
