import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance2 from '../../../axiosInstance2'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';


export async function getCreditDebitTariffHeaderListReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('typeId', action.data?.typeId);
    bodyFormData.append('search', action.data?.searchText);
    bodyFormData.append('page', isNaN(parseInt(action.data?.page)-1) ? 'all' : parseInt(action.data?.page)-1);
    bodyFormData.append('limit', action.data?.pageLimit);


    return axiosInstance2.post(apiEndpoints.GET_TARIFF_HEADER_LIST_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getCreditDebitTariffHeaderDetailsReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('cmsId', action.data?.tariffHeaderId);

    return axiosInstance2.post(apiEndpoints.GET_TARIFF_HEADER_DETAILS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getCreditDebitTariffHeaderAuditReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('cmsId', action.data?.tariffHeaderId);

    return axiosInstance2.post(apiEndpoints.GET_TARIFF_HEADER_AUDIT_LOGS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getCreditDebitTariffHeaderAllAuditReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);

    return axiosInstance2.post(apiEndpoints.GET_TARIFF_HEADER_ALL_AUDIT_LOGS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateCreditDebitTariffHeadersReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.id);
    bodyFormData.append('pageid', action.data?.societyId);
    bodyFormData.append('orderid', parseInt(action.data?.orderNo));
    bodyFormData.append('cause', action.data?.cause);
    bodyFormData.append('GLmapping', action.data?.glMappingId);
    bodyFormData.append('amount', action.data?.amount);
    bodyFormData.append('hsncode', action.data?.hsnCode);
    bodyFormData.append('type', action.data?.type);
    bodyFormData.append('gstapplicable', action.data?.gst);
    bodyFormData.append('servicegood', action.data?.service);

    return axiosInstance2.post(apiEndpoints.UPDATE_TARIFF_HEADER_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function addCreditDebitTariffHeadersReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', 0);
    bodyFormData.append('pageid', action.data?.societyId);
    bodyFormData.append('typeid', action.data?.typeId);
    bodyFormData.append('orderid', parseInt(action.data?.orderNo));
    bodyFormData.append('cause', action.data?.cause);
    bodyFormData.append('GLmapping', action.data?.glMappingId);
    bodyFormData.append('amount', action.data?.amount);
    bodyFormData.append('hsncode', action.data?.hsnCode);
    bodyFormData.append('type', action.data?.type);
    bodyFormData.append('gstapplicable', action.data?.gst);
    bodyFormData.append('servicegood', action.data?.service);

    return axiosInstance2.post(apiEndpoints.ADD_TARIFF_HEADER_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getGLMappingListReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);


    return axiosInstance2.post(apiEndpoints.GET_GL_MAPPING_LIST_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getAccountLedgerHeaderListReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);


    return axiosInstance2.post(apiEndpoints.GET_ACCOUNT_LEDGER_HEADER_LIST_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getDebitSettingReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('typeid', action.data?.invoiceType);
    bodyFormData.append('type', action.data?.type);

    return axiosInstance2.post(apiEndpoints.GET_DEBIT_NOTE_SETTINGS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateDebitSettingsReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('pageid', action.data?.societyId);
    bodyFormData.append('typeid', action.data?.invoiceType);
    bodyFormData.append('type', action.data?.type);
    bodyFormData.append('header', action.data?.header);
    bodyFormData.append('join_member_name', action.data?.displayName ===2 ? 'Yes' : 1);
    bodyFormData.append('hide_show_flat', action.data?.showFlatNo);
    bodyFormData.append('hide_show_type', action.data?.showBilltype);
    bodyFormData.append('hide_show_address', action.data?.showAddress);
    bodyFormData.append('hide_show_gst', action.data?.showGST);
    bodyFormData.append('hide_show_flat_area', action.data?.showFlatArea);
    bodyFormData.append('hide_show_receipt', action.data?.showReceipt);
    bodyFormData.append('hide_show_van', action.data?.showVAN);
    bodyFormData.append('interest_hsn', action.data?.hsnCode);
    bodyFormData.append('interest_service', action.data?.service);
    bodyFormData.append('debitinterest', action.data?.interest);
    bodyFormData.append('gst_applicable', action.data?.gstApplicable);
    bodyFormData.append('interest_gst', action.data?.gstApplicableOnInterest);
    bodyFormData.append('cgst_rate', action.data?.cgstRate);
    bodyFormData.append('sgst_rate', action.data?.sgstRate);
    bodyFormData.append('igst_rate', action.data?.igstRate);
    bodyFormData.append('cgst_head', action.data?.cgst);
    bodyFormData.append('sgst_head', action.data?.sgst);
    bodyFormData.append('igst_head', action.data?.igst);
    bodyFormData.append('roundoffcheck', action.data?.roundOffFeature);
    bodyFormData.append('roundoff_head', action.data?.roundoff);
    bodyFormData.append('notes', action.data?.notes);

    return axiosInstance2.post(apiEndpoints.UPDATE_DEBIT_NOTE_SETTINGS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}