import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/FinanceReqs/BillingManagementReqs/ManageInvoiceReqs'
import { notify } from '../../../../utils/notification';


function* getManageInvoiceCurrent(action) {
    try {
        const result = yield call(req.getManageInvoiceCurrentReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MANAGE_INVOICE_CURRENT_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.GET_MANAGE_INVOICE_CURRENT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
  }

  function* getManageInvoicePrevious(action) {
    try {
        const result = yield call(req.getManageInvoicePreviousReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MANAGE_INVOICE_PREVIOUS_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.GET_MANAGE_INVOICE_PREVIOUS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
  }

  function* getManageInvoiceDraft(action) {
    try {
        const result = yield call(req.getManageInvoiceDraftReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MANAGE_INVOICE_DRAFT_SUCCESS, payload: result});  
            action?.data?.onSuccessCallback()
        } else {
            yield put({ type: types.GET_MANAGE_INVOICE_DRAFT_FAILURE, message: result?.response?.data?.message });
            action?.data?.onFailureCallback(result)
        }
    } catch (e) {
        console.log(e)
    }
  }

  function* getManageInvoiceActionsList(action) {
    try {
        const result = yield call(req.getManageInvoiceActionsListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MANAGE_INVOICE_ACTIONS_LIST_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.GET_MANAGE_INVOICE_ACTIONS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
  }

// function* getSingleInvoiceList(action) {
//     try {
//         const result = yield call(req.getSingleInvoiceReq, action);
//         if (result.success === true) {
//             yield put({ type: types.SINGLE_INVOICE_LIST_SUCCESS, payload: result.data});  
//         } else {
//             yield put({ type: types.SINGLE_INVOICE_LIST_FAILURE, message: result?.response?.data?.message });
//         }
//     } catch (e) {
//         console.log(e)
//     }
// }

function* getAdjustmentPaymentDetails(action) {
    try {
        const result = yield call(req.adjustPaymentDetailReq, action);
        if (result.success === true) {
            yield put({ type: types.ADJUSTMENT_PAYMENT_DETAILS_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.ADJUSTMENT_PAYMENT_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
  }

  function* getAdjustmentPaymentGetAmount(action) {
    try {
        const result = yield call(req.adjustPaymentGetAmountReq, action);
        if (result.success === true) {
            yield put({ type: types.ADJUSTMENT_PAYMENT_GET_AMOUNT_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.ADJUSTMENT_PAYMENT_GET_AMOUNT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
  }

  function* getAdjustmentPaymentSave(action) {
    try {
        const result = yield call(req.adjustPaymentSaveReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.ADJUSTMENT_PAYMENT_SAVE_SUCCESS, payload: result});  
            action.data.onSuccessCallback(result)
        } else {
            yield put({ type: types.ADJUSTMENT_PAYMENT_SAVE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
  }

  function* downloadManageInvoicePaymentExcelSample(action) {
    try {
        const result = yield call(req.downloadPaymentExcelReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* uploadManageInvoicePaymentExcel(action) {
    try {
        const result = yield call(req.detailsSocietyPaymentReq, action);
        if (result.success === true) {
            yield put({ type: types.UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_SUCCESS, payload: result.data, error : result.error});  
        } else {
            yield put({ type: types.UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* saveManageInvoicePaymentExcel(action) {
    try {
        const result = yield call(req.uploadPaymentExcelReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.SAVE_MANAGE_INVOICE_PAYMENT_EXCEL_SUCCESS, payload: result.data}); 
            action.data.onSuccessCallback(result)
        } else {
            yield put({ type: types.SAVE_MANAGE_INVOICE_PAYMENT_EXCEL_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}
function* getEditInvoiceDetails(action) {
    try {
        const result = yield call(req.getEditInvoiceDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_EDIT_INVOICE_DETAILS_SUCCESS, payload: JSON.stringify(result.data)});  
        } else {
            yield put({ type: types.GET_EDIT_INVOICE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* editInvoiceDetails(action) {
    try {
        const result = yield call(req.editInvoiceDetailsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.EDIT_INVOICE_DETAILS_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback?.() 
        } else {
            yield put({ type: types.EDIT_INVOICE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}
function* updateInvoiceRemarks(action) {
    try {
        const result = yield call(req.updateInvoiceRemarksReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.UPDATE_INVOICE_REMARKS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_INVOICE_REMARKS_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* deleteInvoice(action) {
    try {
        const result = yield call(req.deleteInvoiceReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_INVOICE_SUCCESS, payload: action.data});  
            // const newList = yield call(req.getManageCreditListReq, action)
            // if (newList.success === true)
            //     yield put({ type: types.GET_CREDIT_NOTE_LIST_SUCCESS, payload: newList});  
        } else {
            yield put({ type: types.DELETE_INVOICE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getVerifyPostPaymentDetails(action) {
    try {
        const result = yield call(req.verifyPostPaymentDetailsReq, action);
        console.log(result, "saga")
        if (result.success === true) {
            yield put({ type: types.VERIFY_POST_PAYMENT_DETAILS_SUCCESS, payload: JSON.stringify(result.data)});  
        } else {
            yield put({ type: types.VERIFY_POST_PAYMENT_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* verifyPostPaymentUpdate(action) {
    try {
        const result = yield call(req.verifyPostPaymentUpdateReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.VERIFY_POST_PAYMENT_UPDATE_SUCCESS, payload: result.data}); 
            action.data?.onSuccessCallback?.() 
        } else {
            yield put({ type: types.VERIFY_POST_PAYMENT_UPDATE_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* bulkVerifyPostPayment(action) {
    try {
        const result = yield call(req.bulkVerifyPostPaymentReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.BULK_VERIFY_POST_PAYMENT_UPDATE_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.BULK_VERIFY_POST_PAYMENT_UPDATE_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* sendInvoiceReminderEmailSms(action) {
    try {
        const result = yield call(req.sendInvoiceReminderEmailSmsReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback?.()
            notify('success',"Reminder sent successfully")
            yield put({ type: types.SEND_INVOICE_REMINDER_EMAIL_SMS_SUCCESS, payload: action.data});  
        }else{
            yield put({ type: types.SEND_INVOICE_REMINDER_EMAIL_SMS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* sendResendReceiptEmailSms(action) {
    try {
        const result = yield call(req.sendResendReceiptEmailSmsReq, action);
        if (result.success === true) {
            notify('success', "Receipt sent successfully")
            action.data?.onSuccessCallback?.()
            yield put({ type: types.SEND_RESEND_RECEIPT_EMAIL_SMS_SUCCESS, payload: action.data});  
        }else{
            yield put({ type: types.SEND_RESEND_RECEIPT_EMAIL_SMS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* resendInvoiceEmailSms(action) {
    try {
        const result = yield call(req.resendInvoiceEmailSmsReq, action);
        if (result.success === true) {
            notify('success', "Invoice email & SMS sent successfully")
            action.data?.onSuccessCallback?.()
            yield put({ type: types.SEND_INVOICE_EMAIL_SMS_SUCCESS, payload: action.data});  
        }else{
            yield put({ type: types.SEND_INVOICE_EMAIL_SMS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* rejectInvoicePayment(action) {
    try {
        const result = yield call(req.rejectInvoiceReq, action);
        if (result.success === true) {
            notify('success', result?.message)
            yield put({ type: types.REJECT_INVOICE_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.REJECT_INVOICE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadInvoiceDraftExcel(action) {
    try {
        const result = yield call(req.downloadInvoiceDraftExcelReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
            yield put({ type: types.DOWNLOAD_INVOICE_DRAFT_EXCEL_SUCCESS}); 
        }else{
            yield put({ type: types.DOWNLOAD_INVOICE_DRAFT_EXCEL_FAILURE}); 
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* BulkManageInvoice(action) {
    try {
        const result = yield call(req.getBulkManageInvoiceReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_BULK_MANAGE_INVOICE_SUCCESS,
                payload: result,
            }); 
        }else{
            yield put({ type: types.GET_BULK_MANAGE_INVOICE_FAILURE}); 
        }
    } catch (e) {
        console.log(e)
    }
}
function* BulkCreateInvoice(action) {
    try {
        const result = yield call(req.getBulkCreateInvoiceReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_BULK_CREATE_INVOICE_SUCCESS,
                payload: result,
            }); 
            action?.data?.onSuccessCallback(result)
        }else{
            yield put({ type: types.GET_BULK_CREATE_INVOICE_FAILURE}); 
            action?.data?.onFailureCallback(result)
        }
    } catch (e) {
        console.log(e)
    }
}
function* allTransactionsReport(action) {
    try {
        const result = yield call(req.getAllTransactionsReportReq, action);
        if (result.success === true) {
            yield put({ type: types.ALL_TRANSACTION_REPORT_SUCCESS,
                payload: result,
            }); 
            action?.data?.onSuccessCallback(result)
        }else{
            yield put({ type: types.ALL_TRANSACTION_REPORT_FAILURE}); 
            action?.data?.onFailureCallback(result)
        }
    } catch (e) {
        console.log(e)
    }
}
function* projectWiseSocietyDetails(action) {
    console.log('saga')
    try {
        const result = yield call(req.getProjectWiseSocietyDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.PROJECT_WISE_SOCIETY_DETAILS_SUCCESS,
                payload: result,
            }); 
            action?.data?.onSuccessCallback(result)
        }else{
            yield put({ type: types.PROJECT_WISE_SOCIETY_DETAILS_FAILURE}); 
            action?.data?.onFailureCallback(result)
        }
    } catch (e) {
        console.log(e)
    }
}
function* downloadFileFromGcs(action) {
    try {
        const result = yield call(req.getDownloadFileFromGcsReq, action);
        if (result instanceof ArrayBuffer) {
            action?.data?.onSuccessCallback(result)
        }else{
            notify('Unable to download sheet.')
            action?.data?.onFailureCallback(result)
        }
    } catch (e) {
        console.log(e)
    }
}
function* getInvoiceSync(action) {
    console.log('saga')
    try {
        const result = yield call(req.getInvoiceSyncReq, action);
        console.log('result',result)
        if (result.success === true) {
            notify('success',result?.message)
            action?.data?.onSuccessCallback()
            yield put({ type: types.INVOICE_SYNC_SUCCESS}); 
           
        }else{
            yield put({ type: types.INVOICE_SYNC_FAILURE}); 
            action?.data?.onFailureCallback(result)
        }
    } catch (e) {
        console.log(e)
    }
}
function* getInvoiceReceiptSync(action) {
    console.log('saga')
    try {
        const result = yield call(req.getInvoiceReceiptSyncReq, action);
        console.log(result)
        if (result.success === true) {
            notify('success',result?.message)
            action?.data?.onSuccessCallback()
            yield put({ type: types.INVOICE_RECEIPT_SYNC_SUCCESS}); 
        }else{
            yield put({ type: types.INVOICE_RECEIPT_SYNC_FAILURE}); 
            action?.data?.onFailureCallback(result)
        }
    } catch (e) {
        console.log(e)
    }
}
function* getSocietyInvoiceData(action) {
    try {
        const result = yield call(req.getSocietyInvoiceDataReq, action);
        if (result.success === true) {
            yield put({ type: types.SOCIETY_INVOICE_DATA_SUCCESS, payload: result}); 
        }else{
            yield put({ type: types.SOCIETY_INVOICE_DATA_FAILURE}); 
        }
    } catch (e) {
        console.log(e)
    }
}


  export default function* manageInvoiceSaga() {
    yield takeLatest(types.GET_MANAGE_INVOICE_CURRENT, getManageInvoiceCurrent);
    yield takeLatest(types.GET_MANAGE_INVOICE_PREVIOUS, getManageInvoicePrevious);
    yield takeLatest(types.GET_MANAGE_INVOICE_DRAFT, getManageInvoiceDraft);
    yield takeLatest(types.GET_MANAGE_INVOICE_ACTIONS_LIST, getManageInvoiceActionsList);
    yield takeLatest(types.ADJUSTMENT_PAYMENT_DETAILS, getAdjustmentPaymentDetails);
    yield takeLatest(types.ADJUSTMENT_PAYMENT_GET_AMOUNT, getAdjustmentPaymentGetAmount);
    yield takeLatest(types.ADJUSTMENT_PAYMENT_SAVE, getAdjustmentPaymentSave);
    // yield takeLatest(types.SINGLE_INVOICE_LIST, getSingleInvoiceList);
    yield takeLatest(types.DOWNLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_SAMPLE, downloadManageInvoicePaymentExcelSample);
    yield takeLatest(types.UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL, uploadManageInvoicePaymentExcel);
    yield takeLatest(types.SAVE_MANAGE_INVOICE_PAYMENT_EXCEL, saveManageInvoicePaymentExcel);
    yield takeLatest(types.GET_EDIT_INVOICE_DETAILS, getEditInvoiceDetails);
    yield takeLatest(types.EDIT_INVOICE_DETAILS, editInvoiceDetails);
    yield takeLatest(types.DELETE_INVOICE, deleteInvoice);
    yield takeLatest(types.UPDATE_INVOICE_REMARKS, updateInvoiceRemarks);
    yield takeLatest(types.VERIFY_POST_PAYMENT_DETAILS, getVerifyPostPaymentDetails);
    yield takeLatest(types.VERIFY_POST_PAYMENT_UPDATE, verifyPostPaymentUpdate);
    yield takeLatest(types.SEND_RESEND_RECEIPT_EMAIL_SMS, sendResendReceiptEmailSms);
    yield takeLatest(types.SEND_INVOICE_REMINDER_EMAIL_SMS, sendInvoiceReminderEmailSms);
    yield takeLatest(types.DOWNLOAD_INVOICE_DRAFT_EXCEL, downloadInvoiceDraftExcel);
    yield takeLatest(types.REJECT_INVOICE, rejectInvoicePayment);
    yield takeLatest(types.SEND_INVOICE_EMAIL_SMS, resendInvoiceEmailSms);
    yield takeLatest(types.BULK_VERIFY_POST_PAYMENT_UPDATE, bulkVerifyPostPayment);
    yield takeLatest(types.GET_BULK_MANAGE_INVOICE, BulkManageInvoice);
    yield takeLatest(types.GET_BULK_CREATE_INVOICE, BulkCreateInvoice);
    yield takeLatest(types.ALL_TRANSACTION_REPORT, allTransactionsReport);
    yield takeLatest(types.PROJECT_WISE_SOCIETY_DETAILS, projectWiseSocietyDetails);
    yield takeLatest(types.DOWNLOAD_FILE_GCS, downloadFileFromGcs);
    yield takeLatest(types.INVOICE_SYNC, getInvoiceSync);
    yield takeLatest(types.INVOICE_RECEIPT_SYNC, getInvoiceReceiptSync);
    yield takeLatest(types.SOCIETY_INVOICE_DATA, getSocietyInvoiceData);
}