import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

 const BillingPagination2 = ({
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  onChange,
}) => {
  return (
    <div className="flex items-center gap-2">
      <button
      onClick={() => onChange(currentPage - 1)}
        className={`p-2 py-1 border border-[#CCC] rounded-l ${
          start === 1 ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={start === 1} 
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="text-red-650 w-2 h-3"
        />
      </button>
      <span className="mr-2">
        {start}-{end} of {totalCount}
      </span>
      <button
        onClick={() => onChange(currentPage + 1)}
        className={`p-2 py-1 border border-[#CCC] rounded-r ${
          currentPage === totalPages - 1 ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={currentPage === totalPages - 1}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className="text-red-650 w-2 h-3"
        />
      </button>
    </div>
  );
};
export default BillingPagination2;