import * as type from '../../../actions/index.types'

export function sentSmsHistoryReducer(state = {
    error: null,
    loading: false,
    sentSmsHistoryList: null,
    smsModuleList: null,
    sentSmsHistoryDetails: null,
}, action) {

    switch (action.type) {
        case type.GET_SENT_SMS_HISTORY_LIST:
            return {
                ...state,
                loading: true,
            }
        case type.GET_SENT_SMS_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                sentSmsHistoryList: action.payload,
            }
        case type.GET_SENT_SMS_HISTORY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                sentSmsHistoryList: null,
                error: action?.message || 'Somthing went wrong.',
            }

        case type.GET_SMS_MODULE_LIST:
            return {
                ...state,
                loading: true,
            }
        case type.GET_SMS_MODULE_LIST_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                smsModuleList: action.payload,
            }
        case type.GET_SMS_MODULE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                smsModuleList: null,
                error: action?.message || 'Somthing went wrong.',
            }

        case type.GET_SENT_SMS_HISTORY_DETAILS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_SENT_SMS_HISTORY_DETAILS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                sentSmsHistoryDetails: action.payload,
            }
        case type.GET_SENT_SMS_HISTORY_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                sentSmsHistoryDetails: null,
                error: action?.message || 'Somthing went wrong.',
            }

        default:
            return state
    }
}