import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import Button from "../../../../components/Button/Button";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { UploadEx } from "../../../../assets";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  clearBulkUploadError,
} from "../../../../redux/actions/SetupRecordsActions/BulkUploadActions/BulkUploadAction";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";
import { downloadCreditNoteExcelSample, saveCreditNoteExcel, uploadCreditNoteExcel } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageCreditNoteAction";
import ExcelDataTable from "./ExcelDataTable";
import {
  getInvoiceTypeList,
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";

const UploadCreditNote = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const inputFile = useRef(null);
  const [uploadingExcelData, setUploadingExcelData] = useState([])
  const { loginData } = useSelector((state) => state.loginReducer);
  const { uploadLoader, error, excelData } = useSelector(
    (state) => state.manageCreditNoteReducer
  );
  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const [invoiceType, setInvoicetype] = useState(1);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const onUpload = () => {
    if (!excelData) {
      let data = {
        societyId: loginData?.default_community?.community_id,
        invoiceType: invoiceType,
        file,
        onRequestEndCallback: () => {
          setUploadingExcelData([])
          setFile(null);
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        },
      };
      dispatch(uploadCreditNoteExcel(data));
    } else {
      let data = {
        societyId: loginData?.default_community?.community_id,
        invoiceType: invoiceType,
        file,
        uploadingExcelData,
        onRequestEndCallback: () => {
          setUploadingExcelData([])
          dispatch(clearBulkUploadError())
          setFile(null);
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        },
      };
      dispatch(saveCreditNoteExcel(data));
    }
  };

  const handleDownloadSampleAndFormatExcel = (flag) => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Credit Note Excel ${flag}.xlsx` });
    };
    const data = {
      flag,
      onSucessCallback,
    };
    dispatch(downloadCreditNoteExcelSample(data));
  };

  useEffect(() => {
    return () => dispatch(clearBulkUploadError());
  }, []);

  useEffect(() => {
    if (excelData) {
      let data = [...excelData]
      excelData.forEach((item) => {
        item.selected = item?.error ? false : true;
        item.cgstrate = item.cgstrate ? parseFloat(item.cgstrate)?.toFixed(2) : '0.00'
        item.sgstrate = item.sgstrate ? parseFloat(item.sgstrate)?.toFixed(2) : '0.00'
      })
      setUploadingExcelData(data)
    }
  }, [excelData]);
  useEffect(() => {
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Credit Note Excel Upload">
            <div className="border-r border-[#CCC]"></div>
            <Button
              label={
                <span className="w-12 text-enter inline-block">
                  {uploadLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    !excelData ? "Process" : "Upload"
                  )}
                </span>
              }
              className="px-4 h-8 disabled:opacity-50"
              disabled={(!file && !excelData) || uploadLoader}
              onClick={onUpload}
            />
          </ListHeader>
        </div>
        {
          excelData ?
            <div className="w-full mt-4">
              <ExcelDataTable uploadingExcelData={uploadingExcelData} setUploadingExcelData={setUploadingExcelData} />
            </div>
            :
            <div className="w-full mt-4">
              <div className="flex items-center justify-center gap-4 my-3">
                <span className="text-[15px]">Invoice Type</span>
                <Dropdown2
                  options={
                    typeLists
                      ? typeLists?.map(
                        (item) => ({
                          label: item?.invoice_title,
                          value: item?.id,
                        })
                      )
                      : []
                  }
                  placeholder="Invoice type"
                  className="text-[11px] "
                  width="224px"
                  height="32px"
                  name="invoice_type"
                  onSelect={(selectedItem) => {
                    setInvoicetype(selectedItem.value);
                  }}
                  value={
                    typeLists && {
                      value: invoiceType,
                      label: typeLists ? typeLists?.find((item) => item?.id === parseInt(invoiceType))?.invoice_title : "",
                    }
                  }
                />
              </div>
              <p className="font-semibold text-sm text-center mt-6 mb-4">
                {/* Note: Please do not leave any field empty except annual property
              tax, if it is not applied */}
              </p>
              <div className="">
                <label
                  htmlFor="fileInput"
                  className="block font-semibold text-sm"
                >
                  <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    className="w-[60%] ml-48 flex justify-center border-dashed border-2 border-[#CCC] rounded-md text-center p-9 bg-[#F9FAFC] cursor-pointer"
                  >
                    <div className="flex justify-center flex-col">
                      <div className="flex justify-center">
                        <UploadEx className="w-6" />
                      </div>
                      <input
                        type="file"
                        id="fileInput"
                        className="hidden"
                        accept={
                          ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        }
                        onChange={handleFileChange}
                        ref={inputFile}
                      />
                      Upload .xlsx file
                      <p className="text-[#AAAAAA] text-[13px]">or drag and drop</p>
                    </div>
                  </div>
                </label>
                <div className="w-[60%] ml-48 my-3 text-sm h-4 font-semibol text-nowrap text-[#AAAAAA] text-ellipsis overflow-hidden ">
                  {file && `File Name: ${file?.name}`}
                </div>
                <div className="w-[60%] ml-48 flex justify-center text-red-650 cursor-pointer text-xs font-semibold">
                  <div
                    onClick={() => {
                      handleDownloadSampleAndFormatExcel("sample");
                    }}
                  >
                    Download Sample
                  </div>
                </div>
                {error && !uploadLoader && (
                  <div className="w-[60%] mt-4 ml-48 text-red-650 cursor-pointer text-wrap break-all text-xs font-semibold text-justify">
                    Error:
                    <br />
                    {typeof error === "string"
                      ? error
                      : Object.entries(error)?.map((item) => (
                        <div>
                          {/* {item[1]?.includes('Opening') ? '' : `Row ${item[0]}: `}   */}
                          {item[1]}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
        }
      </div>
    </Layout>
  );
};

export default UploadCreditNote;
