import { call, put, takeLatest } from 'redux-saga/effects'
import { notify } from '../../../../utils/notification';
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/FinanceReqs/BillingManagementReqs/ManageDebitNoteReqs'


function* getDebitNoteCurrent(action) {
    try {
        const result = yield call(req.getDebitNoteCurrentReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_CURRENT_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_CURRENT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getDebitNoteActionList(action) {
    try {
        const result = yield call(req.getDebitNoteActionListtReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_ACTION_LIST_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_ACTION_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* bulkVerifyPostDebitNoteUpdate(action) {
    try {
        const result = yield call(req.bulkVerifyPostDebitNoteUpdateReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.BULK_VERIFY_POST_DEBIT_NOTE_UPDATE_SUCCESS, payload: result });
            action.data.onSuccessCallback?.()

        } else {
            yield put({ type: types.BULK_VERIFY_POST_DEBIT_NOTE_UPDATE_FAILURE, message: result?.response?.data?.message });
        }

    } catch (e) {
        console.log(e)
    }
}
function* resendDebitNoteCurrentEmailSms(action) {
    try {
        const result = yield call(req.resendDebitNoteCurrentEmailSmsReq, action);
        if (result.success === true) {
            notify('success', "Bill Email & SMS Resent Successfully")
            action.data.onSuccessCallback?.(result?.message)
            yield put({ type: types.RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_SUCCESS, payload: result });
        } else {
            console.log(result)
            notify('error', result.error)
            action?.data?.onFailureCallback(result?.message)
            yield put({ type: types.RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_FAILURE, message: result?.response?.data?.message });
        }

    } catch (e) {
        console.log(e)
    }
}


function* bulkMakePaymentDebitNoteSave(action) {
    try {
        const result = yield call(req.bulkMakePaymentDebitNoteSaveReq, action);
        if (result.success === true) {
            notify('success', "Payment Successful")
            yield put({ type: types.BULK_MAKE_PAYMENT_DEBIT_NOTE_SAVE_SUCCESS, payload: result });
            action.data.onSuccessCallback?.()
        } else {
            yield put({ type: types.BULK_MAKE_PAYMENT_DEBIT_NOTE_SAVE_FAILURE, message: result?.response?.data?.message });
        }

    } catch (e) {
        console.log(e)
    }
}

function* printDebitNoteReceipt(action) {
    try {
        const result = yield call(req.getDebitNoteReceiptPrintReq, action);
        if (result.success !== false) {
            yield put({ type: types.GET_DEBIT_NOTE_RECEIPT_PRINT_SUCCESS, payload: result });
            action.data.onSuccessCallback?.()
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_RECEIPT_PRINT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* resendDebitNoteReceiptEmailSms(action) {
    try {
        const result = yield call(req.resendDebitNoteReceiptEmailSmsReq, action);
        if (result.success === true) {
            notify('success', result.message || (action.data?.notificationType + " Resent  Successfully"))
            action.data.onSuccessCallback?.(result.message)
            yield put({ type: types.RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS_SUCCESS, payload: result });
        } else {
            notify('error', result.error)
            action.data.onFailureCallback?.(result.message)
            yield put({ type: types.RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteDebitNoteReceipt(action) {
    try {
        const result = yield call(req.deleteDebitNoteReceiptReq, action);
        if (result.success === true) {
            notify('success', result?.message)
            action.data?.onSuccessCallback?.(result?.message)
            yield put({ type: types.DELETE_DEBIT_NOTE_RECEIPT_SUCCESS, payload: result });

        } else {
            action.data?.onFailureCallback?.(result?.message)
            yield put({ type: types.DELETE_DEBIT_NOTE_RECEIPT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getSingleDebitNoteList(action) {
    try {
        const result = yield call(req.getSingleDebitNoteReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_SINGLE_DEBIT_NOTE_LIST_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_SINGLE_DEBIT_NOTE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* rejectSingleReceiptDebitNote(action) {
    try {
        const result = yield call(req.rejectSingleReceiptDebitNoteReq, action);
        if (result.success === true) {
            notify('success', result?.message)
            yield put({ type: types.REJECT_SINGLE_RECEIPT_DEBIT_NOTE_SUCCESS, payload: result });
            action.data?.onSuccessCallback?.()
        } else {
            notify('error', result?.message)
            yield put({ type: types.REJECT_SINGLE_RECEIPT_DEBIT_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* editSingleDebitNoteDetails(action) {
    try {
        const result = yield call(req.editSingleDebitNoteDetailsReq, action);
        if (result.status === "success") {
            yield put({ type: types.EDIT_SINGLE_DEBIT_NOTE_DETAILS_SUCCESS, payload: result?.data });
        } else {
            yield put({ type: types.EDIT_SINGLE_DEBIT_NOTE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* editSingleDebitNoteSave(action) {
    try {
        const result = yield call(req.editSingleDebitNoteSaveReq, action);
        if (result.status === "success") {
            notify('success', "Debite Note Updated Successfully");
            yield put({ type: types.EDIT_SINGLE_DEBIT_NOTE_SAVE_SUCCESS, payload: result });
            action.data?.onSuccessCallback();
        } else {
            notify('error', result?.error);
            yield put({ type: types.EDIT_SINGLE_DEBIT_NOTE_SAVE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getAdjustmentPaymentDetails(action) {
    try {
        const result = yield call(req.adjustPaymentDetailReq, action);
        if (result.success === true) {
            yield put({ type: types.ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE_SUCCESS, payload: result });
        } else {
            yield put({ type: types.ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAdjustmentPaymentSave(action) {
    try {
        const result = yield call(req.adjustPaymentSaveReq, action);
        if (result.success === true) {
            yield put({ type: types.ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE_SUCCESS, payload: result });
            notify('success', result?.message)
            action.data.onSuccessCallback(result)
        } else {
            yield put({ type: types.ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMakePaymentDetails(action) {
    try {
        const result = yield call(req.makePaymentDetailReq, action);
        if (result.success === true) {
            yield put({ type: types.MAKE_PAYMENT_DETAILS_DEBIT_NOTE_SUCCESS, payload: result });
        } else {
            yield put({ type: types.MAKE_PAYMENT_DETAILS_DEBIT_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getMakePaymentSave(action) {
    try {
        const result = yield call(req.makePaymentSaveReq, action);
        if (result.success === true) {
            // notify('success', result?.message)
            yield put({ type: types.MAKE_PAYMENT_SAVE_DEBIT_NOTE_SUCCESS, payload: result });
            action.data?.onSuccessCallback?.(result)

        } else {
            notify('error', result?.error)
            yield put({ type: types.MAKE_PAYMENT_SAVE_DEBIT_NOTE_FAILURE, payload: result });
        }
    } catch (e) {
        console.log(e)
    }
}

function* sendDebitNoteCurrentEmailSms(action) {
    try {
        const result = yield call(req.sendDebitNoteCurrentEmailSmsReq, action);
        if (result.success === true) {
            notify('success', result?.message || "SMS Sent Successfully")
            yield put({ type: types.SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_SUCCESS, payload: result });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteDebitNoteCurrent(action) {
    try {
        const result = yield call(req.deleteDebitNoteCurrentReq, action);
        if (result.success === true) {
            notify('success', result?.message)
            yield put({ type: types.DELETE_DEBIT_NOTE_CURRENT_SUCCESS, payload: result });
            action.data?.onSuccessCallback?.(result)

        } else {
            yield put({ type: types.DELETE_DEBIT_NOTE_CURRENT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}



function* uploadDebitNoteCurrentExcel(action) {
    try {
        const result = yield call(req.uploadDebitNoteCurrentExcelReq, action);
        if (result.success === true) {
            notify('success', result?.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.UPLOAD_DEBIT_NOTE_CURRENT_EXCEL_SUCCESS, payload: result?.data });

        } else {
            notify('error', result?.message)
            yield put({ type: types.UPLOAD_DEBIT_NOTE_CURRENT_EXCEL_FAILURE });
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* downloadDebitNoteCurrentExcel(action) {
    try {
        const result = yield call(req.downloadDebitNoteCurrentExcelReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('error', 'Unable to download sheet.')
        }

    } catch (e) {
        console.log(e)
    }
}

function* uploadDebitNoteCurrentPayment(action) {
    try {
        const result = yield call(req.uploadDebitNoteCurrentPaymentReq, action);
        if (result.success == true) {
            notify('success', "Debit Note Payment Uploaded Successfully")
            yield put({ type: types.UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT_SUCCESS, payload: { error: result?.data?.message } });
            action.data?.onSuccessCallback?.(result)
        } else {
            notify('error', result?.message)
            yield put({ type: types.UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT_FAILURE });
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* downloadDebitNoteCurrentPayment(action) {
    try {
        const result = yield call(req.downloadDebitNoteCurrentPaymentReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('error', 'Unable to download sheet.')
        }

    } catch (e) {
        console.log(e)
    }
}



function* getDebitNotePrevious(action) {
    try {
        const result = yield call(req.getDebitNotePreviousReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_PREVIOUS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_PREVIOUS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getDebitNoteDraft(action) {
    try {
        const result = yield call(req.getDebitNoteDraftReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_DRAFT_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_DRAFT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* editSingleDebitNoteDraftSave(action) {
    try {
       const result = yield call(req.editSingleDebitNoteDraftSaveReq, action);
            if (result?.success == true) {
                notify('success', "Debite Note Updated Successfully");
                action.data?.onSuccessCallback();
                yield put({ type: types.EDIT_SINGLE_DEBIT_NOTE_DRAFT_SAVE_SUCCESS });
            } else {
                 notify('error', result?.message);
                 yield put({ type: types.EDIT_SINGLE_DEBIT_NOTE_DRAFT_SAVE_FAILURE, message: result?.message });
            }
    } catch (e) {
        console.log(e)
    }
}

function* printDebitNoteInvoice(action) {
    try {
        const result = yield call(req.getDebitNoteInvoiceReq, action);
        if (result.success !== false) {
            yield put({ type: types.GET_DEBIT_NOTE_INVOICE_PRINT_SUCCESS, payload: result });
            action.data?.onSuccessCallback();
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_INVOICE_PRINT_FAILURE, message: result?.response?.data?.message });
        }

    } catch (e) {
        console.log(e)
    }
}

// function* getSingleDebitNote(action) {
//     try {
//         const result = yield call(req.getSingleDebitNoteReq, action);
//         if (result.success === true) {
//             yield put({ type: types.SINGLE_DEBIT_NOTE_LIST_SUCCESS, payload: result.data });
//         } else {
//             yield put({ type: types.SINGLE_DEBIT_NOTE_LIST_FAILURE, message: result?.response?.data?.message });
//         }
//     } catch (e) {
//         console.log(e)
//     }
// }


function* getDebiteNoteTarrifList(action) {
    try {
        const result = yield call(req.getDebiteNoteTarrifListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_TARRIF_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_TARRIF_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getDebiteNoteFlatWingWiseList(action) {
    try {
        const result = yield call(req.getDebiteNoteFlatWingWiseListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_FLAT_WING_WISE_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_FLAT_WING_WISE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getDebiteNoteArrearDetails(action) {
    try {
        const result = yield call(req.getDebiteNoteArrearDetailsReq, action);
        if (result?.data) {
            if (result?.message !== "data fetch") {
                notify("error", result?.message)
            }
            yield put({ type: types.GET_DEBIT_NOTE_ARREAR_DETAILS_SUCCESS, payload: { ...result?.data, ...result } });
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_ARREAR_DETAILS_FAILURE, message: result?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* addDebitNote(action) {
    try {
        const result = yield call(req.addDebiteNoteReq, action);
        if (result.status === true) {
            notify('success', "Debit Note Added Successfully");
            yield put({ type: types.ADD_DEBIT_NOTE_SUCCESS, payload: result.data });
            action.data?.onSuccessCallback();
        } else {
            notify('error', result?.error);
            yield put({ type: types.ADD_DEBIT_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* addMultipleDebitNote(action) {
    try {
        const result = yield call(req.addMultipleDebiteNoteReq, action);
        if (result.status === true) {
            notify('success', "Debit Note Added Successfully");
            yield put({ type: types.ADD_MULTIPLE_DEBIT_NOTE_SUCCESS, payload: result.data });
            action.data?.onSuccessCallback();
        } else {
            notify('error', result?.error);
            yield put({ type: types.ADD_MULTIPLE_DEBIT_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getDebiteNoteSync(action) {
    try {
        const result = yield call(req.getDebitNoteSyncReq, action);
        if (result.success === true) {
            notify('success',result?.message)
            action?.data?.onSuccessCallback()
            yield put({ type: types.DEBIT_NOTE_SYNC_SUCCESS}); 
           
        }else{
            action?.data?.onFailureCallback(result?.message)
            yield put({ type: types.DEBIT_NOTE_SYNC_FAILURE}); 
        }
    } catch (e) {
        console.log(e)
    }
}
function* getDebiteNoteReceiptSync(action) {
    console.log('saga')
    try {
        const result = yield call(req.getDebitNoteReceiptSyncReq, action);
        console.log(result)
        if (result.success === true) {
            notify('success',result?.message)
            action?.data?.onSuccessCallback()
            yield put({ type: types.DEBIT_NOTE_RECEIPT_SYNC_SUCCESS}); 
        }else{
            yield put({ type: types.DEBIT_NOTE_RECEIPT_SYNC_FAILURE}); 
            action?.data?.onFailureCallback(result)
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* manageDebitNoteSaga() {
    yield takeLatest(types.GET_DEBIT_NOTE_CURRENT, getDebitNoteCurrent);
    yield takeLatest(types.GET_DEBIT_NOTE_ACTION_LIST, getDebitNoteActionList);
    yield takeLatest(types.BULK_VERIFY_POST_DEBIT_NOTE_UPDATE, bulkVerifyPostDebitNoteUpdate);
    yield takeLatest(types.RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS, resendDebitNoteCurrentEmailSms);
    yield takeLatest(types.BULK_MAKE_PAYMENT_DEBIT_NOTE_SAVE, bulkMakePaymentDebitNoteSave);
    yield takeLatest(types.GET_DEBIT_NOTE_RECEIPT_PRINT, printDebitNoteReceipt);
    yield takeLatest(types.RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS, resendDebitNoteReceiptEmailSms);
    yield takeLatest(types.DELETE_DEBIT_NOTE_RECEIPT, deleteDebitNoteReceipt);

    yield takeLatest(types.GET_SINGLE_DEBIT_NOTE_LIST, getSingleDebitNoteList);
    yield takeLatest(types.REJECT_SINGLE_RECEIPT_DEBIT_NOTE, rejectSingleReceiptDebitNote);
    yield takeLatest(types.EDIT_SINGLE_DEBIT_NOTE_DETAILS, editSingleDebitNoteDetails);
    yield takeLatest(types.EDIT_SINGLE_DEBIT_NOTE_SAVE, editSingleDebitNoteSave);
    yield takeLatest(types.ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE, getAdjustmentPaymentDetails);
    yield takeLatest(types.ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE, getAdjustmentPaymentSave);
    yield takeLatest(types.MAKE_PAYMENT_DETAILS_DEBIT_NOTE, getMakePaymentDetails);
    yield takeLatest(types.MAKE_PAYMENT_SAVE_DEBIT_NOTE, getMakePaymentSave);
    yield takeLatest(types.SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS, sendDebitNoteCurrentEmailSms);
    yield takeLatest(types.DELETE_DEBIT_NOTE_CURRENT, deleteDebitNoteCurrent);

    yield takeLatest(types.UPLOAD_DEBIT_NOTE_CURRENT_EXCEL, uploadDebitNoteCurrentExcel);
    yield takeLatest(types.DOWNLOAD_DEBIT_NOTE_CURRENT_EXCEL, downloadDebitNoteCurrentExcel);
    yield takeLatest(types.UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT, uploadDebitNoteCurrentPayment);
    yield takeLatest(types.DOWNLOAD_DEBIT_NOTE_CURRENT_PAYMENT, downloadDebitNoteCurrentPayment);


    yield takeLatest(types.GET_DEBIT_NOTE_PREVIOUS, getDebitNotePrevious);
    yield takeLatest(types.GET_DEBIT_NOTE_DRAFT, getDebitNoteDraft);
    yield takeLatest(types.EDIT_SINGLE_DEBIT_NOTE_DRAFT_SAVE, editSingleDebitNoteDraftSave);
    yield takeLatest(types.GET_DEBIT_NOTE_INVOICE_PRINT, printDebitNoteInvoice);
    // yield takeLatest(types.SINGLE_DEBIT_NOTE_LIST, getSingleDebitNote);
    yield takeLatest(types.GET_DEBIT_NOTE_TARRIF_LIST, getDebiteNoteTarrifList);
    yield takeLatest(types.GET_DEBIT_NOTE_FLAT_WING_WISE_LIST, getDebiteNoteFlatWingWiseList);
    yield takeLatest(types.GET_DEBIT_NOTE_ARREAR_DETAILS, getDebiteNoteArrearDetails);
    yield takeLatest(types.ADD_DEBIT_NOTE, addDebitNote);
    yield takeLatest(types.ADD_MULTIPLE_DEBIT_NOTE, addMultipleDebitNote);
    yield takeLatest(types.DEBIT_NOTE_SYNC, getDebiteNoteSync);
    yield takeLatest(types.DEBIT_NOTE_RECEIPT_SYNC, getDebiteNoteReceiptSync);

}