import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import Loader from "../../../../components/Loader/Loader";
import { printCreditNoteInvoice } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageCreditNoteAction";

function PrintCreditBill() {
  const data = useLocation().state;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { printLoader, invoiceData } = useSelector(state => state.manageCreditNoteReducer)

  useEffect(() => {
    if (data)
      dispatch(printCreditNoteInvoice({
        societyId: loginData?.default_community?.community_id,
        creditNoteId: data?.invid,
      }));
  }, []);

  return (
    <div>
      {printLoader || !invoiceData ? (
        <Loader />
      ) : (
        <>{parse(invoiceData)}</>
      )}
    </div>
  );
}

export default PrintCreditBill;
