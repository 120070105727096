import React, { useEffect } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import SmsHistoryTable from "../../../components/TableComponent/SmsHistoryList";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { downloadSentSmsHistory, getSentSmsHistoryList, getSmsModuleList } from "../../../redux/actions/SetupRecordsActions/SocietyProfileSetupActions/SentSmsHistoryActions";
import ButtonG from "../../../components/Button/ButtonG";
import { SMS_HISTORY_LIST_ENDPOINT } from "../../../Routing/routes";
import moment from "moment";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../assets";
import { downloadExcel } from "../../../utils/helpers/downloadMedia";
import { CircularProgress } from "@mui/material";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "55px" },
  { id: "module", label: "Module", sort: false, width: "80px" },
  { id: "title", label: "Title", sort: true, width: "180px" },
  { id: "date", label: "Date", sort: true, width: "100px" },
  { id: "moduleCount", label: "Module Count", sort: true, width: "130px" },
  {
    id: "smsCreditCount",
    label: "SMS Credit Count",
    sort: true,
    width: "140px",
  },
  { id: "totalSmsCount", label: "Total SMS Count", sort: true, width: "130px" },
  { id: "route", label: "Route", sort: true, width: "100px" },
  { id: "action", label: "Action", sort: false, },
];

const SmsHistoryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { downLoader } = useSelector((state) => state.downloadSmsHistoryReducer)
  const { loading, sentSmsHistoryList, smsModuleList } = useSelector((state) => state.sentSmsHistoryReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handelSelectModule = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("module")) {
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("module")
      navigate({
        pathname: `${SMS_HISTORY_LIST_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["module", selectedItem.value]])}`,
      })
    }
  }

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${SMS_HISTORY_LIST_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${SMS_HISTORY_LIST_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleDownloadExcel = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: 'sent_sms_sheet.xlsx' })
    }
    const data = {
      society_id: loginData?.default_community?.community_id,
      module: searchParams.get('module') || '',
      from_date: searchParams.get('startDate') || '',
      to_date: searchParams.get('endDate') || '',
      onSucessCallback
    }
    dispatch(downloadSentSmsHistory(data))
  }

  const getTableData = () => {
    const data = {
      society_id: loginData?.default_community?.community_id,
      module: searchParams.get('module'),
      from_date: searchParams.get('startDate'),
      to_date: searchParams.get('endDate'),
      page: searchParams.get('page') || 1,
      pageLimit: searchParams.get('pageLimit') || 10,
    }
    dispatch(getSentSmsHistoryList(data));
  }

  useEffect(() => {
    getTableData();
    dispatch(getSmsModuleList({ society_id: loginData?.default_community?.community_id }))
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="SMS History List">
            {downLoader ? (
              <ButtonIco
                className=" w-32 justify-center"
                children={
                  <CircularProgress sx={{ color: "#d32f2f" }} size={17} />
                }
              />
            ) : (
              <ButtonIco
                icon={DownloadExt}
                children="Download Excel"
                onClick={handleDownloadExcel}
              />
            )}
            <ButtonG label='Clear' className='h-8' onClick={handleNavigation(SMS_HISTORY_LIST_ENDPOINT)} />
          </ListHeader>
        </div>
        <div className="flex justify-between items-center my-2">
          <Dropdown2
            options={smsModuleList?.map((item) => ({
              value: item?.module,
              label: item?.module,
            })) || []}
            name="module"
            onSelect={(selectedItem) => {
              handelSelectModule(selectedItem)
            }}
            value={
              searchParams.get("module") && {
                value: searchParams.get("module"),
                label: smsModuleList?.find((item) => item?.module === searchParams.get("module")).module
              }
            }
            placeholder="Select"
            width="180px"
            height="32px"
          />
          <RangeDatePicker
            className={"w-[190px] h-8"}
            defaultStartValue={
              searchParams.get("startDate")
                ? new Date(searchParams.get("startDate"))
                : ""
            }
            defaultEndValue={
              searchParams.get("endDate")
                ? new Date(searchParams.get("endDate"))
                : ""
            }
            onStartDateChange={handleStartDate}
            onEndDateChange={handleEndDate}
          />
        </div>
        <div className="ml-1">
          <SmsHistoryTable
            data={sentSmsHistoryList?.list?.map((item, index) => ({
              sNo: (((sentSmsHistoryList?.current_page_no - 1) * sentSmsHistoryList?.page_limit + 1) + index)?.toString()?.padStart(2, "0"),
              id: item?.id,
              min_id: item?.min_id,
              max_id: item?.max_id,
              module: item?.module_title,
              title: item?.section,
              date: item?.date,
              moduleCount: item?.module_count,
              smsCreditCount: item?.sms_credit,
              totalSmsCount: item?.total_sms,
              route: item?.route,
            }))}
            columns={headCells}
            checkbox={false}
            pagination={sentSmsHistoryList?.list?.length}
            totalCount={sentSmsHistoryList?.total_record}
            totalPages={sentSmsHistoryList?.total_pages}
            start={(sentSmsHistoryList?.current_page_no - 1) * (searchParams.get("pageLimit") || 10) + 1}
            end={(sentSmsHistoryList?.current_page_no - 1) * (searchParams.get("pageLimit") || 10) + sentSmsHistoryList?.list?.length}
            currentPage={parseInt(sentSmsHistoryList?.current_page_no)}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></SmsHistoryTable>
        </div>
        {!sentSmsHistoryList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SmsHistoryList;
