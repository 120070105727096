import React, { useEffect, useRef } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import Search from "../../../../components/SearchBox/Search";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { ADD_FROM_EXISITING_OCCUPANT_ENDPOINT, OCCUPANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import { addExistingOccupants, getExistingOccupants, getTitleList } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Button from "../../../../components/Button/Button";
import Loader from "../../../../components/Loader/Loader";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import TextRadio from "../../../../components/InputFields/TextRadio";
import { registerAsOptions } from "../../../../consts/DropDownOptions";
import { Form, Formik } from "formik";
import { AddExistingOccupantsValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";

const AddFromExistingOccupant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const formRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams()
  const { loading, updateLoader, titleList, existingOccupantsList } = useSelector(state => state.occupantInfoReducer)
  const { wingsData, flatsData } = useSelector(state => state.wingsReducer)
  const { loginData } = useSelector(state => state.loginReducer)
  const { ipAddress } = useSelector(state => state.ipAddressReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSearch = (text) => {
    if (text) {
      navigate({
        pathname: `${ADD_FROM_EXISITING_OCCUPANT_ENDPOINT}`,
        search: `?${createSearchParams([["search", text?.trim()]])}`,
      }, { replace: true })
    }
  }

  const getTableData = () => {
    const data = {
      searchText: searchParams.get("search") || ''
    }
    dispatch(getExistingOccupants(data))
  }

  useEffect(() => {
    getTableData()
  }, [searchParams])

  useEffect(() => {
    dispatch(getWingsAndFlatDetails({ societyId: loginData?.default_community?.community_id }))
    dispatch(getTitleList({ societyId: loginData?.default_community?.community_id }))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          enableReinitialize
          initialValues={{
            userId: existingOccupantsList?.usr_id,
            prefix: existingOccupantsList?.usr_prefix,
            firstName: existingOccupantsList?.usr_fname,
            lastName: existingOccupantsList?.usr_lname,
            middleName: existingOccupantsList?.usr_mname,
            email: existingOccupantsList?.email,
            mobileNo: existingOccupantsList?.mobile_no,
            profilePic: existingOccupantsList?.usr_pic,
            gender: existingOccupantsList?.gender,
            countryID: existingOccupantsList?.country_id,
            mobileCountryCode: existingOccupantsList?.mobile_country_code,

            wingId: '',
            flatId: '',
            titleId: '',
            registerAs: 'main',
            sendEmail: 'Yes'
          }}

          innerRef={(f) => (formRef.current = f)}
          onSubmit={(values, { resetForm }) =>
            dispatch(addExistingOccupants({
              ...values, ipAddress, societyId: loginData?.default_community?.community_id, sessionId: loginData?.session_id,
              onSuccessCallback: () => {
                resetForm()
                navigate(OCCUPANTS_INFO_ENDPOINT)
              }
            }))}
          validationSchema={AddExistingOccupantsValidation}
        >
          {
            ({ values, setFieldValue, errors }) => {
              return (
                <Form>
                  <div className="border-b border-[#CCC] mb-3">
                    <ListHeaderBack
                      onClick={handleNavigation(OCCUPANTS_INFO_ENDPOINT)}
                      title="Invite Existing Member"
                    >
                      <Search placeholder='Search by email or mobile number' onclick={handleSearch} value={searchParams.get("search")} />
                      <span className="border-r border-[#ccc]"></span>
                      <Button label={
                        <span className="w-12 inline-block">
                          {
                            updateLoader ?
                              <CircularProgress sx={{ color: "white" }} size={17} />
                              :
                              'Send'
                          }
                        </span>
                      }
                        type='button'
                        onClick={() => formRef.current?.submitForm()}
                      />
                    </ListHeaderBack>
                  </div>
                  <div>
                    <div>
                      <div>
                        {
                          loading && searchParams.get("search") ?
                            <Loader />
                            :
                            <>
                              {
                                existingOccupantsList && searchParams.get("search") ?
                                  <div className="ms-5 w-[400px]">
                                    <div className="flex text-sm mb-5">
                                      <div className="w-44">Name</div>
                                      <div>{values.prefix + '. ' + values.firstName + ' ' + values.middleName + ' ' + values.lastName}</div>
                                    </div>
                                    <div className="flex  justify-between text-sm mb-1">
                                      <div className="mt-2 w-48">Role</div>
                                      <DropdownFM2
                                        options={titleList?.map(item => ({
                                          label: item.title,
                                          value: item?.title_id
                                        })) || []}
                                        width={"224px"}
                                        className="text-xs"
                                        placeholder={"Select Role"}
                                        onSelect={(selectedItem) => {
                                          setFieldValue(`titleId`, selectedItem.value)
                                        }}
                                        height='38px'
                                        name='titleId'
                                        {...(values.titleId === '' && { value: null })}
                                      />
                                    </div>
                                    <div className="flex  justify-between text-sm mb-1">
                                      <div className="mt-2 w-48">Wing No.</div>
                                      <DropdownFM2
                                        options={wingsData?.map(item => ({
                                          label: item.number,
                                          value: item?.wing_id
                                        })) || []}
                                        width={"224px"}
                                        className="text-xs"
                                        placeholder={"Select Wing"}
                                        onSelect={(selectedItem) => {
                                          setFieldValue(`wingId`, selectedItem.value)
                                          setFieldValue(`flatId`, '')
                                          dispatch(getFlatDetails({ societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value }))
                                        }}
                                        height='38px'
                                        name='wingId'
                                        {...(values.wingId === '' && { value: null })}
                                      />
                                    </div>
                                    <div className="flex  justify-between text-sm mb-1">
                                      <div className="mt-2 w-48">Flat No.</div>
                                      <DropdownFM2
                                        options={values.wingId && flatsData?.manage_flat_details?.map(item => ({
                                          label: item.flat_no,
                                          value: item?.flat_id,
                                          color: item.flat_allotted ? '#00D94B' : '#000000'
                                        })) || []}
                                        width={"224px"}
                                        className="text-xs"
                                        placeholder={"Select Flat"}
                                        onSelect={(selectedItem) => {
                                          setFieldValue(`flatId`, selectedItem.value)
                                        }}
                                        height='38px'
                                        name='flatId'
                                        {...(values.flatId === '' && { value: null })}
                                      />
                                    </div>
                                    <TextRadio
                                      label="Register As (Member)"
                                      options={registerAsOptions?.map((item) => ({
                                        name: `registerAs`,
                                        value: item.value,
                                        label: item.label
                                      }))}
                                      defaultSelected="main"
                                      onChange={(selectemItem) => { setFieldValue(`registerAs`, selectemItem) }}
                                      className={'w-56 mb-2'}
                                    />
                                    <TextRadio
                                      label="Send Email"
                                      options={[{
                                        name: `sendEmail`,
                                        value: 'Yes',
                                        label:  'Yes',
                                      },{
                                        name: `sendEmail`,
                                        value: 'No',
                                        label: 'No'
                                      }
                                    ]}
                                      defaultSelected="Yes"
                                      onChange={(selectemItem) => { setFieldValue(`sendEmail`, selectemItem) }}
                                      className={'w-56'}
                                    />
                                  </div>
                                  :
                                  <div className="text-center py-4 text-[#999999] flex justify-center">
                                    No Records
                                  </div>
                              }

                            </>
                        }
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </div>
    </Layout>
  );
};

export default AddFromExistingOccupant;
