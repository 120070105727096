import React, { useEffect, useState } from "react";
import {
  NOTICE_AND_CIRCULARS_ENDPOINT,
  DRAFT_NOTICE_AND_CIRCULARS_ENDPOINT,
} from "../../../Routing/routes";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  TextInput,
  TextInputFM,
} from "../../../components/InputFields/TextInput";
import { DatePickerComponentFM } from "../../../components/DatePicker/DatePicker";
import { UploadButtonFM } from "../../../components/Button/UploadButton";
import draftToHtml from "draftjs-to-html";

import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DropdownFM2, Dropdown2 } from "../../../components/Dropdown/dropdown2";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { societyStaffTypeOptions } from "../../../consts/DropDownOptions";
import { Description } from "@mui/icons-material";

import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { notify } from "../../../utils/notification";
import MultiSelect, {
  MultiSelectFM,
  MultiSelectFM2,
} from "../../../components/MultiSelect/MultiSelect";
import { addNoticeCircularValidation } from "../../../validationSchema/socialAndCommunicationSchema/noticeCircularSchema";
import {
  getNoticeCircularTypes,
  getNoticeCircularManagingCommittee,
  addNoticeCircular,
  getNoticeCircularDetails,
  updateNoticeCircular,
} from "../../../redux/actions/SocialAndCommunicationActions/NoticeAndCircularAction";
import { getWingsList } from "../../../redux/actions/DeskActions/LogaComplaintActions";
import { getRoleWiseList } from "../../../redux/actions/ClubActions/AddFacilityAction";
import { getOccupantsDetailsList } from "../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { getTenantsDetailsList } from "../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import { htmlToDOM } from "html-react-parser";

function CreateNoticeandCircular({ edit = false, draft = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const goback = () => {
    navigate(-1);
  };

  const { id } = useParams();
  const [savedAttachment, setSavedAttachment] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [allMembers, setAllMembers] = useState([]);
  const [allTenants, setAllTenants] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [managingCommittees, setManagingCommittees] = useState([]);
  const [checkBox, setCheckBox] = useState({
    allMembersCheckbox: false,
    allTenantsCheckbox: false,
    allEmployeesCheckbox: false,
    managingCommitteeCheckbox: false,
  });
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const {
    loading,
    updateLoader,
    noticeCircularTypes,
    noticeCircularManagingCommittee,
    noticeCircularDetail,
  } = useSelector((state) => state.noticeAndCircularReducer);

  const { wingsList } = useSelector((state) => state.logaComplaintReducer);
  const { employeeList } = useSelector((state) => state.addFacilityReducer);
  const { occupantsList } = useSelector((state) => state.occupantInfoReducer);
  const { tenantsList } = useSelector((state) => state.tenantInfoReducer);

  const handleSelectAllClick = (event, type) => {
    if (type == "member") {
      const newSelected = event.target.checked
        ? wingsList?.map((item) => item.id)
        : [];
      setAllMembers(newSelected);
    }
    if (type == "tenant") {
      const newSelected = event.target.checked
        ? wingsList?.map((item) => item.id)
        : [];
      setAllTenants(newSelected);
    }
    if (type == "committee") {
      const newSelected = event.target.checked
        ? noticeCircularManagingCommittee?.neighbours_list?.map(
          (item) => item.committee_role_id
        )
        : [];
      setManagingCommittees(newSelected);
    }
    if (type == "employee") {
      const newSelected = event.target.checked
        ? employeeList?.map((item) => item.id)
        : [];
      setAllEmployees(newSelected);
    }
  };

  const handleClick = (event, id, type) => {
    if (type == "member") {
      const newSelected = allMembers.some((item) => item == id)
        ? allMembers.filter((item) => id !== item)
        : [...allMembers, id];
      setAllMembers(newSelected);
    }
    if (type == "tenant") {
      const newSelected = allTenants.some((item) => item == id)
        ? allTenants.filter((item) => id !== item)
        : [...allTenants, id];
      setAllTenants(newSelected);
    }
    if (type == "committee") {
      const newSelected = managingCommittees.some((item) => item == id)
        ? managingCommittees.filter((item) => id !== item)
        : [...managingCommittees, id];
      setManagingCommittees(newSelected);
    }
    if (type == "employee") {
      const newSelected = allEmployees.some((item) => item == id)
        ? allEmployees.filter((item) => id !== item)
        : [...allEmployees, id];
      setAllEmployees(newSelected);
    }
  };

  useEffect(() => {
    const data = { societyId: loginData?.default_community?.community_id };
    dispatch(getNoticeCircularTypes(data));
    dispatch(getNoticeCircularManagingCommittee(data));

    dispatch(getWingsList(data));
    dispatch(
      getRoleWiseList({
        societyId: loginData?.default_community?.community_id,
        roleType: "Employees",
      })
    );
    dispatch(
      getOccupantsDetailsList({
        societyId: loginData?.default_community?.community_id,
        pageLimit: 10,
        page: 1,
      })
    );
    dispatch(
      getTenantsDetailsList({
        societyId: loginData?.default_community?.community_id,
        pageLimit: 10,
        page: 1,
      })
    );
    if (id) dispatch(getNoticeCircularDetails({ id }));
  }, []);

  const [loadMoreSpecificFlats, setLoadMoreSpecificFlats] = useState(false);
  const [occupantsListData, setOccupantsListData] = useState([]);

  useEffect(() => {
    if (
      occupantsList?.occupants_list.length >= 10 &&
      occupantsList?.page_limit >= occupantsList?.total_record
    ) {
      setLoadMoreSpecificFlats(true);
      setOccupantsListData(occupantsList?.occupants_list);
    } else if (occupantsList?.total_record < 10) {
      setLoadMoreSpecificFlats(true);
      setOccupantsListData(occupantsList?.occupants_list);
    }
    if (!loadMoreSpecificFlats && occupantsList?.occupants_list?.length <= 10) {
      dispatch(
        getOccupantsDetailsList({
          societyId: loginData?.default_community?.community_id,
          pageLimit: occupantsList?.page_limit * occupantsList?.total_pages,
          page: 1,
        })
      );
    }
    // console.log(loadMoreSpecificFlats);
  }, [occupantsList]);

  const [loadMoreSpecificTenants, setLoadMoreSpecificTenants] = useState(false);
  const [tenantsListData, setTenantsListData] = useState([]);

  useEffect(() => {
    // setLoadMoreSpecificTenants(false);
    if (
      tenantsList?.tenants_list?.length >= 10 &&
      tenantsList?.page_limit >= tenantsList?.total_record
    ) {
      setLoadMoreSpecificTenants(true);
      setTenantsListData(tenantsList?.tenants_list);
    } else if (tenantsList?.total_record < 10) {
      setLoadMoreSpecificTenants(true);
      setTenantsListData(tenantsList?.tenants_list);
    }
    if (!loadMoreSpecificTenants && tenantsList?.tenants_list?.length <= 10) {
      dispatch(
        getTenantsDetailsList({
          societyId: loginData?.default_community?.community_id,
          pageLimit: tenantsList?.page_limit * tenantsList?.total_pages,
          page: 1,
        })
      );
    }
  }, [tenantsList]);

  useEffect(() => {
    if (edit) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(noticeCircularDetail?.notice_detail?.description || "")
          )
        )
      );
      setSavedAttachment(
        noticeCircularDetail?.attachments?.map((item) => item.attachment)
      );
      setAllMembers(noticeCircularDetail?.notice_detail?.wing_ids);
      setAllTenants(noticeCircularDetail?.notice_detail?.tenant_wing_ids);
      setManagingCommittees(
        noticeCircularDetail?.notice_detail?.managing_committee
      );
      setAllEmployees(noticeCircularDetail?.notice_detail?.employee);

      setCheckBox({
        allMembersCheckbox:
          noticeCircularDetail?.notice_detail?.wing_ids?.length > 0
            ? true
            : false,
        allTenantsCheckbox:
          noticeCircularDetail?.notice_detail?.tenant_wing_ids?.length > 0
            ? true
            : false,
        managingCommitteeCheckbox:
          noticeCircularDetail?.notice_detail?.managing_committee?.length > 0
            ? true
            : false,
        allEmployeesCheckbox:
          noticeCircularDetail?.notice_detail?.employee?.length > 0
            ? true
            : false,
      });
    }
  }, [noticeCircularDetail]);

  const handleSaveClick = async (values, { resetForm, initialValues }) => {
    const data = { ...values };
    // if (data.selected_flat_members.length) {
    //   data.selected_flat_members = data.selected_flat_members.filter(
    //     (item) => item != true
    //   );
    // }
    // if (data.selected_flat_tenants.length) {
    //   data.selected_flat_tenants = data.selected_flat_tenants.filter(
    //     (item) => item != true
    //   );
    // }
    // for (let i in data.selected_flat_tenants) {
    //   data.selected_flat_tenants[i] = data.selected_flat_tenants[i].value;
    // }
    for (let i in data.attachments) {
      if (data.attachments[i].file == "") {
        data.attachments.splice(i);
      }
    }
    data.selected_wings_members = allMembers;
    data.selected_wings_tenants = allTenants;
    data.employees = allEmployees;
    data.managing_committee = managingCommittees;
    data.resident_live_members = data.resident_live_members == "Yes" ? 1 : 0;
    data.societyId = loginData?.default_community?.community_id;

    if (!checkBox.allMembersCheckbox) {
      data.selected_wings_members = [];
    }
    if (!checkBox.allTenantsCheckbox) {
      data.selected_wings_tenants = [];
    }
    if (!checkBox.allEmployeesCheckbox) {
      data.employees = [];
    }
    if (!checkBox.managingCommitteeCheckbox) {
      data.managing_committee = [];
    }
    if (!data.specificFlats) {
      data.selected_flat_members = [];
    }
    if (!data.specificTenants) {
      data.selected_flat_tenants = [];
    }
    if (data?.resident_live_members) {
      data.selected_wings_members = [];
      data.selected_wings_tenants = [];
      data.managing_committee = [];
      data.selected_flat_members = [];
      data.selected_flat_tenants = [];
    }
    if (data.specificFlats) {
      data.selected_wings_members = [];
      data.managing_committee = [];
    }
    if (data.specificTenants) {
      data.selected_wings_tenants = [];
    }
    // debugger;
    // return false;
    if (edit) {
      data.id = id;
      data.deleteAttachments = noticeCircularDetail?.attachments?.filter(
        (item, index) => !savedAttachment.includes(item.attachment)
      );
      data.savedAttachments = noticeCircularDetail?.attachments?.filter(
        (item, index) => savedAttachment.includes(item.attachment)
      ).map(item =>  item.attachment );

      // data.savedAttachments = savedAttachment;

      // return false;
    }

    delete data.specificFlats;
    delete data.specificTenants;
    delete data.descriptionDetails;

    if (edit) {
      dispatch(
        updateNoticeCircular({
          ...data,
          ipAddress,
          sessionId: loginData?.session_id,
          onSuccessCallback: () =>
            navigate(
              draft
                ? DRAFT_NOTICE_AND_CIRCULARS_ENDPOINT
                : NOTICE_AND_CIRCULARS_ENDPOINT
            ),
        })
      );
    } else {
      dispatch(
        addNoticeCircular({
          ...data,
          ipAddress,
          sessionId: loginData?.session_id,
          onSuccessCallback: () => navigate(NOTICE_AND_CIRCULARS_ENDPOINT),
        })
      );
    }
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            types:
              (edit ? noticeCircularDetail?.notice_detail?.type : "") ||
              noticeCircularTypes[0]?.notice_type,

            notice_date:
              (edit
                ? noticeCircularDetail?.notice_detail?.notice_date
                : moment(new Date()).format("YYYY-MM-DD")) ||
              moment(new Date()).format("YYYY-MM-DD"),
            ref_no:
              (edit ? noticeCircularDetail?.notice_detail?.ref_no : "") || "",
            title:
              (edit ? noticeCircularDetail?.notice_detail?.title : "") || "",
            description:
              (edit ? noticeCircularDetail?.notice_detail?.description : "") ||
              "",
            descriptionDetails: "",
            expiry_date:
              (edit ? noticeCircularDetail?.notice_detail?.expiry_date : "") ||
              "",
            specificFlats:
              (edit
                ? noticeCircularDetail?.notice_detail?.members.length > 0
                  ? true
                  : false
                : false) || false,
            selected_flat_members:
              (edit ? noticeCircularDetail?.notice_detail?.members : []) || [],
            specificTenants:
              (edit
                ? noticeCircularDetail?.notice_detail?.tenants.length > 0
                  ? true
                  : false
                : false) || false,
            selected_flat_tenants:
              (edit ? noticeCircularDetail?.notice_detail?.tenants : []) || [],

            attachments: [{ file: "" }],
            selected_wings_members: [],
            selected_wings_tenants: [],
            employees: [],
            managing_committee: [],
            resident_live_members:
              (edit
                ? noticeCircularDetail?.notice_detail?.is_residents_only
                : "No") || "No",
            acknowledgment:
              (edit
                ? noticeCircularDetail?.notice_detail?.acknowledgment
                : "No") || "No",
            by_order:
              (edit ? noticeCircularDetail?.notice_detail?.by_order : "") || "",
            is_publish: 0,
            visiblity: "Members",
          }}
          enableReinitialize
          validationSchema={addNoticeCircularValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    title={`${edit
                        ? "Edit Notice & Circular"
                        : "Create Notice & Circular"
                      }`}
                    onClick={goback}
                  >
                    <ButtonG
                      type="button"
                      label="Cancel"
                      className="h-8"
                      onClick={goback}
                    />
                    {edit && !draft ? (
                      <Button
                        label="Save And Resend"
                        type="submit"
                        onClick={() => {
                          formik.setFieldValue("is_publish", 1);
                          if (edit) formik.setFieldValue("resend", 1);
                        }}
                        className="h-8 px-4"
                      />
                    ) : (
                      ""
                    )}
                    <Button
                      label="Save As Draft"
                      type="submit"
                      onClick={() => formik.setFieldValue("is_publish", 0)}
                      className="h-8 px-4"
                    />
                    <Button
                      label="Save"
                      type="submit"
                      onClick={() => formik.setFieldValue("is_publish", 1)}
                      className="h-8 px-4"
                    />
                  </ListHeaderBack>
                </div>

                {updateLoader || loading ? (
                  <Loader />
                ) : (
                  <div className="py-2 gap-2 m-1">
                    <table className="border-separate w-full">
                      <tbody>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Type
                          </td>

                          <td>
                            <DropdownFM2
                              options={noticeCircularTypes?.map((item) => ({
                                label: item.notice_type,
                                value: item.id,
                              }))}
                              placeholder="Select Type"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "types",
                                  selectedItem.label
                                );
                              }}
                              value={
                                formik.values.types && {
                                  label: noticeCircularTypes?.find(
                                    (item) =>
                                      item.notice_type === formik.values?.types
                                  )?.notice_type,
                                  value: formik.values.types,
                                }
                              }
                              name={"types"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Date Of Notice
                          </td>
                          <td>
                            <DatePickerComponentFM
                              className="w-[208px] h-9 justify-between"
                              onDateChange={(selectedDate) => {
                                formik.setFieldValue(
                                  "notice_date",
                                  moment(selectedDate).format("YYYY-MM-DD")
                                );

                                if (
                                  new Date(
                                    formik.values.expiry_date
                                  ).getTime() < new Date(selectedDate).getTime()
                                ) {
                                  formik.setFieldValue("expiry_date", null);
                                }
                              }}
                              name={`notice_date`}
                              defaultValue={
                                formik.values.notice_date
                                  ? new Date(formik.values.notice_date)
                                  : null
                              }
                            // maxDate={new Date()}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Reference No
                          </td>
                          <td>
                            <TextInputFM
                              placeholder="Enter Number"
                              className="w-[208px]"
                              name={`ref_no`}
                              type="number"
                            // maxLength="15"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Subject
                          </td>
                          <td>
                            <TextInputFM
                              placeholder="Enter Subject"
                              className="w-[208px]"
                              name={`title`}
                              type="text"
                            // maxLength="15"
                            />
                          </td>
                        </tr>

                        {/* <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">
                          Category
                        </td>
                        <td>
                          <DropdownFM2
                            options={complaintCategoryList?.map((item) => ({
                              label: item.title,
                              value: item.id,
                            }))}
                            placeholder="Select Category"
                            className=""
                            width="208px"
                            height="36px"
                            onSelect={(selectedItem) => {
                              formik.setFieldValue(
                                "category",
                                selectedItem.value
                              );
                              if (
                                formik.values.category !== selectedItem.value
                              ) {
                                formik.setFieldValue("subCategory", "");
                                dispatch(
                                  getComplaintCategoryDetailsList({
                                    categoryId: selectedItem.value,
                                  })
                                );
                              }
                            }}
                            value={{
                              label: complaintCategoryList?.find(
                                (item) => item.id === formik.values.category
                              )?.title,
                              value: formik.values.category,
                            }}
                            name={"category"}
                          />
                        </td>
                      </tr> */}
                        {/* <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">
                          Sub Category
                        </td>
                        <td>
                          <DropdownFM2
                            options={complaintCategoryDetailsList?.[0]?.sub_category?.map(
                              (item) => ({
                                label: item.sub_title,
                                value: item.id,
                              })
                            )}
                            placeholder="Select SubCategory"
                            className=""
                            width="208px"
                            height="36px"
                            onSelect={(selectedItem) => {
                              formik.setFieldValue(
                                "subCategory",
                                selectedItem.value
                              );
                            }}
                            value={{
                              label:
                                complaintCategoryDetailsList?.[0]?.sub_category?.find(
                                  (item) =>
                                    item.id === formik.values.subCategory
                                )?.sub_title,
                              value: formik.values.subCategory,
                            }}
                            name={"subCategory"}
                          />
                        </td>
                      </tr> */}
                        {/* <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">
                          Assign Society
                        </td>
                        <td>
                          <DropdownFM2
                            options={societyDetailsList?.map((item) => ({
                              label: item?.title,
                              value: item.id,
                            }))}
                            placeholder="Select Society"
                            className=""
                            width="208px"
                            height="36px"
                            onSelect={(selectedItem) => {
                              formik.setFieldValue(
                                "societyName",
                                selectedItem.value
                              );
                            }}
                            name="societyName"
                          />
                        </td>
                      </tr> */}
                        {/* <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">
                          Is Urgent ?
                        </td>
                        <td>
                          <div className="w-52 mb-4">
                            <Checkbox
                              text="Yes"
                              className="text-sm"
                              initialchecked={checked}
                              onChange={(e) => {
                                handleChecked(e, formik.setFieldValue);
                              }}
                            />
                          </div>
                        </td>
                      </tr> */}
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Description
                          </td>
                          <td>
                            <div className=" noticeCircular border border-[#CCC] rounded-md  relative z-0">
                              <Editor
                                editorState={editorState}
                                toolbar={{
                                  options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "list",
                                    "textAlign",
                                    "history",
                                    "colorPicker",
                                    "image",
                                    "link",
                                  ],
                                  inline: {
                                    inDropdown: false,
                                    options: ["bold", "italic", "underline"],
                                  },
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                  link: {
                                    inDropdown: true,
                                    popupClassName: "!h-auto",
                                  },
                                  history: {
                                    inDropdown: false,
                                    options: ["redo", "undo"],
                                  },
                                  colorPicker: {
                                    className: undefined,
                                    component: undefined,
                                    popupClassName: undefined,
                                  },
                                  image: {},
                                }}
                                onEditorStateChange={(neweditorState) => {
                                  onEditorStateChange(neweditorState);
                                  // let data = convertToRaw(
                                  //   neweditorState.getCurrentContent()
                                  // );
                                  // data = data?.blocks?.reduce(
                                  //   (prev, newVal) => {
                                  //     return prev + newVal.text;
                                  //   },
                                  //   ""
                                  // );

                                  formik.setFieldValue(
                                    "descriptionDetails",
                                    convertToRaw(
                                      neweditorState.getCurrentContent()
                                    )?.blocks?.length > 0
                                      ? convertToRaw(
                                        neweditorState.getCurrentContent()
                                      )?.blocks?.reduce((prev, newVal) => {
                                        return prev + newVal.text;
                                      }, "")
                                      : ""
                                  );
                                  formik.setFieldValue(
                                    "description",
                                    draftToHtml(
                                      convertToRaw(
                                        neweditorState.getCurrentContent()
                                      )
                                    )
                                  );
                                }}
                                // contentState={{}}
                                name="descriptionDetails"
                              />
                            </div>
                            <div
                              className={`text-red-500 text-xs italic h-4 text-wrap break-words mb-4`}
                            >
                              <ErrorMessage name={`descriptionDetails`} />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Expiry Date
                          </td>
                          <td>
                            <DatePickerComponentFM
                              className="w-[208px] h-9 justify-between"
                              onDateChange={(selectedDate) => {
                                formik.setFieldValue(
                                  "expiry_date",
                                  moment(selectedDate).format("YYYY-MM-DD")
                                );
                              }}
                              minDate={
                                formik.values.notice_date
                                  ? new Date(formik.values.notice_date)
                                  : new Date()
                                // ? new Date(
                                //     new Date(
                                //       formik.values.notice_date
                                //     ).setDate(
                                //       new Date(
                                //         formik.values.notice_date
                                //       ).getDate() + 1
                                //     )
                                //   )
                                // : new Date()
                              }
                              name={`expiry_date`}
                              defaultValue={
                                formik.values.expiry_date
                                  ? new Date(formik.values.expiry_date)
                                  : null
                              }
                            // maxDate={new Date()}
                            />
                          </td>
                        </tr>
                        {/* <tr className="text-[#222] text-sm">
                        <td className="text-sm w-56 pt-2.5 align-top">
                          View Attachment:
                        </td>
                        <td>
                          {complaintLoggedTicket?.attachments?.length > 0 ? (
                            <span>
                              <Link
                                target="_blank"
                                className="ms-1 underline text-xs text-blue-500"
                                to={
                                  complaintLoggedTicket.attachments[0]
                                    .attachment_Url
                                }
                              >
                                View Uploaded Doc
                              </Link>
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr> */}
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Attachments
                          </td>
                          <td>
                            <table>
                              <tbody>
                                {savedAttachment?.length &&
                                  savedAttachment?.length > 0 ? (
                                  noticeCircularDetail?.attachments?.map(
                                    (attach, index) => {
                                      {
                                        return savedAttachment.includes(
                                          attach?.attachment
                                        ) ? (
                                          <>
                                            <tr>
                                              <td className=" pb-[19px]">
                                                <span key={index}>
                                                  {index + 1 + ". "}
                                                  <Link
                                                    target="_blank"
                                                    className="ms-1 underline text-xs text-blue-500"
                                                    to={attach?.attachment_url}
                                                  >
                                                    View Uploaded Doc
                                                  </Link>
                                                </span>
                                              </td>
                                              <td className=" align-top">
                                                <Button
                                                  label="Delete"
                                                  type="button"
                                                  className="h-8 px-4 align-top"
                                                  onClick={() => {
                                                    setSavedAttachment((e) => {
                                                      return e.filter(
                                                        (item) =>
                                                          item !==
                                                          attach?.attachment
                                                      );
                                                    });
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          </>
                                        ) : (
                                          ""
                                        );
                                      }
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={2}></td>
                                  </tr>
                                )}
                                <FieldArray
                                  name="attachments"
                                  render={(arrayHelpers) => (
                                    <>
                                      {formik.values.attachments.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td>
                                              <div className="w-52  mb-1 d-flex">
                                                <UploadButtonFM
                                                  showFileName={true}
                                                  name={`attachments.${index}.file`}
                                                  accept={
                                                    "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                                  }
                                                  onSelect={(e) =>
                                                    formik.setFieldValue(
                                                      `attachments.${index}.file`,
                                                      e
                                                    )
                                                  }
                                                  fileName={
                                                    formik.values?.attachments[
                                                      index
                                                    ]?.file?.name
                                                  }
                                                />
                                              </div>
                                            </td>
                                            <td className=" align-top">
                                              {index !==
                                                formik.values.attachments
                                                  ?.length -
                                                1 && (
                                                  <Button
                                                    label="Delete"
                                                    type="button"
                                                    className="h-8 px-4"
                                                    onClick={() => {
                                                      arrayHelpers.remove(index);
                                                    }}
                                                  />
                                                )}

                                              {index ==
                                                formik.values.attachments
                                                  ?.length -
                                                1 &&
                                                !formik.values.attachments.some(
                                                  (e) => e.file == ""
                                                ) &&
                                                formik.values?.attachments
                                                  ?.length +
                                                savedAttachment?.length <=
                                                4 && (
                                                  <Button
                                                    label="Add"
                                                    type="button"
                                                    className="h-8 px-4"
                                                    onClick={() => {
                                                      arrayHelpers.push({
                                                        file: "",
                                                      });
                                                    }}
                                                  />
                                                )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  )}
                                ></FieldArray>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="2"
                            className="text-sm w-56 pb-2.5 align-top font-semibold"
                          >
                            Choose Members To Send Email Notification
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="2"
                            className="text-sm w-56 pt-2.5 align-top border-b pb-3"
                          >
                            <Checkbox
                              text={"Resident Lives Members"}
                              initialchecked={
                                formik.values.resident_live_members == "Yes"
                              }
                              className="font-semibold"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `resident_live_members`,
                                  e.target.checked ? "Yes" : "No"
                                )
                              }
                            />
                          </td>
                        </tr>
                        <tr
                          className={`${formik.values.resident_live_members == "Yes" ||
                              formik.values?.specificFlats
                              ? "pointer-events-none select-none opacity-30"
                              : " "
                            } `}
                        >
                          <td
                            colSpan="2"
                            className="text-sm w-56 pt-2.5 align-top border-b pb-3"
                          >
                            <Checkbox
                              text={"All Members"}
                              initialchecked={checkBox.allMembersCheckbox}
                              className="font-semibold"
                              onChange={() => {
                                setCheckBox((ele) => {
                                  return {
                                    ...checkBox,
                                    allMembersCheckbox: ele.allMembersCheckbox
                                      ? false
                                      : true,
                                  };
                                });
                              }}
                            />
                            <div className="w-[75%] grid grid-cols-4 gap-y-1.5">
                              {checkBox.allMembersCheckbox && (
                                <>
                                  <Checkbox
                                    text={"Select All"}
                                    className={`!items-start`}
                                    initialchecked={
                                      wingsList.length > 0 &&
                                      wingsList.length == allMembers.length
                                    }
                                    onChange={(e) => {
                                      handleSelectAllClick(e, "member");
                                    }}
                                  />
                                  {wingsList?.map((item, index) => {
                                    return (
                                      <Checkbox
                                        key={item.id}
                                        text={`${item?.number} (${item?.user_count})`}
                                        className={`!items-start`}
                                        initialchecked={allMembers.some(
                                          (elem) => elem == item.id
                                        )}
                                        onChange={(e) =>
                                          handleClick(e, item?.id, "member")
                                        }
                                      />
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr
                          className={`${formik.values.resident_live_members == "Yes" ||
                              formik.values?.specificTenants
                              ? "pointer-events-none select-none opacity-30"
                              : " "
                            }`}
                        >
                          <td
                            colSpan="2"
                            className="text-sm w-56 pt-2.5 align-top border-b pb-3"
                          >
                            <Checkbox
                              text={"All Tenants"}
                              initialchecked={checkBox.allTenantsCheckbox}
                              className="font-semibold"
                              onChange={() => {
                                setCheckBox((ele) => {
                                  return {
                                    ...checkBox,
                                    allTenantsCheckbox: ele.allTenantsCheckbox
                                      ? false
                                      : true,
                                  };
                                });
                              }}
                            />
                            <div className="w-[75%] grid grid-cols-4 gap-y-1.5">
                              {checkBox.allTenantsCheckbox && (
                                <>
                                  <Checkbox
                                    text={"Select All"}
                                    className={`!items-start`}
                                    initialchecked={
                                      wingsList.length > 0 &&
                                      wingsList.length == allTenants.length
                                    }
                                    onChange={(e) => {
                                      handleSelectAllClick(e, "tenant");
                                    }}
                                  />
                                  {wingsList?.map((item, index) => {
                                    return (
                                      <Checkbox
                                        key={item.id}
                                        text={`${item?.number}`}
                                        className={`!items-start`}
                                        initialchecked={allTenants.some(
                                          (elem) => elem == item.id
                                        )}
                                        onChange={(e) =>
                                          handleClick(e, item?.id, "tenant")
                                        }
                                      />
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>

                        <tr
                          className={`${formik.values.resident_live_members == "Yes" ||
                              formik.values?.specificFlats
                              ? "pointer-events-none select-none opacity-30"
                              : " "
                            }`}
                        >
                          <td
                            colSpan="2"
                            className="text-sm w-56 pt-2.5 align-top border-b pb-3"
                          >
                            <Checkbox
                              text={"All Managing Committee"}
                              initialchecked={
                                checkBox.managingCommitteeCheckbox
                              }
                              className="font-semibold"
                              onChange={() => {
                                setCheckBox((ele) => {
                                  return {
                                    ...checkBox,
                                    managingCommitteeCheckbox:
                                      ele.managingCommitteeCheckbox
                                        ? false
                                        : true,
                                  };
                                });
                              }}
                            />
                            <div className="w-[75%] grid grid-cols-4 gap-y-1.5">
                              {checkBox.managingCommitteeCheckbox && (
                                <>
                                  <Checkbox
                                    text={"Select All"}
                                    className={`!items-start`}
                                    initialchecked={
                                      noticeCircularManagingCommittee
                                        ?.neighbours_list?.length > 0 &&
                                      noticeCircularManagingCommittee
                                        ?.neighbours_list?.length ==
                                      managingCommittees.length
                                    }
                                    onChange={(e) => {
                                      handleSelectAllClick(e, "committee");
                                    }}
                                  />
                                  {noticeCircularManagingCommittee?.neighbours_list?.map(
                                    (item, index) => {
                                      return (
                                        <Checkbox
                                          key={index}
                                          text={`${item?.full_name}`}
                                          className={`!items-start`}
                                          initialchecked={managingCommittees.some(
                                            (elem) =>
                                              elem == item.committee_role_id
                                          )}
                                          onChange={(e) =>
                                            handleClick(
                                              e,
                                              item?.committee_role_id,
                                              "committee"
                                            )
                                          }
                                        />
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="2"
                            className="text-sm w-56 pt-2.5 align-top border-b pb-3"
                          >
                            <Checkbox
                              text={"All Employees"}
                              initialchecked={checkBox.allEmployeesCheckbox}
                              className="font-semibold"
                              onChange={() => {
                                setCheckBox((ele) => {
                                  return {
                                    ...checkBox,
                                    allEmployeesCheckbox:
                                      ele.allEmployeesCheckbox ? false : true,
                                  };
                                });
                              }}
                            />
                            <div className="w-[75%] grid grid-cols-4 gap-y-1.5">
                              {checkBox.allEmployeesCheckbox && (
                                <>
                                  <Checkbox
                                    text={"Select All"}
                                    className={`!items-start`}
                                    initialchecked={
                                      employeeList.length > 0 &&
                                      employeeList.length == allEmployees.length
                                    }
                                    onChange={(e) => {
                                      handleSelectAllClick(e, "employee");
                                    }}
                                  />
                                  {employeeList?.map((item, index) => {
                                    return (
                                      <Checkbox
                                        key={item.id}
                                        text={`${item?.full_name} - ${item?.assigned_title}`}
                                        className={`!items-start`}
                                        initialchecked={allEmployees.some(
                                          (elem) => elem == item.id
                                        )}
                                        onChange={(e) =>
                                          handleClick(e, item?.id, "employee")
                                        }
                                      />
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr
                          className={`${formik.values.resident_live_members == "Yes"
                              ? "pointer-events-none select-none opacity-30"
                              : " "
                            }`}
                        >
                          <td className="text-sm w-56 pt-3 align-top">
                            <Checkbox
                              text={"Select Specific Flats"}
                              initialchecked={
                                formik.values?.specificFlats === true
                              }
                              className="font-semibold"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `specificFlats`,
                                  e.target.checked ? true : false
                                );
                              }}
                            />
                          </td>

                          <td className=" pt-3">
                            {formik.values?.specificFlats && (
                              <MultiSelectFM2
                                options={
                                  loadMoreSpecificFlats
                                    ? [
                                      ...occupantsListData?.map((item) => ({
                                        label:
                                          item.wing_no +
                                          " " +
                                          item.flat_no +
                                          " " +
                                          item.subscriber_name,
                                        value: item.flat_id,
                                      })),
                                    ]
                                    : []
                                }
                                width="208px"
                                height={"fit-content"}
                                placeholder={`Select Specific Flats`}
                                className="text-xs"
                                isMulti={true}
                                loading={!loadMoreSpecificFlats}
                                value={
                                  loadMoreSpecificFlats
                                    ? occupantsListData
                                      ?.filter((item) =>
                                        formik.values.selected_flat_members.includes(
                                          item.flat_id
                                        )
                                      )
                                      .map((item) => ({
                                        label:
                                          item.wing_no +
                                          " " +
                                          item.flat_no +
                                          " " +
                                          item.subscriber_name,
                                        value: item.flat_id,
                                      }))
                                    : []
                                }
                                onSelect={(selectedItem) => {
                                  formik.setFieldValue(
                                    "selected_flat_members",
                                    selectedItem.map((item) => item?.value)
                                  );
                                }}
                                name={`selected_flat_members`}
                              />
                            )}
                          </td>
                        </tr>

                        <tr
                          className={`${formik.values.resident_live_members == "Yes"
                              ? "pointer-events-none select-none opacity-30"
                              : " "
                            }`}
                        >
                          <td className="text-sm w-56 pt-2.5 pb-3 align-top border-b">
                            <Checkbox
                              text={"Select Specific Tenants"}
                              initialchecked={
                                formik.values?.specificTenants === true
                              }
                              className="font-semibold"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `specificTenants`,
                                  e.target.checked ? true : false
                                );
                              }}
                            />
                          </td>
                          <td className="border-b">
                            {formik.values?.specificTenants && (
                              <>
                                <MultiSelectFM2
                                  options={
                                    loadMoreSpecificTenants
                                      ? [
                                        ...tenantsListData?.map((item) => ({
                                          label:
                                            item.wing_no +
                                            " " +
                                            item.flat_no +
                                            " " +
                                            item.tenant_name,
                                          value: item.tenant_id,
                                        })),
                                      ]
                                      : []
                                  }
                                  width="208px"
                                  height={"fit-content"}
                                  placeholder={`Select Specific Tenants`}
                                  className="text-xs"
                                  isMulti={true}
                                  loading={!loadMoreSpecificTenants}
                                  value={
                                    loadMoreSpecificTenants
                                      ? tenantsListData
                                        ?.filter((ele) =>
                                          formik.values.selected_flat_tenants.includes(
                                            ele.tenant_id
                                          )
                                        )
                                        ?.map((item) => ({
                                          label:
                                            item.wing_no +
                                            " " +
                                            item.flat_no +
                                            " " +
                                            item.tenant_name,
                                          value: item.tenant_id,
                                        }))
                                      : []
                                  }
                                  onSelect={(selectedItem) => {
                                    formik.setFieldValue(
                                      "selected_flat_tenants",
                                      selectedItem.map((item) => item?.value)
                                    );
                                  }}
                                  name={`selected_flat_tenants`}
                                />
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="2"
                            className="text-sm w-56 pt-2 align-top"
                          >
                            <Checkbox
                              text={
                                "Send Acknowledgment notification in Email Notification"
                              }
                              initialchecked={
                                formik.values.acknowledgment == "Yes"
                              }
                              className="font-semibold"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `acknowledgment`,
                                  e.target.checked ? "Yes" : "No"
                                )
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Order By
                          </td>
                          <td className="pt-2.5">
                            <TextInputFM
                              placeholder="By Order Of Secretary"
                              className="w-[208px]"
                              name={`by_order`}
                              type="text"
                            // maxLength="15"
                            />
                          </td>
                        </tr>

                        {/* <tr>
                        <td
                          colspan="2"
                          className="text-sm w-56  align-top font-semibold"
                        >
                          <div>
                            <Checkbox
                              text={"Select Specific Flats"}
                              initialChecked={
                                societyStaffTypeOptions?.length &&
                                societyStaffTypeOptions?.length ===
                                  societyStaffTypeOptions?.length
                              }
                              onChange={handleSelectAll}
                            />
                          </div>

                           <div>
                            <Checkbox
                              text={"Select All"}
                              initialChecked={
                                societyStaffTypeOptions?.length &&
                                societyStaffTypeOptions?.length ===
                                  societyStaffTypeOptions?.length
                              }
                              onChange={handleSelectAll}
                            />
                          </div>
                          <div></div>
                          {societyStaffTypeOptions?.map((item) => {
                            return (
                              <div key={item}>
                                <Checkbox
                                  initialChecked={selectOptions?.includes(item)}
                                  text={item}
                                  onChange={(e) => {
                                    handleSingleCheckbox(e, item);
                                  }}
                                />
                              </div>
                            );
                          })} 
                        </td>
                      </tr> */}

                        {/* <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">
                          On Behalf Of
                        </td>
                        <td>
                          <DropdownFM2
                            options={onBehalfOfOptions}
                            width="208px"
                            height="36px"
                            name={"isTenant"}
                            placeholder="Select OnBehalf"
                            onSelect={(selectedItem) => {
                              if (selectedItem.value === 0) {
                                formik.setFieldValue(
                                  "tenantId",
                                  selectedItem.value
                                );
                              }
                              formik.setFieldValue(
                                "isTenant",
                                selectedItem.value
                              );
                              if (formik.values.wingId) {
                                let data = {
                                  societyId:
                                    loginData?.default_community?.community_id,
                                  wingId: formik.values.wingId,
                                  userType: selectedItem.value
                                    ? "Tenant"
                                    : "Member",
                                };
                                dispatch(getFlatsNo(data));
                              }
                              formik.setFieldValue("flatsNo", "");
                            }}
                            value={
                              formik.values.isTenant === ""
                                ? null
                                : {
                                    label: onBehalfOfOptions?.find(
                                      (item) =>
                                        item.value === formik.values.isTenant
                                    )?.label,
                                    value: formik.values.isTenant,
                                  }
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">
                          Member/Tenant Details
                        </td>
                        <td>
                          <div className="flex items-center">
                            <div className="w-52 flex gap-4 mr-4">
                              <DropdownFM2
                                className={"me-4"}
                                options={dropdownData.wingsList?.map(
                                  (item) => ({
                                    label: item.title,
                                    value: item.id,
                                  })
                                )}
                                width="96px"
                                height="36px"
                                placeholder="Wing"
                                name={"wingId"}
                                onSelect={(selectedItem) => {
                                  formik.setFieldValue(
                                    "wingId",
                                    selectedItem.value
                                  );
                                  let data = {
                                    societyId:
                                      loginData?.default_community
                                        ?.community_id,
                                    wingId: selectedItem.value,
                                  };
                                  dispatch(getFlatsNo(data));
                                }}
                                value={{
                                  label: dropdownData?.wingsList?.find(
                                    (item) => item.id === formik.values.wingId
                                  )?.title,
                                  value: formik.values.wingId,
                                }}
                              />
                              <DropdownFM2
                                options={dropdownData.flatsList?.map(
                                  (item) => ({
                                    label: item.flat_no,
                                    value: item.house_unique_id,
                                    subscriber_id: item.subscriber_id,
                                    memberName: item.full_name,
                                    tenant_id: item.tenantid,
                                    tenantName: item.tenant_full_name,
                                  })
                                )}
                                name={"flatsNo"}
                                width="96px"
                                height="36px"
                                placeholder="Flat/Unit"
                                onSelect={(selectedItem) => {
                                  formik.setFieldValue(
                                    "flatsNo",
                                    selectedItem.value
                                  );
                                  formik.setFieldValue(
                                    "subscriber_id",
                                    selectedItem.subscriber_id
                                  );
                                  formik.setFieldValue(
                                    "tenantId",
                                    selectedItem.tenant_id
                                  );
                                  formik.setFieldValue(
                                    "memberName",
                                    selectedItem.memberName
                                  );
                                  formik.setFieldValue(
                                    "tenantName",
                                    selectedItem.tenantName
                                  );
                                }}
                                value={
                                  formik.values.flatsNo
                                    ? {
                                        label: dropdownData.flatsList?.find(
                                          (item) =>
                                            item.house_unique_id ===
                                            formik.values.flatsNo
                                        )?.flat_no,
                                        value: formik.values.flatsNo,
                                      }
                                    : null
                                }
                              />
                            </div>
                            <div className="text-[#AAAAAA] font-bold text-xs -mt-3">
                              —{" "}
                              {formik.values.isTenant
                                ? formik.values.tenantName
                                : formik.values.memberName}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-44 py-2 text-sm align-top">
                          Email to:
                        </td>
                        <td className="py-2">
                          <div className="text-sm">
                            <Checkbox
                              text={"Assignee"}
                              className={"w-28"}
                              initialchecked={formik.values.emailAssignee === 1}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `emailAssignee`,
                                  e.target.checked ? 1 : 0
                                );
                              }}
                            />
                            <Checkbox
                              text={"Owner"}
                              className={"w-28"}
                              initialchecked={formik.values.emailOwner === 1}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `emailOwner`,
                                  e.target.checked ? 1 : 0
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
}

export default CreateNoticeandCircular;
