import React, { useState } from "react";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import TextRadio from "../../../../../components/InputFields/TextRadio";
import { TextInput } from "../../../../../components/InputFields/TextInput";

const InterestCalculation = ({ setInvoiceSettingList, invoiceSettingList }) => {
  const [checked, setChecked] = useState(false);

  const handleChecked = (event, setFieldValue) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue("isUrgent", "Yes");
    } else {
      setFieldValue("isUrgent", "No");
    }
  };

  const handleTimeOfDayChange = (value) => {};

  const timeOfDayOptions = [{ name: "timeOfDay", value: "day", label: "" }];
  return (
    <div>
      <div className="mt-2">
        <div className="flex justify-between items-center w-[70%] p-1.5">
          <div className="font-semibold">
            Interest Calculation Period - Start
          </div>
        </div>
        <table className="border-separate w-[74%]">
          <tbody>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Start period of Last Invoice</td>
              <td className="pb-4">
                <input
                  type="radio"
                  name="startOption"
                  value="periodStartFromDateOfLastInv"
                  checked={
                    invoiceSettingList.periodStart ===
                    "periodStartFromDateOfLastInv"
                  }
                  onChange={() =>
                    setInvoiceSettingList({
                      ...invoiceSettingList,
                      periodStart: "periodStartFromDateOfLastInv",
                    })
                  }
                  className="mr-2"
                  style={{
                    accentColor: "#CF001C",
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Invoice Date of Last Invoice</td>
              <td className="pb-4">
                <input
                  type="radio"
                  name="startOption"
                  value="periodStartLastInv"
                  checked={
                    invoiceSettingList.periodStart === "periodStartLastInv"
                  }
                  onChange={() =>
                    setInvoiceSettingList({
                      ...invoiceSettingList,
                      periodStart: "periodStartLastInv",
                    })
                  }
                  className="mr-2"
                  style={{
                    accentColor: "#CF001C",
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td>Payment Due Date of Last Invoice</td>
              <td>
                <input
                  type="radio"
                  name="startOption"
                  value="periodStartDueDate"
                  checked={
                    invoiceSettingList.periodStart === "periodStartDueDate"
                  }
                  onChange={() =>
                    setInvoiceSettingList({
                      ...invoiceSettingList,
                      periodStart: "periodStartDueDate",
                    })
                  }
                  className="mr-2"
                  style={{
                    accentColor: "#CF001C",
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr className="fill-[#CCC] mt-4" />
        <div className="flex justify-between items-center w-[75%] p-1.5">
          <div className="font-semibold">Interest Calculation Period - End</div>
        </div>
        <table className="border-separate w-[80%]">
          <tbody>
            <tr className="items-center text-sm">
              <td className="pb-4">From Date of Current Invoice </td>
              <td className="pb-4">
                <input
                  type="radio"
                  name="endOption"
                  value="periodEndInvFromDate"
                  checked={
                    invoiceSettingList.periodEnd === "periodEndInvFromDate"
                  }
                  onChange={() =>
                    setInvoiceSettingList({
                      ...invoiceSettingList,
                      periodEnd: "periodEndInvFromDate",
                    })
                  }
                  className="mr-2"
                  style={{
                    accentColor: "#CF001C",
                  }}
                />
              </td>
            </tr>
            <tr className="items-center text-sm">
              <td className="pb-4">Invoice Date of Current Invoice</td>
              <td className="pb-4">
                <input
                  type="radio"
                  name="endOption"
                  value="periodEndInvDate"
                  checked={invoiceSettingList.periodEnd === "periodEndInvDate"}
                  onChange={() =>
                    setInvoiceSettingList({
                      ...invoiceSettingList,
                      periodEnd: "periodEndInvDate",
                    })
                  }
                  className="mr-2"
                  style={{
                    accentColor: "#CF001C",
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4"> Rate Of Interest</td>
              <td className="flex items-center pb-2">
                <TextInput
                  label=""
                  type="decimal"
                  value={invoiceSettingList.roi}
                  onChange={(e) =>
                    setInvoiceSettingList({
                      ...invoiceSettingList,
                      roi: e.target.value,
                    })
                  }
                  className="w-32"
                  placeholder="Enter rate of Interest"
                />
                <span className="text-[#AAAAAA] ml-2">
                  (e.g. if the interest rate is 21% write 0.21)
                </span>
              </td>
            </tr>
            <tr className="items-center text-sm">
              <td className="pb-4">
                Don’t apply interest on sub total in case of late payment
              </td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={
                    invoiceSettingList.exceedDueDate === "Yes" ? true : false
                  }
                  onChange={(e) => {
                    setInvoiceSettingList({
                      ...invoiceSettingList,
                      exceedDueDate: e.target.checked ? "Yes" : "No",
                    });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">
                Apply Interest Only On Outstanding Amount
              </td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={
                    invoiceSettingList.applyInterestOnOutstandingAmt === "Yes"
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setInvoiceSettingList({
                      ...invoiceSettingList,
                      applyInterestOnOutstandingAmt: e.target.checked
                        ? "Yes"
                        : "No",
                    });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Apply Interest On Interest Arrears</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={
                    invoiceSettingList.applyInterestOnInterestArrears === "Yes"
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setInvoiceSettingList({
                      ...invoiceSettingList,
                      applyInterestOnInterestArrears: e.target.checked
                        ? "Yes"
                        : "No",
                    });
                  }}
                />
              </td>
            </tr>

            <tr className="items-center text-sm">
              <td className="pb-4">Interest Method </td>
              <td className="pb-4">
                <span className="mr-2">
                  <input
                    type="radio"
                    name="appliedOn"
                    value="simple"
                    checked={invoiceSettingList.interestMethod === "simple"}
                    onChange={() =>
                      setInvoiceSettingList({
                        ...invoiceSettingList,
                        interestMethod: "simple",
                      })
                    }
                    className="mr-2"
                    style={{
                      accentColor: "#CF001C",
                    }}
                  />{" "}
                  Simple{" "}
                </span>
                <span className="mr-2">
                  <input
                    type="radio"
                    name="appliedOn"
                    value="dynamic"
                    checked={invoiceSettingList.interestMethod === "dynamic"}
                    onChange={() =>
                      setInvoiceSettingList({
                        ...invoiceSettingList,
                        interestMethod: "dynamic",
                      })
                    }
                    className="mr-2"
                    style={{
                      accentColor: "#CF001C",
                    }}
                  />{" "}
                  Dynamic
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InterestCalculation;
