import { call, put, takeLatest } from 'redux-saga/effects'
import { notify } from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/SocietyProfileSetupReqs/SentSmsHistoryReqs'

function* getSentSmsHistoryList(action) {
    try {
        const result = yield call(req.getSentSmsHistoryListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SENT_SMS_HISTORY_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SENT_SMS_HISTORY_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (error) {

    }
}

function* getSmsModuleList(action) {
    try {
        const result = yield call(req.getSmsModuleListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SMS_MODULE_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SMS_MODULE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (error) {

    }
}

function* getSentSmsHistoryDetails(action) {
    try {
        const result = yield call(req.getSentSmsHistoryDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SENT_SMS_HISTORY_DETAILS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SENT_SMS_HISTORY_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (error) {

    }
}

function* downloadSentSmsHistory(action) {
    try {
        const result = yield call(req.downloadSentSmsHistoryReq, action);
        if (result instanceof ArrayBuffer) {
            yield put({ type: types.DOWNLOAD_SENT_SMS_HISTORY_SUCCESS});
            action.data?.onSucessCallback(result)
        } else {
            yield put({ type: types.DOWNLOAD_SENT_SMS_HISTORY_FAILURE});
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

export default function* SentSmsHistorySaga() {
    yield takeLatest(types.GET_SENT_SMS_HISTORY_LIST, getSentSmsHistoryList)
    yield takeLatest(types.GET_SMS_MODULE_LIST, getSmsModuleList)
    yield takeLatest(types.GET_SENT_SMS_HISTORY_DETAILS, getSentSmsHistoryDetails)
    yield takeLatest(types.DOWNLOAD_SENT_SMS_HISTORY, downloadSentSmsHistory)
}