import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import { logOut } from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import { errorMsg } from '../../../../consts/responseMessages';

export async function getSmsCreditPackageListReq(action) {
    return axiosInstance
        .get(
            `${apiEndpoints.GET_SMS_CREDIT_PACKAGE_API}`
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}

export async function getSmsCreditOrderHistoryListReq(action) {
    const Body = {
        page: action.data?.page,
        limit: action.data?.pageLimit
    }
    return axiosInstance
        .get(
            `${apiEndpoints.GET_SMS_CREDIT_ORDER_HISTORY_API}`, { params: Body }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}

export async function getSmsCreditOrderDetailsReq(action) {
    const Params = {
        request_order_id: action?.data?.id
    }
    return axiosInstance
        .get(
            `${apiEndpoints.GET_SMS_CREDIT_ORDER_DETAILS_API}`, { params: Params }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}

export async function buySmsRequestReq(action) {
    const BODY = {
        credit: action.data?.credit,
        amount: action.data?.amount,
    };

    return axiosInstance
        .post(apiEndpoints.BUY_SMS_CREDIT_API, { ...BODY })
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 400) {
                notify("error", errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify("error", errors.response.data.message);
                logOut();
            } else {
                notify("warning", "Something went wrong");
            }
            return errors;
        });
}