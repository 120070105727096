import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import styled from "@emotion/styled";
import {
  stableSort,
  getComparator,
} from "../../../../utils/helpers/universalFunctions";
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Tooltip } from "@mui/material";
import "../../../../components/TableComponent/Table.css";
import {
  ApproveIcon,
  DeleteIcon,
  EditIcon,
  PrinterIcon,
  SortIcon,
} from "../../../../assets";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import SendSmsModal from "../../../../components/Modal/SendSmsModal";
import Loader from "../../../../components/Loader/Loader";
import ItemsPerPage from "../../../../components/ItemsPerPage/ItemsPerPage";
import Pagination from "../../../../components/Pagination/Pagination";
import {
  CREDIT_NOTE_PAYMENT_ENDPOINT,
  EDIT_ADVANCE_NOTE_ENDPOINT,
  MANAGE_ADVANCE_NOTE_ENDPOINT,
  PRINT_ADVANCE_NOTE_INVOICE_MULTI_ENDPOINT,
  PRINT_ADVANCE_NOTE_RECIEPT_ENDPOINT,
} from "../../../../Routing/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteAdvanceNote } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../Routing/permissions";
import { getCreditActionsList, getCreditSync } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageCreditNoteAction";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Button from "../../../../components/Button/Button";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import Modal from "../../../../components/Modal/Modal";
import { notify } from "../../../../utils/notification";

function ActionComponent({ advanceNoteId, jounralId, status }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSendSMSModalModal, setShowSendSMSModalModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader } = useSelector(
    (state) => state.manageAdvanceNoteReducer
  );

  const handleSendSMSModalClick = () => {
    setShowSendSMSModalModal(true);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    let data = {
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || "",
      pageLimit: searchParams.get("pageLimit") || 10,
      wingId: searchParams.get("wingId") || "",
      flatId: searchParams.get("flatId") || "",
      searchBy: searchParams.get("searchBy") || "",
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
      status: searchParams.get("status") || "",
      advanceNoteId,
      jounralId,
      onSuccessCallback: () => {
        setShowDeleteModal(false);
      },
    };
    dispatch(deleteAdvanceNote(data));
  };
  const handleSendSMS = () => {
    // const data={
    //   staffData: [{
    //     memberName,
    //     staffCode,
    //     advanceNoteId,
    //     mobileNo: mobileNo?.includes('+') ? mobileNo?.replace('+','') : `91-${mobileNo}`
    //   }]
    // }
    // dispatch(sendStaffCodeSmsToStaff(data))
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {getPermission(loginData, [permissions.EDIT_ADVANCE_NOTE]) &&
        (status === "Outstanding" || status === "Unverified") && (
          <Tooltip title="Edit" arrow>
            <EditIcon
              className="fill-[#555] hover:fill-red-650 me-1.5"
              onClick={() => {
                navigate(`${EDIT_ADVANCE_NOTE_ENDPOINT}/${advanceNoteId}`);
              }}
            />
          </Tooltip>
        )}
      {status === "Unverified" && (
        <Tooltip title="Pay & verify" arrow>
          <ApproveIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={() => {
              navigate(`${CREDIT_NOTE_PAYMENT_ENDPOINT}/${advanceNoteId}`);
            }}
          />
        </Tooltip>
      )}
      <Tooltip title="Print" arrow>
        <Link
          target="_blank"
          to={`${PRINT_ADVANCE_NOTE_RECIEPT_ENDPOINT}/${advanceNoteId}`}
        >
          <PrinterIcon className="fill-[#555] hover:fill-red-650 me-1.5" />
        </Link>
      </Tooltip>
      {getPermission(loginData, [permissions.DELETE_ADVANCE_NOTE]) &&
        status !== "Adjusted In Invoice" && (
          <Tooltip title="Delete" arrow>
            <DeleteIcon
              className="fill-[#555] hover:fill-red-650 me-1.5"
              onClick={() => handleDeleteClick()}
            />
          </Tooltip>
        )}
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
        loading={updateLoader}
      />
      <SendSmsModal
        isOpen={showSendSMSModalModal}
        onCancel={() => setShowSendSMSModalModal(false)}
        onConfirm={handleSendSMS}
      />
    </Box>
  );
}

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const ManageAdvanceNoteTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [action, setAction] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModal, setIsModal] = useState(false)
  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader, creditActionList, actionLoader } = useSelector(
    (state) => state.manageCreditNoteReducer
  );
  console.log('creditActionList', creditActionList)
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    setSelected([]);
  }, [searchParams]);
  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked
      ? data.map((item) => item.advanceNoteId)
      : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page");
    navigate({
      pathname: `${MANAGE_ADVANCE_NOTE_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["page", newPage],
      ])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      navigate({
        pathname: `${MANAGE_ADVANCE_NOTE_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["pageLimit", value],
        ])}`,
      });
    }
  };
  const handleApply = ({ AdvanceNoteId }) => {
    const societyId = loginData?.default_community?.community_id;
    const commonData = {
      invIds: selected,
      societyId,
      onSuccessCallback: () => {
        setSelected([]);
        setIsModal(false);
      },
    };

    switch (action) {

      case 'advanceSync':
        if (getPermission(loginData, [permissions.ADVANCE_SYNC])) {
          const commonData = {
            invIds: selected,
            societyId,
            onSuccessCallback: () => {
              setSelected([]);
              setIsModal(false);
            },
          };
          dispatch(getCreditSync(commonData));
        }
      case 'printAdvanceNote':
        navigate(PRINT_ADVANCE_NOTE_INVOICE_MULTI_ENDPOINT, {
          state: { invid: selected },
        });
        break;
    }
  };
  const isSelected = (id) => selected.includes(id);
  useEffect(() => {
    dispatch(getCreditActionsList({ type: "advance" }));
  }, []);
  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <Modal
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        title="Are you sure?"
        children="Are you sure you want to apply this action? This change cannot be undone."
        onConfirm={
          handleApply
        }
        onCancel={() => setIsModal(false)}
        loading={actionLoader}
      />
      {!!selected?.length && (
        <div className="flex justify-end items-center border-t border-[#CCC] gap-2 p-2">
          <div className="flex items-center gap-2">
            <Dropdown2
              options={
                creditActionList?.advanceAction
                  ? [...Object.entries(
                    creditActionList?.advanceAction
                  )?.map((item) => ({
                    value: item?.[0],
                    label: item?.[1],
                  })), { value: "printAdvanceNote", label: "Print" }]
                  : []
              }
              placeholder="Choose Action"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                setAction(selectedItem.value);
              }}
            />
            <Button
              label={
                <span className="inline-block w-8">
                  {actionLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Apply"
                  )}
                </span>
              }
              className="h-8 px-4 font-semibold"
              type="button"
              disabled={actionLoader}
              onClick={() => {
                if (!action) {
                  notify("warning", "Please select an action from the dropdown.");
                } else {
                  setIsModal(true);
                }
              }}
            />
          </div>
        </div>
      )}
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{ textWrap: "nowrap" }}>
              {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{ pl: "0 !important", pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={
                      data?.length > 0 && selected?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      const isRowSelected = isSelected(row?.id);

                      return (
                        <TableRowStyled
                          key={row?.advanceNoteId}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox && (
                            <TableCellStyled
                              size="small"
                              sx={{ pl: "0 !important", zIndex: 0 }}
                              onClick={(event) =>
                                handleClick(event, row?.advanceNoteId)
                              }
                            >
                              <Checkbox
                                checked={selected?.includes(row?.advanceNoteId)}
                                onChange={(e) =>
                                  handleClick(e, row?.advanceNoteId)
                                }
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}
                              />
                            </TableCellStyled>
                          )}

                          {/* Serial Number */}
                          <TableCellStyled
                            size="small"
                            align="left"
                            onClick={(event) =>
                              handleClick(event, row?.advanceNoteId)
                            }
                          >
                            {row?.sNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.invoiceNo}
                          </TableCellStyled>

                          <TableCellStyled
                            size="small"
                            align="left"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {row.date}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.debitName}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.creditName}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.memberName}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.amount}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.remarks}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {<p>{row?.status}</p>}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <ActionComponent
                              status={row.status}
                              jounralId={row.jounralId}
                              advanceNoteId={row.advanceNoteId}
                            />
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

ManageAdvanceNoteTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default ManageAdvanceNoteTable;
