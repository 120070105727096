import React, { useEffect } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import SMSCreditPackageTable from "../../../components/TableComponent/SmsCreditPackage";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { SMS_CREDIT_ORDER_HISTORY_ENDPOINT } from "../../../Routing/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSmsCreditPackageList } from "../../../redux/actions/SetupRecordsActions/SocietyProfileSetupActions/SmsCreditAction";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "120px" },
  { id: "smsCredit", label: "SMS Credit", sort: true, width: "260px" },
  { id: "amount", label: "Amount", sort: true, width: "300px" },
  { id: "action", label: "Action", sort: false, width: "100px" },
];

const SmsCreditPackages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, smsCreditPackageList } = useSelector((state) => state.smsCreditReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(() => {
    dispatch(getSmsCreditPackageList());
  }, [])
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">

        <ListHeader leftContent="SMS Credit Package">
          <div className="text-[#AAA] items-center text-xs mt-2 font-semibold">Remaining SMS Credit ({smsCreditPackageList?.remaining_credit})</div>
          <div className="border-r border-[#CCC]"></div>
          <Button
            label="Order History"
            onClick={handleNavigation(SMS_CREDIT_ORDER_HISTORY_ENDPOINT)}
          />
        </ListHeader>
        <div className="ml-1">
          <SMSCreditPackageTable
            data={smsCreditPackageList?.list?.map((item, index) => ({
              sNo: index + 1,
              id: item.id,
              credit: item.credit,
              amount: item.amount
            }))}
            columns={headCells}
            checkbox={true}
            pagination={false}
          // totalCount={staffPersonnelList?.total_count}
          // totalPages={staffPersonnelList?.total_pages}
          // start={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + 1}
          // end={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + staffPersonnelList?.staff_list?.length}
          // currentPage={parseInt(staffPersonnelList?.current_page)}
          // rowsPerPage={searchParams.get("pageLimit") || 10}
          // getTableData={getTableData}
          ></SMSCreditPackageTable>
        </div>
        {!smsCreditPackageList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SmsCreditPackages;
