import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeader from "../../../components/ListHeader/ListHeader";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../assets";
import Button from "../../../components/Button/Button";
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Search from "../../../components/SearchBox/Search";
import {
  COMPLAINT_AGING_ENDPOINT,
  COMPLAINT_DASHBOARD_ENDPOINT,
  LOG_A_COMPLAINT_AUDIT_LOG_ENDPOINT,
  LOG_A_COMPLAINT_ENDPOINT,
} from "../../../Routing/routes";
import { useDispatch, useSelector } from "react-redux";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import {
  downloadComplaintsExcelSheet,
  getComplaintDashboardContentList,
  getComplaintDashboardList,
  getComplaintEscalationSettings,
  getComplaintStatusList,
} from "../../../redux/actions/DeskActions/DeskAction";
import ComplaintDashboardTable from "../../../components/TableComponent/ComplaintDashboardTable";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
import { downloadExcel } from "../../../utils/helpers/downloadMedia";
import ButtonG from "../../../components/Button/ButtonG";
import { getComplaintCategoryList } from "../../../redux/actions/DeskActions/ComplaintCategoryAction";
import { CircularProgress, Skeleton } from "@mui/material";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";

const complaintData = [
  {
    row1: {
      label: "Old Complaints",
      value: "05",
      key: "count_according_to_status",
      data: [
        { label: "Open", value: "05" },
        { label: "In-progress", value: "05" },
        { label: "On-hold", value: "05" },
      ],
    },
    row2: {
      label: "Current Complaints",
      value: "05",
      key: "count_according_to_status",
      data: [
        { label: "Open", value: "05" },
        { label: "In-progress", value: "05" },
        { label: "On-hold", value: "05" },
      ],
    },
  },
];

const ComplaintData1 = [
  {
    row1: {
      label: "Old Complaints",
      value: "",
      key: "count_according_to_level",
      data: [
        { label: "L-1", value: "05" },
        { label: "L-2", value: "05" },
        { label: "L-3", value: "05" },
      ],
    },
    row2: {
      label: "Current Complaints",
      value: "",
      key: "count_according_to_level",
      data: [
        { label: "L-1", value: "05" },
        { label: "L-2", value: "05" },
        { label: "L-3", value: "05" },
      ],
    },
  },
];

const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: "50px" },
  { id: "ticketNo", label: "Ticket No.", sort: true, width: "90px" },
  { id: "flatNo", label: "Flat No.", sort: true, width: "85px" },
  { id: "subject", label: "Subject", sort: true, width: "" },
  { id: "category", label: "Category", sort: true, width: "100px" },
  { id: "level", label: "Level", sort: true, width: "60px" },
  { id: "assigned", label: "Assigned To", sort: true, width: "100px" },
  { id: "loggedBy", label: "Logged By", sort: true, width: "100px" },
  { id: "loggedOn", label: "Logged On", sort: true, width: "105px" },
  { id: "status", label: "Status", sort: true, width: "80px" },
  { id: "aging", label: "Aging", sort: false, width: "110px" },
  { id: "review", label: "review", sort: false, width: "110px" },
  { id: "action", label: "Action", sort: false },
];

function ComplaintDashboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  // const { downLoader } = useSelector(state => state.downloadComplaintsExcelReducer)
  const { downLoader } = useSelector(
    (state) => state.downloadComplaintsExcelReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading,
    complaintDashboardContentList,
    complaintDashboardList,
    complaintEscalationList,
    complaintStatusList,
  } = useSelector((state) => state.deskReducers);
  const { complaintCategoryList } = useSelector(
    (state) => state.complaintCategoryReducer
  );
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${COMPLAINT_DASHBOARD_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(COMPLAINT_DASHBOARD_ENDPOINT);
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("startDate");
      navigate({
        pathname: `${COMPLAINT_DASHBOARD_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("endDate");
      navigate({
        pathname: `${COMPLAINT_DASHBOARD_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleStatusSearch = (selectedItem) => {
    if (selectedItem.value !== searchParams.get("status")) {
      searchParams.delete("status");
      navigate({
        pathname: `${COMPLAINT_DASHBOARD_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["status", selectedItem.value],
        ])}`,
      });
    }
  };
  const handleCategorySelect = (selectedItem) => {
    if (selectedItem.value !== searchParams.get("category")) {
      searchParams.delete("category");
      navigate({
        pathname: `${COMPLAINT_DASHBOARD_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["category", selectedItem.label],
        ])}`,
      });
    }
  };

  const handleLevel = (selectedItem) => {
    if (selectedItem.value !== searchParams.get("level")) {
      searchParams.delete("level");
      navigate({
        pathname: `${COMPLAINT_DASHBOARD_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["level", selectedItem.label],
        ])}`,
      });
    }
  };

  const handleDownloadExcel = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: "complaints_sheet.xlsx" });
    };
    const data = {
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || "",
      status: searchParams.get("status") || "",
      pageLimit: searchParams.get("pageLimit") || 10,
      category: searchParams.get("category") || "",
      level: searchParams.get("level") || "",
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
      onSucessCallback,
    };
    dispatch(downloadComplaintsExcelSheet(data));
  };

  const getComplaintTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || "",
      status: searchParams.get("status") || "",
      pageLimit: searchParams.get("pageLimit") || 10,
      category: searchParams.get("category") || "",
      level: searchParams.get("level") || "",
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
    };
    dispatch(getComplaintDashboardList(data));
    dispatch(getComplaintDashboardContentList(data));
  };

  useEffect(() => {
    getComplaintTableData();
  }, [searchParams]);

  useEffect(() => {
    dispatch(getComplaintStatusList());
    dispatch(getComplaintCategoryList());
    dispatch(
      getComplaintEscalationSettings({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2.5">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Complaints Dashboard">
            <div className="flex text-sm gap-3">
              <div>
                <Search
                  height="8"
                  onclick={handleSearch}
                  placeholder={"Seacrh by Ticket No"}
                  value={searchParams.get("search")}
                />
              </div>
              {/* <ButtonIco icon={faPrint} children="Print" /> */}
              {downLoader ? (
                <ButtonIco
                  className=" w-32 justify-center"
                  children={
                    <CircularProgress sx={{ color: "#d32f2f" }} size={17} />
                  }
                />
              ) : (
                <ButtonIco
                  icon={DownloadExt}
                  children="Download Excel"
                  onClick={handleDownloadExcel}
                />
              )}
              <div className="border-r border-[#CCC]"></div>
              <Link to={COMPLAINT_AGING_ENDPOINT} className="text-white">
                <Button label="Complaint Aging" className="h-8" />
              </Link>
              {getPermission(loginData, [
                permissions.LOG_A_COMPLAINT_PERMISSION
              ]) &&
                <>
                  <div className="border-r border-[#CCC]"></div>
                  <Button
                    label="Log a Complaint"
                    onClick={() => navigate(LOG_A_COMPLAINT_ENDPOINT)}
                    className="px-4"
                    type={"button"}
                  />
                </>
              }
              {getPermission(loginData, [
                permissions.VIEW_AUDIT_OF_COMPLAINT_PERMISSION
              ]) &&
                <Button
                  label="Audit Log"
                  onClick={() => navigate(LOG_A_COMPLAINT_AUDIT_LOG_ENDPOINT)}
                  className="px-4"
                  type={"button"}
                />
              }
            </div>
          </ListHeader>
        </div>

        <div className="flex flex-row items-center justify-between py-3">
          <div className="flex items-center text-[13px] ">
            <div className="flex mr-2 items-center">
              <div className="mr-2 font-normal">Status</div>
              <Dropdown2
                options={complaintStatusList?.map((item) => ({
                  label: item.key,
                  value: item.value,
                }))}
                placeholder="Select Status"
                className="text-[11px] ms-4"
                width="170px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleStatusSearch(selectedItem);
                }}
                value={
                  searchParams.get("status") && {
                    label: complaintStatusList?.find(
                      (item) =>
                        item.value?.toString() === searchParams.get("status")
                    )?.key,
                    value: complaintStatusList?.find(
                      (item) =>
                        item.value?.toString() === searchParams.get("status")
                    )?.value,
                  }
                }
              />
            </div>
            <div className="flex mr-2 items-center">
              <div className="mr-2 font-normal">Category</div>
              <Dropdown2
                options={complaintCategoryList?.map((item) => ({
                  label: item.title,
                  value: item.id,
                }))}
                placeholder="Select Category"
                className="text-[11px] ms-4"
                width="190px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleCategorySelect(selectedItem);
                }}
                value={
                  searchParams.get("category") && {
                    label: searchParams.get("category"),
                    value: complaintCategoryList?.find(
                      (item) =>
                        item.value?.toString() === searchParams.get("category")
                    )?.label,
                  }
                }
              />
            </div>
            <div className="flex mr-2 items-center">
              <div className="mr-2 font-normal">Level</div>
              <Dropdown2
                options={complaintEscalationList?.map((item) => ({
                  label: item.level,
                  value: item.id,
                }))}
                placeholder="Select Level"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleLevel(selectedItem);
                }}
                value={
                  searchParams.get("level") && {
                    value: searchParams.get("level"),
                    label: searchParams.get("level"),
                  }
                }
              />
            </div>
            <RangeDatePicker
              className="w-44 h-8"
              defaultStartValue={
                searchParams.get("startDate")
                  ? new Date(searchParams.get("startDate"))
                  : moment().subtract(7, "days").toDate()
              }
              defaultEndValue={
                searchParams.get("endDate")
                  ? new Date(searchParams.get("endDate"))
                  : new Date()
              }
              startMaxValue={new Date()}
              endMaxValue={new Date()}
              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}
            />
          </div>
          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>
        {loading ? (
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={{ height: "120px", width: "100%" }}
          />
        ) : (
          <>
            <div className="w-full pt-1">
              <div className="grid grid-cols-8 gap-3 w-full text-[11px] mb-4">
                <div className="p-1 border-[1px] rounded-lg bg-[#FDF2F3] text-center">
                  <div className="font-bold">Complaint Count</div>
                  <div className="text-[#CF001C] font-bold text-[34px]">
                    {complaintDashboardList?.complaint_count}
                  </div>
                  <div className="text-[#AAAAAA]">
                    Till {moment().format("DD-MM-YYYY hh:mm")}
                  </div>
                </div>

                {complaintData?.map((item, index) => {
                  return (
                    <div className="py-1.5 border-[1px] rounded-lg bg-[#FDF2F3] col-span-3 font-bold text-center">
                      <div className="grid grid-cols-5 gap-0 text-[11px] h-12">
                        <div className="col-span-2 border-b-[1px] mx-2 flex flex-col justify-center">
                          <div
                            className={!item?.row2?.value && "text-[#CF001C]"}
                          >
                            {item.row1.label}
                          </div>
                          <div className="text-[#CF001C] text-[20px]">
                            {complaintDashboardContentList?.[
                              item.row1.key
                            ]?.reduce((partialSum, item) => {
                              const old_total11 = item.old_total || 0;
                              return partialSum + old_total11;
                            }, 0)}
                          </div>
                        </div>
                        {item?.row1?.data?.map((item1) => (
                          <div className="border-l-[1px] flex flex-col justify-between">
                            <div className="text-[#AAAAAA]">{item1.label}</div>
                            <div className="text-[18px] border-b-[1px] mx-auto pb-0.5 w-12">
                              {
                                complaintDashboardContentList?.[
                                  item.row1.key
                                ]?.find((item) => item.status === item1.label)
                                  ?.old_total
                              }
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="grid grid-cols-5 gap-0 text-[11px] h-12">
                        <div className="col-span-2 mx-2 flex flex-col justify-center">
                          <div
                            className={!item?.row2?.value && "text-[#CF001C]"}
                          >
                            {item.row2.label}
                          </div>
                          <div className="text-[#CF001C] text-[20px]">
                            {complaintDashboardContentList?.[
                              item.row1.key
                            ]?.reduce((partialSum, item) => {
                              const new_total11 = item.new_total || 0;
                              return partialSum + new_total11;
                            }, 0)}
                          </div>
                        </div>
                        {item?.row2?.data?.map((item1) => (
                          <div className="border-l-[1px] flex flex-col justify-center">
                            <div className="text-[18px]">
                              {
                                complaintDashboardContentList?.[
                                  item.row1.key
                                ]?.find((item) => item.status === item1.label)
                                  ?.new_total
                              }
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
                {ComplaintData1?.map((item, index) => {
                  return (
                    <div className="py-1.5 border-[1px] rounded-lg bg-[#FDF2F3] col-span-3 font-bold text-center">
                      <div className="grid grid-cols-5 gap-0 text-[11px] h-12">
                        <div className="col-span-2 border-b-[1px] mx-2 flex flex-col justify-center">
                          <div className={!item?.row2?.value}>
                            {item.row1.label}
                          </div>
                          <div className="text-[#CF001C] text-[20px]">
                            {complaintDashboardContentList?.[
                              item.row1.key
                            ]?.reduce((partialSum, item) => {
                              const old_total1 = Number(item.old_total) || 0;
                              return partialSum + old_total1;
                            }, 0)}
                          </div>
                        </div>
                        {complaintDashboardContentList?.count_according_to_level?.map(
                          (item1, i) => (
                            <div className="border-l-[1px] flex flex-col justify-between">
                              <div className="text-[#AAAAAA]">
                                {complaintDashboardContentList
                                  ?.count_according_to_level?.[i]?.level || 0}
                              </div>
                              <div className="text-[18px] border-b-[1px] mx-auto pb-0.5 w-12">
                                {complaintDashboardContentList
                                  ?.count_according_to_level?.[i]?.old_total ||
                                  0}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <div className="grid grid-cols-5 gap-0 text-[11px] h-12">
                        <div className="col-span-2 mx-2 flex flex-col justify-center">
                          <div className={!item?.row2?.value}>
                            {item.row2.label}
                          </div>
                          <div className="text-[#CF001C] text-[20px]">
                            {complaintDashboardContentList?.[
                              item.row1.key
                            ]?.reduce((partialSum, item) => {
                              const new_total1 = Number(item.new_total) || 0;
                              return partialSum + new_total1;
                            }, 0)}
                          </div>
                        </div>
                        {complaintDashboardContentList?.count_according_to_level?.map(
                          (item1, i) => (
                            <div className="border-l-[1px] flex flex-col justify-center">
                              <div className="text-[18px]">
                                {complaintDashboardContentList
                                  ?.count_according_to_level?.[i]?.new_total ||
                                  0}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  );
                })}

                <div className="p-1 border-[1px] rounded-lg bg-[#FDF2F3] text-center">
                  <div className="font-bold">Closed Complaint</div>
                  <div className="text-[#CF001C] font-bold text-[34px]">
                    {complaintDashboardList?.close_complaint_count}
                  </div>
                  <div className="text-[#AAAAAA]">
                    Till {moment().format("DD-MM-YYYY hh:mm")}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-5 gap-3 w-full">
                {complaintDashboardContentList?.count_according_to_category
                  ?.slice(0, 5)
                  ?.map((category) => {
                    return (
                      <div className="px-2 py-1 border-[1px] font-bold rounded-lg bg-[#FDF2F3] flex items-center justify-between">
                        <span className="text-sm">
                          {category?.complaint_category}
                        </span>
                        <span className="text-[#CF001C] text-[24px] border-l-[1px]  pl-1">
                          {category.total}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="mt-4">
              {complaintDashboardList?.complaint_list && (
                <ComplaintDashboardTable
                  loading={loading}
                  data={complaintDashboardList?.complaint_list?.map(
                    (item, index) => ({
                      sNo: (
                        (complaintDashboardList?.current_page - 1) *
                        complaintDashboardList?.current_limit +
                        1 +
                        index
                      )
                        ?.toString()
                        ?.padStart(2, "0"),
                      id: item?.id,
                      ticketNo: item?.ticket,
                      flatNo: item?.wing_flat,
                      subject: item?.subject,
                      category: item?.complaint_category,
                      level: item?.level,
                      assigned_to: item?.assigned_to || "",
                      assigned_title: item?.assigned_title || "",
                      assigned_type: item?.assigned_type || "",
                      serviced_by: item?.serviced_by || "",
                      loggedBy: item?.logged_by,
                      loggedOn: item?.creation_date,
                      status: item?.status,
                      aging:
                        item?.days !== undefined &&
                          item?.hours !== undefined &&
                          item?.minutes !== undefined
                          ? `${item?.days || 0} Days, ${item?.hours || 0
                          } Hrs, ${item?.minutes || 0}mins`
                          : "",
                      review_rating: item?.review_rating,
                      review_comment: item?.review_comment,
                    })
                  )}
                  columns={headCells}
                  checkbox={false}
                  pagination={complaintDashboardList?.complaint_list?.length}
                  totalCount={complaintDashboardList?.complaint_count}
                  totalPages={complaintDashboardList?.total_pages}
                  start={
                    (complaintDashboardList?.current_page - 1) *
                    (searchParams.get("pageLimit") || 10) +
                    1
                  }
                  end={
                    (complaintDashboardList?.current_page - 1) *
                    (searchParams.get("pageLimit") || 10) +
                    complaintDashboardList?.complaint_list?.length
                  }
                  currentPage={parseInt(complaintDashboardList?.current_page)}
                  rowsPerPage={searchParams.get("pageLimit") || 10}
                  // getTableData={getComplaintDashboardList}
                  getTableData={getComplaintTableData}
                ></ComplaintDashboardTable>
              )}
            </div>
            {!!complaintDashboardContentList?.complaint_list?.length && (
              <div className="text-sm text-[#AAAAAA] my-4 text-center">
                No Records
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  );
}

export default ComplaintDashboard;
