import * as types from '../../index.types';

export function getManageCreditList(data) {
    return {
        type: types.GET_CREDIT_NOTE_LIST,
        data,
    };
}

export function getCreditNoteDetails(data) {
    return {
        type: types.GET_CREDIT_NOTE_DETAILS,
        data,
    };
}

export function addCreditNote(data) {
    return {
        type: types.ADD_CREDIT_NOTE,
        data,
    };
}

export function updateCreditNote(data) {
    return {
        type: types.UPDATE_CREDIT_NOTE,
        data,
    };
}

export function deleteCreditNote(data) {
    return {
        type: types.DELETE_CREDIT_NOTE,
        data,
    };
}

export function printCreditNoteInvoice(data) {
    return {
        type: types.PRINT_CREDIT_NOTE_INVOICE,
        data,
    };
}

export function getFlatWiseInvoiceList(data) {
    return {
        type: types.GET_FLAT_WISE_INVOICE_LIST,
        data,
    };
}

export function downloadCreditNoteExcelSample(data) {
    return {
        type: types.DOWNLOAD_CREDIT_NOTE_EXCEL_SAMPLE,
        data,
    };
}

export function uploadCreditNoteExcel(data) {
    return {
        type: types.UPLOAD_CREDIT_NOTE_EXCEL,
        data,
    };
}

export function saveCreditNoteExcel(data) {
    return {
        type: types.SAVE_CREDIT_NOTE_EXCEL,
        data,
    };
}

export function getCreditSync(data) {
    return {
        type: types.CREDIT_NOTE_SYNC,
        data,
    };
}
export function getCreditActionsList(data) {
    return {
        type: types.CREDIT_NOTE_ACTION_LIST,
        data,
    };
}
