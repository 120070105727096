import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/FinanceReqs/BillingManagementReqs/ManageCreditNoteReqs'

function* getManageCreditList(action) {
    try {
        const result = yield call(req.getManageCreditListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_CREDIT_NOTE_LIST_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.GET_CREDIT_NOTE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getCreditNoteDetails(action) {
    try {
        const result = yield call(req.getCreditNoteDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_CREDIT_NOTE_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_CREDIT_NOTE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addCreditNote(action) {
    try {
        const result = yield call(req.addCreditNoteReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.ADD_CREDIT_NOTE_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.ADD_CREDIT_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateCreditNote(action) {
    try {
        const result = yield call(req.updateCreditNoteReq, action);
        if (result.success === true) {
            notify('success', 'Credit Note updated successfully')
            action.data?.onSuccessCallback?.()
            yield put({ type: types.UPDATE_CREDIT_NOTE_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_CREDIT_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteCreditNote(action) {
    try {
        const result = yield call(req.deleteCreditNoteReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_CREDIT_NOTE_SUCCESS, payload: action.data});  
            const newList = yield call(req.getManageCreditListReq, action)
            if (newList.success === true)
                yield put({ type: types.GET_CREDIT_NOTE_LIST_SUCCESS, payload: newList});  
        } else {
            yield put({ type: types.DELETE_CREDIT_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* printCreditNoteInvoice(action) {
    try {
        const result = yield call(req.printCreditNoteInvoiceReq, action);
        yield put({ type: types.PRINT_CREDIT_NOTE_INVOICE_SUCCESS, payload: result});  
    } catch (e) {
        yield put({ type: types.PRINT_CREDIT_NOTE_INVOICE_FAILURE, message: e });
        console.log(e)
    }
}

function* getFlatWiseInvoiceList(action) {
    try {
        const result = yield call(req.getFlatWiseInvoiceListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_FLAT_WISE_INVOICE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_FLAT_WISE_INVOICE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadCreditNoteExcelSample(action) {
    try {
        const result = yield call(req.downloadCreditNoteExcelSampleReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* uploadCreditNoteExcel(action) {
    try {
        const result = yield call(req.uploadCreditNoteExcelReq, action);
        if (result.success === true) {
            yield put({ type: types.UPLOAD_CREDIT_NOTE_EXCEL_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPLOAD_CREDIT_NOTE_EXCEL_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* saveCreditNoteExcel(action) {
    try {
        const result = yield call(req.saveCreditNoteExcelReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.SAVE_CREDIT_NOTE_EXCEL_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.SAVE_CREDIT_NOTE_EXCEL_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}
function* getCreditSync(action) {
    console.log('saga')
    try {
        const result = yield call(req.getCreditSyncReq, action);
        console.log('result',result)
        if (result.success === true) {
            notify('success',result?.message)
            action?.data?.onSuccessCallback()
            yield put({ type: types.CREDIT_NOTE_SYNC_SUCCESS }); 
           
        }else{
            yield put({ type: types.CREDIT_NOTE_SYNC_FAILURE}); 
            action?.data?.onFailureCallback(result?.message)
        }
    } catch (e) {
        console.log(e)
    }
}
function* getCreditActionsList(action) {
    console.log('saga')
    try {
        const result = yield call(req.getCreditActionsListReq, action);
        console.log('result',result.success)
        
        if (result.success === true) {
            yield put({ type: types.CREDIT_NOTE_ACTION_LIST_SUCCESS,payload: result.data}); 
            // action?.data?.onSuccessCallback()
           
        }else{
            yield put({ type: types.CREDIT_NOTE_ACTION_LIST_FAILURE}); 
            action?.data?.onFailureCallback(result?.message)
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* manageCreditNoteSaga() {
    yield takeLatest(types.GET_CREDIT_NOTE_LIST, getManageCreditList);
    yield takeLatest(types.GET_CREDIT_NOTE_DETAILS, getCreditNoteDetails);
    yield takeLatest(types.ADD_CREDIT_NOTE, addCreditNote);
    yield takeLatest(types.UPDATE_CREDIT_NOTE, updateCreditNote);
    yield takeLatest(types.DELETE_CREDIT_NOTE, deleteCreditNote);
    yield takeLatest(types.PRINT_CREDIT_NOTE_INVOICE, printCreditNoteInvoice);
    yield takeLatest(types.GET_FLAT_WISE_INVOICE_LIST, getFlatWiseInvoiceList)
    yield takeLatest(types.DOWNLOAD_CREDIT_NOTE_EXCEL_SAMPLE, downloadCreditNoteExcelSample)
    yield takeLatest(types.UPLOAD_CREDIT_NOTE_EXCEL, uploadCreditNoteExcel)
    yield takeLatest(types.SAVE_CREDIT_NOTE_EXCEL, saveCreditNoteExcel)
    yield takeLatest(types.CREDIT_NOTE_SYNC, getCreditSync)
    yield takeLatest(types.CREDIT_NOTE_ACTION_LIST, getCreditActionsList)
}